import React, {memo, useReducer} from 'react';
import {Button, Col, Empty, Icon, message, Row} from 'antd';
import {post} from '../../../mc_axios/McAxios';
import './LevelLeaderBoardCard.css';

const initialState = {
  showLeaderboard: false,
  leaderBoardData: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_leaderboard_visibility_and_loading_status':
      return {
        ...state,
        showLeaderboard: true,
        loading: true,
      };
    case 'update_leaderboard_data':
      return {
        ...state,
        leaderBoardData: action.payload,
        loading: false,
      };
    case 'update_loading_status':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

const LevelLeaderBoardCard = memo(() => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const getLeaderboardData = () => {
    post('/api/user-manager/admin/level-leaderboard', {}).
        then(({data}) => {
          if (data.status === true && data.code === 200) {
            const resData = data.data;
            if (resData.hasOwnProperty('level_leaderboard') &&
                resData.level_leaderboard.length > 0) {
              dispatch({
                type: 'update_leaderboard_data',
                payload: resData.level_leaderboard,
              });
            } else dispatch({
              type: 'update_loading_status',
              payload: false,
            });
          } else {
            message.error('Something went wrong while getting data');
            dispatch({
              type: 'update_loading_status',
              payload: false,
            });
          }
        }).
        catch(() => {
          message.error('Something went wrong while getting data');
          dispatch({
            type: 'update_loading_status',
            payload: false,
          });
        });
  };

  const changeLeaderboardVisibility = () => {
    dispatch({
      type: 'update_leaderboard_visibility_and_loading_status',
    });
    getLeaderboardData();
  };

  const {showLeaderboard, leaderBoardData, loading} = state;

  return (
      <div className="level-leaderboard-card">
        {
          showLeaderboard ? (
              loading ? (
                  <Row>
                    <Col span={24}>
                      <h3 className='text-center mc-mt-50'><b><Icon type="loading" className="mc-mr-15"/> Getting leaderboard data...</b></h3>
                    </Col>
                  </Row>
              ) : (
                  leaderBoardData.length === 0 ? (
                      <Row>
                        <Col span={24}>
                          <Empty
                              className="mc-mtb-50"
                              image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                              description={<span>Data not found</span>}
                          />
                        </Col>
                      </Row>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <div className="mc-table-responsive">
                            <table className="mc-table">
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Level</th>
                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                leaderBoardData.map((data, index) => {
                                  const {user: {id, name, email}, level} = data;
                                  const redirectToUserDetail = () => {
                                    window.open('/dashboard/users/' + id,
                                        '_blank');
                                  };
                                  return (
                                      <tr key={id}>
                                        <td>{index + 1}</td>
                                        <td>{name}</td>
                                        <td>{email}</td>
                                        <td>{level}</td>
                                        <td onClick={redirectToUserDetail}>
                                          <Icon
                                              type="solution"
                                              title="Go to user details"
                                          />
                                        </td>
                                      </tr>
                                  );
                                })
                              }
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                  )
              )
          ) : (
              <Row>
                <Col span={24} className="text-center">
                  <Button
                      type="primary"
                      ghost
                      onClick={changeLeaderboardVisibility}
                  >Show Leaderboard</Button>
                </Col>
              </Row>
          )
        }
      </div>
  );
});

export {LevelLeaderBoardCard};
