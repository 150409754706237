import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Col, Divider, Form, Icon, Input, message, Row} from 'antd';
import {McCkEditor, SearchNotes} from '../../../components';
import {post} from '../../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../../components/util';

class CreateMasterNoteForm extends React.Component {
  constructor(props) {
    super(props);

    let nCount = getLocalData('create-master-note-list-count', 1);
    nCount = nCount === '' ? 1 : nCount;

    let nContentList = getLocalData('create-master-note-list', 2);

    if (nContentList.length === 0) {
      nContentList.push({
        title: '',
        note_id: '',
      });
    }

    this.state = {
      masterNoteTitle: getLocalData('create-master-note-title', 1),
      masterNoteIntroduction: getLocalData(
          'create-master-note-introduction',
          1,
      ),
      notesFieldCount: nCount,
      notesFieldContent: nContentList,
    };
  }

  handleNoteTitleChange = (e, index) => {
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent[index].title = e.target.value;

    setLocalData('create-master-note-list', notesFieldContent, 2);

    this.setState({
      notesFieldContent,
    });
  };

  handleNotesChange = (value, option, index) => {
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent[index].note_id = option.props['note-id'];

    setLocalData('create-master-note-list', notesFieldContent, 2);

    this.setState({
      notesFieldContent,
    });
  };

  buildNoteFields = () => {
    const {notesFieldContent, notesFieldCount} = this.state;
    let children = [];
    for (let i = 0; notesFieldCount > i; i++) {
      children.push(
          <Row gutter={16} key={i} className={'mc-mb-10'}>
            <Col span={10}>
              <Input
                  placeholder={'Title'}
                  size={'large'}
                  value={notesFieldContent[i].title}
                  onChange={e => this.handleNoteTitleChange(e, i)}
              />
            </Col>
            <Col span={14}>
              <SearchNotes
                  fieldIndex={i}
                  onSearchNotesChange={this.handleNotesChange}
              />
            </Col>
          </Row>,
      );
    }
    return children;
  };

  addNotesField = () => {
    let newNoteFieldsCount = this.state.notesFieldCount + 1;
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent.push({
      title: '',
      note_id: '',
    });

    setLocalData('create-master-note-list-count', newNoteFieldsCount, 1);
    setLocalData('create-master-note-list', notesFieldContent, 2);

    this.setState({
      notesFieldCount: newNoteFieldsCount,
      notesFieldContent,
    });
  };

  removeNotesField = () => {
    let newNoteFieldsCount = this.state.notesFieldCount - 1;
    let notesFieldContent = this.state.notesFieldContent.slice(0, -1);

    setLocalData('create-master-note-list-count', newNoteFieldsCount, 1);
    setLocalData('create-master-note-list', notesFieldContent, 2);

    this.setState({
      notesFieldCount: newNoteFieldsCount,
      notesFieldContent,
    });
  };

  handleMasterTitleChange = e => {
    let title = e.target.value;
    setLocalData('create-master-note-title', title, 1);
    this.setState({
      masterNoteTitle: title,
    });
  };

  handleMasterIntroductionChange = evt => {
    let introduction = evt.editor.getData();
    setLocalData('create-master-note-introduction', introduction, 1);
    this.setState({
      masterNoteIntroduction: introduction,
    });
  };

  handleSubmit = saveType => {
    const {
      masterNoteTitle,
      masterNoteIntroduction,
      notesFieldContent,
    } = this.state;

    let data = {
      master_title: masterNoteTitle,
      introduction: masterNoteIntroduction,
      status: saveType,
      notes: notesFieldContent,
    };

    post('/api/master-notes/master-notes/create', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        localStorage.clear();
        message.success('Master Note has been created');
        this.props.history.push('/dashboard/master-notes/');
      } else {
        message.error('Something went wrong.');
      }
    });
  };

  render() {
    const {masterNoteTitle, masterNoteIntroduction} = this.state;
    const ButtonGroup = Button.Group;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/master-notes/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create Master Note
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Save
              </Button>
              <Button
                  type={'primary'}
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
              >
                Save & Verify
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    <Input
                        placeholder="Enter title"
                        size={'large'}
                        value={masterNoteTitle}
                        onChange={this.handleMasterTitleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Introduction">
                    <McCkEditor
                        content={masterNoteIntroduction}
                        onMcEditorChange={evt =>
                            this.handleMasterIntroductionChange(evt)
                        }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <p>Add Notes :</p>
                    </Col>
                    <Col span={18} className="text-right">
                      <ButtonGroup size="small">
                        <Button htmlType="button" onClick={this.addNotesField}>
                          +
                        </Button>
                        <Button htmlType="button"
                                onClick={this.removeNotesField}>
                          -
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  {this.buildNoteFields()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const CreateMasterNote = withRouter(
    Form.create({name: 'create_master_note'})(CreateMasterNoteForm));

export {CreateMasterNote};
