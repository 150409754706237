import {post} from '../../components/mc_axios/McAxios';
import {
  FETCH_SEARCH_DATA,
  FETCH_SEARCH_FAILURE,
  FETCH_SEARCH_SUCCESS,
} from '../types';

export function fetchSearchData(tag) {
  return async dispatch => {
    // Initiate loading state
    dispatch({
      type: FETCH_SEARCH_DATA,
    });
    try {
      // Call the API
      const {status, data} = await post('/api/coordinates/coordinates/tags', {
        tag: tag,
      });

      if(status === 200) {
        const tempTags = data.data.tags ?? [];
        const uniqueTags = [...new Set(tempTags)];
        const tagsList = uniqueTags.map(tag => ({
          text: tag,
          value: tag,
        }));
        // Update payload in reducer on success
        dispatch({
          type: FETCH_SEARCH_SUCCESS,
          payload: tagsList,
        });
      } else {
        dispatch({
          type: FETCH_SEARCH_FAILURE,
          error: {},
        });
      }
    } catch (err) {
      // Update error in reducer on failure
      dispatch({
        type: FETCH_SEARCH_FAILURE,
        error: err,
      });
    }
  };
}
