import React, {PureComponent} from 'react';
import {Button, Form, Input, message, Select} from 'antd';
import {post} from '../mc_axios/McAxios';
import {CreateSubCategoryModal} from '../module_components/med_video/CreateSubCategoryModal';

const InputGroup = Input.Group;
const Option = Select.Option;

class SubCategories extends PureComponent {
  state = {
    subCategoryList: [],
    showAddSubCategoryPopup: false,
  };

  handleSubCategorySearch = value => {
    if (value.length > 2) {
      const {key} = this.props.categoryValue;
      post('/api/coordinates/coordinates/subcategory/search', {
        category_id: key,
        sub_category: value,
      }).then(response => {
        if (response.data.status === true) {
          const subcategories = response.data.data.sub_categories;
          if (subcategories !== undefined) {
            this.setState({
              subCategoryList: subcategories,
            });
          }
        }
      });
    }
  };

  showCSCModal = () => {
    this.setState({
      showAddSubCategoryPopup: true,
    });
  };

  saveSCCModalFormRef = formRef => {
    this.cscModalFormRef = formRef;
  };

  handleCSC = () => {
    const {form} = this.cscModalFormRef.props;
    const {categoryValue: {key}} = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      post('/api/coordinates/coordinates/subcategory/add', {
        category_id: key,
        sub_category: values.sub_category_name,
      }).then(response => {
        if (response.hasOwnProperty('data') && response.data.status === true) {
          const {id, sub_category} = response.data.data.sub_category;
          message.success('Sub-Category has been created');
          form.resetFields();
          this.props.onCreateSubCategory({
            key: id,
            label: sub_category,
          });
          this.setState({
            showAddSubCategoryPopup: false,
          });
        }
      });
    });
  };

  handleSCCModalCancel = () => {
    this.setState({
      showAddSubCategoryPopup: false,
    });
  };

  render() {
    const {value, categoryValue, onChange} = this.props;
    const {subCategoryList, showAddSubCategoryPopup} = this.state;

    return (<>
      <Form.Item label="Sub-Category">
        <InputGroup compact>
          <Select
              showSearch
              allowClear
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              labelInValue
              placeholder="Select Sub-Category"
              value={value}
              onSearch={this.handleSubCategorySearch}
              onChange={onChange}
              disabled={categoryValue === undefined}
              size={'large'}
              className="category-input"
          >
            {subCategoryList.map(
                d => <Option key={d.id}>{d.sub_category}</Option>)}
          </Select>
          <Button
              htmlType="button"
              size="large"
              disabled={categoryValue === undefined}
              style={{width: 70}}
              onClick={this.showCSCModal}
          >Add</Button>
        </InputGroup>
      </Form.Item>
      <CreateSubCategoryModal
          wrappedComponentRef={this.saveSCCModalFormRef}
          visible={showAddSubCategoryPopup}
          onCreate={this.handleCSC}
          onCancel={this.handleSCCModalCancel}
      />
    </>);
  }
}

export {SubCategories};
