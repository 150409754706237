import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';
import SearchReducer from './search_reducer/index';
import QbankReducer from './qbank_reducer/index';
import NotesReducer from './notes_reducer/index';
import MasterNotesReducer from './master_notes_reducer/index';
import MedChallengeReducer from './medchallenge_reducer/index';
import PWChallengeReducer from './pw_challenge_reducer';
import PrepMedReducer from './prepmed_reducer/index';
import UtilReducer from './util_reducer/UtilReducer';

export default combineReducers({
  auth: AuthReducer,
  qbank: QbankReducer,
  notes: NotesReducer,
  masterNotes: MasterNotesReducer,
  search: SearchReducer,
  medchallenge: MedChallengeReducer,
  pwchallenge: PWChallengeReducer,
  prepmed: PrepMedReducer,
  util: UtilReducer,
});
