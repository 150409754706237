import {CHANGE_USER_ROLE, STORE_USER_ID} from '../actions/types';

const INITIAL_STATE = {
  userId: null,
  userRole: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_USER_ID:
      return {...state, userId: action.payload};
    case CHANGE_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
};
