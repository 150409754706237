import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  Spin,
  Upload,
} from 'antd';
import {McCkEditor} from '../../../components';
import {post} from '../../../components/mc_axios/McAxios';
import {
  arrayConcat, debounce,
  getSubjectList,
  getSystemList,
  slugify,
} from '../../../components/util';

const {TextArea} = Input;

class CreateMedpixxForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: '',
      subjectsList: [],
      systemsList: [],
      tagsList: [],
      fetching: false,
      reference: '',
    };
  }

  componentDidMount() {
    getSubjectList(subjects => {
      this.setState({
        subjectsList: subjects,
      });
    });

    getSystemList(systems => {
      this.setState({
        systemsList: systems,
      });
    });
  }

  getTagList = debounce(value => {
    this.setState({tagsList: [], fetching: true});
    post('/api/coordinates/coordinates/tags', {tag: value}).then(response => {
      if (response.status === 200) {
        const tempTags = response.data.data.tags ?? [];
        const uniqueTags = [...new Set(tempTags)];
        const tagsList = uniqueTags.map(tag => ({
          text: tag,
          value: tag,
        }));
        this.setState({tagsList, fetching: false});
      }
    });
  }, 1000);

  fillMetaTitleSlug = e => {
    let slugifyTitle = slugify(e.target.value);
    this.props.form.setFieldsValue({
      meta_title: e.target.value,
      slug: slugifyTitle,
    });
  };

  fillMetaDescription = e => {
    this.props.form.setFieldsValue({
      meta_description: e.target.value,
    });
  };

  fillMetaKeywords = value => {
    const {getFieldValue, setFieldsValue} = this.props.form;
    let keywords = value.map(d => d.key);
    let metaKeyWords = getFieldValue('meta_keywords') === undefined ?
        [] :
        getFieldValue('meta_keywords');
    setFieldsValue({
      meta_keywords: arrayConcat(keywords, metaKeyWords),
    });
  };

  handleSubmit = saveType => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const {description} = this.state;
      let list = [];
      if (values.tags !== undefined) {
        let tags = values.tags;
        for (let j = 0; j < tags.length; j++) {
          list.push(tags[j].key);
        }
      }
      values.tags = list;
      values.medpixx_image_url = values['file'].file.response.data.file_url;
      values.medpixx_image_alt_text =
          values['file'].file.response.data.alt_text;
      delete values['file'];
      values.description = description;
      values.status = parseInt(saveType);
      values.reference = this.state.reference;
      post('/api/medpixx/images/create', values).then(response => {
        if (response.data.status === true) {
          message.success('medPixx has been created');
          this.props.history.push('/dashboard/medpixx/');
        } else {
          message.success('Something went wrong. Try again.');
        }
      });
    });
  };

  handleDescriptionChange(description) {
    this.setState({
      description,
    });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const Option = Select.Option;
    const Dragger = Upload.Dragger;
    const {description, subjectsList, systemsList, tagsList, fetching} = this.state;

    const props = {
      name: 'file',
      action: '/api/media/upload',
      data: {
        product_type: 2,
      },
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
        <Form>
          <Row gutter={16} className="main-content-block">
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/medpixx/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create MedPixx
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Button
                  type="default"
                  htmlType="button"
                  className="mc-mr-5"
                  onClick={() => this.handleSubmit(1)}
              >
                Save
              </Button>
              <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
              >
                Save & Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    {getFieldDecorator('title', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input the title!',
                        },
                      ],
                    })(
                        <Input
                            placeholder="Enter medpixx title"
                            size={'large'}
                            onBlur={this.fillMetaTitleSlug}
                        />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Subject">
                    {getFieldDecorator('subject')(
                        <Select
                            mode="multiple"
                            placeholder="Select subject"
                            size={'large'}
                            showSearch
                            optionFilterProp="children"
                        >
                          {subjectsList.map(function(subject, index) {
                            return (
                                <Option value={subject} key={index}>
                                  {subject}
                                </Option>
                            );
                          })}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="System">
                    {getFieldDecorator('system')(
                        <Select
                            mode="multiple"
                            placeholder="Select system"
                            size={'large'}
                            showSearch
                            optionFilterProp="children"
                        >
                          {systemsList.map(function(system, index) {
                            return (
                                <Option value={system} key={index}>
                                  {system}
                                </Option>
                            );
                          })}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Tags">
                    {getFieldDecorator('tags')(
                        <Select
                            mode="tags"
                            labelInValue
                            placeholder="Enter tags"
                            notFoundContent={fetching ?
                                <Spin size="small"/> :
                                null}
                            filterOption={true}
                            onSearch={this.getTagList}
                            onBlur={this.fillMetaKeywords}
                            size={'large'}
                        >
                          {tagsList.map(d => (
                              <Option key={d.value}>{d.text}</Option>
                          ))}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={evt =>
                            this.handleDescriptionChange(evt.editor.getData())
                        }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Image">
                    {getFieldDecorator('file', {
                      rules: [
                        {
                          required: true,
                          message: 'Image is required',
                        },
                      ],
                    })(
                        <Dragger {...props} listType="picture">
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly
                            prohibit
                            from uploading company data or other band files
                          </p>
                        </Dragger>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <Row>
                <Col span={24}>
                  <Form.Item label="Reference">
                    <McCkEditor
                        type={1}
                        content={this.state.reference}
                        onMcEditorChange={evt =>
                            this.setState({
                              reference: evt.editor.getData(),
                            })
                        }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="seo-section">
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      {getFieldDecorator('meta_title', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter meta title',
                          },
                        ],
                      })(<Input placeholder="Enter meta title"
                                size={'large'}/>)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      {getFieldDecorator('slug', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter slug',
                          },
                        ],
                      })(<Input size={'large'}/>)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      {getFieldDecorator('meta_description', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter meta description',
                          },
                        ],
                      })(<TextArea rows={4}/>)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      {getFieldDecorator('meta_keywords', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter alteast one keyword',
                          },
                        ],
                      })(
                          <Select
                              mode="tags"
                              placeholder="Enter keywords"
                              size={'large'}
                          />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

const CreateMedpixx = withRouter(
    Form.create({name: 'create_medpixx'})(CreateMedpixxForm));

export {CreateMedpixx};
