import {
  RESET_TEST_DATA,
  TEST_BASIS_CHANGE,
  TEST_CORRECT_QUESTION_MARKS_CHANGE,
  TEST_DESCRIPTION_CHANGE,
  TEST_END_DATE_CHANGE,
  TEST_FEATURED_IMAGE_CHANGE,
  TEST_IS_PREMIUM_CHANGE,
  TEST_MODE_CHANGE,
  TEST_SEO_META_DESCRIPTION_CHANGE,
  TEST_SEO_META_KEYWORDS_CHANGE,
  TEST_SEO_META_TITLE_CHANGE,
  TEST_SEO_SLUG_CHANGE,
  TEST_SERUM_CHANGE,
  TEST_START_DATE_CHANGE,
  TEST_TITLE_CHANGE,
  TEST_TOTAL_QUESTIONS_CHANGE,
  TEST_TOTAL_TIME_CHANGE,
  TEST_WRONG_QUESTION_MARKS_CHANGE,
} from '../../../actions/types';
import {getLocalData} from '../../../components/util';

let isPremium = getLocalData('create-test-is-premium', 1);
isPremium = isPremium !== '' && isPremium === 'true';

let testBasis = getLocalData('create-test-basis', 3);
testBasis = testBasis !== 0 ? testBasis : 1;

let testMode = getLocalData('create-test-mode', 3);

let totalQuestions = getLocalData('create-test-total-questions', 1);
totalQuestions = totalQuestions !== '' ? totalQuestions : 0;

let totalTime = getLocalData('create-test-tt', 1);
totalTime = totalTime !== '' ? totalTime : 0;

const initialState = {
  title: getLocalData('create-test-title', 1),
  isPremium,
  basis: testBasis,
  mode: testMode,
  totalQuestions: totalQuestions,
  totalTime,
  correctQuestionMarks: getLocalData('create-test-cqm', 3),
  wrongQuestionMarks: getLocalData('create-test-wqm', 3),
  startDate: getLocalData('create-test-start-date', 1),
  endDate: getLocalData('create-test-end-date', 1),
  serum: getLocalData('create-test-serum', 3),
  description: getLocalData('create-test-description', 1),
  featuredImageUrl: getLocalData('create-test-featured-image-url', 1),
  featuredImageAltText: getLocalData('create-test-featured-image-alt-text', 1),
  explanationVideoUrl: '',
  explanationVideoAltText: '',
  isExplanationVideoPremium: false,
  masterNoteId: '',
  isMasterNotePremium: false,
  seoMetaTitle: getLocalData('create-test-meta-title', 1),
  seoSlug: getLocalData('create-test-meta-slug', 1),
  seoMetaDescription: getLocalData('create-test-meta-description', 1),
  seoMetaKeywords: getLocalData('create-test-meta-keywords', 2),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEST_TITLE_CHANGE:
      let payload = action.payload;
      return {
        ...state,
        title: payload !== undefined ? payload.title : '',
        seoMetaTitle: payload !== undefined ? payload.metaTitle : '',
        seoSlug: payload !== undefined ? payload.slug : '',
      };
    case TEST_IS_PREMIUM_CHANGE:
      return {
        ...state,
        isPremium: action.payload,
      };
    case TEST_BASIS_CHANGE:
      return {
        ...state,
        basis: action.payload,
      };
    case TEST_MODE_CHANGE:
      return {
        ...state,
        mode: action.payload,
      };
    case TEST_TOTAL_QUESTIONS_CHANGE:
      return {
        ...state,
        totalQuestions: action.payload,
      };
    case TEST_TOTAL_TIME_CHANGE:
      return {
        ...state,
        totalTime: action.payload,
      };
    case TEST_CORRECT_QUESTION_MARKS_CHANGE:
      return {
        ...state,
        correctQuestionMarks: action.payload,
      };
    case TEST_WRONG_QUESTION_MARKS_CHANGE:
      return {
        ...state,
        wrongQuestionMarks: action.payload,
      };
    case TEST_START_DATE_CHANGE:
      return {
        ...state,
        startDate: action.payload,
      };
    case TEST_END_DATE_CHANGE:
      return {
        ...state,
        endDate: action.payload,
      };
    case TEST_SERUM_CHANGE:
      return {
        ...state,
        serum: action.payload,
      };
    case TEST_DESCRIPTION_CHANGE:
      return {
        ...state,
        description: action.payload,
      };
    case TEST_FEATURED_IMAGE_CHANGE:
      return {
        ...state,
        featuredImageUrl:
            action.payload === undefined ? '' : action.payload.imgUrl,
        featuredImageAltText:
            action.payload === undefined ? '' : action.payload.imgAltText,
      };
    case TEST_SEO_META_TITLE_CHANGE:
      return {
        ...state,
        seoMetaTitle: action.payload,
      };
    case TEST_SEO_SLUG_CHANGE:
      return {
        ...state,
        seoSlug: action.payload,
      };
    case TEST_SEO_META_DESCRIPTION_CHANGE:
      return {
        ...state,
        seoMetaDescription: action.payload,
      };
    case TEST_SEO_META_KEYWORDS_CHANGE:
      return {
        ...state,
        seoMetaKeywords: action.payload,
      };
    case RESET_TEST_DATA:
      return {
        ...state,
        title: '',
        isPremium: false,
        basis: 1,
        mode: 0,
        totalQuestions: 0,
        totalTime: 0,
        startDate: '',
        endDate: '',
        serum: 0,
        description: '',
        featuredImageUrl: '',
        featuredImageAltText: '',
        seoMetaTitle: '',
        seoSlug: '',
        seoMetaDescription: '',
        seoMetaKeywords: [],
      };
    default:
      return state;
  }
};
