import React from 'react';
import {Button, Col, Empty, message, Pagination, Row} from 'antd';
import {Link} from 'react-router-dom';
import {post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import './Slider.css';

class Slider extends React.Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('slider-page-number', 1);
    lastVisitedPageNum = lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('slider-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let sliderStatus = getLocalData('slider-status', 1);
    sliderStatus = sliderStatus !== '' ? parseInt(sliderStatus) : 0;

    this.state = {
      slides: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      sliderStatus,
      sliderSearchTerm: getLocalData('slider-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }), () => this.getSlides(),
    );
  }

  getSlides = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      sliderStatus,
      sliderSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      status: parseInt(sliderStatus),
      search_term: sliderSearchTerm,
    };

    post('/api/coordinates/coordinates/sliders', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('sliders')) {
          this.setState({
            slides: response.data.data.sliders,
            totalItems: response.data.data.total_count,
          });
        }
      } else message.error('Something went wrong! Try again.');
    });
  };

  handleItemsCount = value => {
    setLocalData('slider-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getSlides();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('slider-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          sliderStatus: value,
        },
        () => {
          this.getSlides();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('slider-search-term', value, 1);
    this.setState({
      sliderSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getSlides();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('slider-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getSlides();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          sliderStatus: 0,
          sliderSearchTerm: '',
        },
        () => {
          setLocalData('slider-items-per-page', 25, 1);
          setLocalData('slider-page-number', 1, 1);
          setLocalData('slider-status', 0, 1);
          setLocalData('slider-search-term', '', 1);
          this.getSlides();
        },
    );
  };

  render() {
    const {
      slides,
      currentPageNumber,
      itemsPerPage,
      sliderStatus,
      sliderSearchTerm,
      totalItems,
    } = this.state;

    return (<>
      <div className="secondary-header">
        <Row>
          <Col span={12} className="secondary-header-left">
            <p>Slider</p>
          </Col>
          <Col span={12} className="secondary-header-right">
            <Button type="primary" className="mc-mr-10" htmlType="button">
              <Link to={'/dashboard/slider/create-slide/'}>Create Slide</Link>
            </Button>
            <Button className="mc-mr-10" htmlType="button">
              <Link to={'/dashboard/slider/homepage-slider/'}>View Homepage
                Slider</Link>
            </Button>
          </Col>
        </Row>
      </div>
      <div className="main-content-block">
        <Filters
            countValue={itemsPerPage}
            onItemsCountChange={this.handleItemsCount}
            statusValue={sliderStatus}
            onStatusChange={this.handleStatusChange}
            searchTermValue={sliderSearchTerm}
            onSearchTermChange={this.handleSearchTermChange}
            onSearchTermSubmit={this.handleSearchTermSubmit}
            onFiltersReset={this.resetFilters}
        />
        {slides !== undefined && slides.length > 0 ? (
            <Row gutter={16} className="mc-mt-10 slider-grid">
              {slides.map((data, index) => (<Col span={6} key={index}>
                    <Link to={`/dashboard/slider/edit/${data.slider_id}`}>
                      <div className="slider-image">
                        <img
                            src={data.mobile_image}
                            alt={data.alt_text}
                        />
                      </div>
                    </Link>
                  </Col>
              ))}
              <Col span={24}>
                <br/>
                <Pagination
                    current={currentPageNumber}
                    pageSize={itemsPerPage}
                    total={totalItems}
                    onChange={this.onPageChange}
                />
              </Col>
            </Row>
        ) : (<Row gutter={16}>
          <Col span={24}>
            <Empty
                className="mc-mtb-100"
                image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                description={<span>No slides found</span>}
            />
          </Col>
        </Row>)}
      </div>
    </>);
  }
}

export {Slider};
