import React, {Component} from 'react';
import './Video.css';

class Video extends Component {
  render() {
    return (
        <div className="mc-video-section">
          <video
              src={this.props.videoSrc}
              poster={this.props.videoPoster}
              controls
          />
        </div>
    );
  }
}

export {Video};
