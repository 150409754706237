import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Avatar,
  Button,
  Col,
  Comment,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import moment from 'moment';
import {get, post} from '../../../components/mc_axios/McAxios';

const styles = {
  viewReplies: {
    fontSize: 11,
    color: 'blue',
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: 12,
    right: 8,
    zIndex: 5,
    cursor: 'pointer',
  },
};

const selectedDomain = process.env.NODE_ENV === 'development' ?
    'http://uat.medcampus.io' :
    'https://www.medcampus.io';

class CommentDetailedPre extends React.Component {

  state = {
    commentData: null,
    seenComment: false,
    replyComment: '',
    showReplyModal: false,
    repliesData: null,
  };

  componentDidMount() {
    this.getCommentData();
  }

  getCommentData = () => {
    const {commentId} = this.props.match.params;
    get('/api/backtalks/backtalks/' + commentId).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('back_talk')) {
          this.setState({
            commentData: resData.back_talk,
            seenComment: resData.back_talk.seen || false,
          }, () => this.getCommentReplies());
        }
      } else message.error('Something went wrong! Try again.');
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  getCommentReplies = () => {
    const {backtalk_id, message, product_type} = this.state.commentData;
    const data = {
      first: 100,
      page_number: 1,
      product_type,
      reply_to_id: backtalk_id,
    };
    post('/api/backtalks/backtalks/replies', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('backtalks')) {
          this.setState({
            repliesData: resData,
          });
        }
      }
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  getProductType = prodType => {
    switch (prodType) {
      case 1:
        return 'infoMed';
      case 2:
        return 'medPixx';
      case 3:
        return 'mVideo';
      case 4:
        return 'challenges';
      case 5:
        return 'qBank';
      case 6:
        return 'notes';
      case 7:
        return 'prepMed';
      default:
        return 'Unknown';
    }
  };

  handleReplyCommentChange = e => {
    const replyComment = e.target.value;
    this.setState({
      replyComment,
    });
  };

  replyComment = () => {
    const {commentData: {backtalk_id, post_id, product_type}, replyComment} = this.state;
    if (replyComment !== '') {
      const data = {
        post_id,
        product_type: product_type,
        reply_to_id: backtalk_id,
        message: replyComment,
      };
      post('/api/backtalks/backtalks/reply', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          message.success('Reply sent successfully');
          this.setState({
            replyComment: '',
          });
        } else message.error('Something went wrong. Please try again.');
      }).catch(() => message.error('Something went wrong'));
    } else message.warning('Reply message should not be empty');
  };

  handleSeenStatus = () => {
    const {commentData: {backtalk_id, product_type}, seenComment} = this.state;
    const data = {
      id: backtalk_id,
      product_type,
      seen: !seenComment,
    };
    post('/api/backtalks/backtalks/mark_as_seen', data).then(response => {
      this.setState({
        seenComment: !seenComment,
      });
    }).catch(() => message.error('Something went wrong'));
  };

  publishComment = () => {
    this.handleCommentPublishStatus(1);
  };

  unpublishComment = () => {
    this.handleCommentPublishStatus(3);
  };

  handleCommentPublishStatus = status => {
    const {backtalk_id, product_type} = this.state.commentData;
    const data = {
      back_talk_id: backtalk_id,
      product_type,
      back_talk_type: 0,
      status,
    };
    post('/api/backtalks/backtalks/update_status', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Status updated successfully');
        this.setState(state => ({
          commentData: {
            ...state.commentData,
            status,
          },
        }));
      } else message.error('Something went wrong');
    }).catch(() => message.error('Something went wrong'));
  };

  handleReplyPublishStatus = (replyId, replyIndex, status) => {
    const {product_type} = this.state.commentData;
    const data = {
      back_talk_id: replyId,
      product_type,
      back_talk_type: 1,
      status,
    };
    post('/api/backtalks/backtalks/update_status', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Status updated successfully');
        this.setState(state => {
          let tempRepliesData = state.repliesData;
          tempRepliesData.backtalks[replyIndex].status = status;
          return {
            repliesData: tempRepliesData,
          };
        });
      } else message.error('Something went wrong');
    }).catch(() => message.error('Something went wrong'));
  };

  showModal = () => {
    this.setState({
      showReplyModal: true,
    });
  };

  handleOk = () => {
    this.setState({
      showReplyModal: false,
    });
  };

  handleCancel = () => {
    this.setState({
      showReplyModal: false,
    });
  };

  goToPost = () => {
    const {post_id, product_type} = this.state.commentData;
    let url;
    switch (product_type) {
      case 1:
        url = '/mnotes/sample-' + post_id;
        break;
      case 2:
        url = '/medpixx/sample-' + post_id;
        break;
      case 3:
        url = '/mvideos/sample-123456789/sample-123456789/sample-' + post_id;
        break;
      case 4:
        url = '/challenges/sample-' + post_id;
        break;
      case 7:
        url = '/prepmed/test/sample-' + post_id;
        break;
      case 5:
      case 6:
      default:
        url = '';
        break;
    }
    window.open(selectedDomain + url, '_blank');
  };

  render() {
    const {commentData, repliesData, seenComment, replyComment, showReplyModal} = this.state;

    if (commentData === null) return null;

    const {message, product_type, status, created_at} = commentData;

    return (
        <>
          <Row className="main-content-block" gutter={16}>
            <Col span={24}>
              <Form>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <div className="page-header">
                      <Link to={'/dashboard/comments/'}>
                        <Icon type="arrow-left" title={'Back'}/>
                      </Link>
                      <Divider type="vertical"/> Comment
                    </div>
                  </Col>
                  <Col xs={24} sm={12} className="text-right">
                    <Switch
                        className="mc-ml-15"
                        checked={seenComment}
                        checkedChildren="Seen"
                        unCheckedChildren="Unseen"
                        size="large"
                        onChange={this.handleSeenStatus}
                    />
                    <Button className="mc-ml-15" onClick={this.goToPost}>Go to
                      Post</Button>
                    {
                      status === 1 ? <Button
                          type="primary"
                          className="mc-ml-15"
                          onClick={this.unpublishComment}
                      >Disapprove</Button> : <Button
                          type="primary"
                          className="mc-ml-15"
                          onClick={this.publishComment}
                      >Approve</Button>
                    }
                  </Col>
                  <Col span={24}>
                    <hr className="mc-mt-10"/>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Row gutter={16}>
                      <Col xs={24} sm={12}>
                        <Form.Item label="Module">
                          <Input
                              size="large"
                              value={this.getProductType(product_type)}
                              disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item label="Date">
                          <Input
                              size="large"
                              value={moment.unix(created_at).
                                  format('DD/MM/YYYY')}
                              disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <p style={styles.viewReplies}
                           onClick={this.showModal}>View Replies</p>
                        <Form.Item label="Comment">
                          <Input.TextArea
                              rows={4}
                              size="large"
                              value={message}
                              disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item label="Reply">
                          <Input.TextArea
                              rows={9}
                              size="large"
                              value={replyComment}
                              onChange={this.handleReplyCommentChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="text-right">
                        <Form.Item>
                          <Button
                              type="primary"
                              size="large"
                              onClick={this.replyComment}
                          >Send</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Modal
              title="Replies"
              visible={showReplyModal}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
          >
            {
              repliesData === null ? (
                  <p className="text-center">No replies found</p>
              ) : (
                  repliesData.backtalks.map((backtalk, index) => {
                    const {backtalk_id: replyBackTalkId, message: replyMessage, status, created_at} = backtalk;
                    const replyDate = moment(created_at).
                        format('DD-MM-YYYY HH:mm:ss');
                    const replyFromNow = moment(created_at).fromNow();
                    const handlePublishStatus = () => {
                      if (status === 1) {
                        this.handleReplyPublishStatus(replyBackTalkId, index,
                            3);
                      } else this.handleReplyPublishStatus(replyBackTalkId,
                          index, 1);
                    };
                    return (
                        <Comment
                            key={replyBackTalkId}
                            author={<a>User</a>}
                            avatar={
                              <Avatar
                                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                  alt="Han Solo"
                              />
                            }
                            content={<p>{replyMessage}</p>}
                            datetime={
                              <Tooltip title={replyDate}>
                                <span>{replyFromNow}</span>
                              </Tooltip>
                            }
                            actions={[
                              <span onClick={handlePublishStatus}>{status ===
                              1 ?
                                  'Unpublish' :
                                  'Publish'}</span>]}
                        />
                    );
                  })
              )
            }
          </Modal>
        </>
    );
  }
};

const CommentDetailed = withRouter(CommentDetailedPre);

export {CommentDetailed};
