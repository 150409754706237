import React, {Component} from 'react';

class UpscVideos extends Component {
  render() {
    return (
        <>
        </>
    );
  }
}

export {UpscVideos};
