import React, {useEffect, useReducer} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Avatar, Button, Col, Divider, Empty, Icon, message, Row} from 'antd';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import {UserMedBook} from '../../../components/user_medbook/UserMedBook';
import {get} from '../../../components/mc_axios/McAxios';
import {getAvatarUrl} from '../../../components/util';
import './UserDetailed.css';

const emailCopiedAlert = () => {
  message.success('Email copied to clipboard');
};

const mobileNumberCopiedAlert = () => {
  message.success('Mobile number copied to clipboard');
};

const initialState = {
  userData: null,
  isExtraInfoVisible: false,
  userDataLoading: true,
  isAnalysisVisible: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_user_data':
      return {
        ...state,
        userData: action.payload,
        userDataLoading: false,
      };
    case 'update_show_extra_info_status':
      return {
        ...state,
        isExtraInfoVisible: !state.isExtraInfoVisible,
      };
    case 'update_user_data_loading_status':
      return {
        ...state,
        userDataLoading: action.payload,
      };
    case 'update_show_medbook_info_status':
      return {
        ...state,
        isAnalysisVisible: !state.isAnalysisVisible,
      };
    default:
      return state;
  }
};

const UserDetailed = () => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const {userId} = useParams();

  useEffect(() => {
    getUserData();
  }, [userId]);

  const getUserData = () => {
    get('/api/user-manager/admin/user/' + userId).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('user_info')) {
          dispatch({
            type: 'update_user_data',
            payload: resData.user_info,
          });
        } else dispatch({
          type: 'update_user_data',
          payload: null,
        });
      } else dispatch({
        type: 'update_user_data_loading_status',
        payload: false,
      });
    }).catch(() => {
      dispatch({
        type: 'update_user_data_loading_status',
        payload: false,
      });
    });
  };

  const showExtraInformation = () => {
    dispatch({
      type: 'update_show_extra_info_status',
    });
  };

  const showMedBookInformation = () => {
    dispatch({
      type: 'update_show_medbook_info_status',
    });
  };

  const {userData, isExtraInfoVisible, userDataLoading, isAnalysisVisible} = state;

  if (userDataLoading) return (
      <Row>
        <Col span={24}>
          <h3 className='text-center mc-mt-100'>
            <b><Icon type="loading" className="mc-mr-15"/> Loading...</b>
          </h3>
        </Col>
      </Row>
  );

  if (userData === null) {
    return (
        <div className="main-content-block">
          <Row gutter={16}>
            <Col span={24}>
              <Empty
                  className="mc-mtb-100"
                  image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                  description={<span>User data not found</span>}
              />
            </Col>
          </Row>
        </div>
    );
  }

  const {name, user_name, avatar, email = '--', email_verified, premium = false, contact_number, country_data: {dial_code, name: countryName = '--'}, state: userState = '--', qualification = '--', specialization = '--', university = '--', mbbs_percentage = '--', created_at} = userData;

  const mobileNumberText = contact_number ?
      '+' + dial_code + '-' + contact_number :
      '--';
  const registeredDate = created_at ?
      moment.unix(created_at).format('DD MMMM, YYYY') :
      '--';

  return (
      <>
        <div className="main-content-block">
          <Row gutter={32}>
            <Col span={24}>
              <div className="page-header">
                <Link to={'/dashboard/users/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> User Information
              </div>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
          </Row>
          <div className="userInfo">
            <Row gutter={16}>
              <Col xs={24} sm={10} className="text-center">
                <Avatar size={100} src={getAvatarUrl(avatar)}/>
                <p className="userInfo__userFullName"
                   title="Full Name">{name}</p>
                <p className="userInfo__userName"
                   title="Username">{user_name}</p>
              </Col>
              <Col xs={24} sm={14}>
                <Row gutter={32}>
                  <Col span={6}>
                    <h4>Email Id</h4>
                  </Col>
                  <Col span={18}>
                    <h4 className="ww-bw">:
                      <span className="mc-ml-15">
                      {email}
                        {
                          email_verified && (
                              <Icon
                                  className="blue-text mc-ml-5"
                                  title="Verified"
                                  type="check-circle"
                              />
                          )
                        }
                        {
                          email !== '--' && (
                              <CopyToClipboard
                                  text={email}
                                  onCopy={emailCopiedAlert}
                              >
                                <Icon
                                    type="copy"
                                    className="blue-text mc-ml-5"
                                />
                              </CopyToClipboard>
                          )
                        }
                    </span>
                    </h4>
                  </Col>
                </Row>
                <Row gutter={32} className="mc-mt-10">
                  <Col span={6}>
                    <h4>Mobile Number</h4>
                  </Col>
                  <Col span={18}>
                    <h4 className="ww-bw">:
                      <span className="mc-ml-15">
                      {mobileNumberText}
                        {
                          contact_number !== undefined && (
                              <CopyToClipboard
                                  text={mobileNumberText}
                                  onCopy={mobileNumberCopiedAlert}
                              >
                                <Icon
                                    type="copy"
                                    className="blue-text mc-ml-5"
                                />
                              </CopyToClipboard>
                          )
                        }
                    </span>
                    </h4>
                  </Col>
                </Row>
                <Row gutter={32} className="mc-mt-10">
                  <Col span={6}>
                    <h4>Subscription</h4>
                  </Col>
                  <Col span={18}>
                    <h4>: <span className="mc-ml-15">{premium ?
                        'Premium' :
                        'Freemium'}</span></h4>
                  </Col>
                </Row>
                <Row gutter={32} className="mc-mt-10">
                  <Col span={6}>
                    <h4>Qualification</h4>
                  </Col>
                  <Col span={18}>
                    <h4>: <span className="mc-ml-15">{qualification}</span></h4>
                  </Col>
                </Row>
                {
                  isExtraInfoVisible && (
                      <>
                        <Row gutter={32} className="mc-mt-10">
                          <Col span={6}>
                            <h4>Specialization</h4>
                          </Col>
                          <Col span={18}>
                            <h4>: <span
                                className="mc-ml-15">{specialization}</span>
                            </h4>
                          </Col>
                        </Row>
                        <Row gutter={32} className="mc-mt-10">
                          <Col span={6}>
                            <h4>University</h4>
                          </Col>
                          <Col span={18}>
                            <h4>: <span className="mc-ml-15">{university}</span>
                            </h4>
                          </Col>
                        </Row>
                        <Row gutter={32} className="mc-mt-10">
                          <Col span={6}>
                            <h4>MBBS Percentage</h4>
                          </Col>
                          <Col span={18}>
                            <h4>: <span
                                className="mc-ml-15">{mbbs_percentage}</span>
                            </h4>
                          </Col>
                        </Row>
                        <Row gutter={32} className="mc-mt-10">
                          <Col span={6}>
                            <h4>State</h4>
                          </Col>
                          <Col span={18}>
                            <h4>: <span className="mc-ml-15">{userState}</span>
                            </h4>
                          </Col>
                        </Row>
                        <Row gutter={32} className="mc-mt-10">
                          <Col span={6}>
                            <h4>Country</h4>
                          </Col>
                          <Col span={18}>
                            <h4>: <span
                                className="mc-ml-15">{countryName}</span></h4>
                          </Col>
                        </Row>
                        <Row gutter={32} className="mc-mt-10">
                          <Col span={6}>
                            <h4>Registered Date</h4>
                          </Col>
                          <Col span={18}>
                            <h4>: <span
                                className="mc-ml-15">{registeredDate}</span>
                            </h4>
                          </Col>
                        </Row>
                      </>
                  )
                }
                <Button
                    type="primary"
                    ghost
                    className="mc-mt-15"
                    onClick={showExtraInformation}
                >{isExtraInfoVisible ? 'Hide' : 'Show more'}</Button>
              </Col>
            </Row>
          </div>
        </div>
        {
          isAnalysisVisible ? <UserMedBook userId={userId}/> : (
              <div className="main-content-block">
                <Row gutter={16}>
                  <Col span={24} className="text-center">
                    <Button
                        type="primary"
                        ghost
                        size="large"
                        onClick={showMedBookInformation}
                    >Show medBook data</Button>
                  </Col>
                </Row>
              </div>
          )
        }
      </>
  );
};

export {UserDetailed};
