import {createSelector} from "reselect";

const getUtilDataSelector = state => state.util;
const getAuthDataSelector = state => state.auth;
const getUserIdSelector = state => state.auth.userId;
const getUserRoleSelector = state => state.auth.userRole;

export const getUtilDataReselector = createSelector(getUtilDataSelector, util => util);
export const getAuthDataReselector = createSelector(getAuthDataSelector, auth => auth);
export const getUserIdReselector = createSelector(getUserIdSelector, userId => userId);
export const getUserRoleReselector = createSelector(getUserRoleSelector, userRole => userRole);

// PrepMed Selectors
const getCreateTestDataSelector = state => state.prepmed.createTest;
const getEditTestDataSelector = state => state.prepmed.editTest;

export const getCreateTestDataReselector = createSelector(getCreateTestDataSelector, createTest => createTest);
export const getEditTestDataReselector = createSelector(getEditTestDataSelector, editTest => editTest);
