import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css';
import './UpdateAdminData.css';
import {get, post} from '../../components/mc_axios/McAxios';

const Option = Select.Option;

const styles = {
  otpInput: {
    width: 40,
    height: 40,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'gray',
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  otpInputFocused: {
    borderColor: '#00155b',
    outline: 'none',
  },
};

class UpdateAdminDataPre extends React.Component {

  state = {
    countryData: {
      countryCode: 'in',
      dialCode: '91',
      name: 'India',
    },
    mobileNumber: '',
    isMobileNumberVerified: false,
    showVerifyModal: false,
    otp: '',
    timer: 30,
  };

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    get('/api/user-manager/admin/instructor/instructor-info').
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            const {name, user_name, email} = response.data.data.user;
            this.props.form.setFieldsValue({
              name: name ?? '',
              user_name: user_name ?? '',
              email: email ?? '',
            });
          }
        }).
        catch(errors => console.log(errors));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {isMobileNumberVerified} = this.state;
        if (isMobileNumberVerified) {
          values.avatar = "";
          values.batch = "";
          values.state = "";
          values.category = "";
          values.mbbs_percentage = 60.5;

          post('/api/user-manager/admin/update-admin-profile', values).
              then(response => {
                if (response.data.status === true) {
                  message.success('Successfully updated profile.');
                  window.location.href = '/';
                }
              }).
              catch(() => message.error('Something went wrong.'));
        }
      } else message.warning(
          'You need to verify your mobile number to continue');
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleMobileNumberChange = (mobileNumber, countryData) => {
    this.setState({
      countryData,
    });
  };

  sendOtp = () => {
    const {countryData, mobileNumber} = this.state;
    post('/api/user-manager/admin/send-otp', {country_data: countryData, contact_number: mobileNumber}).then(response => {
      this.setState({
        showVerifyModal: true,
      }, () => {
        message.success("OTP sent successfully");
        this.startTimer();
      });
    }).catch(() => {
      message.error('Something went wrong');
    });
  };

  handleOtpInput = otp => {
    this.setState({
      otp,
    });
  };

  handleOtpSubmit = () => {
    const {otp} = this.state;
    if (otp.length === 4) {
      post('/api/user-manager/admin/verify-otp', {otp: parseInt(otp, 10)}).
          then(response => {
            if (response.status === 200) {
              clearInterval(this._interval);
              this.setState({
                isMobileNumberVerified: true,
                showVerifyModal: false,
                timer: 30,
              });
            } else message.error('OTP is incorrect');
          }).
          catch(() => message.error('OTP is incorrect'));
    }
  };

  closeVerifyModal = () => {
    clearInterval(this._interval);
    this.setState({
      showVerifyModal: false,
      timer: 30,
    });
  };

  handleResend = () => {
    this.setState({
      timer: 30,
    }, () => {
      this.startTimer();
      this.sendOtp();
    });
  };

  startTimer = () => {
    this._interval = setInterval(() => {
      const {timer} = this.state;
      if (timer > 0) {
        this.setState({
          timer: timer - 1,
        });
        if (timer === 1) {
          clearInterval(this._interval);
        }
      }
    }, 1000);
  };

  logOut() {
    post('/api/user-manager/admin/logout', '').then(response => {
      if (response.data.status === true && response.data.code === 200) {
        localStorage.clear();
        window.location.href = '/';
      }
    });
  }

  render() {
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const {countryData: {countryCode}, mobileNumber, showVerifyModal, otp, timer, isMobileNumberVerified} = this.state;
    const getFormItemsBasedOnBackground = () => {
      switch (getFieldValue('qualification')) {
        case 'MBBS':
          return (
              <Form.Item>
                {
                  getFieldDecorator('university', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your university name!',
                      }],
                  })(
                      <Input
                          prefix={
                            <Icon
                                type="user"
                                style={{color: 'rgba(0,0,0,.25)'}}
                            />
                          }
                          placeholder="University Name"
                          size={'large'}
                      />,
                  )
                }
              </Form.Item>
          );
        case 'Post MBBS':
          return (
              <Form.Item>
                {
                  getFieldDecorator('mca_register_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your MCA register id!',
                      }],
                  })(
                      <Input
                          prefix={
                            <Icon
                                type="user"
                                style={{color: 'rgba(0,0,0,.25)'}}
                            />
                          }
                          placeholder="MCA Register Id"
                          size={'large'}
                      />,
                  )
                }
              </Form.Item>
          );
        case 'MD/MS/DNB':
        case 'DM/DCH':
          return (
              <>
                <Form.Item>
                  {
                    getFieldDecorator('specialization', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your specialization!',
                        }],
                    })(
                        <Input
                            prefix={
                              <Icon
                                  type="user"
                                  style={{color: 'rgba(0,0,0,.25)'}}
                              />
                            }
                            placeholder="Specialization"
                            size={'large'}
                        />,
                    )
                  }
                </Form.Item>
                <Form.Item>
                  {
                    getFieldDecorator('hospital_name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your hospital name!',
                        }],
                    })(
                        <Input
                            prefix={
                              <Icon
                                  type="user"
                                  style={{color: 'rgba(0,0,0,.25)'}}
                              />
                            }
                            placeholder="Hospital Name"
                            size={'large'}
                        />,
                    )
                  }
                </Form.Item>
              </>
          );
        default:
          return null;
      }
    };
    const isAgreed = getFieldValue('agreement');

    return (
        <div className={'container text-center'}>
          <img
              className="logo-white"
              src={'https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2018/08/01131559/MC_Logo_Black.png'}
              alt="medcampus"
          />
          <p className="text-center mc-mt-10">Please add required details to
            access the dashboard</p>
          <div className="signup-container">
            <Form onSubmit={this.handleSubmit} className="signup-form">
              <Row>
                <Col span={24}>
                  <Form.Item>
                    {
                      getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your name!',
                          }],
                      })(
                          <Input
                              prefix={
                                <Icon
                                    type="user"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              placeholder="Full Name"
                              size={'large'}
                          />,
                      )
                    }
                  </Form.Item>
                  <Form.Item>
                    {
                      getFieldDecorator('user_name', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your user_name!',
                          }],
                      })(
                          <Input
                              prefix={
                                <Icon
                                    type="user"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              placeholder="Username"
                              size={'large'}
                          />,
                      )
                    }
                  </Form.Item>
                  <Form.Item>
                    {
                      getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your email id!',
                          }],
                      })(
                          <Input
                              prefix={
                                <Icon
                                    type="mail"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              placeholder="Email"
                              size={'large'}
                              disabled={true}
                          />,
                      )
                    }
                  </Form.Item>
                  <Row>
                    <Col span={20}>
                      <ReactPhoneInput
                          inputClass="phone-input"
                          containerClass="phone-input-container"
                          country={countryCode}
                          value={mobileNumber}
                          onChange={this.handleMobileNumberChange}
                          disabled={isMobileNumberVerified}
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                          type="link"
                          htmlType="button"
                          onClick={this.sendOtp}
                      >{isMobileNumberVerified ? 'Verified' : 'Verify'}</Button>
                    </Col>
                  </Row>
                  <Form.Item>
                    {
                      getFieldDecorator('qualification', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select your educational background!',
                          }],
                      })(
                          <Select
                              placeholder="Educational Background"
                              size="large"
                              onChange={this.handleEducationBackgroundChange}
                          >
                            <Option value="MBBS">MBBS</Option>
                            <Option value="Post MBBS">Post MBBS</Option>
                            <Option value="MD/MS/DNB">MD/MS/DNB</Option>
                            <Option value="DM/DCH">DM/DCH</Option>
                          </Select>,
                      )
                    }
                  </Form.Item>
                  {getFormItemsBasedOnBackground()}
                  <Form.Item>
                    {
                      getFieldDecorator('address', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter address for communication!',
                          }],
                      })(<Input.TextArea
                          placeholder="Address for communication"
                          rows={4}
                      />)
                    }
                  </Form.Item>
                  <Form.Item>
                    {
                      getFieldDecorator('agreement', {
                        valuePropName: 'checked',
                      })(<Checkbox>I have read the <a
                          href="">agreement</a></Checkbox>)
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                        type="primary"
                        size={'large'}
                        htmlType="submit"
                        className="signup-form-button"
                        disabled={!isAgreed}
                    >Submit</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Button type="link" onClick={this.logOut}>Logout</Button>
          </div>
          <Modal
              title="Verify mobile number"
              visible={showVerifyModal}
              centered
              onOk={this.handleOtpSubmit}
              onCancel={this.closeVerifyModal}
              keyboard={false}
              maskClosable={false}
              okText="Submit"
              cancelText="Cancel"
              width={300}
          >
            <Row className="text-center">
              <Col span={24}>
                <p className="mc-mb-20">We sent you the <b>OTP</b></p>
              </Col>
              <Col span={24} className="otp-input-wrapper">
                <OtpInput
                    inputStyle={styles.otpInput}
                    focusStyle={styles.otpInputFocused}
                    value={otp}
                    onChange={this.handleOtpInput}
                    separator={<span>-</span>}
                />
              </Col>
              <Col span={24} className="mc-mt-20">
                {
                  timer === 0 ? (
                      <Button
                          type="link"
                          htmlType="button"
                          onClick={this.handleResend}
                      >Resend</Button>
                  ) : <p className="mc-ml-5"><b>Resend in {timer}</b></p>
                }
              </Col>
            </Row>
          </Modal>
        </div>
    );
  }
}

const UpdateAdminData = Form.create({name: 'update_admin_form'})(
    UpdateAdminDataPre);

export {UpdateAdminData};
