import React from 'react';
import {
  Button, Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Spin, Typography,
  Upload,
} from 'antd';
import {Link} from 'react-router-dom';
import {McCkEditor} from '../../../components';
import {get, patch, post} from '../../../components/mc_axios/McAxios';
import {
  arrayConcat, debounce,
  getSubjectList,
  getSystemList,
} from '../../../components/util';

const {TextArea} = Input;
const {Paragraph} = Typography;

class EditInfomedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infomedId: this.props.match.params.infomedId,
      description: '',
      reference: '',
      featuredImageUrl: '',
      featuredImageAltText: '',
      createdAt: '',
      subjectsList: [],
      systemsList: [],
      tagsList: [],
      fetching: false,
    };
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleReferenceChange = this.handleReferenceChange.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount() {
    getSubjectList(subjects => {
      this.setState({
        subjectsList: subjects,
      });
    });

    getSystemList(systems => {
      this.setState({
        systemsList: systems,
      });
    });

    this.getInfomedDetails();
  }

  getTagList = debounce(value => {
    this.setState({tagsList: [], fetching: true});
    post('/api/coordinates/coordinates/tags', {tag: value}).then(response => {
      if (response.status === 200) {
        const tempTags = response.data.data.tags ?? [];
        const uniqueTags = [...new Set(tempTags)];
        const tagsList = uniqueTags.map(tag => ({
          text: tag,
          value: tag,
        }));
        this.setState({tagsList, fetching: false});
      }
    });
  }, 1000);

  getInfomedDetails = () => {
    get('/api/infomed/articles/' + this.state.infomedId).then(response => {
      let infomedData = response.data.data.infomed;
      let imageUrl,
          imageAltText = '';

      if (infomedData.featured_image_url !== undefined) {
        imageUrl = infomedData.featured_image_url;
        imageAltText = infomedData.featured_image_alt_text;
      }
      let list = [];
      if (infomedData.tags !== undefined && infomedData.tags.length > 0) {
        let tags = infomedData.tags;
        for (let i = 0; i < tags.length; i++) {
          list.push({
            key: tags[i],
            label: tags[i],
          });
        }
      }

      this.setState({
        description: infomedData.description,
        reference: infomedData.reference,
        featuredImageUrl: imageUrl,
        featuredImageAltText: imageAltText,
        createdAt: infomedData.created_at,
      });

      this.props.form.setFieldsValue({
        title: infomedData.title,
        subject: infomedData.subject,
        system: infomedData.system,
        tags: list,
        serum: infomedData.serum,
        brief_description: infomedData.brief_description,
        status: infomedData.status,
        reference: infomedData.reference,
        meta_title: infomedData.meta_title,
        meta_description: infomedData.meta_description,
        meta_keywords: infomedData.meta_keywords,
        slug: infomedData.slug,
      });
    });
  };

  fillMetaKeywords = value => {
    const {getFieldValue, setFieldsValue} = this.props.form;
    let keywords = value.map(d => d.key);
    let metaKeyWords = getFieldValue('meta_keywords') === undefined ?
        [] :
        getFieldValue('meta_keywords');
    setFieldsValue({
      meta_keywords: arrayConcat(keywords, metaKeyWords),
    });
  };

  handleDescriptionChange(description) {
    this.setState({
      description: description,
    });
  }

  handleReferenceChange(reference) {
    this.setState({
      reference: reference,
    });
  }

  handleSubmit = saveType => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const {
        featuredImageUrl,
        featuredImageAltText,
        description,
        reference,
        infomedId,
        createdAt,
      } = this.state;
      if (featuredImageUrl === '' && values.file !== undefined) {
        values.featured_image_url = values['file'].file.response.data.file_url;
        values.featured_image_alt_text =
            values['file'].file.response.data.alt_text;
      } else {
        values.featured_image_url = featuredImageUrl;
        values.featured_image_alt_text = featuredImageAltText;
      }
      delete values['file'];
      let list = [];
      if (values.tags !== undefined) {
        let tags = values.tags;
        for (let j = 0; j < tags.length; j++) {
          list.push(tags[j].key);
        }
      }
      values.tags = list;
      values.description = description;
      values.reference = reference;
      values.status = parseInt(saveType);
      values.infomed_id = infomedId;
      values.created_at = createdAt;

      post('/api/infomed/articles/update', values).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          message.success('mNote has been updated');
          this.props.history.push('/dashboard/mnotes/');
        } else {
          message.error('Something went wrong.');
        }
      });
    });
  };

  confirmDelete = () => {
    patch('/api/infomed/articles/delete', {
      infomed_id: this.state.infomedId,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('mNote has deleted successfully');
        this.props.history.push('/dashboard/mnotes');
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  render() {
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const Option = Select.Option;
    const Dragger = Upload.Dragger;
    const {
      infomedId,
      subjectsList,
      systemsList,
      description,
      featuredImageUrl,
      featuredImageAltText,
      fetching,
      tagsList,
      reference,
    } = this.state;
    const props = {
      name: 'file',
      action: '/api/media/upload',
      data: {
        product_type: 1,
      },
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    const mNoteFullUrl = 'https://www.medcampus.io/mnotes/' +
        getFieldValue('slug') + '-' + infomedId;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/mnotes/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit mNote
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  onCancel={() => message.error('You have canceled the delete')}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  className="mc-mr-5"
                  onClick={() => this.handleSubmit(1)}
              >
                Update
              </Button>
              <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
              >
                Update & Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    {getFieldDecorator('title', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input the title!',
                        },
                      ],
                    })(
                        <Input placeholder="Enter article title"
                               size={'large'}/>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Subject">
                    {getFieldDecorator('subject')(
                        <Select
                            mode="multiple"
                            placeholder="Select subject"
                            size={'large'}
                        >
                          {subjectsList.map(function(subject, index) {
                            return (
                                <Option value={subject} key={index}>
                                  {subject}
                                </Option>
                            );
                          })}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="System">
                    {getFieldDecorator('system')(
                        <Select
                            mode="multiple"
                            placeholder="Select system"
                            size={'large'}
                        >
                          {systemsList.map(function(system, index) {
                            return (
                                <Option value={system} key={index}>
                                  {system}
                                </Option>
                            );
                          })}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Tags">
                    {getFieldDecorator('tags')(
                        <Select
                            mode="tags"
                            labelInValue
                            placeholder="Enter tags"
                            notFoundContent={fetching ?
                                <Spin size="small"/> :
                                null}
                            filterOption={true}
                            onSearch={this.getTagList}
                            onBlur={this.fillMetaKeywords}
                            size={'large'}
                        >
                          {tagsList.map(d => (
                              <Option key={d.value}>{d.text}</Option>
                          ))}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Form.Item label="Serum">
                    {getFieldDecorator('serum')(
                        <InputNumber
                            min={0}
                            size={'large'}
                            style={{width: '100%'}}
                        />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Brief Description">
                    {getFieldDecorator('brief_description', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter brief description',
                        },
                      ],
                    })(<TextArea rows={4}/>)}
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={evt =>
                            this.handleDescriptionChange(evt.editor.getData())
                        }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {featuredImageUrl === '' ? (
                      <Form.Item label="Feature Image">
                        {getFieldDecorator('file', {
                          rules: [
                            {
                              required: true,
                              message: 'Feature image is required',
                            },
                          ],
                        })(
                            <Dragger
                                {...props}
                                listType="picture"
                                accept={'.jpg, .png, .PNG, .JPG'}
                            >
                              <p className="ant-upload-drag-icon">
                                <Icon type="inbox"/>
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibit
                                from uploading company data or other band files
                              </p>
                            </Dragger>,
                        )}
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <img
                              src={featuredImageUrl}
                              style={{width: 400}}
                              alt={featuredImageAltText}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className="mc-mt-15"
                              type="primary"
                              htmlType="button"
                              size="small"
                              onClick={() => this.setState(
                                  {featuredImageUrl: ''})}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <Row>
                <Col span={24}>
                  <p className="mc-mt-10">mNote url:</p>
                  <Card className="mc-mb-10">
                    <Paragraph
                        className="mc-mb-0"
                        copyable
                    >{mNoteFullUrl}</Paragraph>
                  </Card>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reference">
                    <McCkEditor
                        type={1}
                        content={reference}
                        onMcEditorChange={evt =>
                            this.setState({
                              reference: evt.editor.getData(),
                            })
                        }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="seo-section">
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      {getFieldDecorator('meta_title', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter meta title',
                          },
                        ],
                      })(<Input placeholder="Enter meta title"
                                size={'large'}/>)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      {getFieldDecorator('slug', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter slug',
                          },
                        ],
                      })(<Input size={'large'}/>)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      {getFieldDecorator('meta_description', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter meta description',
                          },
                        ],
                      })(<TextArea rows={4}/>)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      {getFieldDecorator('meta_keywords', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter alteast one keyword',
                          },
                        ],
                      })(
                          <Select
                              mode="tags"
                              placeholder="Enter keywords"
                              size={'large'}
                          />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

const EditInfomed = Form.create({name: 'edit_infomed'})(EditInfomedForm);

export {EditInfomed};
