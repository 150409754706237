import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Col, Empty, Icon, Pagination, Row, Spin} from 'antd';
import moment from 'moment';
import {Filters} from '../../components';
import {showQuestionPreview} from '../../actions/qbank_actions/PreviewQuestionActions';
import {createMarkup, getLocalData, setLocalData} from '../../components/util';
import {post} from '../../components/mc_axios/McAxios';
import './QBank.css';

class QBankPre extends React.Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('qbank-page-number', 1);
    lastVisitedPageNum = lastVisitedPageNum === '' ?
        1 :
        parseInt(lastVisitedPageNum);

    let itemsPerPage = getLocalData('qbank-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let qbankStatus = getLocalData('qbank-status', 1);
    qbankStatus = qbankStatus !== '' ? parseInt(qbankStatus) : 0;

    this.state = {
      questionsList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      qbankStatus,
      qbankSystem: getLocalData('qbank-system', 1),
      qbankSubject: getLocalData('qbank-subject', 1),
      qbankSearchTerm: getLocalData('qbank-search-term', 1),
      loading: false,
    };

    this.handleSearchTermSubmit = this.handleSearchTermSubmit.bind(this);
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart: state.currentPageNumber > 0 ?
              (state.currentPageNumber - 1) * state.itemsPerPage + 1 :
              1,
        }), () => {
          this.getQuestions();
        },
    );
  }

  getQuestions = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      qbankStatus,
      qbankSubject,
      qbankSystem,
      qbankSearchTerm,
    } = this.state;

    this.setState({loading: true});

    let pData = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      status: qbankStatus,
      subject: qbankSubject,
      system: qbankSystem,
      search_term: qbankSearchTerm,
    };

    post('/api/cosmos/questions', pData).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          loading: false,
          totalItems: resData.count,
          questionsList: resData.list,
        });
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('qbank-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getQuestions();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('qbank-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          qbankStatus: value,
        },
        () => {
          this.getQuestions();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('qbank-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          qbankSubject: value,
        },
        () => {
          this.getQuestions();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('qbank-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          qbankSystem: value,
        },
        () => {
          this.getQuestions();
        },
    );
  }

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('qbank-search-term', value, 1);
    this.setState({
      qbankSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
        },
        () => {
          this.getQuestions();
        },
    );
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('qbank-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getQuestions();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          qbankStatus: 0,
          qbankSubject: '',
          qbankSystem: '',
          qbankSearchTerm: '',
        },
        () => {
          setLocalData('qbank-page-number', 1, 1);
          setLocalData('qbank-items-per-page', 25, 1);
          setLocalData('qbank-status', 0, 1);
          setLocalData('qbank-subject', '', 1);
          setLocalData('qbank-system', '', 1);
          setLocalData('qbank-search-term', '', 1);
          this.getQuestions();
        },
    );
  };

  redirectToEditPage = queId => {
    this.props.history.push(`/dashboard/qbank/edit-question/${queId}`);
  };

  render() {
    const {
      questionsList,
      loading,
      currentPageNumber,
      itemsPerPage,
      qbankStatus,
      qbankSubject,
      qbankSystem,
      qbankSearchTerm,
      totalItems,
      paginationStart,
    } = this.state;
    const antIcon = <Icon type="loading" style={{fontSize: 30}} spin/>;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={6} className="secondary-header-left">
                <p>qBank</p>
              </Col>
              <Col span={18} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/qbank/create-question'}>
                    Create Question
                  </Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/qbank/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={qbankStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={qbankSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={qbankSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={qbankSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />

            {
              questionsList !== undefined && questionsList.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <Spin indicator={antIcon} spinning={loading}>
                        <div className="mc-table-responsive">
                          <table className="mc-table qbank-table">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Updated At</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                              questionsList.map((question, index) => (
                                  <tr key={question.question_id}>
                                    <td>{paginationStart + index}</td>
                                    <td
                                        className="table-question-title"
                                        onClick={() =>
                                            this.redirectToEditPage(
                                                question.question_id)
                                        }
                                    >
                                      <div
                                          dangerouslySetInnerHTML={createMarkup(
                                              question.title,
                                          )}
                                      />
                                    </td>
                                    <td>
                                      {moment.unix(question.updated_at).
                                          format('DD/MM/YYYY')}
                                    </td>
                                    <td className="status-td">
                                      {
                                        question.status === 2 ? (
                                            <Icon
                                                type="check-circle"
                                                title={'Verified'}
                                                className="active"
                                                theme="filled"
                                            />
                                        ) : (
                                            <Icon
                                                type="check-circle"
                                                title={'Not Verified'}
                                            />
                                        )
                                      }
                                      {
                                        question.explanation === true ? (
                                            <Icon
                                                type="file-text"
                                                title={'Explanation'}
                                                className="active"
                                                theme="filled"
                                            />
                                        ) : (
                                            <Icon
                                                type="file-text"
                                                title={'No Explanation'}
                                            />
                                        )
                                      }
                                      {
                                        question.video === true ? (
                                            <Icon
                                                type="video-camera"
                                                title={'Explanation video assigned'}
                                                className="active"
                                                theme="filled"
                                            />
                                        ) : (
                                            <Icon
                                                type="video-camera"
                                                title={'No explanation video assigned'}
                                            />
                                        )
                                      }
                                    </td>
                                    <td className="actions-icons">
                                      <Icon
                                          type="eye"
                                          style={{
                                            fontSize: 24,
                                            marginRight: 5,
                                            marginLeft: 5,
                                            verticalAlign: 'center',
                                          }}
                                          title="Preview"
                                          onClick={() =>
                                              this.props.showQuestionPreview(
                                                  `${question.question_id}`,
                                              )
                                          }
                                      />
                                    </td>
                                  </tr>
                              ))
                            }
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                            className="mc-mt-15"
                            showQuickJumper
                            current={currentPageNumber}
                            pageSize={itemsPerPage}
                            total={totalItems}
                            onChange={this.onPageChange}
                        />
                      </Spin>
                    </Col>
                  </Row>
              ) : (
                  <Empty
                      className="mc-mtb-100"
                      image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                      description={<span>No questions found</span>}
                  />
              )
            }
          </div>
        </>
    );
  }
}

const QBank = connect(
    null,
    {showQuestionPreview},
)(QBankPre);

export {QBank};
