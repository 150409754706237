import {get} from '../../components/mc_axios/McAxios';
import {
  GET_MASTER_NOTE_DATA,
  MASTER_NOTE_ACTIVE_NOTE_CONTENT,
  RESET_MASTER_NOTE_DATA,
} from '../types';
import {message} from 'antd';

export function showMasterNotePreview(id) {
  return async dispatch => {
    get('/api/master-notes/master-notes/' + id).then(response => {
      if (response.data.status === true) {
        dispatch({
          type: GET_MASTER_NOTE_DATA,
          payload: response.data.data.master_note,
        });
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };
}

export function getNoteData(id) {
  if (id !== undefined) {
    return async dispatch => {
      get(`/api/notes/notes/${id}`).then(response => {
        if (response.data.status === true) {
          dispatch({
            type: MASTER_NOTE_ACTIVE_NOTE_CONTENT,
            payload: response.data.data.note,
          });
        } else {
          message.error('Something went wrong. Try again.');
        }
      });
    };
  } else {
    return {
      type: MASTER_NOTE_ACTIVE_NOTE_CONTENT,
      payload: '',
    };
  }
}

export function resetMasterNoteData() {
  return {
    type: RESET_MASTER_NOTE_DATA,
  };
}
