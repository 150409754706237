import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Divider, Icon, message, Popconfirm, Row} from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {get, post} from '../../../components/mc_axios/McAxios';

class HomepageSlider extends React.Component {
  state = {
    slides: [],
  };

  componentDidMount() {
    this.getHomepageSlider();
  }

  getHomepageSlider = () => {
    get('/api/coordinates/coordinates/homepage/sliders').then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const {sliders} = response.data.data;
        if (sliders !== undefined) {
          this.setState({
            slides: sliders,
          });
        }
      } else message.error('Something went wrong! Try again.');
    });
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    const {slides} = this.state;
    let sortedList = arrayMove(
        slides,
        oldIndex,
        newIndex,
    );
    this.setState({
      slides: sortedList,
    });
  };

  updateHomepageSlider = () => {
    const {slides} = this.state;
    let arr = slides.map(item => item.slider_id);
    post('/api/coordinates/coordinates/homepage/sliders', {sliders: arr}).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            message.success('Slider has been updated.');
          } else message.error('Something went wrong! Try again.');
        });
  };

  removeSlide = id => {
    post('/api/coordinates/coordinates/sliders/remove_from_homepage',
        {slider_id: id}).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Slide has been removed.');
        this.getHomepageSlider();
      } else message.error('Something went wrong! Try again.');
    });
  };

  redirectToEditSlide = id => {
    window.open('/dashboard/slider/edit/' + id, '_blank');
  };

  render() {
    const {slides} = this.state;

    const DragHandle = sortableHandle(() => (
        <Icon className="cursor" type="align-center"/>
    ));

    const SortableItem = sortableElement(({value, index}) => (
        <tr>
          <td width={100}>
            <DragHandle/>
          </td>
          <td onClick={() => this.redirectToEditSlide(value.slider_id)}>
            <img src={value.desktop_image} height={100} alt={'Slide'}/>
          </td>
          <td width={150}>
            <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() => this.removeSlide(value.slider_id)}
                okText="Yes"
                cancelText="No"
            >
              <Icon
                  type="delete"
                  className="mc-icon"
                  title="Remove"
              />
            </Popconfirm>

          </td>
        </tr>
    ));

    const SortableContainer = sortableContainer(({children}) => {
      return (
          <div className="mc-table-responsive">
            <table className="mc-table">
              <thead>
              <tr>
                <th>#</th>
                <th>Slide</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
      );
    });

    return (<Row className="main-content-block" gutter={16}>
          <Col span={12}>
            <div className="page-header">
              <Link to={'/dashboard/slider/'}>
                <Icon type="arrow-left" title={'Back'}/>
              </Link>
              <Divider type="vertical"/> Homepage Slider
            </div>
          </Col>
          <Col span={12} className={'text-right'}>
            <Button htmlType={'button'}
                    onClick={this.updateHomepageSlider}>Update</Button>
          </Col>
          <Col span={24}>
            <hr className="mc-mt-10"/>
          </Col>
          <Col span={24}>
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
              {slides.map((value, index) => (
                  <SortableItem key={`item-${index}`} index={index}
                                value={value}/>
              ))}
            </SortableContainer>
          </Col>
        </Row>
    );
  }
}

export {HomepageSlider};
