import React from 'react';
import {Button, Col, Form, Icon, Input, message, Row} from 'antd';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import GoogleLogin from 'react-google-login';
import {storeUserId} from '../../actions/auth_actions/AuthActions';
import {parseJWT} from '../../components/util';
import {post} from '../../components/mc_axios/McAxios';
import './Login.css';

class LoginPre extends React.Component {

  responseGoogle = response => {
    if (!response.hasOwnProperty('error')) {
      const token = response.accessToken;
      post('/api/user-manager/admin/google-login', {access_token: token}).
          then(response => {
            window.location.href = '/';
          }).
          catch(errors => {
            console.log(errors);
          });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        post('/api/user-manager/admin/login', values).then(response => {
          const responseData = response.data;
          if (responseData.status === true && responseData.code === 200) {
            const userData = parseJWT(responseData.data.auth_token);
            this.props.storeUserId(userData.sub);
            window.location.href = '/';
          } else if (responseData.status === false && responseData.code === 400) {
            message.error(responseData.message);
          }
        }).catch(() => message.error('Server error. Please try again later.'));
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
        <Row>
          <Col span={24} className="text-center">
            <img
                className="logo-white"
                src='https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2018/08/01131559/MC_Logo_Black.png'
                alt="logo"
            />
            <div className="login-container">
              <Row>
                <Col span={24}>
                  <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item>
                      {
                        getFieldDecorator('email', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input your Email id!',
                            },
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                          ],
                        })(
                            <Input
                                prefix={<Icon
                                    type="user"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />}
                                placeholder="Email"
                                size={'large'}
                            />,
                        )
                      }
                    </Form.Item>
                    <Form.Item>
                      {
                        getFieldDecorator('password', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input your Password!',
                            },
                          ],
                        })(
                            <Input.Password
                                prefix={
                                  <Icon
                                      type="lock"
                                      style={{color: 'rgba(0,0,0,.25)'}}
                                  />
                                }
                                size={'large'}
                                placeholder="Password"
                            />,
                        )
                      }
                    </Form.Item>
                    <Form.Item>
                      <Link className="login-form-forgot" to="/forgot-password">Forgot
                        Password</Link>
                      <Button
                          type="primary"
                          size='large'
                          htmlType="submit"
                          className="login-form-button"
                      >Log In</Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24}>
                  <p className="text-center">or connect using</p>
                </Col>
                <Col span={24}>
                  <GoogleLogin
                      clientId="1024032446363-se4ofiu70op8doke2jd32enqu0tfhgdq.apps.googleusercontent.com"
                      render={renderProps => <Button
                          className="mc-mtb-20"
                          type="primary"
                          shape="round"
                          icon="google"
                          ghost
                          block
                          size="large"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                      >Sign in with Google</Button>}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={'single_host_origin'}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
    );
  }
}

const mapStateToProps = state => {
  const {userId} = state.auth;
  return {userId};
};

const Login = connect(mapStateToProps, {storeUserId})(
    Form.create({name: 'normal_login'})(LoginPre));

export {Login};
