import moment from 'moment';
import {
  ADD_TEST_QUESTIONS,
  GET_TEST_DETAILS,
  REMOVE_QUESTION_FROM_CHALLENZ,
  RESET_TEST_DATA,
  SORT_TEST_QUESTIONS_LIST,
  TEST_BASIS_CHANGE,
  TEST_CORRECT_QUESTION_MARKS_CHANGE,
  TEST_DESCRIPTION_CHANGE,
  TEST_END_DATE_CHANGE,
  TEST_FEATURED_IMAGE_CHANGE,
  TEST_IS_PREMIUM_CHANGE,
  TEST_MODE_CHANGE,
  TEST_SEO_META_DESCRIPTION_CHANGE,
  TEST_SEO_META_KEYWORDS_CHANGE,
  TEST_SEO_META_TITLE_CHANGE,
  TEST_SEO_SLUG_CHANGE,
  TEST_SERUM_CHANGE,
  TEST_START_DATE_CHANGE,
  TEST_TITLE_CHANGE,
  TEST_TOTAL_QUESTIONS_CHANGE,
  TEST_TOTAL_TIME_CHANGE,
  TEST_WRONG_QUESTION_MARKS_CHANGE,
} from '../../../actions/types';
import {convertToHHMM} from '../../../components/util';

const initialState = {
  id: '',
  title: '',
  isPremium: true,
  basis: 0,
  mode: 0,
  totalQuestions: 0,
  totalTime: 0,
  status: 0,
  correctQuestionMarks: 0,
  wrongQuestionMarks: 0,
  startDate: '',
  endDate: '',
  serum: 0,
  description: '',
  featuredImageUrl: '',
  featuredImageAltText: '',
  questionIds: [],
  questionsList: [],
  seoMetaTitle: '',
  seoSlug: '',
  seoMetaDescription: '',
  seoMetaKeywords: [],
  createdAt: '',
  updatedAt: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEST_DETAILS:
      let data = action.payload.test;
      return {
        ...state,
        id: data.test_id,
        title: data.test_title,
        isPremium: data.prime === undefined ? false : data.prime,
        basis: data.test_basis === undefined ? 0 : data.test_basis,
        mode: data.mode === undefined ? 0 : data.mode,
        totalQuestions:
            data.number_of_questions === undefined ?
                0 :
                data.number_of_questions,
        totalTime: data.total_time === undefined ?
            0 :
            convertToHHMM(data.total_time),
        status: data.status === undefined ? 0 : data.status,
        correctQuestionMarks: data.correct_question_marks === undefined ?
            0 :
            data.correct_question_marks,
        wrongQuestionMarks: data.wrong_question_marks === undefined ?
            0 :
            data.wrong_question_marks,
        startDate:
            data.start_time !== undefined
                ? moment.unix(data.start_time).format('DD/MM/YYYY HH:mm:ss')
                : '',
        endDate:
            data.end_time !== undefined
                ? moment.unix(data.end_time).format('DD/MM/YYYY HH:mm:ss')
                : '',
        serum: data.serum === undefined ? 0 : data.serum,
        description: data.description !== undefined ? data.description : '',
        featuredImageUrl:
            data.featured_image_url !== undefined ?
                data.featured_image_url :
                '',
        featuredImageAltText:
            data.featured_image_alt_text !== undefined
                ? data.featured_image_alt_text
                : '',
        questionIds: data.question_ids !== undefined ? data.question_ids : [],
        questionsList:
            action.payload.questions !== undefined
                ? action.payload.questions
                : [],
        seoMetaTitle: data.meta_title,
        seoSlug: data.slug,
        seoMetaDescription: data.meta_description,
        seoMetaKeywords:
            data.meta_keywords !== undefined ? data.meta_keywords : [],
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      };
    case TEST_TITLE_CHANGE:
      let payload = action.payload;
      return {
        ...state,
        title: payload !== undefined ? payload.title : '',
        seoMetaTitle: payload !== undefined ? payload.metaTitle : '',
        seoSlug: payload !== undefined ? payload.slug : '',
      };
    case TEST_IS_PREMIUM_CHANGE:
      return {
        ...state,
        isPremium: action.payload,
      };
    case TEST_BASIS_CHANGE:
      return {
        ...state,
        basis: action.payload,
      };
    case TEST_MODE_CHANGE:
      return {
        ...state,
        mode: action.payload,
      };
    case TEST_TOTAL_QUESTIONS_CHANGE:
      return {
        ...state,
        totalQuestions: action.payload,
      };
    case TEST_TOTAL_TIME_CHANGE:
      return {
        ...state,
        totalTime: action.payload,
      };
    case TEST_CORRECT_QUESTION_MARKS_CHANGE:
      return {
        ...state,
        correctQuestionMarks: action.payload,
      };
    case TEST_WRONG_QUESTION_MARKS_CHANGE:
      return {
        ...state,
        wrongQuestionMarks: action.payload,
      };
    case TEST_START_DATE_CHANGE:
      return {
        ...state,
        startDate: action.payload,
      };
    case TEST_END_DATE_CHANGE:
      return {
        ...state,
        endDate: action.payload,
      };
    case TEST_SERUM_CHANGE:
      return {
        ...state,
        serum: action.payload,
      };
    case TEST_DESCRIPTION_CHANGE:
      return {
        ...state,
        description: action.payload,
      };
    case TEST_FEATURED_IMAGE_CHANGE:
      return {
        ...state,
        featuredImageUrl:
            action.payload === undefined ? '' : action.payload.imgUrl,
        featuredImageAltText:
            action.payload === undefined ? '' : action.payload.imgAltText,
      };
    case TEST_SEO_META_TITLE_CHANGE:
      return {
        ...state,
        seoMetaTitle: action.payload,
      };
    case TEST_SEO_SLUG_CHANGE:
      return {
        ...state,
        seoSlug: action.payload,
      };
    case TEST_SEO_META_DESCRIPTION_CHANGE:
      return {
        ...state,
        seoMetaDescription: action.payload,
      };
    case TEST_SEO_META_KEYWORDS_CHANGE:
      return {
        ...state,
        seoMetaKeywords: action.payload,
      };
    case ADD_TEST_QUESTIONS:
      if (action.payload !== undefined) {
        return {
          ...state,
          questionsIds: action.payload.ids,
          questionsList: action.payload.questions,
        };
      } else {
        return {
          ...state,
        };
      }
    case SORT_TEST_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.payload.questionsList,
        questionIds: action.payload.questionIds,
      };
    case REMOVE_QUESTION_FROM_CHALLENZ:
      return {
        ...state,
        questionsList: action.payload.questionsList,
        questionIds: action.payload.questionIds,
      };
    case RESET_TEST_DATA:
      return {
        ...state,
        id: '',
        title: '',
        isPremium: true,
        basis: 0,
        mode: 0,
        totalQuestions: 0,
        startDate: '',
        endDate: '',
        serum: 0,
        description: '',
        featuredImageUrl: '',
        featuredImageAltText: '',
        questionIds: [],
        questionsList: [],
        seoMetaTitle: '',
        seoSlug: '',
        seoMetaKeywords: [],
      };
    default:
      return state;
  }
};
