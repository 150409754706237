import React from 'react';
import './ContentAnalysisCard.css';

const styles = {
  firstCol: {
    minWidth: 200,
    textAlign: 'left',
  },
  secondCol: {
    minWidth: 100,
    textAlign: 'center',
  },
  thirdCol: {
    minWidth: 100,
  },
  fourthCol: {
    minWidth: 100,
  },
};

const ContentAnalysisCard = ({data}) => {

  const {
    challenge: {free: freeChallengeCount = 0, premium: premiumChallengeCount = 0},
    infomed: {free: freeInfoMedCount = 0, premium: premiumInfoMedCount = 0},
    medpixx: {free: freeMedPixxCount = 0, premium: premiumMedPixxCount = 0},
    prepmed: {free: freePrepMedCount = 0, premium: premiumPrepMedCount = 0},
    television: {free: freeMVideoCount = 0, premium: premiumMVideoCount = 0},
  } = data;

  return (
      <div className="content-analysis-card">
        <div className="mc-table-responsive">
          <table className="mc-table">
            <thead>
            <tr>
              <th style={styles.firstCol}>Product</th>
              <th style={styles.secondCol}>Free</th>
              <th style={styles.thirdCol}>Premium</th>
              <th style={styles.fourthCol}>Total</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="text-left">Challenges</td>
              <td className="text-center">{freeChallengeCount}</td>
              <td>{premiumChallengeCount}</td>
              <td>{freeChallengeCount + premiumChallengeCount}</td>
            </tr>
            <tr>
              <td className="text-left">PrepMed</td>
              <td className="text-center">{freePrepMedCount}</td>
              <td>{premiumPrepMedCount}</td>
              <td>{freePrepMedCount + premiumPrepMedCount}</td>
            </tr>
            <tr>
              <td className="text-left">MVideos</td>
              <td className="text-center">{freeMVideoCount}</td>
              <td>{premiumMVideoCount}</td>
              <td>{freeMVideoCount + premiumMVideoCount}</td>
            </tr>
            <tr>
              <td className="text-left">InfoMed</td>
              <td className="text-center">{freeInfoMedCount}</td>
              <td>{premiumInfoMedCount}</td>
              <td>{freeInfoMedCount + premiumInfoMedCount}</td>
            </tr>
            <tr>
              <td className="text-left">MedPixx</td>
              <td className="text-center">{freeMedPixxCount}</td>
              <td>{premiumMedPixxCount}</td>
              <td>{freeMedPixxCount + premiumMedPixxCount}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
  );
};

export {ContentAnalysisCard};
