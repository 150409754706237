import React, {memo} from 'react';
import {nFormatter} from '../../../util';
import './CounterCard.css';

const CountCard = memo(({title = 'Title', count = 0, countTwo, isMoney}) => {
  const parseIsMoney = isMoney ? '₹ ' : '';
  const parseCount = parseIsMoney + nFormatter(count, 2);
  const parseCountTwo = countTwo === undefined ? undefined : parseIsMoney + nFormatter(countTwo, 2);

  return (
      <div className="count-card">
        <p className="count-card__title">{title}</p>
        <p className="count-card__count" title={parseIsMoney + count.toLocaleString()}>{parseCount} {parseCountTwo !== undefined && ' / ' + parseCountTwo} </p>
      </div>
  );
});

export {CountCard};
