import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import {withCookies} from 'react-cookie';
import {Link, withRouter} from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import './Signup.css';

const Option = Select.Option;

const styles = {
  otpInput: {
    width: 40,
    height: 40,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'gray',
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  otpInputFocused: {
    borderColor: '#00155b',
    outline: 'none',
  },
};

class NormalSignupForm extends React.Component {

  state = {
    countryData: {
      countryCode: 'in',
      dialCode: '91',
      name: 'India',
    },
    mobileNumber: '',
    showVerifyModal: false,
    otp: '',
    timer: 30,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios({
          method: 'post',
          url: '/api/user-manager/admin/signup',
          data: JSON.stringify({
            name: values.name,
            email: values.email,
            password: values.password,
          }),
        }).then(response => {
          if (response.data.status === true) {
            message.success('Successfully signed up.');
            window.location.href = '/';
          }
        }).catch(error => {
          message.error('Something went wrong.');
        });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleMobileNumberChange = (mobileNumber, countryData) => {
    this.setState({
      countryData,
      mobileNumber,
    });
  };

  sendOtp = () => {
    this.setState({
      showVerifyModal: true,
    }, () => {
      this.startTimer();
    });
  };

  handleOtpInput = otp => {
    this.setState({
      otp,
    });
  };

  handleOtpSubmit = () => {
    clearInterval(this._interval);
    this.setState({
      showVerifyModal: false,
      timer: 30,
    });
  };

  closeVerifyModal = () => {
    clearInterval(this._interval);
    this.setState({
      showVerifyModal: false,
      timer: 30,
    });
  };

  handleResend = () => {
    this.setState({
      timer: 30,
    }, () => {
      this.startTimer();
    });
  };

  handleEducationBackgroundChange = () => {

  };

  startTimer = () => {
    this._interval = setInterval(() => {
      const {timer} = this.state;
      if (timer > 0) {
        this.setState({
          timer: timer - 1,
        });
        if (timer === 1) {
          clearInterval(this._interval);
        }
      }
    }, 1000);
  };

  render() {
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const {countryData: {countryCode}, mobileNumber, showVerifyModal, otp, timer} = this.state;

    const getFormItemsBasedOnBackground = () => {
      switch (getFieldValue('educationalBackground')) {
        case 1:
          return (
              <Form.Item>
                {
                  getFieldDecorator('College Name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your name!',
                      }],
                  })(
                      <Input
                          prefix={
                            <Icon
                                type="user"
                                style={{color: 'rgba(0,0,0,.25)'}}
                            />
                          }
                          placeholder="College Name"
                          size={'large'}
                      />,
                  )
                }
              </Form.Item>
          );
        case 2:
          return (
              <Form.Item>
                {
                  getFieldDecorator('mcaRegisterId', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your MCA register id!',
                      }],
                  })(
                      <Input
                          prefix={
                            <Icon
                                type="user"
                                style={{color: 'rgba(0,0,0,.25)'}}
                            />
                          }
                          placeholder="MCA Register Id"
                          size={'large'}
                      />,
                  )
                }
              </Form.Item>
          );
        case 3:
        case 4:
          return (
              <>
                <Form.Item>
                  {
                    getFieldDecorator('specialization', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your specialization!',
                        }],
                    })(
                        <Input
                            prefix={
                              <Icon
                                  type="user"
                                  style={{color: 'rgba(0,0,0,.25)'}}
                              />
                            }
                            placeholder="Specialization"
                            size={'large'}
                        />,
                    )
                  }
                </Form.Item>
                <Form.Item>
                  {
                    getFieldDecorator('hospital name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your hospital name!',
                        }],
                    })(
                        <Input
                            prefix={
                              <Icon
                                  type="user"
                                  style={{color: 'rgba(0,0,0,.25)'}}
                              />
                            }
                            placeholder="Hospital Name"
                            size={'large'}
                        />,
                    )
                  }
                </Form.Item>
              </>
          );
        default:
          return null;
      }
    };

    return (
        <div className={'container text-center'}>
          <img
              className="logo-white"
              src={'https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2018/08/01131559/MC_Logo_Black.png'}
              alt="medcampus"
          />
          <div className="signup-container">
            <Form onSubmit={this.handleSubmit} className="signup-form">
              <Row>
                <Col span={24}>
                  <Form.Item>
                    {
                      getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your name!',
                          }],
                      })(
                          <Input
                              prefix={
                                <Icon
                                    type="user"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              placeholder="Name"
                              size={'large'}
                          />,
                      )
                    }
                  </Form.Item>
                  <Form.Item>
                    {
                      getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your Email id!',
                          },
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                        ],
                      })(
                          <Input
                              prefix={
                                <Icon
                                    type="mail"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              placeholder="Email"
                              size={'large'}
                          />,
                      )
                    }
                  </Form.Item>
                  <Form.Item>
                    {
                      getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your Password!',
                          },
                        ],
                      })(
                          <Input.Password
                              prefix={
                                <Icon
                                    type="lock"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              size={'large'}
                              placeholder="Password"
                          />,
                      )
                    }
                  </Form.Item>
                  <Form.Item>
                    {
                      getFieldDecorator('confirm', {
                        rules: [
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          {
                            validator: this.compareToFirstPassword,
                          },
                        ],
                      })(
                          <Input.Password
                              prefix={
                                <Icon
                                    type="lock"
                                    style={{color: 'rgba(0,0,0,.25)'}}
                                />
                              }
                              placeholder="Confirm password"
                              size="large"
                          />,
                      )
                    }
                  </Form.Item>
                  <Row>
                    <Col span={20}>
                      <ReactPhoneInput
                          inputClass="phone-input"
                          containerClass="phone-input-container"
                          country={countryCode}
                          value={mobileNumber}
                          onChange={this.handleMobileNumberChange}
                          disabled={false}
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                          type="link"
                          htmlType="button"
                          onClick={this.sendOtp}
                      >Verify</Button>
                    </Col>
                  </Row>
                  <Form.Item>
                    {
                      getFieldDecorator('educationalBackground', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select your educational background!',
                          }],
                      })(
                          <Select
                              placeholder="Educational Background"
                              size="large"
                              onChange={this.handleEducationBackgroundChange}
                          >
                            <Option value={1}>MBBS</Option>
                            <Option value={2}>Post MBBS</Option>
                            <Option value={3}>MD/MS/DNB</Option>
                            <Option value={4}>DM/DCH</Option>
                          </Select>,
                      )
                    }
                  </Form.Item>
                  {getFormItemsBasedOnBackground()}
                  <Form.Item>
                    {
                      getFieldDecorator('agreement', {
                        valuePropName: 'checked',
                      })(
                          <Checkbox>I have read the <a
                              href="">agreement</a></Checkbox>,
                      )
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                        type="primary"
                        size={'large'}
                        htmlType="submit"
                        className="signup-form-button"
                    >Register</Button>
                  </Form.Item>
                  <p className="text-center">Already registered? <Link
                      to={'/login'}>Login</Link></p>
                </Col>
              </Row>
            </Form>
          </div>
          <Modal
              title="Verify mobile number"
              visible={showVerifyModal}
              centered
              onOk={this.handleOtpSubmit}
              onCancel={this.closeVerifyModal}
              keyboard={false}
              maskClosable={false}
              okText="Submit"
              cancelText="Cancel"
              width={300}
          >
            <Row className="text-center">
              <Col span={24}>
                <p className="mc-mb-20">We sent you the <b>OTP</b></p>
              </Col>
              <Col span={24} className="otp-input-wrapper">
                <OtpInput
                    inputStyle={styles.otpInput}
                    focusStyle={styles.otpInputFocused}
                    value={otp}
                    onChange={this.handleOtpInput}
                    separator={<span>-</span>}
                />
              </Col>
              <Col span={24} className="mc-mt-20">
                {
                  timer === 0 ? (
                      <Button
                          type="link"
                          htmlType="button"
                          onClick={this.handleResend}
                      >Resend</Button>
                  ) : <p className="mc-ml-5"><b>Resend in {timer}</b></p>
                }
              </Col>
            </Row>
          </Modal>
        </div>
    );
  }
}

const Signup = withCookies(
    withRouter(Form.create({name: 'normal_signup'})(NormalSignupForm)));

export {Signup};
