import React from 'react';
import {Alert, Col, Collapse, Modal, Row} from 'antd';
import {createMarkup} from '../util';
import {connect} from 'react-redux';
import {
  getNoteData,
  resetMasterNoteData,
} from '../../actions/master_notes_actions/PreviewMasterNoteActions';
import './PreviewMasterNote.css';

const Panel = Collapse.Panel;

function PreviewMasterNotePre(props) {
  const {previewMasterNote: {visible, title, introduction, notesList, activeNoteContent}, getNoteData, resetMasterNoteData} = props;

  return (
      <Modal
          title="Preview Master Note"
          centered
          width={1200}
          visible={visible}
          onCancel={() => resetMasterNoteData()}
          footer={null}
          className="preview-master-note-modal"
      >
        <Row>
          <Col span={24}>
            <h4>
              <u>Title:</u>
            </h4>
            <p>{title}</p>
          </Col>
          {
            (introduction !== undefined && introduction !== '') && (
                <Col span={24}>
                  <h4>
                    <u>Introduction:</u>
                  </h4>
                  <div dangerouslySetInnerHTML={createMarkup(introduction)}/>
                </Col>
            )
          }
          {
            (notesList !== undefined && notesList.length > 0) && (
                <Col span={24}>
                  <Collapse
                      accordion={true}
                      bordered={false}
                      onChange={key => getNoteData(key)}
                  >
                    {
                      notesList.map(note => {
                        return (
                            <Panel header={note.title} key={note.note_id}>
                              {
                                activeNoteContent !== '' ? (
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            activeNoteContent,
                                        )}
                                    />
                                ) : (
                                    <Alert
                                        message="No content found"
                                        type="info"
                                        className="text-center"
                                    />
                                )
                              }
                            </Panel>
                        );
                      })
                    }
                  </Collapse>
                </Col>
            )
          }
        </Row>
      </Modal>
  );
}

const mapStateToProps = state => {
  return state.masterNotes;
};

const PreviewMasterNote = connect(
    mapStateToProps,
    {getNoteData, resetMasterNoteData},
)(PreviewMasterNotePre);

export {PreviewMasterNote};
