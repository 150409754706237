import moment from 'moment';
import {get} from './mc_axios/McAxios';

export const parseJWT = function(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const getSystemList = function(callback) {
  let storedSystems = localStorage.getItem('mc-systems');
  if (storedSystems === null) {
    get('/api/coordinates/coordinates/systems').then(response => {
      if (response.data.status === true) {
        if (response.data.data.systems === undefined) {
          callback([]);
        } else {
          let systems = response.data.data.systems;
          localStorage.setItem('mc-systems', JSON.stringify(systems));
          callback(systems);
        }
      }
    });
  } else {
    callback(JSON.parse(storedSystems));
  }
};

export const getSubjectList = function(callback) {
  let storedSubjects = localStorage.getItem('mc-subjects');
  if (storedSubjects === null) {
    get('/api/coordinates/coordinates/subjects').then(response => {
      if (response.data.status === true) {
        if (response.data.data.subjects === undefined) {
          callback([]);
        } else {
          let subjects = response.data.data.subjects;
          localStorage.setItem('mc-subjects', JSON.stringify(subjects));
          callback(subjects);
        }
      }
    });
  } else {
    callback(JSON.parse(storedSubjects));
  }
};

export const SYSTEM_BLOCK_OPTIONS_LIST = [
  {
    label: 'Cervical',
    value: 1,
    children: [
      {
        label: 'Block 1',
        value: 1,
      },
      {
        label: 'Block 2',
        value: 2,
      },
      {
        label: 'Block 3',
        value: 3,
      },
      {
        label: 'Block 4',
        value: 4,
      },
      {
        label: 'Block 5',
        value: 5,
      },
      {
        label: 'Block 6',
        value: 6,
      },
      {
        label: 'Block 7',
        value: 7,
      },
    ],
  },
  {
    label: 'Thoracic',
    value: 2,
    children: [
      {
        label: 'Block 8',
        value: 8,
      },
      {
        label: 'Block 9',
        value: 9,
      },
      {
        label: 'Block 10',
        value: 10,
      },
      {
        label: 'Block 11',
        value: 11,
      },
      {
        label: 'Block 12',
        value: 12,
      },
      {
        label: 'Block 13',
        value: 13,
      },
      {
        label: 'Block 14',
        value: 14,
      },
      {
        label: 'Block 15',
        value: 15,
      },
      {
        label: 'Block 16',
        value: 16,
      },
      {
        label: 'Block 17',
        value: 17,
      },
      {
        label: 'Block 18',
        value: 18,
      },
      {
        label: 'Block 19',
        value: 19,
      },
    ],
  },
  {
    label: 'Lumbar',
    value: 3,
    children: [
      {
        label: 'Block 20',
        value: 20,
      },
      {
        label: 'Block 21',
        value: 21,
      },
      {
        label: 'Block 22',
        value: 22,
      },
      {
        label: 'Block 23',
        value: 23,
      },
      {
        label: 'Block 24',
        value: 24,
      },
    ],
  },
  {
    label: 'Sacral',
    value: 4,
    children: [
      {
        label: 'Block 25',
        value: 25,
      },
      {
        label: 'Block 26',
        value: 26,
      },
      {
        label: 'Block 27',
        value: 27,
      },
      {
        label: 'Block 28',
        value: 28,
      },
      {
        label: 'Block 29',
        value: 29,
      },
    ],
  },
  {
    label: 'Caudal',
    value: 5,
    children: [
      {
        label: 'Block 30',
        value: 30,
      },
      {
        label: 'Block 31',
        value: 31,
      },
      {
        label: 'Block 32',
        value: 32,
      },
      {
        label: 'Block 33',
        value: 33,
      },
    ],
  },
];

export const slugify = function(string) {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return string.toString().
      toLowerCase().
      replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with ‘and’
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
};

export const createMarkup = function(articleHtml) {
  return {__html: articleHtml};
};

export const setLocalData = function(key, value, type) {
  if (type === 1) {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getLocalData = function(key, type) {
  let data = localStorage.getItem(key);
  if (data === null) {
    switch (type) {
      case 1:
        data = '';
        break;
      case 2:
        data = [];
        break;
      case 3:
        data = 0;
        break;
      default:
        data = '';
        break;
    }
  } else {
    switch (type) {
      case 2:
        data = JSON.parse(data);
        break;
      case 3:
        data = parseInt(data);
        break;
      default:
        break;
    }
  }
  return data;
};

export const dateToUnix = function(date) {
  return date !== undefined ? moment(date, 'DD/MM/YYYY').unix() : '';
};

export const unixToData = function(unix) {
  return unix !== undefined ? moment.unix(unix).format('MM/DD/YYYY') : '';
};

export const removeArrayItemByIndex = function(array, index) {
  return array.filter(function(elem, _index) {
    return index !== _index;
  });
};

export const addResponsiveTableCode = str => {
  let replacedStr = str.replace(
      /<table/g,
      '<div class=\'mc-table-responsive\'><table',
  );
  replacedStr = replacedStr.replace(/<\/table>/g, '</table></div>');
  return replacedStr;
};

export const convertToSeconds = duration => {
  if (duration !== 0) {
    let splittedDuration = duration.split(':');
    return splittedDuration[0] * 60 * 60 + splittedDuration[1] * 60;
  } else return 0;
};

const pad = num => {
  return ('0' + num).slice(-2);
};

export const convertToHHMM = secs => {
  let minutes = Math.floor(secs / 60);
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}`;
};

export const arrayConcat = (arr1, arr2) => {
  let arr = [...arr1, ...arr2];
  for (let i = 0; i < arr.length; ++i) {
    for (let j = i + 1; j < arr.length; ++j) {
      if (arr[i].toLowerCase() === arr[j].toLowerCase())
        arr.splice(j--, 1);
    }
  }
  return arr;
};

export const redirectToEditQuestionPage = id => {
  window.open('/dashboard/qbank/edit-question/' + id, '_blank');
};

const DEFAULT_AVATAR_URL = 'https://assets.medcampus.io/assets/images/default-avatar.png';

export const getAvatarUrl = function(url) {
  return (url === '' || url === undefined ||
      url.includes('platform-lookaside.fbsbx.com')) ? DEFAULT_AVATAR_URL : url;
};

export const convertUnixToDMY = (unixTime, type) => {
  if (type === 1) {
    return moment.unix(unixTime).format('DD/MM/YYYY');
  } else if (type === 2) {
    return moment.unix(unixTime).format('MMM DD, YYYY');
  } else {
    return moment.unix(unixTime).format('MMM DD');
  }
};

export const isEmpty = data => {
  if (typeof (data) === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true;
    } else if (!data) {
      return true;
    }
    return false;
  } else if (typeof (data) === 'string') {
    if (!data.trim()) {
      return true;
    }
    return false;
  } else if (typeof (data) === 'undefined') {
    return true;
  } else {
    return false;
  }
};

export const nFormatter = (num, digits) => {
  let si = [
    {value: 1, symbol: ''},
    {value: 1E3, symbol: 'K'},
    {value: 1E6, symbol: 'M'},
    {value: 1E9, symbol: 'G'},
    {value: 1E12, symbol: 'T'},
    {value: 1E15, symbol: 'P'},
    {value: 1E18, symbol: 'E'},
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const debounce = (func, delay) => {
  let debounceTimer
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  }
}
