import React from 'react';
import {Button, Col, Empty, message, Pagination, Row} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import './Testimonials.css';

const styles = {
  firstCol: {
    minWidth: 70,
  },
  secondCol: {
    minWidth: 100,
  },
  thirdCol: {
    minWidth: 200,
    textAlign: 'left',
  },
  fourthCol: {
    minWidth: 150,
  },
};

class Testimonials extends React.Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('testimonial-page-number', 1);
    lastVisitedPageNum = lastVisitedPageNum !== '' ?
        parseInt(lastVisitedPageNum) :
        1;

    let itemsPerPage = getLocalData('testimonial-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    this.state = {
      testimonials: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      sliderSearchTerm: getLocalData('testimonial-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }), () => this.getTestimonials(),
    );
  }

  getTestimonials = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      sliderSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      search_term: sliderSearchTerm,
    };

    post('/api/coordinates/coordinates/testimonies', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('testimonies')) {
          this.setState({
            testimonials: resData.testimonies,
            totalItems: resData.total_count,
          });
        }
      } else message.error('Something went wrong! Try again.');
    });
  };

  handleItemsCount = value => {
    setLocalData('testimonial-items-per-page', value, 1);
    this.setState({
      currentPageNumber: 1,
      paginationStart: 1,
      itemsPerPage: value,
    }, () => this.getTestimonials());
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('testimonial-search-term', value, 1);
    this.setState({
      sliderSearchTerm: value,
    });
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('testimonial-page-number', pageNumber, 1);
    this.setState({
      currentPageNumber: pageNumber,
      paginationStart: start + 1,
    }, () => this.getTestimonials());
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          sliderSearchTerm: '',
        },
        () => {
          setLocalData('testimonial-items-per-page', 25, 1);
          setLocalData('testimonial-page-number', 1, 1);
          setLocalData('testimonial-status', 0, 1);
          setLocalData('testimonial-search-term', '', 1);
          this.getTestimonials();
        },
    );
  };

  render() {
    const {
      testimonials,
      currentPageNumber,
      itemsPerPage,
      sliderSearchTerm,
      totalItems,
    } = this.state;
    const {history} = this.props;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>Testimonials</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/testimonials/create-testimonial/'}>Create
                    Testimonial</Link>
                </Button>
                <Button className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/testimonials/published/'}>Published Testimonials</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                searchTermValue={sliderSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.getTestimonials}
                onFiltersReset={this.resetFilters}
            />
            {
              testimonials.length > 0 ? (
                  <Row gutter={16} className="mc-mt-10">
                    <div className="mc-table-responsive">
                      <table className="mc-table">
                        <thead>
                        <tr>
                          <th style={styles.firstCol}>#</th>
                          <th style={styles.secondCol}>User Name</th>
                          <th style={styles.thirdCol} className="text-left">Message</th>
                          <th style={styles.fourthCol}>Updated At</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          testimonials.map((testimonial, index) => {
                            const {testimony_id, name, message, updatedat} = testimonial;
                            const redirectToEditPage = () => {
                              history.push(`/dashboard/testimonials/edit/${testimony_id}`);
                            };
                            const updatedAt = moment.unix(updatedat).format('DD/MM/YYYY');
                            return (
                                <tr key={testimony_id}>
                                  <td>{index + 1}</td>
                                  <td onClick={redirectToEditPage}>{name}</td>
                                  <td className="text-left">{message}</td>
                                  <td>{updatedAt}</td>
                                </tr>
                            );
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                    <Col span={24}>
                      <br/>
                      <Pagination
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No testimonials found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

export {Testimonials};
