import {get} from '../../components/mc_axios/McAxios';
import {GET_NOTE_DATA, RESET_NOTE_DATA} from '../types';
import {message} from 'antd';

export function showNotePreview(id) {
  return async dispatch => {
    get('/api/notes/notes/' + id).then(response => {
      if (response.data.status === true) {
        dispatch({
          type: GET_NOTE_DATA,
          payload: response.data.data.note,
        });
      } else {
        message.error('Something went wrong.');
      }
    });
  };
}

export function resetNoteData() {
  return {
    type: RESET_NOTE_DATA,
  };
}
