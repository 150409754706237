import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
  TimePicker,
  Upload,
} from 'antd';
import {connect} from 'react-redux';
import moment from 'moment';
import {McCkEditor} from '../../../components';
import {
  handleCreateTestFieldChanges,
  handleCreateTestFormReset,
} from '../../../actions/prepmed_actions/CreateTestActions';
import {post} from '../../../components/mc_axios/McAxios';
import {convertToSeconds} from '../../../components/util';
import {
  getCreateTestDataReselector,
  getUserRoleReselector,
} from '../../../redux/selectors';

const {TextArea} = Input;
const Option = Select.Option;
const Dragger = Upload.Dragger;

class CreateTestForm extends React.Component {
  handleSubmit = () => {
    const {userRole, createTest} = this.props;
    if (createTest.title !== '') {
      const {title, isPremium, basis, mode, totalQuestions, totalTime, correctQuestionMarks, wrongQuestionMarks, startDate, endDate, serum, description, featuredImageUrl, featuredImageAltText, seoMetaTitle, seoSlug, seoMetaDescription, seoMetaKeywords} = createTest;

      let data = {
        test_title: title,
        status: 0,
        prime: isPremium,
        test_basis: userRole === 2 ? parseInt(basis) : 8,
        mode: parseInt(mode),
        number_of_questions: parseInt(totalQuestions),
        total_time: convertToSeconds(totalTime),
        correct_question_marks: parseInt(correctQuestionMarks),
        wrong_question_marks: parseInt(wrongQuestionMarks),
        start_time: moment(startDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        end_time: moment(endDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        serum,
        description: description,
        featured_image_url: featuredImageUrl,
        featured_image_alt_text: featuredImageAltText,
        meta_title: seoMetaTitle,
        slug: seoSlug,
        meta_description: seoMetaDescription,
        meta_keywords: seoMetaKeywords,
      };

      post('/api/tests/tests/create', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          const {history, handleCreateTestFormReset} = this.props;
          handleCreateTestFormReset();
          message.success('Test has been created');
          history.push(
              `/dashboard/prepmed/add-questions/${response.data.data.test_id}`);
        } else {
          message.error('Something went wrong! Try again.');
        }
      });
    } else {
      message.warning('Please enter title');
    }
  };

  render() {
    const {createTest, userRole, handleCreateTestFieldChanges} = this.props;
    const isRestricted = userRole !== 2;
    const testBasis = isRestricted === true ? 8 : parseInt(createTest.basis);
    const testMode = parseInt(createTest.mode);
    const uploaderProps = {
      name: 'file',
      accept: '.png, .jpg, .jpeg',
      action: '/api/media/upload',
      data: {
        product_type: 8,
      },
      onChange(info) {
        if (info.file.status === 'done' && info.file.response.status === true) {
          let data = info.file.response.data;
          message.success(`${info.file.name} file uploaded successfully`);
          handleCreateTestFieldChanges('featured-image',
              {imgUrl: data.file_url, imgAltText: data.alt_text});
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/prepmed/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create Test
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={this.handleSubmit}
                  className="mc-mr-5"
              >
                Create
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <p className={'mc-mt-10'}>
                    Title:
                    {
                      !isRestricted && (
                          <span className="pull-right">
                          Premium
                          <Switch
                              className="mc-ml-15"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              checked={createTest.isPremium}
                              onChange={checked => handleCreateTestFieldChanges(
                                  'premium', checked)}
                              style={{width: 60}}
                          />
                        </span>
                      )
                    }
                  </p>
                </Col>
                <Col span={24}>
                  <Input
                      placeholder="Enter title"
                      size={'large'}
                      value={createTest.title}
                      onChange={e => handleCreateTestFieldChanges('title',
                          e.target.value)}
                      className="mc-mb-15"
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="Type">
                    <Select
                        size={'large'}
                        value={testBasis}
                        onChange={value => handleCreateTestFieldChanges('basis',
                            value)}
                        disabled={isRestricted}
                    >
                      <Option value={1}>NEET PG</Option>
                      <Option value={2}>AIIMS PG</Option>
                      <Option value={3}>USMLE STEP I</Option>
                      <Option value={4}>USMLE STEP II</Option>
                      {/*<Option value={5}>PGI CHANDIGARH</Option>*/}
                      {/*<Option value={6}>JIPMER</Option>*/}
                      {/*<Option value={7}>NIMHANS</Option>*/}
                      <Option value={9}>UPSC CMS</Option>
                      <Option value={10}>Subject Wise Tests</Option>
                      <Option value={8}>ANY</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mode">
                    <Select
                        size={'large'}
                        value={testMode}
                        onChange={value => handleCreateTestFieldChanges('mode',
                            value)}
                    >
                      <Option value={0}>Exam & Practice</Option>
                      <Option value={1}>Exam</Option>
                      <Option value={2}>Practice</Option>
                      <Option value={3}>Grand Test</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {
                testBasis === 8 && <>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Total Questions">
                        <InputNumber
                            min={0}
                            value={createTest.totalQuestions}
                            onChange={value => handleCreateTestFieldChanges(
                                'total-questions', value)}
                            className="width-fluid"
                            size={'large'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Total Time (hh:mm)">
                        <TimePicker
                            format={'HH:mm'}
                            size={'large'}
                            allowClear={false}
                            value={moment(createTest.totalTime, 'HH:mm')}
                            className="width-fluid"
                            onChange={(
                                time,
                                timeString) => handleCreateTestFieldChanges(
                                'total-time', timeString)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Correct Answer Marks">
                        <InputNumber
                            min={0}
                            value={createTest.correctQuestionMarks}
                            onChange={value => handleCreateTestFieldChanges(
                                'cqm', value)}
                            className="width-fluid"
                            size={'large'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Incorrect Answer Marks">
                        <InputNumber
                            min={0}
                            value={createTest.wrongQuestionMarks}
                            onChange={value => handleCreateTestFieldChanges(
                                'wqm', value)}
                            className="width-fluid"
                            size={'large'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              }
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Start Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        showTime
                        format="DD/MM/YYYY HH:mm:ss"
                        value={
                          createTest.startDate !== '' ?
                              moment(createTest.startDate,
                                  'DD/MM/YYYY HH:mm:ss') :
                              undefined
                        }
                        onChange={(date, dateString) =>
                            handleCreateTestFieldChanges('start-date',
                                dateString)
                        }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="End Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        showTime
                        format="DD/MM/YYYY HH:mm:ss"
                        value={
                          createTest.endDate !== '' ?
                              moment(createTest.endDate,
                                  'DD/MM/YYYY HH:mm:ss') :
                              undefined
                        }
                        onChange={(date, dateString) =>
                            handleCreateTestFieldChanges('end-date', dateString)
                        }
                    />
                  </Form.Item>
                </Col>
                {
                  !isRestricted && (
                      <Col xs={24} sm={12}>
                        <Form.Item label="Serum">
                          <InputNumber
                              value={createTest.serum}
                              onChange={value => handleCreateTestFieldChanges(
                                  'serum', value)}
                              className="width-fluid"
                              size={'large'}
                          />
                        </Form.Item>
                      </Col>
                  )
                }
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={createTest.description}
                        onMcEditorChange={evt =>
                            handleCreateTestFieldChanges('description',
                                evt.editor.getData())
                        }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {createTest.featuredImageUrl === '' ? (
                      <Form.Item label="Featured Image">
                        <Dragger {...uploaderProps} listType="picture">
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <img
                              src={createTest.featuredImageUrl}
                              alt={createTest.featuredImageAltText}
                              style={{width: '100%', maxWidth: 400}}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className={'mc-mt-10'}
                              htmlType={'button'}
                              type={'primary'}
                              onClick={() => handleCreateTestFieldChanges(
                                  'remove-featured-image', '')}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <div className={'seo-section'}>
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      <Input
                          size={'large'}
                          value={createTest.seoMetaTitle}
                          onChange={e =>
                              handleCreateTestFieldChanges('meta-title',
                                  e.target.value)
                          }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      <Input
                          size={'large'}
                          value={createTest.seoSlug}
                          onChange={e =>
                              handleCreateTestFieldChanges('meta-slug',
                                  e.target.value)
                          }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      <TextArea
                          rows={4}
                          value={createTest.seoMetaDescription}
                          onChange={e =>
                              handleCreateTestFieldChanges(
                                  'meta-description',
                                  e.target.value,
                              )
                          }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      <Select
                          mode="tags"
                          size={'large'}
                          value={createTest.seoMetaKeywords}
                          onChange={value => handleCreateTestFieldChanges(
                              'meta-keywords', value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    createTest: getCreateTestDataReselector(state),
    userRole: getUserRoleReselector(state),
  };
};

const CreateTest = withRouter(
    connect(
        mapStateToProps,
        {
          handleCreateTestFieldChanges,
          handleCreateTestFormReset,
        },
    )(Form.create({name: 'create_test'})(CreateTestForm)),
);

export {CreateTest};
