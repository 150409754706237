import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Empty, Icon, message, Pagination, Row, Tag} from 'antd';
import moment from 'moment';
import {Filters} from '../../components';
import {post} from '../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../components/util';

const styles = {
  firstCol: {
    minWidth: 70,
  },
  secondCol: {
    minWidth: 200,
  },
  thirdCol: {
    textAlign: 'left',
  },
  fourthCol: {
    minWidth: 120,
  },
  fifthCol: {
    minWidth: 150,
  },
};

class CommentsPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('comments-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('comments-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    this.state = {
      comments: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      contactsSearchTerm: getLocalData('comments-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(state => ({
      paginationStart: state.currentPageNumber > 0 ?
          (state.currentPageNumber - 1) * state.itemsPerPage + 1 :
          1,
    }), () => this.getComments());
  }

  getComments = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      contactsSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      search_term: contactsSearchTerm,
    };

    post('/api/backtalks/backtalks', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('backtalks')) {
          this.setState({
            comments: resData.backtalks,
            totalItems: resData.total_count,
          });
        }
      } else message.error('Something went wrong! Try again.');
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  handleItemsCount = value => {
    setLocalData('comments-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getComments();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('comments-search-term', value, 1);
    this.setState({
      contactsSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getComments();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('comments-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getComments();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          contactsSearchTerm: '',
        },
        () => {
          setLocalData('comments-items-per-page', 25, 1);
          setLocalData('comments-page-number', 1, 1);
          setLocalData('comments-search-term', '', 1);
          this.getComments();
        },
    );
  };

  redirectToEditOfferPage = id => {
    this.props.history.push('/dashboard/comments/contact-detailed/' + id);
  };

  getStatusBadge = (status) => {
    if (status === 1) {
      return <Tag color="#28a745">Approved</Tag>;
    } else return <Tag color="#17a2b8">Disapproved</Tag>;
  };

  getProductType = prodType => {
    switch (prodType) {
      case 1:
        return 'infoMed';
      case 2:
        return 'medPixx';
      case 3:
        return 'mVideo';
      case 4:
        return 'challenges';
      case 5:
        return 'qBank';
      case 6:
        return 'notes';
      case 7:
        return 'prepMed';
      default:
        return 'Unknown';
    }
  };

  render() {
    const {
      comments,
      currentPageNumber,
      itemsPerPage,
      paginationStart,
      contactsSearchTerm,
      totalItems,
    } = this.state;

    const {history} = this.props;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={24} className="secondary-header-left">
                <p>Comments</p>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                searchTermValue={contactsSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {
              comments.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table notes-table">
                          <thead>
                          <tr>
                            <th style={styles.firstCol}>#</th>
                            <th style={styles.thirdCol}>Message</th>
                            <th style={styles.fourthCol}>Module</th>
                            <th style={styles.fifthCol}>Date</th>
                            <th style={styles.fifthCol}>Seen</th>
                            <th style={styles.fourthCol}>Status</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            comments.map((contact, index) => {
                              const {backtalk_id, message, status, product_type, seen, updated_at} = contact;

                              const redirectToEditOfferPage = () => {
                                history.push('/dashboard/comments/comment-' +
                                    backtalk_id);
                              };

                              return (
                                  <tr key={backtalk_id}>
                                    <td>{paginationStart + index}</td>
                                    <td className="text-left" onClick={redirectToEditOfferPage}>{message}</td>
                                    <td>{this.getProductType(product_type)}</td>
                                    <td>{moment.unix(updated_at).format('DD/MM/YYYY')}</td>
                                    <td>{
                                      seen ?
                                          <Icon
                                              type="eye"
                                              className="active"
                                              title="Seen"
                                          /> : <Icon type="eye-invisible" title="Unseen"/>
                                    }</td>
                                    <td>{this.getStatusBadge(status || 1)}</td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No comments found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

const Comments = withRouter(CommentsPre);

export {Comments};
