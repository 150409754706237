import React from 'react';
import CKEditor from 'react-ckeditor-component';

const ckUrl = '//cdn.ckeditor.com/4.19.0/full/ckeditor.js';

const normalCkConfig = {
  height: 350,
  toolbarGroups: [
    {name: 'document', groups: ['mode', 'document', 'doctools']},
    {name: 'clipboard', groups: ['clipboard', 'undo']},
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing'],
    },
    {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
    },
    {name: 'links', groups: ['links']},
    {name: 'insert', groups: ['insert']},
    {name: 'styles', groups: ['styles']},
    {name: 'colors', groups: ['colors']},
    {name: 'tools', groups: ['tools']},
    {name: 'others', groups: ['others']},
    {name: 'forms', groups: ['forms']},
    {name: 'about', groups: ['about']},
  ],
  removeButtons:
      'Save,NewPage,Preview,Templates,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,RemoveFormat,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,About,Print,ShowBlocks,Iframe,PageBreak,Undo,Redo,Unlink',
};

const questionOptionCkConfig = {
  height: 100,
  toolbarGroups: [
    {name: 'document', groups: ['mode', 'document', 'doctools']},
    {name: 'clipboard', groups: ['clipboard', 'undo']},
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing'],
    },
    {name: 'forms', groups: ['forms']},
    {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
    },
    {name: 'links', groups: ['links']},
    {name: 'insert', groups: ['insert']},
    {name: 'styles', groups: ['styles']},
    {name: 'colors', groups: ['colors']},
    {name: 'tools', groups: ['tools']},
    {name: 'others', groups: ['others']},
    {name: 'about', groups: ['about']},
  ],
  removeButtons:
      'Source,Save,Templates,Cut,Undo,Find,Redo,NewPage,Preview,Print,PasteText,PasteFromWord,Paste,Copy,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,PageBreak,Iframe,Styles,Format,Font,FontSize,TextColor,BGColor,ShowBlocks,About,Strike',
};

const refCkConfig = {
  height: 100,
  toolbarGroups: [
    {name: 'document', groups: ['mode', 'document', 'doctools']},
    {name: 'clipboard', groups: ['clipboard', 'undo']},
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing'],
    },
    {name: 'forms', groups: ['forms']},
    {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
    },
    {name: 'links', groups: ['links']},
    {name: 'insert', groups: ['insert']},
    {name: 'styles', groups: ['styles']},
    {name: 'colors', groups: ['colors']},
    {name: 'tools', groups: ['tools']},
    {name: 'others', groups: ['others']},
    {name: 'about', groups: ['about']},
  ],
  removeButtons:
      'Source,Save,Templates,Cut,Undo,Find,Redo,NewPage,Preview,Print,PasteText,PasteFromWord,Paste,Copy,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,Styles,Format,Font,FontSize,TextColor,BGColor,ShowBlocks,About,Strike',
};

function McCkEditor(props) {
  const {className, type, content, onMcEditorChange} = props;
  const getCkConfig = () => {
    switch (type) {
      case 1:
        return refCkConfig;
      case 2:
        return questionOptionCkConfig;
      default:
        return normalCkConfig;
    }
  };

  return (
      <div className={className}>
        <CKEditor
            scriptUrl={ckUrl}
            config={getCkConfig()}
            content={content}
            events={{
              change: onMcEditorChange,
            }}
        />
      </div>
  );
}

export {McCkEditor};
