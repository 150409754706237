import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  Empty,
  Icon,
  message,
  Pagination,
  Row,
  Spin,
  Tag,
} from 'antd';
import moment from 'moment';
import {showNotePreview} from '../../actions/notes_actions/PreviewNoteActions';
import {get, post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import './Notes.css';

class NotesPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('note-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('note-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let noteStatus = getLocalData('note-status', 1);
    noteStatus = noteStatus !== '' ? parseInt(noteStatus) : 0;

    this.state = {
      noteList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      noteStatus,
      noteSearchTerm: getLocalData('note-search-term', 1),
      modalVisible: false,
      noteTitle: '',
      noteContent: '',
      noteImage: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }),
        () => {
          this.getNotesList();
        },
    );
  }

  getNotesList = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      noteStatus,
      noteSearchTerm,
    } = this.state;

    this.setState({loading: true});

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: '',
      status: parseInt(noteStatus),
      search_term: noteSearchTerm,
    };

    post('/api/notes/notes', data).then(response => {
      if (response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          loading: false,
          totalItems: resData.count,
          noteList: resData.notes,
        });
      }
    });
  };

  setModalVisible(modalVisible) {
    this.setState({modalVisible});
  }

  previewNote = noteId => {
    get(`/api/notes/notes/${noteId}`).then(response => {
      if (response.data.status === true) {
        let noteData = response.data.data.note;
        this.setState({
          noteTitle: noteData.title,
          noteContent: noteData.explanation,
          reference: noteData.reference,
        });
        this.setModalVisible(true);
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('note-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getNotesList();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('note-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          noteStatus: value,
        },
        () => {
          this.getNotesList();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('note-search-term', value, 1);
    this.setState({
      noteSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getNotesList();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('note-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getNotesList();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          noteStatus: 0,
          noteSearchTerm: '',
        },
        () => {
          setLocalData('note-page-number', 1, 1);
          setLocalData('note-page-number', 1, 1);
          setLocalData('note-items-per-page', 25, 1);
          setLocalData('note-status', 0, 1);
          setLocalData('note-search-term', '', 1);
          this.getNotesList();
        },
    );
  };

  redirectToEditPage = id => {
    this.props.history.push(`/dashboard/notes/edit/${id}`);
  };

  render() {
    const {
      noteList,
      currentPageNumber,
      itemsPerPage,
      noteStatus,
      noteSearchTerm,
      totalItems,
      paginationStart,
      loading,
    } = this.state;
    const antIcon = <Icon type="loading" style={{fontSize: 30}} spin/>;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={6} className="secondary-header-left">
                <p>Notes</p>
              </Col>
              <Col span={18} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/notes/create'}>Add Note</Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/notes/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={noteStatus}
                onStatusChange={this.handleStatusChange}
                searchTermValue={noteSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {noteList !== undefined && noteList.length > 0 ? (
                <Row className="mc-mt-10">
                  <Col xs={24}>
                    <Spin indicator={antIcon} spinning={loading}>
                      <div className="mc-table-responsive">
                        <table className="mc-table notes-table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Updated At</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          {noteList.map((note, index) => (
                              <tr key={note.note_id}>
                                <td>{paginationStart + index}</td>
                                <td
                                    onClick={() =>
                                        this.redirectToEditPage(note.note_id)
                                    }
                                >
                                  {note.title}
                                </td>
                                <td>
                                  {moment.unix(note.updated_at).
                                      format('DD/MM/YYYY')}
                                </td>
                                <td>
                                  {note.status === 2 ? (
                                      <Tag color="#28a745">Verified</Tag>
                                  ) : (
                                      <Tag color="#17a2b8">Draft</Tag>
                                  )}
                                </td>
                                <td className="actions-icons">
                                  <Icon
                                      type="eye"
                                      style={{
                                        fontSize: 24,
                                        marginRight: 5,
                                        marginLeft: 5,
                                        verticalAlign: 'center',
                                      }}
                                      title="Preview"
                                      onClick={() =>
                                          this.props.showNotePreview(
                                              `${note.note_id}`)
                                      }
                                  />
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Spin>
                  </Col>
                </Row>
            ) : (
                <Empty
                    className="mc-mtb-100"
                    image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                    description={<span>No notes found</span>}
                />
            )}
          </div>
        </>
    );
  }
}

const Notes = withRouter(connect(
    null,
    {showNotePreview},
)(NotesPre));

export {Notes};
