import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Empty, Icon, message, Row} from 'antd';
import moment from 'moment';
import {
  ContentAnalysisCard,
  CountCard,
  FeedbackCard,
  LevelLeaderBoardCard,
} from '../../components/page_components/home';
import {post} from '../../components/mc_axios/McAxios';

const {RangePicker} = DatePicker;

const fixedDateRanges = {
  Today: [moment(), moment()],
  'Last 7 days': [moment().subtract(7, 'days'), moment()],
  'Last 30 days': [moment().subtract(30, 'days'), moment()],
  'Last 90 days': [moment().subtract(90, 'days'), moment()],
};

const Home = () => {

  const [analysisData, setAnalysisData] = useState({data: null, loading: true});

  useEffect(() => {
    getData();
  }, []);

  const getData = (dateRanges) => {
    const data = {
      start_date: dateRanges && dateRanges.length > 1 ?
          dateRanges[0].unix() :
          0,
      end_date: dateRanges && dateRanges.length > 1 ? dateRanges[1].unix() : 0,
    };

    post('/api/user-manager/admin/analysis', data).
        then(response => {
          const resData = response.data;
          if (resData.status === true && resData.code === 200) {
            setAnalysisData({
              data: resData.data,
              loading: false,
            });
          } else {
            message.error('Something went wrong while getting data');
            setAnalysisData({
              data: null,
              loading: false,
            });
          }
        }).
        catch(() => {
          message.error('Something went wrong while getting data');
          setAnalysisData({
            data: null,
            'loading': false,
          });
        });
  };

  const {data, loading} = analysisData;

  if (loading) return (
      <Row>
        <Col span={24}>
          <h3 className='text-center mc-mt-100'>
            <b><Icon type="loading" className="mc-mr-15"/> Loading...</b>
          </h3>
        </Col>
      </Row>
  );

  if (!data) return (
      <div className="main-content-block">
        <Row gutter={16}>
          <Col span={24}>
            <Empty
                className="mc-mtb-100"
                image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                description={<span>Data not found</span>}
            />
          </Col>
        </Row>
      </div>
  );

  const {
    app_count,
    challenges_count: {total_count: challengesCount},
    test_count: {total_count: testsCount},
    clap_count: {total_count: clapCount},
    subscription_count: {pass_count: passCount = 0, serum_count: serumCount = 0},
    ribbons_count: {total_count: bookmarkCount},
    user_count,
    views_count: {total_count: viewsCount},
    wallet_analysis: {cash_earned, cash_used, serum_earned, serum_used},
    content_analysis,
    feedback_analysis: {
      challenge: {
        great: challengeGreatFeedback,
        normal: challengeNormalFeedback,
        disappointed: challengeDisappointedFeedback,
      },
      prepmed: {
        great: prepmedGreatFeedback,
        normal: prepmedNormalFeedback,
        disappointed: prepmedDisappointedFeedback,
      },
    },
  } = data;

  return (
      <div className="main-content-block-transparent">
        <Row gutter={18}>
          <Col xs={24} sm={12} md={14}>
            <h2 className="mc-mb-15">Registrations & medPass</h2>
          </Col>
          <Col xs={24} sm={12} md={10}>
            <RangePicker
                className="mc-mb-15 width-fluid"
                size="large"
                format="DD-MM-YYYY"
                ranges={fixedDateRanges}
                onChange={getData}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Registrations"
                count={user_count}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="App Downloads"
                count={app_count}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="MedPass / Serum"
                count={passCount}
                countTwo={serumCount}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Completed Challenges"
                count={challengesCount}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Completed Tests"
                count={testsCount}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="View Count"
                count={viewsCount}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Total clap count"
                count={clapCount}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Total bookmark count"
                count={bookmarkCount}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <h2 className="mc-mb-15">Play & Win</h2>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Money Used"
                count={cash_used}
                isMoney
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Money Won"
                count={cash_earned}
                isMoney
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Serum Used"
                count={serum_used}
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <CountCard
                title="Serum Won"
                count={serum_earned}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <h2 className="mc-mt-10 mc-mb-15">Content</h2>
          </Col>
          <Col span={24}>
            <ContentAnalysisCard data={content_analysis}/>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <h2 className="mc-mt-20 mc-mb-15">Feedback</h2>
          </Col>
          <Col xs={24} sm={12}>
            <FeedbackCard
                title="Challenges"
                great={challengeGreatFeedback}
                normal={challengeNormalFeedback}
                disappointed={challengeDisappointedFeedback}
            />
          </Col>
          <Col xs={24} sm={12}>
            <FeedbackCard
                title="PrepMed"
                great={prepmedGreatFeedback}
                normal={prepmedNormalFeedback}
                disappointed={prepmedDisappointedFeedback}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <h2 className="mc-mt-20 mc-mb-15">Level Leaderboard</h2>
          </Col>
          <Col span={24}>
            <LevelLeaderBoardCard/>
          </Col>
        </Row>
      </div>
  );
};

export {Home};
