import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  Empty,
  Icon,
  message,
  Modal,
  Pagination,
  Row,
  Spin,
  Tag,
} from 'antd';
import moment from 'moment';
import {get, post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {createMarkup, getLocalData, setLocalData} from '../../components/util';
import './Infomed.css';

class Infomed extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('infomed-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('infomed-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let infomedStatus = getLocalData('infomed-status', 1);
    infomedStatus = infomedStatus !== '' ? parseInt(infomedStatus) : 0;

    this.state = {
      infomedList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      loading: false,
      infomedStatus,
      infomedSystem: getLocalData('infomed-system', 1),
      infomedSubject: getLocalData('infomed-subject', 1),
      infomedSearchTerm: getLocalData('infomed-search-term', 1),
      modalVisible: false,
      articleTitle: '',
      articleContent: '',
      articleImageUrl: '',
      reference: '',
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }),
        () => {
          this.getInfomedList();
        },
    );
  }

  setModalVisible(modalVisible) {
    this.setState({modalVisible});
  }

  getInfomedList = () => {
    this.setState({loading: true});
    const {
      itemsPerPage,
      currentPageNumber,
      infomedSubject,
      infomedSystem,
      infomedStatus,
      infomedSearchTerm,
    } = this.state;
    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: infomedSubject,
      system: infomedSystem,
      status: parseInt(infomedStatus),
      search_term: infomedSearchTerm,
    };

    post('/api/infomed/articles', data).then(response => {
      if (response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          loading: false,
          totalItems: resData.count,
          infomedList: resData.list,
        });
      }
    });
  };

  previewArticle = articleId => {
    get(`/api/infomed/articles/${articleId}`).then(response => {
      if (response.data.status === true) {
        let infomedData = response.data.data.infomed;
        this.setState({
          articleTitle: infomedData.title,
          articleImageUrl: infomedData.featured_image_url,
          articleContent: infomedData.description,
          reference: infomedData.reference,
        });
        this.setModalVisible(true);
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('infomed-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getInfomedList();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('infomed-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          infomedStatus: value,
        },
        () => {
          this.getInfomedList();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('infomed-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          infomedSubject: value,
        },
        () => {
          this.getInfomedList();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('infomed-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          infomedSystem: value,
        },
        () => {
          this.getInfomedList();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('infomed-search-term', value, 1);
    this.setState({
      infomedSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getInfomedList();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('infomed-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getInfomedList();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          infomedStatus: 0,
          infomedSubject: '',
          infomedSystem: '',
          infomedSearchTerm: '',
        },
        () => {
          setLocalData('infomed-items-per-page', 25, 1);
          setLocalData('infomed-page-number', 1, 1);
          setLocalData('infomed-status', 0, 1);
          setLocalData('infomed-subject', '', 1);
          setLocalData('infomed-system', '', 1);
          setLocalData('infomed-search-term', '', 1);
          this.getInfomedList();
        },
    );
  };

  redirectToEditPage = id => {
    this.props.history.push(`/dashboard/mnotes/edit/${id}`);
  };

  getStatusBadge = (status) => {
    switch (status) {
      case 2:
        return (
            <Tag color="#28a745">Published</Tag>
        );
      case 1:
        return (
            <Tag color="#17a2b8">Draft</Tag>
        );
      default:
        return (
            <Tag color="gray">Saved</Tag>
        );
    }
  };

  render() {
    const {
      infomedList,
      currentPageNumber,
      itemsPerPage,
      infomedStatus,
      infomedSubject,
        infomedSystem,
      infomedSearchTerm,
      totalItems,
      paginationStart,
      modalVisible,
      articleTitle,
      articleImageUrl,
      articleContent,
      reference,
      loading,
    } = this.state;
    const antIcon = <Icon type="loading" style={{fontSize: 30}} spin/>;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={6} className="secondary-header-left">
                <p>mNotes</p>
              </Col>
              <Col span={18} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/mnotes/create'}>create mNote</Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/mnotes/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={infomedStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={infomedSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={infomedSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={infomedSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {infomedList !== undefined && infomedList.length > 0 ? (
                <Row className="mc-mt-10">
                  <Col xs={24}>
                    {' '}
                    <Spin indicator={antIcon} spinning={loading}>
                      <div className="mc-table-responsive">
                        <table className="mc-table infomed-table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Updated At</th>
                            <th>Unlock Count</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          {infomedList.map((infomed, index) => {
                            const {infomed_id, title, status, updated_at, unlock_count} = infomed;
                            return (
                                <tr key={infomed_id}>
                                  <td>{paginationStart + index}</td>
                                  <td
                                      onClick={() =>
                                          this.redirectToEditPage(
                                              infomed_id)
                                      }
                                  >
                                    {title}
                                  </td>
                                  <td>{this.getStatusBadge(status)}</td>
                                  <td>
                                    {moment.unix(updated_at).
                                        format('DD/MM/YYYY')}
                                  </td>
                                  <td>
                                    {unlock_count ? unlock_count : 0}
                                  </td>
                                  <td className="actions-icons">
                                    <Icon
                                        type="eye"
                                        style={{
                                          fontSize: 24,
                                          marginRight: 5,
                                          marginLeft: 5,
                                          verticalAlign: 'center',
                                        }}
                                        title="Preview"
                                        onClick={() => this.previewArticle(
                                            `${infomed_id}`)}
                                    />
                                  </td>
                                </tr>
                            );
                          })}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                      <Modal
                          title="Preview Article"
                          centered
                          width={1000}
                          visible={modalVisible}
                          onCancel={() => this.setModalVisible(false)}
                          footer={null}
                          className="preview-modal-content"
                      >
                        <div className="watermark-layer"/>
                        <Row>
                          <Col span={24}>
                            <h4>
                              <u>Title:</u>
                            </h4>
                            <h3>{articleTitle}</h3>
                            <br/>
                          </Col>
                          <Col span={24}>
                            <img
                                src={articleImageUrl}
                                alt={articleTitle}
                                className="mc-mb-10"
                            />
                          </Col>
                          {articleContent !== undefined && articleContent !==
                          '' ? (
                              <Col span={24}>
                                {/*<h4>*/}
                                {/*    <u>Explanation:</u>*/}
                                {/*</h4>*/}
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        articleContent,
                                    )}
                                />
                              </Col>
                          ) : (
                              ''
                          )}
                          {reference !== undefined && reference !== '' ? (
                              <Col span={24}>
                                <hr/>
                                <h4>
                                  <u>Reference:</u>
                                </h4>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        reference,
                                    )}
                                />
                              </Col>
                          ) : (
                              ''
                          )}
                        </Row>
                      </Modal>
                    </Spin>
                  </Col>
                </Row>
            ) : (
                <Empty
                    className="mc-mtb-100"
                    image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                    description={<span>No articles found</span>}
                />
            )}
          </div>
        </>
    );
  }
}

export {Infomed};
