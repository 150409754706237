import React from 'react';
import {
  Card,
  Col,
  Divider,
  Empty,
  Icon,
  message,
  Pagination,
  Popconfirm,
  Row,
} from 'antd';
import {Link} from 'react-router-dom';
import {patch, post} from '../../../components/mc_axios/McAxios';
import {Video} from '../../../components';

class MedvideoTrash extends React.Component {
  state = {
    medvideoList: [],
    currentPageNumber: 1,
    itemsPerPage: 10,
    totalItems: 0,
    paginationStart: 1,
  };

  componentDidMount() {
    this.getMedvideoList();
  }

  getMedvideoList = () => {
    const {itemsPerPage, currentPageNumber} = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: '',
      status: 3,
      search_term: '',
    };

    post('/api/television/videos', data).then(response => {
      if (response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          totalItems: resData.count,
          medvideoList: resData.list,
        });
      }
    });
  };

  confirm(actionType, medvideoId) {
    if (actionType === 1) {
      patch('/api/television/videos/restore', {video_id: medvideoId}).then(
          response => {
            if (response.data.status === true) {
              message.success('Medvideo has been restored successfully');
              this.getMedvideoList();
            } else {
              message.error('Something went wrong. Try again.');
            }
          },
      );
    } else {
      post('/api/television/videos/delete', {video_id: medvideoId}).then(
          response => {
            if (response.data.status === true) {
              message.success('Medvideo has been deleted successfully');
              this.getMedvideoList();
            } else {
              message.error('Something went wrong. Try again.');
            }
          },
      );
    }
  }

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getMedvideoList();
        },
    );
  };

  render() {
    const {
      medvideoList,
      currentPageNumber,
      itemsPerPage,
      totalItems,
    } = this.state;

    return (
        <div className="main-content-block">
          <Row gutter={16}>
            <Col span={24}>
              <div className="page-header">
                <Link to={'/dashboard/medvideo/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Trash
              </div>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
          </Row>
          {medvideoList !== undefined && medvideoList.length > 0 ? (
              <Row gutter={16} className="mc-mt-10 medvideo-grid">
                {medvideoList.map((data, index) => (
                    <Col span={6} key={index}>
                      <Card
                          actions={[
                            <Popconfirm
                                title="Are you sure restore this medvideo?"
                                onConfirm={() => this.confirm(1, data.video_id)}
                                onCancel={() =>
                                    message.error(
                                        'You have canceled the restore operation')
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                              <Icon type="undo" title="Restore"/>
                            </Popconfirm>,
                            <Popconfirm
                                title="Are you sure delete this medvideo?"
                                onConfirm={() => this.confirm(2, data.video_id)}
                                onCancel={() =>
                                    message.error(
                                        'You have canceled the delete operation')
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                              <Icon type="delete" title="Delete"/>
                            </Popconfirm>,
                          ]}
                      >
                        <Video videoSrc={data.video_url}
                               videoTitle="Med Video"/>
                      </Card>
                    </Col>
                ))}
                <Col span={24}>
                  <br/>
                  <Pagination
                      current={currentPageNumber}
                      pageSize={itemsPerPage}
                      total={totalItems}
                      onChange={this.onPageChange}
                  />
                </Col>
              </Row>
          ) : (
              <Empty
                  className="mc-mtb-100"
                  image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                  description={<span>No deleted medvideos found</span>}
              />
          )}
        </div>
    );
  }
}

export {MedvideoTrash};
