import React from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
  Upload,
} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {McCkEditor} from '../../../components';
import {
  getTestData,
  handleEditTestFieldChanges,
  handleEditTestFormReset,
  handleSeoDataChange,
} from '../../../actions/prepmed_actions/EditTestActions';
import {patch, post} from '../../../components/mc_axios/McAxios';
import {convertToSeconds} from '../../../components/util';
import {
  getEditTestDataReselector,
  getUserRoleReselector,
} from '../../../redux/selectors';

const Option = Select.Option;
const Dragger = Upload.Dragger;
const {TextArea} = Input;
const {Paragraph} = Typography;

class EditTestForm extends React.Component {
  componentDidMount() {
    const {match, getTestData} = this.props;
    getTestData(match.params.testId);
  }

  componentWillUnmount() {
    this.props.handleEditTestFormReset();
  }

  handleSubmit = (status) => {
    const {totalQuestions, questionIds, featuredImageUrl} = this.props.editTest;
    if (status === 2) {
      if (totalQuestions > questionIds.length) {
        message.warning(
            'You can\'t publish the test without adding required questions.');
      } else this.updateTest(status);
    } else if (status === 4) {
      if (featuredImageUrl === '') {
        message.warning('Please add image to create');
      } else this.updateTest(status);
    } else this.updateTest(status);
  };

  updateTest = (currStatus) => {
    const {
      editTest: {id, title, isPremium, basis, mode, totalQuestions, totalTime, status, questionIds, correctQuestionMarks, wrongQuestionMarks, startDate, endDate, serum, description, featuredImageUrl, featuredImageAltText, seoMetaTitle, seoSlug, seoMetaDescription, seoMetaKeywords, createdAt, updatedAt},
      handleEditTestFormReset,
      history,
    } = this.props;

    let data = {
      test_id: id,
      test_title: title,
      status: currStatus === 2 ? status : currStatus,
      prime: isPremium,
      test_basis: parseInt(basis),
      mode: parseInt(mode),
      number_of_questions: parseInt(totalQuestions),
      total_time: convertToSeconds(totalTime),
      correct_question_marks: parseInt(correctQuestionMarks),
      wrong_question_marks: parseInt(wrongQuestionMarks),
      start_time: moment(startDate, 'DD/MM/YYYY HH:mm:ss').unix(),
      end_time: moment(endDate, 'DD/MM/YYYY HH:mm:ss').unix(),
      serum,
      description: description,
      featured_image_url: featuredImageUrl,
      featured_image_alt_text: featuredImageAltText,
      question_ids: questionIds,
      meta_title: seoMetaTitle,
      slug: seoSlug,
      meta_description: seoMetaDescription,
      meta_keywords: seoMetaKeywords,
      created_at: createdAt,
      updated_at: updatedAt,
    };

    post('/api/tests/tests/update', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        handleEditTestFormReset();
        if (currStatus === 2) {
          this.publishTest(id);
        } else message.success('Test has been updated');
        history.push(
            `/dashboard/prepmed/add-questions/${response.data.data.test_id}`);
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  publishTest = id => {
    post('/api/tests/tests/publish', {test_id: id}).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Test has been published');
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  confirmDelete = () => {
    patch('/api/tests/tests/delete', {
      test_id: this.props.editTest.id,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Notes deleted successfully');
        this.props.history.push('/dashboard/prepmed/');
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  render() {
    const {
      editTest: {id, title, isPremium, basis, mode, totalQuestions, totalTime, correctQuestionMarks, wrongQuestionMarks, startDate, endDate, serum, description, featuredImageUrl, featuredImageAltText, seoMetaTitle, seoSlug, seoMetaDescription, seoMetaKeywords},
      userRole,
      handleEditTestFieldChanges,
      handleSeoDataChange,
    } = this.props;

    const isRestricted = userRole !== 2;
    const testBasis = parseInt(basis);
    const testMode = parseInt(mode);
    const uploaderProps = {
      name: 'file',
      accept: '.png, .jpg, .jpeg',
      action: '/api/media/upload',
      data: {
        product_type: 8,
      },
      onChange(info) {
        if (info.file.status === 'done' && info.file.response.status === true) {
          let fileData = info.file.response.data;
          message.success(`${info.file.name} file uploaded successfully`);
          let data = {
            imgUrl: fileData.file_url,
            imgAltText: fileData.alt_text,
          };
          handleEditTestFieldChanges('featured-image', data);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    const testFullUrl = 'https://www.medcampus.io/prepmed/test/' + seoSlug + '-' + id;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/prepmed/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Test
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  onCancel={() => message.error('You have canceled the delete')}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Update
              </Button>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(4)}
                  className="mc-mr-5"
              >
                Partial Publish
              </Button>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
                  className="mc-mr-5"
              >
                Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <p className={'mc-mt-10'}>
                    Title:
                    {
                      !isRestricted && (
                          <span className="pull-right">
                            Premium
                            <Switch
                                className="mc-ml-15"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={isPremium}
                                onChange={checked =>
                                    handleEditTestFieldChanges(
                                        'premium', checked)
                                }
                                style={{width: 60}}
                            />
                          </span>
                      )
                    }
                  </p>
                </Col>
                <Col span={24}>
                  <Input
                      placeholder="Enter title"
                      size={'large'}
                      value={title}
                      onChange={e =>
                          handleEditTestFieldChanges('title', e.target.value)
                      }
                      className="mc-mb-15"
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="Type">
                    <Select
                        size={'large'}
                        value={testBasis}
                        disabled
                    >
                      <Option value={1}>NEET PG</Option>
                      <Option value={2}>AIIMS PG</Option>
                      <Option value={3}>USMLE STEP I</Option>
                      <Option value={4}>USMLE STEP II</Option>
                      {/*<Option value={5}>PGI CHANDIGARH</Option>*/}
                      {/*<Option value={6}>JIPMER</Option>*/}
                      {/*<Option value={7}>NIMHANS</Option>*/}
                      <Option value={9}>UPSC CMS</Option>
                      <Option value={10}>Subject Wise Tests</Option>
                      <Option value={8}>ANY</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mode">
                    <Select
                        size={'large'}
                        value={testMode}
                        onChange={value => handleEditTestFieldChanges('mode',
                            value)}
                    >
                      <Option value={0}>Exam & Practice</Option>
                      <Option value={1}>Exam</Option>
                      <Option value={2}>Practice</Option>
                      <Option value={3}>Grand Test</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {
                basis === 8 ? <>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Total Questions">
                        <InputNumber
                            min={0}
                            value={totalQuestions}
                            onChange={value => handleEditTestFieldChanges(
                                'total-questions', value)}
                            className="width-fluid"
                            size={'large'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Total Time (hh:mm)">
                        <TimePicker
                            format={'HH:mm'}
                            size={'large'}
                            allowClear={false}
                            value={moment(totalTime, 'HH:mm')}
                            className="width-fluid"
                            onChange={(
                                time, timeString) => handleEditTestFieldChanges(
                                'total-time', timeString)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Correct Answer Marks">
                        <InputNumber
                            min={0}
                            value={correctQuestionMarks}
                            onChange={value => handleEditTestFieldChanges('cqm',
                                value)}
                            className="width-fluid"
                            size={'large'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Incorrect Answer Marks">
                        <InputNumber
                            min={0}
                            value={wrongQuestionMarks}
                            onChange={value => handleEditTestFieldChanges('wqm',
                                value)}
                            className="width-fluid"
                            size={'large'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </> : ''
              }
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Start Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        showTime
                        format="DD/MM/YYYY HH:mm:ss"
                        value={
                          startDate !== '' ?
                              moment(startDate, 'DD/MM/YYYY HH:mm:ss') :
                              undefined
                        }
                        onChange={(
                            date, dateString) => handleEditTestFieldChanges(
                            'start-date', dateString)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="End Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        showTime
                        format="DD/MM/YYYY HH:mm:ss"
                        value={
                          endDate !== ''
                              ? moment(endDate, 'DD/MM/YYYY HH:mm:ss')
                              : undefined
                        }
                        onChange={(date, dateString) =>
                            handleEditTestFieldChanges('end-date', dateString)
                        }
                    />
                  </Form.Item>
                </Col>
                {
                  !isRestricted && (
                      <Col xs={24} sm={12}>
                        <Form.Item label="Serum">
                          <InputNumber
                              value={serum}
                              onChange={value => handleEditTestFieldChanges(
                                  'serum', value)}
                              className="width-fluid"
                              size={'large'}
                          />
                        </Form.Item>
                      </Col>
                  )
                }
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={evt =>
                            handleEditTestFieldChanges(
                                'description',
                                evt.editor.getData(),
                            )
                        }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {featuredImageUrl === '' ? (
                      <Form.Item label="Featured Image">
                        <Dragger {...uploaderProps} listType="picture">
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <img
                              src={featuredImageUrl}
                              alt={featuredImageAltText}
                              style={{width: '100%', maxWidth: 400}}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className={'mc-mt-10'}
                              htmlType={'button'}
                              type={'primary'}
                              onClick={() => handleEditTestFieldChanges(
                                  'remove-featured-image', '')}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <Row>
                <Col span={24}>
                  <p className="mc-mt-10">Test url</p>
                  <Card>
                    <Paragraph className="mc-mb-0"
                               copyable>{testFullUrl}</Paragraph>
                  </Card>
                </Col>
                <Col span={24}>
                  <div className={'seo-section'}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <h3>SEO</h3>
                        <hr/>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Meta Title">
                          <Input
                              size={'large'}
                              value={seoMetaTitle}
                              onChange={e =>
                                  handleSeoDataChange('seo-meta-title',
                                      e.target.value)
                              }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Slug">
                          <Input
                              size={'large'}
                              value={seoSlug}
                              onChange={e =>
                                  handleSeoDataChange('seo-meta-slug',
                                      e.target.value)
                              }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Meta Description">
                          <TextArea
                              rows={4}
                              value={seoMetaDescription}
                              onChange={e =>
                                  handleSeoDataChange(
                                      'seo-meta-description',
                                      e.target.value,
                                  )
                              }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Meta Keywords">
                          <Select
                              mode="tags"
                              size={'large'}
                              value={seoMetaKeywords}
                              onChange={value =>
                                  handleSeoDataChange('seo-meta-keywords',
                                      value)
                              }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    editTest: getEditTestDataReselector(state),
    userRole: getUserRoleReselector(state),
  };
};

const EditTest = withRouter(
    connect(
        mapStateToProps,
        {
          getTestData,
          handleEditTestFieldChanges,
          handleSeoDataChange,
          handleEditTestFormReset,
        },
    )(Form.create({name: 'edit_test'})(EditTestForm)),
);

export {EditTest};
