import React, {Component} from 'react';
import {Button, Col, Empty, Pagination, Row, Tag} from 'antd';
import {Link} from 'react-router-dom';
import {post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import moment from 'moment';

class PWChallenges extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('pw-challenge-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('pw-challenge-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let pwChallengeStatus = getLocalData('pw-challenge-status', 1);
    pwChallengeStatus = pwChallengeStatus !== '' ?
        parseInt(pwChallengeStatus) :
        0;

    this.state = {
      pwChallengesList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      pwChallengeStatus,
      pwChallengeSubject: getLocalData('pw-challenge-subject', 1),
      pwChallengeSystem: getLocalData('pw-challenge-system', 1),
      pwChallengeSearchTerm: getLocalData('pw-challenge-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }), () => this.getPWChallengesList());
  }

  getPWChallengesList = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      pwChallengeSubject,
      pwChallengeSystem,
      pwChallengeStatus,
      pwChallengeSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: pwChallengeSubject,
      system: pwChallengeSystem,
      status: parseInt(pwChallengeStatus),
      search_term: pwChallengeSearchTerm,
    };

    post('/api/challenzes/plays', data).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          totalItems: resData.count,
          pwChallengesList: resData.plays,
        });
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('pw-challenge-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getPWChallengesList();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('pw-challenge-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          pwChallengeStatus: value,
        },
        () => {
          this.getPWChallengesList();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('pw-challenge-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          pwChallengeSubject: value,
        },
        () => {
          this.getPWChallengesList();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('pw-challenge-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          pwChallengeSystem: value,
        },
        () => {
          this.getPWChallengesList();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('pw-challenge-search-term', value, 1);
    this.setState({
      pwChallengeSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getPWChallengesList();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('pw-challenge-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getPWChallengesList();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          pwChallengeStatus: 0,
          pwChallengeSubject: '',
          pwChallengeSystem: '',
          pwChallengeSearchTerm: '',
        },
        () => {
          setLocalData('pw-challenge-items-per-page', 25, 1);
          setLocalData('pw-challenge-page-number', 1, 1);
          setLocalData('pw-challenge-status', 0, 1);
          setLocalData('pw-challenge-subject', '', 1);
          setLocalData('pw-challenge-system', '', 1);
          setLocalData('pw-challenge-search-term', '', 1);
          this.getPWChallengesList();
        },
    );
  };

  redirectToAddQuestionsPage = id => {
    this.props.history.push(
        `/dashboard/play-and-win-challenges/add-questions/${id}`);
  };

  getStatusBadge = (status) => {
    switch (status) {
      case 2:
        return (
            <Tag color="#28a745">Published</Tag>
        );
      case 1:
        return (
            <Tag color="gray">Draft</Tag>
        );
      default:
        return (
            <Tag color="#17a2b8">Saved</Tag>
        );
    }
  };

  render() {
    const {
      pwChallengesList,
      currentPageNumber,
      itemsPerPage,
      pwChallengeStatus,
      pwChallengeSubject,
      pwChallengeSystem,
      pwChallengeSearchTerm,
      totalItems,
      paginationStart,
    } = this.state;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>P&W Challenges</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/play-and-win-challenges/create/'}>
                    Create Challenge
                  </Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link
                      to={'/dashboard/play-and-win-challenges/trash/'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={pwChallengeStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={pwChallengeSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={pwChallengeSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={pwChallengeSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {
              pwChallengesList !== undefined && pwChallengesList.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table notes-table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Updated At</th>
                            <th>Attempted Users</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            pwChallengesList.map((challenge, index) => {
                              const {play_id, play_name, status, updated_at, completed_count} = challenge;
                              return (
                                  <tr key={play_id}>
                                    <td>{paginationStart + index}</td>
                                    <td onClick={() => this.redirectToAddQuestionsPage(play_id)}>
                                      {play_name}
                                    </td>
                                    <td>
                                      {this.getStatusBadge(status)}
                                    </td>
                                    <td>
                                      {
                                        moment.unix(updated_at).
                                            format('DD/MM/YYYY')
                                      }
                                    </td>
                                    <td>
                                      {
                                        completed_count ?
                                            completed_count : 0
                                      }
                                    </td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No challenges found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

export {PWChallenges};
