import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Row,
  Upload,
} from 'antd';
import {post} from '../../../components/mc_axios/McAxios';

const Dragger = Upload.Dragger;

class CreateSlideForm extends React.Component {

  state = {
    desktopImageUrl: '',
    mobileImageUrl: '',
    altText: '',
    referenceUrl: '',
  };

  handleDesktopImageChange = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      message.success(`${info.file.name} file uploaded successfully`);
      const {data} = info.file.response;
      this.setState({
        desktopImageUrl: data.file_url,
        altText: data.alt_text,
      });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleMobileImageChange = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      message.success(`${info.file.name} file uploaded successfully`);
      let data = info.file.response.data;
      this.setState({
        mobileImageUrl: data.file_url,
        altText: data.alt_text,
      });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeImage = (type) => {
    if (type === 'desktop') {
      this.setState({
        desktopImageUrl: '',
      });
    } else {
      this.setState({
        mobileImageUrl: '',
      });
    }
  };

  handleRefUrlChange = e => {
    this.setState({
      referenceUrl: e.target.value,
    });
  };

  handleCreateSlide = () => {
    const {desktopImageUrl, mobileImageUrl, altText, referenceUrl} = this.state;
    if (desktopImageUrl === '' || mobileImageUrl === '' || referenceUrl === '') {
      message.warning('Web, Mobile image and reference links are mandatory');
    } else {
      const data = {
        desktop_image: desktopImageUrl,
        mobile_image: mobileImageUrl,
        alt_text: altText,
        reference: referenceUrl,
      };

      post('/api/coordinates/coordinates/sliders/create', data).
          then(response => {
            if (response.data.status === true && response.data.code === 200) {
              message.success('Slide has been created successfully');
              this.props.history.push('/dashboard/slider/');
            } else {
              message.error('Something went wrong! Try again.');
            }
          });
    }
  };

  render() {
    const {desktopImageUrl, mobileImageUrl, altText, referenceUrl} = this.state;

    return (
        <Row className="main-content-block" gutter={16}>
          <Col span={24}>
            <Form>
              <Row gutter={32}>
                <Col xs={24} sm={12}>
                  <div className="page-header">
                    <Link to={'/dashboard/slider/'}>
                      <Icon type="arrow-left" title={'Back'}/>
                    </Link>
                    <Divider type="vertical"/> Create Slide
                  </div>
                </Col>
                <Col xs={24} sm={12} className="text-right">
                  <Button
                      htmlType="button"
                      className="mc-mr-5"
                      onClick={this.handleCreateSlide}
                  >
                    Create
                  </Button>
                </Col>
                <Col span={24}>
                  <hr className="mc-mt-10"/>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item label="Desktop Image">
                    {
                      desktopImageUrl === '' ? (
                          <Dragger
                              name="file"
                              accept=".png, .jpg, .jpeg"
                              action="/api/media/upload"
                              data={{product_type: 2}}
                              listType="picture"
                              onChange={this.handleDesktopImageChange}
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox"/>
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                      ) : (<>
                        <img
                            alt={altText}
                            src={desktopImageUrl}
                            className="responsive-image"
                        />
                        <Button
                            type="danger"
                            htmlType={'button'}
                            className="mc-mt-10"
                            onClick={() => this.removeImage('desktop')}
                        >Remove</Button>
                      </>)
                    }
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mobile Image">
                    {
                      mobileImageUrl === '' ? (
                          <Dragger
                              name="file"
                              accept=".png, .jpg, .jpeg"
                              action="/api/media/upload"
                              data={{product_type: 2}}
                              listType="picture"
                              onChange={this.handleMobileImageChange}
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox"/>
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                      ) : (<>
                        <img
                            alt={altText}
                            src={mobileImageUrl}
                            className="responsive-image"
                        />
                        <Button
                            type="danger"
                            htmlType={'button'}
                            className="mc-mt-10"
                            onClick={() => this.removeImage('mobile')}
                        >Remove</Button>
                      </>)
                    }
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} md={12}>
                  <Form.Item label="Reference Link">
                    <Input
                        placeholder={'https://www.xyz.com/'}
                        size={'large'}
                        value={referenceUrl}
                        onChange={this.handleRefUrlChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    );
  }
}

const CreateSlide = Form.create({name: 'create_slide'})(CreateSlideForm);

export {CreateSlide};
