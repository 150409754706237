import React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {McCkEditor} from '../../../components';
import {post} from '../../../components/mc_axios/McAxios';
import {getLocalData, setLocalData, slugify} from '../../../components/util';

const {TextArea} = Input;
const Dragger = Upload.Dragger;

class CreatePWChallenge extends React.Component {
  handlers = {};

  constructor(props) {
    super(props);

    let challengeBasis = getLocalData('create-pw-challenge-basis', 1);
    challengeBasis = challengeBasis !== '' ? challengeBasis : 0;

    let totalQuestions = getLocalData('create-pw-challenge-totalQuestions', 1);
    totalQuestions = totalQuestions !== '' ? totalQuestions : 0;

    let rewardAmount = getLocalData('create-pw-challenge-rewardAmount', 1);
    rewardAmount = rewardAmount !== '' ? rewardAmount : 0;

    this.state = {
      title: getLocalData('create-pw-challenge-title', 1),
      basis: challengeBasis,
      totalQuestions: totalQuestions,
      startDate: getLocalData('create-pw-challenge-startDate', 1),
      description: getLocalData('create-pw-challenge-description', 1),
      featuredImageUrl: getLocalData('create-pw-challenge-featuredImageUrl', 1),
      featuredImageAltText: getLocalData(
          'create-pw-challenge-featuredImageAltText', 1),
      featuredImageLink: getLocalData('create-pw-challenge-featuredImageLink',
          1),
      thumbnailImageUrl: getLocalData('create-pw-challenge-thumbnailImageUrl',
          1),
      thumbnailImageAltText: getLocalData(
          'create-pw-challenge-thumbnailImageAltText', 1),
      sponsorAdMediaUrl: getLocalData('create-pw-challenge-sponsorAdMediaUrl',
          1),
      sponsorAdLink: getLocalData('create-pw-challenge-sponsorAdLink', 1),
      rewardAmount,
      metaTitle: getLocalData('create-pw-challenge-metaTitle', 1),
      slug: getLocalData('create-pw-challenge-slug', 1),
      metaDescription: getLocalData('create-pw-challenge-metaDescription', 1),
      metaKeywords: getLocalData('create-pw-challenge-metaKeywords', 2),
    };
  }

  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const {value} = event.target;
        setLocalData('create-pw-challenge-' + name, value, 1);
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        setLocalData('create-pw-challenge-' + name, value, 2);
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleChangeByDateValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = (moment, dateString) => {
        setLocalData('create-pw-challenge-' + name, dateString, 1);
        this.setState({
          [name]: dateString,
        });
      };
    }
    return this.handlers[name];
  };

  handleEditorChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const data = event.editor.getData();
        setLocalData('create-pw-challenge-' + name, data, 1);
        this.setState({
          [name]: data,
        });
      };
    }
    return this.handlers[name];
  };

  handleMediaUpload = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = info => {
        if (info.file.status === 'done' && info.file.response.status === true) {
          const {file_url, alt_text} = info.file.response.data;
          message.success(`${info.file.name} file uploaded successfully`);
          if (name === 'featuredImageUrl') {
            setLocalData('create-pw-challenge-featuredImageUrl', file_url, 1);
            setLocalData('create-pw-challenge-featuredImageAltText', alt_text,
                1);
            this.setState({
              featuredImageUrl: file_url,
              featuredImageAltText: alt_text,
            });
          } else if (name === 'thumbnailImageUrl') {
            setLocalData('create-pw-challenge-thumbnailImageUrl', file_url, 1);
            setLocalData('create-pw-challenge-thumbnailImageAltText', alt_text,
                1);
            this.setState({
              thumbnailImageUrl: file_url,
              thumbnailImageAltText: alt_text,
            });
          } else {
            setLocalData('create-pw-challenge-sponsorAdMediaUrl', file_url, 1);
            this.setState({
              sponsorAdMediaUrl: file_url,
            });
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      };
    }
    return this.handlers[name];
  };

  handleMediaRemoval = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = () => {
        if (name === 'featuredImageUrl') {
          setLocalData('create-pw-challenge-featuredImageUrl', '', 1);
          setLocalData('create-pw-challenge-featuredImageAltText', '', 1);
          this.setState({
            featuredImageUrl: '',
            featuredImageAltText: '',
          });
        } else if (name === 'thumbnailImageUrl') {
          setLocalData('create-pw-challenge-thumbnailImageUrl', '', 1);
          setLocalData('create-pw-challenge-thumbnailImageAltText', '', 1);
          this.setState({
            thumbnailImageUrl: '',
            thumbnailImageAltText: '',
          });
        } else {
          setLocalData('create-pw-challenge-sponsorAdMediaUrl', '', 1);
          this.setState({
            sponsorAdMediaUrl: '',
          });
        }
      };
    }
    return this.handlers[name];
  };

  handleFillMetaTitleSlug = e => {
    const {value} = e.target;
    const slugifyTitle = slugify(value);
    setLocalData('create-pw-challenge-metaTitle', value, 1);
    setLocalData('create-pw-challenge-slug', slugifyTitle, 1);
    this.setState({
      metaTitle: value,
      slug: slugifyTitle,
    });
  };

  handleSubmit = () => {
    if (this.state.title !== '') {
      const {title, totalQuestions, startDate, featuredImageUrl, featuredImageAltText, featuredImageLink, thumbnailImageUrl, thumbnailImageAltText, description, sponsorAdMediaUrl, sponsorAdLink, rewardAmount, metaTitle, metaDescription, slug, metaKeywords} = this.state;

      let data = {
        play_name: title,
        status: 0,
        number_of_questions: parseInt(totalQuestions),
        start_time: moment(startDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        description: description,
        featured_image_url: featuredImageUrl,
        featured_image_alt_text: featuredImageAltText,
        featured_image_link: featuredImageLink,
        thumbnail_image_url: thumbnailImageUrl,
        thumbnail_image_alt_text: thumbnailImageAltText,
        sponsored_add: sponsorAdMediaUrl,
        sponsor_link: sponsorAdLink,
        reward_amount: parseFloat(rewardAmount),
        meta_title: metaTitle,
        slug: slug,
        meta_description: metaDescription,
        meta_keywords: metaKeywords,
      };

      post('/api/challenzes/plays/create', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          setLocalData('create-pw-challenge-title', '', 1);
          setLocalData('create-pw-challenge-totalQuestions', '', 1);
          setLocalData('create-pw-challenge-startDate', '', 1);
          setLocalData('create-pw-challenge-description', '', 1);
          setLocalData('create-pw-challenge-thumbnailImageUrl', '', 1);
          setLocalData('create-pw-challenge-thumbnailImageAltText', '', 1);
          setLocalData('create-pw-challenge-featuredImageUrl', '', 1);
          setLocalData('create-pw-challenge-featuredImageAltText', '', 1);
          setLocalData('create-pw-challenge-featuredImageLink', '', 1);
          setLocalData('create-pw-challenge-sponsorAdMediaUrl', '', 1);
          setLocalData('create-pw-challenge-sponsorAdLink', '', 1);
          setLocalData('create-pw-challenge-rewardAmount', '', 1);
          setLocalData('create-pw-challenge-metaTitle', '', 1);
          setLocalData('create-pw-challenge-slug', '', 1);
          setLocalData('create-pw-challenge-metaDescription', '', 1);
          setLocalData('create-pw-challenge-metaKeywords', [], 2);
          message.success('Arena challenge has been created');
          this.props.history.push(`/dashboard/play-and-win-challenges/`);
        } else message.error('Something went wrong! Try again.');
      });
    } else message.warning('Please enter title');
  };

  render() {
    const {title, totalQuestions, startDate, featuredImageUrl, featuredImageAltText, featuredImageLink, thumbnailImageUrl, thumbnailImageAltText, description, sponsorAdMediaUrl, sponsorAdLink, rewardAmount, metaTitle, metaDescription, slug, metaKeywords} = this.state;

    const startDateTime = startDate !== '' ?
        moment(startDate, 'DD/MM/YYYY HH:mm:ss') :
        undefined;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/play-and-win-challenges/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create Challenge
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={this.handleSubmit}
                  className="mc-mr-5"
              >
                Create
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    <Input
                        placeholder="Enter title"
                        size={'large'}
                        value={title}
                        className="mc-mb-15"
                        onChange={this.handleChangeByEvent('title')}
                        onBlur={this.handleFillMetaTitleSlug}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Start Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        showTime
                        format="DD/MM/YYYY HH:mm:ss"
                        value={startDateTime}
                        onChange={this.handleChangeByDateValue('startDate')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Total Questions">
                    <InputNumber
                        min={0}
                        value={totalQuestions}
                        onChange={this.handleChangeByValue('totalQuestions')}
                        className="width-fluid"
                        size={'large'}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={this.handleEditorChange(
                            'description')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {
                    thumbnailImageUrl === '' ? (
                        <Form.Item label="Thumbnail Image">
                          <Dragger
                              name="file"
                              accept=".png, .jpg, .jpeg"
                              action="/api/media/upload"
                              data={{product_type: 4}}
                              listType="picture"
                              onChange={this.handleMediaUpload(
                                  'thumbnailImageUrl')}
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox"/>
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                        </Form.Item>
                    ) : (
                        <Row className={'mc-mb-10'}>
                          <Col span={24}>
                            <p>Thumbnail Image:</p>
                            <img
                                src={thumbnailImageUrl}
                                alt={thumbnailImageAltText}
                                style={{width: '100%', maxWidth: 400}}
                            />
                          </Col>
                          <Col span={24}>
                            <Button
                                className={'mc-mt-10'}
                                htmlType={'button'}
                                type={'primary'}
                                size={'small'}
                                onClick={this.handleMediaRemoval(
                                    'thumbnailImageUrl')}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                    )
                  }
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      {
                        featuredImageUrl === '' ? (
                            <Form.Item label="Featured Image">
                              <Dragger
                                  name="file"
                                  accept=".png, .jpg, .jpeg"
                                  action="/api/media/upload"
                                  data={{
                                    product_type: 4,
                                  }}
                                  listType="picture"
                                  onChange={this.handleMediaUpload(
                                      'featuredImageUrl')}
                              >
                                <p className="ant-upload-drag-icon">
                                  <Icon type="inbox"/>
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                              </Dragger>
                            </Form.Item>
                        ) : (
                            <Row className={'mc-mb-10'}>
                              <Col span={24}>
                                <p>Featured Image</p>
                                <img
                                    src={featuredImageUrl}
                                    alt={featuredImageAltText}
                                    style={{width: '100%', maxWidth: 400}}
                                />
                              </Col>
                              <Col span={24}>
                                <Button
                                    className={'mc-mt-10'}
                                    htmlType={'button'}
                                    type={'primary'}
                                    size={'small'}
                                    onClick={this.handleMediaRemoval(
                                        'featuredImageUrl')}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                        )
                      }
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Featured Image Link">
                        <Input
                            placeholder="Enter link"
                            size={'large'}
                            value={featuredImageLink}
                            className="mc-mb-15"
                            onChange={this.handleChangeByEvent(
                                'featuredImageLink')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <div className={'seo-section'}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <h3>Sponsor</h3>
                        <hr/>
                      </Col>
                      <Col span={24}>
                        {
                          sponsorAdMediaUrl === '' ? (
                              <Form.Item label="Sponsor Ad">
                                <Dragger
                                    name="file"
                                    accept=".png, .jpg, .jpeg"
                                    action="/api/media/upload"
                                    data={{
                                      product_type: 4,
                                    }}
                                    listType="picture"
                                    onChange={this.handleMediaUpload(
                                        'sponsorAdMediaUrl')}
                                >
                                  <p className="ant-upload-drag-icon">
                                    <Icon type="inbox"/>
                                  </p>
                                  <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                  </p>
                                </Dragger>
                              </Form.Item>
                          ) : (
                              <Row className={'mc-mb-10'}>
                                <Col span={24}>
                                  <p>Sponsor Ad:</p>
                                  <img
                                      src={sponsorAdMediaUrl}
                                      alt={'Sponsor'}
                                      style={{width: '100%', maxWidth: 400}}
                                  />
                                </Col>
                                <Col span={24}>
                                  <Button
                                      className={'mc-mt-10'}
                                      htmlType={'button'}
                                      type={'primary'}
                                      size={'small'}
                                      onClick={this.handleMediaRemoval(
                                          'sponsorAdMediaUrl')}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                          )
                        }
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Sponsor Link">
                          <Input
                              placeholder="Enter link"
                              size={'large'}
                              value={sponsorAdLink}
                              className="mc-mb-15"
                              onChange={this.handleChangeByEvent(
                                  'sponsorAdLink')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Reward Amount">
                          <InputNumber
                              min={0}
                              value={rewardAmount}
                              onChange={this.handleChangeByValue(
                                  'rewardAmount')}
                              className="width-fluid"
                              size={'large'}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <div className={'seo-section'}>
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      <Input
                          size={'large'}
                          value={metaTitle}
                          onChange={this.handleChangeByEvent('metaTitle')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      <Input
                          size={'large'}
                          value={slug}
                          onChange={this.handleChangeByEvent('slug')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      <TextArea
                          rows={4}
                          value={metaDescription}
                          onChange={this.handleChangeByEvent('metaDescription')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      <Select
                          mode="tags"
                          size={'large'}
                          value={metaKeywords}
                          onChange={this.handleChangeByValue('metaKeywords')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

export {CreatePWChallenge};
