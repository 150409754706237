import React from 'react';
import {Select, Spin} from 'antd';
import {post} from '../mc_axios/McAxios';

const Option = Select.Option;

class SearchMasterNotesInput extends React.Component {
  state = {
    masterNotesList: [],
    fetchingNotes: false,
  };

  handleSearch = value => {
    this.setState({masterNotesList: [], fetchingNotes: true});
    post('/api/master-notes/master-notes', {
      count: 100,
      page_number: 1,
      search_term: value,
    }).then(response => {
      if (response.data.status === true) {
        if (response.data.data.master_notes !== undefined) {
          const masterNotesList = response.data.data.master_notes.map(
              masterNote => ({
                text: masterNote.master_title,
                value: masterNote.master_note_id,
              }),
          );
          this.setState({masterNotesList, fetchingNotes: false});
        }
      }
    });
  };

  render() {
    const {masterNotesList, fetchingNotes} = this.state;
    const {onSearchMasterNotesInputChange, value} = this.props;

    const masterNotes = masterNotesList.map(masterNote => (
        <Option key={masterNote.text} master-note-id={masterNote.value}>
          {masterNote.text}
        </Option>
    ));

    return (
        <Select
            showSearch
            showArrow={false}
            labelInValue
            placeholder="Select master note"
            notFoundContent={fetchingNotes ? <Spin size="small"/> : null}
            filterOption={true}
            onSearch={this.handleSearch}
            onChange={(value, option) => onSearchMasterNotesInputChange(option)}
            size={'large'}
            value={value}
        >
          {masterNotes}
        </Select>
    );
  }
}

export {SearchMasterNotesInput};
