export const EMAIL_CHANGED = 'email_changed';
export const STORE_USER_ID = 'store_user_id';
export const CHANGE_USER_ROLE = 'change_user_role';
export const CHANGE_LOGIN_STATUS = 'change_login_status';
export const CHANGE_LOADING_STATUS = 'change_loading_status';

export const FETCH_SEARCH_DATA = 'fetch_search_data';
export const FETCH_SEARCH_SUCCESS = 'fetch_search_success';
export const FETCH_SEARCH_FAILURE = 'fetch_search_failure';
export const RESET_SEARCH_DATA = 'reset_search_data';

export const GET_NOTE_DATA = 'get_note_data';
export const NOTE_TITLE_CHANGE = 'note_title_change';
export const NOTE_EXPLANATION_CHANGE = 'note_explanation_change';
export const NOTE_TAGS_CHANGE = 'note_tags_change';
export const NOTE_REFERENCE_CHANGE = 'note_reference_change';
export const RESET_NOTE_DATA = 'reset_note_data';
export const RESET_NOTES_DATA = 'reset_notes_data';

// Challenges constants
export const GET_CHALLENZES_LIST = 'get_challenzes_list';
export const CHALLENZ_TITLE_CHANGE = 'challenz_title_change';
export const CHALLENZ_IS_PREMIUM_CHANGE = 'challenz_premium_change';
export const CHALLENZ_SUBJECTS_CHANGE = 'challenz_subjects_change';
export const CHALLENZ_SYSTEMS_CHANGE = 'challenz_systems_change';
export const CHALLENZ_TAGS_CHANGE = 'challenz_tags_change';
export const CHALLENZ_BASIS_CHANGE = 'challenz_basis_change';
export const CHALLENZ_TOTAL_QUESTIONS_CHANGE = 'challenz_total_questions_change';
export const CHALLENZ_START_DATE_CHANGE = 'challenz_start_date_change';
export const CHALLENZ_END_DATE_CHANGE = 'challenz_end_date_change';
export const CHALLENZ_DESCRIPTION_CHANGE = 'challenz_description_change';
export const CHALLENZ_FEATURED_IMAGE_CHANGE = 'challenz_featured_image_change';
export const CHALLENZ_VIDEO_EXPLANATION_CHANGE = 'challenz_video_explanation_change';
export const CHALLENZ_IS_VIDEO_EXPLANATION_PREMIUM_CHANGE = 'challenz_is_premium_video_explanation_change';
export const CHALLENZ_MASTER_NOTE_CHANGE = 'challenz_master_note_change';
export const CHALLENZ_IS_PREMIUM_MASTER_NOTE_CHANGE = 'challenz_is_premium_master_note_change';
export const CHALLENZ_SEO_META_TITLE_CHANGE = 'challenz_seo_meta_title_change';
export const CHALLENZ_SEO_SLUG_CHANGE = 'challenz_seo_slug_change';
export const CHALLENZ_SEO_META_DESCRIPTION_CHANGE = 'challenz_seo_meta_description_change';
export const CHALLENZ_SEO_META_KEYWORDS_CHANGE = 'challenz_seo_meta_keywords_change';
export const GET_CHALLENZ_DETAILS = 'get_challenz_details';
export const ADD_CHALLENZ_QUESTIONS = 'add_challenz_questions';
export const SORT_CHALLENZ_QUESTIONS_LIST = 'sort_challenz_questions_list';
export const REMOVE_QUESTION_FROM_CHALLENZ = 'remove_question_from_challenz';
export const RESET_CHALLENZ_DATA = 'reset_challenz_data';

// Play & Win challenges constants
export const PW_CHALLENGE_TITLE_CHANGE = 'pw_challenge_title_change';
export const PW_CHALLENGE_TOTAL_QUESTIONS_CHANGE = 'pw_challenge_total_questions_change';
export const PW_CHALLENGE_START_DATE_CHANGE = 'pw_challenge_start_date_change';
export const PW_CHALLENGE_END_DATE_CHANGE = 'pw_challenge_end_date_change';
export const PW_CHALLENGE_DESCRIPTION_CHANGE = 'pw_challenge_description_change';
export const PW_CHALLENGE_FEATURED_IMAGE_CHANGE = 'pw_challenge_featured_image_change';
export const PW_CHALLENGE_THUMBNAIL_IMAGE_CHANGE = 'pw_challenge_thumbnail_image_change';
export const PW_CHALLENGE_FEATURED_IMAGE_LINK_CHANGE = 'pw_challenge_featured_image_link_change';
export const PW_CHALLENGE_SPONSOR_ADD_MEDIA_CHANGE = 'pw_challenge_sponsor_add_media_change';
export const PW_CHALLENGE_SPONSOR_LINK_CHANGE = 'pw_challenge_sponsor_link_change';
export const PW_CHALLENGE_REWARD_AMOUNT_CHANGE = 'pw_challenge_reward_amount_change';
export const PW_CHALLENGE_SEO_META_TITLE_CHANGE = 'pw_challenge_seo_meta_title_change';
export const PW_CHALLENGE_SEO_SLUG_CHANGE = 'pw_challenge_seo_slug_change';
export const PW_CHALLENGE_SEO_META_DESCRIPTION_CHANGE = 'pw_challenge_seo_meta_description_change';
export const PW_CHALLENGE_SEO_META_KEYWORDS_CHANGE = 'pw_challenge_seo_meta_keywords_change';
export const GET_PW_CHALLENGE_DETAILS = 'get_pw_challenge_details';
export const ADD_PW_CHALLENGE_QUESTIONS = 'add_pw_challenge_questions';
export const SORT_PW_CHALLENGE_QUESTIONS_LIST = 'sort_pw_challenge_questions_list';
export const REMOVE_QUESTION_FROM_PW_CHALLENGE = 'remove_question_from_pw_challenge';
export const RESET_PW_CHALLENGE_DATA = 'reset_pw_challenge_data';

// Question constants
export const GET_QUESTION_DATA = 'get_question_data';
export const RESET_QUESTION_DATA = 'reset_question_data';

export const GET_MASTER_NOTE_DATA = 'get_master_note_data';
export const MASTER_NOTE_ACTIVE_NOTE_CONTENT = 'master_note_active_note_constent';
export const RESET_MASTER_NOTE_DATA = 'reset_master_note_data';

export const CHANGE_PRODUCT_TYPE = 'change_product_type';

// Test constants
export const ADD_TEST_QUESTIONS = 'add_test_questions';
export const TEST_BASIS_CHANGE = 'test_basis_change';
export const TEST_MODE_CHANGE = 'test_mode_change';
export const TEST_DESCRIPTION_CHANGE = 'test_description_change';
export const TEST_END_DATE_CHANGE = 'test_end_date_change';
export const TEST_SERUM_CHANGE = 'test_serum_change';
export const TEST_FEATURED_IMAGE_CHANGE = 'test_featured_image_change';
export const TEST_IS_PREMIUM_CHANGE = 'test_is_premium_change';
export const TEST_START_DATE_CHANGE = 'test_start_date_change';
export const TEST_TITLE_CHANGE = 'test_title_change';
export const TEST_TOTAL_QUESTIONS_CHANGE = 'test_total_questions_change';
export const TEST_TOTAL_TIME_CHANGE = 'test_total_time_change';
export const TEST_CORRECT_QUESTION_MARKS_CHANGE = 'test_correct_question_marks_change';
export const TEST_WRONG_QUESTION_MARKS_CHANGE = 'test_wrong_question_marks_change';
export const RESET_TEST_DATA = 'reset_test_data';
export const GET_TEST_DETAILS = 'get_test_details';
export const SORT_TEST_QUESTIONS_LIST = 'sort_test_questions_list';
export const TEST_SEO_META_DESCRIPTION_CHANGE = 'test_seo_meta_description_change';
export const TEST_SEO_META_KEYWORDS_CHANGE = 'test_seo_meta_keywords_change';
export const TEST_SEO_META_TITLE_CHANGE = 'test_seo_meta_title_change';
export const TEST_SEO_SLUG_CHANGE = 'test_seo_slug_change';
