import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {convertUnixToDMY} from '../../util';
import './UserWeekTargetsCard.css';

const UserWeekTargetsCard = props => {

  const {data} = props;

  const expiringDate = () => {
    if (data !== null) {
      if (data.expiresOn === undefined) {
        return 0;
      } else {
        return convertUnixToDMY(data.expiresOn, 3);
      }
    } else return 0;
  };

  const nextMonday = () => {
    if (data !== null) {
      if (data.expiresOn === undefined) {
        return 0;
      } else {
        return convertUnixToDMY(data.expiresOn + 1, 2);
      }
    } else return 0;
  };

  return (
      <div className='wt-card completed fixed'>
        <Row>
          <Col span={20}>
            <h2>Week Targets <span style={{fontSize: 11, opacity: 0.7}}>Expires on {expiringDate()}</span></h2>
          </Col>
          <Col span={4} className="text-right">
            <img
                src={'https://assets.medcampus.io/assets/images/trophy-after-weekly-targets.png'}
                alt={'Trophy'}
                width={20}
            />
          </Col>
        </Row>
        {
          data !== null && (
              <Row>
                {
                  data.completedStatus ? (
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <h3>Congratulations</h3>
                          </Col>
                        </Row>
                        <Row
                            gutter={32}
                            type="flex"
                            justify="space-around"
                            align="middle"
                            className="mc-mtb-10"
                        >
                          <Col span={12}>
                            <img
                                src={'https://assets.medcampus.io/assets/images/trophy-after-weekly-targets.png'}
                                className="responsive-image"
                                alt="Trophy"
                            />
                          </Col>
                          <Col span={12}>
                            <p>You've completed your goals this week. Stay
                              focused.</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="text-center mc-mt-10">
                            <p>Next week targets live from {nextMonday()}</p>
                          </Col>
                        </Row>
                      </Col>
                  ) : (
                      <Col span={24}>
                        <div className="target-list">
                          {
                            data.targetStatus.map((target, index) => {
                              if (target.status) {
                                return <p key={index}
                                          className="completed">{target.targetName}</p>;
                              } else return <p
                                  key={index}>{target.targetName}</p>;
                            })
                          }
                        </div>
                      </Col>
                  )
                }
              </Row>
          )
        }
      </div>
  );
};

export {UserWeekTargetsCard};
