import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Divider, Icon, message, Popconfirm, Row} from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {get, post} from '../../../components/mc_axios/McAxios';

const styles = {
  firstCol: {
    minWidth: 70,
  },
  secondCol: {
    minWidth: 100,
  },
  thirdCol: {
    minWidth: 200,
    textAlign: 'left',
  },
  fourthCol: {
    minWidth: 150,
  },
};

class PublishedTestimonials extends React.Component {
  state = {
    testimonials: [],
  };

  componentDidMount() {
    this.getTestimonials();
  }

  getTestimonials = () => {
    get('/api/coordinates/coordinates/approved/testimonies').then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const testimonials = response.data.data.testimonies;
        if (testimonials !== undefined) {
          this.setState({
            testimonials,
          });
        }
      } else message.error('Something went wrong! Try again.');
    });
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    const {testimonials} = this.state;
    let sortedList = arrayMove(
        testimonials,
        oldIndex,
        newIndex,
    );
    this.setState({
      testimonials: sortedList,
    });
  };

  updateTestimonials = () => {
    const {testimonials} = this.state;
    let arr = testimonials.map(item => item.testimony_id);
    post('/api/coordinates/coordinates/approved/testimonies', {testimonies: arr}).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            message.success('Testimonies has been updated.');
          } else message.error('Something went wrong! Try again.');
        });
  };

  removeTestimonial = id => {
    post('/api/coordinates/coordinates/testimonies/remove_from_testimonies',
        {testimony_id: id}).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Testimonial has been removed.');
        this.getTestimonials();
      } else message.error('Something went wrong! Try again.');
    });
  };

  redirectToEditSlide = id => {
    window.open('/dashboard/slider/edit/' + id, '_blank');
  };

  render() {
    const {testimonials} = this.state;

    const DragHandle = sortableHandle(() => (
        <Icon className="cursor" type="align-center"/>
    ));

    const SortableItem = sortableElement(({value, index}) => (
        <tr key={`item-${index}`}>
          <td width={100}>
            <DragHandle/>
          </td>
          <td>
            {value.name}
          </td>
          <td>
            {value.message}
          </td>
          <td width={150}>
            <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() => this.removeTestimonial(value.testimony_id)}
                okText="Yes"
                cancelText="No"
            >
              <Icon
                  type="delete"
                  className="mc-icon"
                  title="Remove"
              />
            </Popconfirm>
          </td>
        </tr>
    ));

    const SortableContainer = sortableContainer(({children}) => {
      return (
          <div className="mc-table-responsive">
            <table className="mc-table">
              <thead>
              <tr>
                <th>#</th>
                <th style={styles.secondCol}>User Name</th>
                <th style={styles.thirdCol} className="text-left">Message</th>
                <th style={styles.fourthCol}>Actions</th>
              </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
      );
    });

    return (
        <Row className="main-content-block" gutter={16}>
          <Col span={12}>
            <div className="page-header">
              <Link to={'/dashboard/testimonials/'}>
                <Icon type="arrow-left" title={'Back'}/>
              </Link>
              <Divider type="vertical"/> Published Testimonials
            </div>
          </Col>
          <Col span={12} className={'text-right'}>
            <Button
                htmlType={'button'}
                onClick={this.updateTestimonials}
            >Update</Button>
          </Col>
          <Col span={24}>
            <hr className="mc-mt-10"/>
          </Col>
          <Col span={24}>
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
              {
                testimonials.map((value, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                    />
                ))
              }
            </SortableContainer>
          </Col>
        </Row>
    );
  }
}

export {PublishedTestimonials};
