import React, {Component} from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import {Alert} from 'antd';
import {isMobile} from 'react-device-detect';
import {ConceptBaseAnalysisCard} from '../concept_base_analysis_card/ConceptBaseAnalysisCard';
import './SubjectWiseAnalysisCard.css';
import {GuessMeterCard} from '../quess_meter_card/GuessMeterCard';
import SystemSubjectWisePerformanceCard
  from '../system_subject_wise_performance_card/SystemSubjectWisePerformanceCard';

const {Option} = Select;

class SubjectWiseAnalysisCard extends Component {
  state = {
    activeCategory: 0,
    subjectAnalysisData: [],
    allAnalysisData: [],
    subjectIndex: 0,
    systemsList: [
      'Renal System',
      'Blood & Lymphoreticular System',
      'Cardiovascular System',
      'Musculoskeletal System',
      'Nervous System',
      'Reproductive System',
      'Endocrine System',
      'Respiratory System',
      'Gastrointestinal System',
      'Skin & Subcutaneous Tissue'],
  };

  componentDidMount() {
    const {systemsList} = this.state;
    let arr = this.props.data;
    let avg = 0;
    arr.forEach(a => {
      avg += (a.investigation.conceptAnalysis.core['correct'] +
          a.investigation.conceptAnalysis.capsule['correct'] +
          a.investigation.conceptAnalysis.cortex['correct']) +
          (a.investigation.conceptAnalysis.core['inCorrect'] +
              a.investigation.conceptAnalysis.capsule['inCorrect'] +
              a.investigation.conceptAnalysis.cortex['inCorrect']);
    });
    avg = avg / (arr.length);
    let above_arr = [];
    let bel_arr = [];
    arr.forEach(a => {
      if (avg < (a.investigation.conceptAnalysis.core['correct'] +
          a.investigation.conceptAnalysis.capsule['correct'] +
          a.investigation.conceptAnalysis.cortex['correct']) +
          (a.investigation.conceptAnalysis.core['inCorrect'] +
              a.investigation.conceptAnalysis.capsule['inCorrect'] +
              a.investigation.conceptAnalysis.cortex['inCorrect'])) {
        above_arr.push(a);
      } else {
        bel_arr.push(a);
      }
    });
    above_arr.sort((b, a) => {
      return (
          ((a.investigation.conceptAnalysis.core['correct'] +
              a.investigation.conceptAnalysis.capsule['correct'] +
              a.investigation.conceptAnalysis.cortex['correct']) /
              (a.investigation.conceptAnalysis.core['correct'] +
                  a.investigation.conceptAnalysis.capsule['correct'] +
                  a.investigation.conceptAnalysis.cortex['correct'] +
                  a.investigation.conceptAnalysis.core['inCorrect'] +
                  a.investigation.conceptAnalysis.capsule['inCorrect'] +
                  a.investigation.conceptAnalysis.cortex['inCorrect'])) -
          ((b.investigation.conceptAnalysis.core['correct'] +
              b.investigation.conceptAnalysis.capsule['correct'] +
              b.investigation.conceptAnalysis.cortex['correct']) /
              (b.investigation.conceptAnalysis.core['correct'] +
                  b.investigation.conceptAnalysis.capsule['correct'] +
                  b.investigation.conceptAnalysis.cortex['correct'] +
                  b.investigation.conceptAnalysis.core['inCorrect'] +
                  b.investigation.conceptAnalysis.capsule['inCorrect'] +
                  b.investigation.conceptAnalysis.cortex['inCorrect']))
      );
    });

    bel_arr.sort((b, a) => {
      return (
          ((a.investigation.conceptAnalysis.core['correct'] +
              a.investigation.conceptAnalysis.capsule['correct'] +
              a.investigation.conceptAnalysis.cortex['correct']) /
              (a.investigation.conceptAnalysis.core['correct'] +
                  a.investigation.conceptAnalysis.capsule['correct'] +
                  a.investigation.conceptAnalysis.cortex['correct'] +
                  a.investigation.conceptAnalysis.core['inCorrect'] +
                  a.investigation.conceptAnalysis.capsule['inCorrect'] +
                  a.investigation.conceptAnalysis.cortex['inCorrect'])) -
          ((b.investigation.conceptAnalysis.core['correct'] +
              b.investigation.conceptAnalysis.capsule['correct'] +
              b.investigation.conceptAnalysis.cortex['correct']) /
              (b.investigation.conceptAnalysis.core['correct'] +
                  b.investigation.conceptAnalysis.capsule['correct'] +
                  b.investigation.conceptAnalysis.cortex['correct'] +
                  b.investigation.conceptAnalysis.core['inCorrect'] +
                  b.investigation.conceptAnalysis.capsule['correct'] +
                  b.investigation.conceptAnalysis.cortex['inCorrect']))
      );
    });

    above_arr = above_arr.concat(bel_arr);

    let temparr = [];
    above_arr.forEach(element => {
      if (systemsList.includes(element.name)) {
        temparr.push(element);
      }
    });
    this.setState({
      subjectAnalysisData: temparr,
      allAnalysisData: above_arr,
    });
  }

  componentWillReceiveProps(nextProps) {
    const {subjectAnalysisData, activeCategory} = this.state;
    const {showSubject} = nextProps;
    if (showSubject.length > 0) {
      if (activeCategory + 1 === showSubject[1]) {
        subjectAnalysisData.forEach((item, index) => {
          if (item.name === showSubject[0]) {
            this.changeSubCategory(index);
          }
        });
      } else {
        this.handleShowChangeCategory(showSubject[1], showSubject[0]);
      }
    }
  }

  handleShowChangeCategory = (active, name) => {
    const {allAnalysisData, systemsList} = this.state;
    let index = 0;
    let temparr = [];
    if (active === 1) {
      allAnalysisData.forEach(element => {
        if (systemsList.includes(element.name)) {
          temparr.push(element);
          if (name === element.name) {
            index = temparr.length - 1;
          }
        }
      });
    } else {
      allAnalysisData.forEach(element => {
        if (!systemsList.includes(element.name)) {
          temparr.push(element);
          if (name === element.name) {
            index = temparr.length - 1;
          }
        }
      });
    }
    this.setState({
      subjectAnalysisData: temparr,
      activeCategory: active - 1,
    }, () => {
      this.setState({
        subjectIndex: index,
      });
    });
  };

  changeCategory = e => {
    // const systemsList = ["Renal System", "Blood & Lymphoreticular System", "Cardiovascular System", "Musculoskeletal System", "Nervous System", "Reproductive System", "Endocrine System", "Respiratory System", "Gastrointestinal System", "Skin & Subcutaneous Tissue"];
    const {allAnalysisData, systemsList} = this.state;
    let temparr = [];
    if (e.target.value === 0) {
      allAnalysisData.forEach(element => {
        if (systemsList.includes(element.name)) {
          temparr.push(element);
        }
      });
    } else {
      allAnalysisData.forEach(element => {
        if (!systemsList.includes(element.name)) {
          temparr.push(element);
        }
      });
    }
    this.setState({
      subjectAnalysisData: temparr,
      activeCategory: e.target.value,
    });
  };

  changeSubCategory = value => {
    this.setState({
      subjectIndex: value,
    });
  };

  render() {
    const {subjectAnalysisData, subjectIndex, activeCategory} = this.state;
    if (subjectAnalysisData === null || subjectAnalysisData.length === 0) {
      return '';
    }
    const title = subjectAnalysisData[subjectIndex] !== undefined ?
        subjectAnalysisData[subjectIndex]['name'] :
        null;
    const conceptCircleData = subjectAnalysisData[subjectIndex] !== undefined ?
        subjectAnalysisData[subjectIndex]['investigation']['conceptAnalysis'] :
        null;
    const guessData = subjectAnalysisData[subjectIndex] !== undefined ?
        subjectAnalysisData[subjectIndex]['investigation']['guessAnalysis'] :
        null;

    const guessTotal = guessData !== null ? (guessData.core['correct'] +
        guessData.core['inCorrect'] +
        guessData.capsule['correct'] +
        guessData.capsule['inCorrect'] +
        guessData.cortex['correct'] +
        guessData.cortex['inCorrect']) : 0;

    const guessCorrect = guessData !== null ? (guessData.core['correct'] +
        guessData.capsule['correct'] +
        guessData.cortex['correct']) : 0;

    const guessPercent = guessTotal !== 0 ?
        (guessCorrect * 100) / guessTotal :
        0;

    const conceptCortex = (conceptCircleData !== null &&
        (conceptCircleData.cortex['correct'] +
            conceptCircleData.cortex['inCorrect']) !== 0) ?
        (conceptCircleData.cortex['correct'] * 100) /
        (conceptCircleData.cortex['correct'] +
            conceptCircleData.cortex['inCorrect']) :
        0;

    const conceptCore = (conceptCircleData !== null &&
        (conceptCircleData.core['correct'] +
            conceptCircleData.core['inCorrect']) !== 0) ?
        (conceptCircleData.core['correct'] * 100) /
        (conceptCircleData.core['correct'] +
            conceptCircleData.core['inCorrect']) :
        0;

    const conceptCapsule = (conceptCircleData !== null &&
        (conceptCircleData.capsule['correct'] +
            conceptCircleData.capsule['inCorrect']) !== 0) ?
        (conceptCircleData.capsule['correct'] * 100) /
        (conceptCircleData.capsule['correct'] +
            conceptCircleData.capsule['inCorrect']) :
        0;

    const totalCorrect = conceptCircleData !== null ?
        conceptCircleData.core['correct'] +
        conceptCircleData.capsule['correct'] +
        conceptCircleData.cortex['correct'] :
        null;

    const totalIncorrect = conceptCircleData !== null ?
        conceptCircleData.core['inCorrect'] +
        conceptCircleData.capsule['inCorrect'] +
        conceptCircleData.cortex['inCorrect'] :
        null;
    const options = subjectAnalysisData !== null ?
        subjectAnalysisData.map((item, index) => {
              return <Option value={index} key={index}>{item.name}</Option>;
            },
        ) :
        '';

    return (
        <div className="sa-card">
          <Row gutter={16}>
            <Col span={24}>
              <h2 className="text-center">{title}</h2>
            </Col>
            <Col xs={24} sm={12}>
              <Radio.Group
                  defaultValue={0}
                  buttonStyle="solid"
                  onChange={this.changeCategory}
                  className="ss-radio mc-mr-5"
                  value={activeCategory}
              >
                <Radio.Button value={0}>System</Radio.Button>
                <Radio.Button value={1}>Subject</Radio.Button>
              </Radio.Group>
              <Select
                  defaultValue={0}
                  onChange={this.changeSubCategory}
                  className="ss-category-list"
                  value={subjectIndex}
              >
                {options}
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <h3 className="text-right">
                Questions Answered: {totalCorrect + totalIncorrect}
              </h3>
            </Col>
            <Col span={24}><br/></Col>
            <Col span={24}>
              <SystemSubjectWisePerformanceCard
                  data={[totalCorrect, totalIncorrect]}
                  score={Math.round(
                      totalCorrect * 100 / (totalCorrect + totalIncorrect))}
                  test={false}
                  subject={true}
                  shadow={false}
              />
            </Col>
            <Col xs={24} md={12}>
              <Row>
                <Col span={24}>
                  {
                    conceptCircleData !== null ? (
                        <ConceptBaseAnalysisCard
                            data={[
                              conceptCore,
                              conceptCortex,
                              conceptCapsule,
                            ]}
                        />
                    ) : (
                        <Alert
                            className="text-center"
                            message={'No Analysis from this section'}
                            type={'info'}
                        />
                    )
                  }
                </Col>
                <Col span={24}>
                  <div className="bordered-div mc-mb-20">
                    <Row className="text-center">
                      <Col span={8}>
                        <p>Capsule <Icon
                            type="check-circle"
                            className="mc-ml-5"
                            style={{color: 'green'}}/>{isMobile ?
                            <br/> : ''} {conceptCircleData !== null ?
                            conceptCircleData.capsule['correct'] + '/' +
                            (conceptCircleData.capsule['correct'] +
                                conceptCircleData.capsule['inCorrect']) +
                            ' Q\'s' :
                            ''}
                        </p>
                      </Col>
                      <Col span={8}>
                        <p>Cortex <Icon
                            type="check-circle"
                            className="mc-ml-5"
                            style={{color: 'green'}}/>{isMobile ?
                            <br/> : ''} {conceptCircleData !== null ?
                            conceptCircleData.cortex['correct'] + '/' +
                            (conceptCircleData.cortex['correct'] +
                                conceptCircleData.cortex['inCorrect']) +
                            ' Q\'s' :
                            ''}
                        </p>
                      </Col>
                      <Col span={8}>
                        <p>Core <Icon
                            type="check-circle"
                            className="mc-ml-5"
                            style={{color: 'green'}}/>
                          {isMobile ?
                              <br/> : ''} {conceptCircleData !== null ?
                              conceptCircleData.core['correct'] + '/' +
                              (conceptCircleData.core['correct'] +
                                  conceptCircleData.core['inCorrect']) +
                              ' Q\'s' :
                              ''}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row>
                <Col span={24}>
                  {
                    guessData !== null ?
                        <GuessMeterCard data={guessPercent.toFixed(1)}/> :
                        <Alert
                            className="text-center"
                            message={'No Analysis from this section'}
                            type={'info'}
                        />
                  }
                </Col>
                <Col span={24}>
                  <div className="bordered-div">
                    <Row>
                      <Col span={24} className="text-center">
                        <p>Guess Questions <Icon
                            type="check-circle"
                            className="mc-ml-5"
                            style={{color: 'green'}}/> {guessCorrect +
                        ' out of ' + guessTotal}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
    );
  }
}

export {SubjectWiseAnalysisCard};
