import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Empty, message, Pagination, Row, Tag} from 'antd';
import {Filters} from '../../components';
import {post} from '../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../components/util';
import moment from 'moment';

const styles = {
  row: {
    cursor: 'pointer',
  },
  firstCol: {
    minWidth: 70,
  },
  secondCol: {
    minWidth: 150,
  },
  thirdCol: {
    minWidth: 200,
  },
  fourthCol: {
    minWidth: 120,
  },
  fifthCol: {
    minWidth: 150,
  },
};

class MeducatorsPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('meducators-page-number', 1);
    lastVisitedPageNum = lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('meducators-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let verifyStatus = getLocalData('meducators-verify-status', 1);
    verifyStatus = verifyStatus !== '' ? parseInt(verifyStatus) : 0;

    let dateRanges = getLocalData('meducators-date-ranges', 2);
    dateRanges = dateRanges.length > 0 ? dateRanges.map(date => moment(date, 'DD-MM-YYYY')) : null;

    this.state = {
      meducators: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      verifyStatus,
      dateRanges,
      searchTerm: getLocalData('meducators-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(state => ({
      paginationStart: state.currentPageNumber > 0 ?
          (state.currentPageNumber - 1) * state.itemsPerPage + 1 :
          1,
    }), () => this.getUsers());
  }

  getUsers = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      verifyStatus,
      dateRanges,
      searchTerm,
    } = this.state;

    const data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      verified: verifyStatus,
      start_date: dateRanges && dateRanges.length > 1 ? dateRanges[0].unix() : 0,
      end_date: dateRanges && dateRanges.length > 1 ? dateRanges[1].unix() : 0,
      search_string: searchTerm,
    };

    post('/api/user-manager/admin/instructors-list', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('users')) {
          const {users, total_count} = resData;
          this.setState({
            meducators: users,
            totalItems: total_count,
          });
        } else this.setState({
          meducators: [],
          totalItems: 0,
        });
      } else message.error('Something went wrong! Try again.');
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  handleItemsCount = value => {
    setLocalData('meducators-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getUsers();
        },
    );
  };

  handleVerifyStatusChange = value => {
    setLocalData('meducators-verify-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          verifyStatus: value,
        },
        () => {
          this.getUsers();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('meducators-search-term', value, 1);
    this.setState({
      searchTerm: value,
    });
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('meducators-page-number', pageNumber, 1);
    this.setState({
      currentPageNumber: pageNumber,
      paginationStart: start + 1,
    }, () => this.getUsers());
  };

  handleDateRangeChange = (dateRanges, dateStrings) => {
    if (dateRanges.length > 0) {
      setLocalData('meducators-date-ranges', dateStrings, 2);
      this.setState({
        dateRanges,
      }, () => this.getUsers());
    } else {
      setLocalData('meducators-date-ranges', [], 2);
      this.setState({
        dateRanges: null,
      }, () => this.getUsers());
    }
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          verifyStatus: 0,
          dateRanges: null,
          searchTerm: '',
        },
        () => {
          setLocalData('meducators-items-per-page', 25, 1);
          setLocalData('meducators-page-number', 1, 1);
          setLocalData('meducators-verify-status', 0, 1);
          setLocalData('meducators-date-ranges', [], 2);
          setLocalData('meducators-search-term', '', 1);
          this.getUsers();
        },
    );
  };

  redirectToEditOfferPage = id => {
    this.props.history.push('/dashboard/meducators/contact-detailed/' + id);
  };

  getStatusBadge = (status) => {
    if (status === 1) {
      return <Tag color="#28a745">Approved</Tag>;
    } else return <Tag color="#17a2b8">Disapproved</Tag>;
  };

  getProductType = prodType => {
    switch (prodType) {
      case 1:
        return 'infoMed';
      case 2:
        return 'medPixx';
      case 3:
        return 'mVideo';
      case 4:
        return 'challenges';
      case 5:
        return 'qBank';
      case 6:
        return 'notes';
      case 7:
        return 'prepMed';
      default:
        return 'Unknown';
    }
  };

  render() {
    const {
      state: {
        meducators,
        currentPageNumber,
        itemsPerPage,
        paginationStart,
        verifyStatus,
        dateRanges,
        searchTerm,
        totalItems,
      }, props: {history},
    } = this;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>Meducators</p>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                verifyStatusValue={verifyStatus}
                onVerifyStatusChange={this.handleVerifyStatusChange}
                dateRangeValue={dateRanges}
                onDateRangeChange={this.handleDateRangeChange}
                searchTermValue={searchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.getUsers}
                onFiltersReset={this.resetFilters}
            />
            {
              meducators.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table">
                          <thead>
                          <tr>
                            <th style={styles.firstCol}>#</th>
                            <th style={styles.secondCol}>Name</th>
                            <th style={styles.thirdCol}>Mobile Number</th>
                            <th style={styles.fourthCol}>Email Id</th>
                            <th style={styles.fifthCol}>Country</th>
                            <th style={styles.fifthCol}>Registered Date</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            meducators.map((user, index) => {
                              const {user_id, name, email, contact_number, country_data: {dial_code, name: countryName}, created_at} = user;
                              const mobileNumberText = contact_number === undefined ? '--' : '+' + dial_code + '-' + contact_number;
                              const emailText = email || '--';
                              const countryText = countryName || '--';
                              const registeredDate = moment.unix(created_at).format('DD MMMM, YYYY');

                              const redirectToUserDetailedPage = () => history.push('/dashboard/meducators/' + user_id);

                              return (
                                  <tr key={user_id} style={styles.row}>
                                    <td>{paginationStart + index}</td>
                                    <td onClick={redirectToUserDetailedPage}>{name}</td>
                                    <td>{mobileNumberText}</td>
                                    <td>{emailText}</td>
                                    <td>{countryText}</td>
                                    <td>{registeredDate}</td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No meducators found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

const Meducators = withRouter(MeducatorsPre);

export {Meducators};
