import {CHANGE_PRODUCT_TYPE} from '../../actions/types';

const initialState = {
  productType: 0,
};

export default (state = initialState, action) => {
  if (action.type === CHANGE_PRODUCT_TYPE) {
    return {
      ...state,
      productType: action.payload,
    };
  } else {
    return state;
  }
};
