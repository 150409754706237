import React from 'react';
import {Form, Input, Modal} from 'antd';

export const CreateCategoryModal = Form.create({name: 'category_create_modal'})(
    class extends React.Component {
      render() {
        const {visible, onCancel, onCreate, form} = this.props;
        const {getFieldDecorator} = form;
        return (
            <Modal
                visible={visible}
                title="Create Category"
                okText="Create"
                onCancel={onCancel}
                onOk={onCreate}
                width={400}
                centered
            >
              <Form layout="vertical">
                <Form.Item label="Category Name">
                  {getFieldDecorator('category_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Category name is required',
                      }],
                  })(<Input size="large"/>)}
                </Form.Item>
              </Form>
            </Modal>
        );
      }
    },
);
