import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from 'antd';
import {
  Categories,
  McCkEditor,
  SubCategories,
  Tags,
  Video,
} from '../../../components';
import {get, patch, post} from '../../../components/mc_axios/McAxios';
import {
  arrayConcat,
  getSubjectList,
  getSystemList,
  slugify,
} from '../../../components/util';
import '../Medvideo.css';
import {getUserRoleReselector} from '../../../redux/selectors';
import {connect} from 'react-redux';

const {TextArea} = Input;
const Dragger = Upload.Dragger;
const Option = Select.Option;
const {Paragraph} = Typography;

class EditMedvideoForm extends React.Component {
  handlers = {};

  constructor(props) {
    super(props);
    const {match: {params: {medvideoId}}} = this.props;
    this.state = {
      id: medvideoId,
      title: '',
      isPrime: false,
      subjectsList: [],
      subjects: [],
      systemsList: [],
      systems: [],
      tags: [],
      category: undefined,
      subCategory: undefined,
      description: '',
      videoUrl: '',
      videoDuration: 0,
      videoThumbnail: '',
      reference: '',
      mNotesUrl: '',
      metaTitle: '',
      slug: '',
      metaDescription: '',
      metaKeywords: [],
      createdAt: '',
    };
  }

  componentDidMount() {
    getSubjectList(subjects => {
      this.setState({
        subjectsList: subjects,
      });
    });

    getSystemList(systems => {
      this.setState({
        systemsList: systems,
      });
    });

    this.getMedVideoDetails();
  }

  getMedVideoDetails = () => {
    get('/api/television/videos/' + this.state.id).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const {category, subcategory, video} = response.data.data;
        const {title, prime, subject, system, tags, description, video_url, video_thumbnail, reference, m_notes_url, meta_title, slug, meta_description, meta_keywords, created_at} = video;

        let prevCategory;
        if (category.category_id !== '') {
          prevCategory = {
            key: category.category_id,
            label: category.category,
          };
        }

        let prevSubCategory;
        if (subcategory.sub_category_id !== '') {
          prevSubCategory = {
            key: subcategory.sub_category_id,
            label: subcategory.sub_category,
          };
        }

        let prevTags = [];
        if (tags !== undefined && tags.length > 0) {
          prevTags = tags.map(tag => ({
            key: tag,
            label: tag,
          }));
        }

        if (video_url !== undefined) {
          this.getVideoDuration(video_url);
        }

        this.setState({
          title: title === undefined ? '' : title,
          isPrime: prime === undefined ? false : prime,
          subjects: subject === undefined ? [] : subject,
          systems: system === undefined ? [] : system,
          tags: prevTags,
          category: prevCategory,
          subCategory: prevSubCategory,
          description: description === undefined ? '' : description,
          videoThumbnail: video_thumbnail === undefined ? '' : video_thumbnail,
          reference: reference === undefined ? '' : reference,
          mNotesUrl: m_notes_url === undefined ? '' : m_notes_url,
          metaTitle: meta_title === undefined ? '' : meta_title,
          slug: slug === undefined ? '' : slug,
          metaDescription: meta_description === undefined ?
              '' :
              meta_description,
          metaKeywords: meta_keywords === undefined ? '' : meta_keywords,
          createdAt: created_at === undefined ? '' : created_at,
        });
      } else {
        message.error('Something went wrong.');
      }
    });
  };

  getVideoDuration = (videoUrl) => {
    if (videoUrl !== '') {
      let video = document.createElement('video');
      video.setAttribute('src', videoUrl);
      document.body.appendChild(video);
      video.onloadeddata = (event) => {
        const duration = event.target.duration;
        this.setState({
          videoUrl,
          videoDuration: Math.trunc(duration),
        }, () => document.body.removeChild(video));
      };
    }
  };

  // New methods
  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.setState({
          [name]: event.target.value,
        });
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  //Tags methods
  handleMetaKeywordsFill = value => {
    const keywords = value.map(d => d.key);
    this.setState(prevState => ({
      metaKeywords: arrayConcat(keywords, prevState.metaKeywords),
    }));
  };

  //Category methods
  handleCategoryCreate = value => {
    this.setState({
      category: {
        key: value.key,
        label: value.label,
      },
    });
  };

  handleCategoryChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        this.setState(prevState => ({
          category: value === undefined ? undefined : value,
          subCategory: undefined,
        }));
      };
    }
    return this.handlers[name];
  };

  //Sub Category methods
  handleCreateSubCategory = value => {
    this.setState({
      subCategory: {
        key: value.key,
        label: value.label,
      },
    });
  };

  handleSubCategoryChange = value => {
    this.setState({
      subCategory: value === undefined ? undefined : value,
    });
  };

  //Editor methods
  handleEditorChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.setState({
          [name]: event.editor.getData(),
        });
      };
    }
    return this.handlers[name];
  };

  //Video methods
  handleVideoChange = info => {
    if (info.file.status === 'done') {
      this.getVideoDuration(info.file.response.data.file_url);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleRemoveVideo = () => {
    this.setState({
      videoUrl: '',
      videoDuration: 0,
    });
  };

  handleVideoThumbnailChange = info => {
    if (info.file.status === 'done') {
      const {file_url} = info.file.response.data;
      this.setState({
        videoThumbnail: file_url,
      });
      message.success('Thumbnail uploaded successfully');
    } else if (info.file.status === 'error') {
      message.error('Thumbnail upload failed');
    }
  };

  handleVideoThumbnailRemove = () => {
    this.setState({
      videoThumbnail: '',
    });
  };

  //Update & Publish methods
  update = () => {
    this.handleSubmit(1);
  };

  updateAndPublish = () => {
    this.handleSubmit(2);
  };

  handleSubmit = saveType => {
    const {
      id,
      title,
      isPrime,
      subjects,
      systems,
      tags,
      category,
      subCategory,
      description,
      videoUrl,
      videoDuration,
      videoThumbnail,
      reference,
      mNotesUrl,
      metaTitle,
      slug,
      metaDescription,
      metaKeywords,
      createdAt,
    } = this.state;

    if (title === '' || videoUrl === '') {
      message.error('Title and video is mandatory');
    } else {
      let tagsList = [];
      if (tags !== undefined) {
        for (let j = 0; j < tags.length; j++) {
          tagsList.push(tags[j].key);
        }
      }

      let data = {
        video_id: id,
        title,
        status: saveType,
        prime: isPrime,
        subject: subjects,
        system: systems,
        tags: tagsList,
        category_id: category !== undefined && category.hasOwnProperty('key') ?
            category.key :
            '',
        sub_category_id: subCategory !== undefined &&
        subCategory.hasOwnProperty('key') ? subCategory.key : '',
        description,
        video_url: videoUrl,
        video_alt_text: title,
        duration: videoDuration,
        video_thumbnail: videoThumbnail,
        reference,
        m_notes_url: mNotesUrl,
        meta_title: metaTitle,
        slug,
        meta_description: metaDescription,
        meta_keywords: metaKeywords,
        created_at: createdAt,
      };

      post('/api/television/videos/update', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          message.success('medVideo has been updated');
          this.props.history.push('/dashboard/medvideo/');
        } else {
          message.error('Something went wrong. Please try again.');
        }
      });
    }
  };

  confirmDelete = () => {
    patch('/api/television/videos/delete', {
      video_id: this.state.id,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Video deleted successfully');
        const {history} = this.props;
        history.push('/dashboard/medvideo');
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  getFullUrl = () => {
    const {id, title, category, subCategory} = this.state;
    if (category !== undefined && subCategory !== undefined) {
      const categorySlug = slugify(category.label);
      const subCategorySlug = slugify(subCategory.label);
      const titleSlug = slugify(title);
      return 'https://www.medcampus.io/mvideos/' + categorySlug + '-' +
          category.key + '/' + subCategorySlug + '-' + subCategory.key + '/' +
          titleSlug + '-' + id;
    }
    return '';
  };

  render() {
    const {userRole} = this.props;
    const {title, description, subjectsList, subjects, systemsList, systems, tags, videoUrl, videoThumbnail, isPrime, category, subCategory, reference, mNotesUrl, metaTitle, slug, metaDescription, metaKeywords} = this.state;
    const fullMVideoUrl = this.getFullUrl();
    const isRestricted = userRole !== 2;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/medvideo/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit MedVideo
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  className="mc-mr-5"
                  onClick={this.update}
              >
                Update
              </Button>
              <Button
                  type="primary"
                  htmlType="button"
                  onClick={this.updateAndPublish}
              >
                Update & Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <p className={'mc-mt-10'}>
                    Title:
                    {
                      !isRestricted && (
                          <span className="pull-right">
                              Premium
                              <Switch
                                  className="mc-ml-15"
                                  checked={isPrime}
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  onChange={this.handleChangeByValue('isPrime')}
                                  style={{width: 60}}
                              />
                          </span>
                      )
                    }
                  </p>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Input
                        placeholder="Enter medVideo title"
                        size={'large'}
                        value={title}
                        onChange={this.handleChangeByEvent('title')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Subject">
                    <Select
                        mode="multiple"
                        placeholder="Select subject"
                        size={'large'}
                        value={subjects}
                        onChange={this.handleChangeByValue('subjects')}
                    >
                      {subjectsList.map(function(subject, index) {
                        return (
                            <Option value={subject} key={index}>
                              {subject}
                            </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="System">
                    <Select
                        mode="multiple"
                        placeholder="Select system"
                        size={'large'}
                        value={systems}
                        onChange={this.handleChangeByValue('systems')}
                    >
                      {systemsList.map(function(system, index) {
                        return (
                            <Option value={system} key={index}>
                              {system}
                            </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Tags
                      value={tags}
                      onChange={this.handleChangeByValue('tags')}
                      onBlur={this.handleMetaKeywordsFill}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Categories
                      value={category}
                      onChange={this.handleCategoryChange('category')}
                      onCreateCategory={this.handleCategoryCreate}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <SubCategories
                      value={subCategory}
                      categoryValue={category}
                      onChange={this.handleSubCategoryChange}
                      onCreateSubCategory={this.handleCreateSubCategory}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={this.handleEditorChange(
                            'description')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {videoUrl === '' ? (
                      <Form.Item label="Video">
                        <Dragger
                            name="file"
                            accept=".mp4, .mvk, .wmv"
                            action="/api/media/upload"
                            data={{
                              product_type: 3,
                            }}
                            multiple={false}
                            onChange={this.handleVideoChange}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly
                            prohibit
                            from uploading company data or other band files
                          </p>
                        </Dragger>
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <p>Video :</p>
                        </Col>
                        <Col span={24}>
                          <Video
                              videoSrc={videoUrl}
                              videoTitle="MedVideo"
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className="mc-mt-10"
                              type="primary"
                              htmlType="button"
                              onClick={this.handleRemoveVideo}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
                <Col span={24}>
                  {videoThumbnail === '' ? (
                      <Form.Item label="Video Thumbnail">
                        <Dragger
                            name="file"
                            accept=".jpg, .jpeg, .png"
                            action="/api/media/upload"
                            data={{
                              product_type: 3,
                            }}
                            multiple={false}
                            onChange={this.handleVideoThumbnailChange}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <p className="mc-mt-20">Video Thumbnail :</p>
                        </Col>
                        <Col span={24}>
                          <img
                              src={videoThumbnail}
                              style={{width: '100%', maxWidth: 400}}
                              alt="Thumbnail"
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className="mc-mt-10"
                              type="primary"
                              htmlType="button"
                              onClick={this.handleVideoThumbnailRemove}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <Row>
                <Col span={24}>
                  <p className="mc-mt-10">mVideo url:</p>
                  <Card className="mc-mb-10">
                    <Paragraph
                        className="mc-mb-0"
                        copyable
                    >{fullMVideoUrl}</Paragraph>
                  </Card>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reference">
                    <McCkEditor
                        type={1}
                        content={reference}
                        onMcEditorChange={this.handleEditorChange('reference')}
                    />
                  </Form.Item>
                </Col>
                {
                  !isRestricted && (
                      <Col span={24}>
                        <Form.Item label={'mNote'}>
                          <Input
                              placeholder="Enter mNote url"
                              size={'large'}
                              value={mNotesUrl}
                              onChange={this.handleChangeByEvent('mNotesUrl')}
                          />
                        </Form.Item>
                      </Col>
                  )
                }
              </Row>
              <div className="seo-section">
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      <Input
                          placeholder="Enter meta title"
                          size={'large'}
                          value={metaTitle}
                          onChange={this.handleChangeByEvent('metaTitle')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      <Input
                          size={'large'}
                          value={slug}
                          onChange={this.handleChangeByEvent('slug')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      <TextArea
                          rows={4}
                          value={metaDescription}
                          onChange={this.handleChangeByEvent('metaDescription')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      <Select
                          mode="tags"
                          placeholder="Enter keywords"
                          size={'large'}
                          value={metaKeywords}
                          onChange={this.handleChangeByValue('metaKeywords')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    userRole: getUserRoleReselector(state),
  };
};

const EditMedvideo = withRouter(
    connect(mapStateToProps, null)(EditMedvideoForm));

export {EditMedvideo};
