import React, {useEffect, useReducer} from 'react';
import {Col, Empty, Icon, Row} from 'antd';
import {UserLevelCard} from './user_level_card/UserLevelCard';
import {UserMedalsCard} from './user_medals_card/UserMedalsCard';
import {UserTrophiesCard} from './user_trophies_card/UserTrophiesCard';
import {UserWeekTargetsCard} from './user_week_targets_card/UserWeekTargetsCard';
import {ConceptBaseAnalysisCard} from './concept_base_analysis_card/ConceptBaseAnalysisCard';
import {GuessMeterCard} from './quess_meter_card/GuessMeterCard';
import {ChallengesComparativeAnalysisCard} from './challenges_comparative_analysis_card/ChallengesComparativeAnalysisCard';
import {SystemSubjectAnalysisCard} from './system_subject_analysis_card/SystemSubjectAnalysisCard';
import {post} from '../mc_axios/McAxios';
import {SubjectWiseAnalysisCard} from './subject_wise_analysis_card/SubjectWiseAnalysisCard';
import {UserWalletCard} from './user_wallet_card/UserWalletCard';
import {UserChallengePrepMedCountCard} from './user_challenge_prepmed_count_card/UserChallengePrepMedCountCard';

const initialState = {
  userMedBookData: null,
  userMedBookDataLoading: true,
  selectedSubject: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_user_medbook_data':
      return {
        ...state,
        userMedBookData: action.payload,
        userMedBookDataLoading: false,
      };
    case 'update_user_medbook_data_loading_status':
      return {
        ...state,
        userMedBookDataLoading: action.payload,
      };
    case 'updated_selected_subject':
      return {
        ...state,
        selectedSubject: action.payload,
      };
    default:
      return state;
  }
};

const UserMedBook = ({userId}) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getUserMedBookData();
  }, []);

  const getUserMedBookData = () => {
    post('/api/user-manager/admin/user/medbook', {user_id: userId}).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            const resData = response.data.data;
            setTimeout(() => {
              dispatch({
                type: 'update_user_medbook_data',
                payload: resData,
              });
            }, 1000);
          } else dispatch({
            type: 'update_user_medbook_data',
            payload: [],
          });
        }).
        catch(() => dispatch({
          type: 'update_user_medbook_data_loading_status',
          payload: false,
        }));
  };

  const {userMedBookData, userMedBookDataLoading, selectedSubject} = state;

  if (userMedBookDataLoading) {
    return (
        <Row>
          <Col span={24}>
            <h3 className='text-center mc-mt-30'>
              <b><Icon type="loading" className="mc-mr-15"/> Getting medBook
                data..</b>
            </h3>
          </Col>
        </Row>
    );
  }

  if (userMedBookData === null) {
    return (
        <div className="main-content-block">
          <Row gutter={16}>
            <Col span={24}>
              <Empty
                  className="mc-mt-15"
                  description={<span>User medbook data not found</span>}
              />
            </Col>
          </Row>
        </div>
    );
  }

  const {medal_count, level, trophy_count, trophy_status, web: {investigations}, comparative_analysis, wallet, completed_challenges_count: { total_count: challengesCount = 0}, completed_test_count: { total_count: testsCount = 0}} = userMedBookData;

  const handleChangeSubject = subject => {
    dispatch({
      type: 'updated_selected_subject',
      payload: subject,
    });
    window.scrollTo(0, 1800);
  };

  const generateGraphData = () => {

    /*  Concept analysis variables */
    let caCorrectCoreSum = 0;
    let caIncorrectCoreSum = 0;
    let caCorrectCortexSum = 0;
    let caIncorrectCortexSum = 0;
    let caCorrectCapsuleSum = 0;
    let caIncorrectCapsuleSum = 0;

    /* Guess meter variables */
    let correctGuess = 0;
    let totalGuess = 0;

    /* System and Subject wise analysis variables */
    let ssData = {};
    let correctAnswerCount = 0;
    let totalAnswerCount = 0;

    investigations && investigations.forEach(({investigation, name}) => {
      const {
        conceptAnalysis: {
          core: {correct: caCorrectCore, inCorrect: caIncorrectCore},
          cortex: {correct: caCorrectCortex, inCorrect: caIncorrectCortex},
          capsule: {correct: caCorrectCapsule, inCorrect: caIncorrectCapsule},
        },
        guessAnalysis: {
          core: {correct: gaCorrectCore, inCorrect: gaIncorrectCore},
          cortex: {correct: gaCorrectCortex, inCorrect: gaIncorrectCortex},
          capsule: {correct: gaCorrectCapsule, inCorrect: gaIncorrectCapsule},
        },
      } = investigation;

      /* Concept analysis calculations */
      caCorrectCoreSum += caCorrectCore * 100;
      caIncorrectCoreSum += caCorrectCore + caIncorrectCore;
      caCorrectCortexSum += caCorrectCortex * 100;
      caIncorrectCortexSum += caCorrectCortex + caIncorrectCortex;
      caCorrectCapsuleSum += caCorrectCapsule * 100;
      caIncorrectCapsuleSum += caCorrectCapsule + caIncorrectCapsule;

      /* Guess meter analysis calculations */
      correctGuess += (gaCorrectCore + gaCorrectCortex + gaCorrectCapsule) *
          100;
      totalGuess += gaCorrectCore + gaIncorrectCore + gaCorrectCortex +
          gaIncorrectCortex + gaCorrectCapsule + gaIncorrectCapsule;

      /* System and Subject wise analysis calculations */
      const correctAnsweredCount = caCorrectCore + caCorrectCortex +
          caCorrectCapsule;
      const totalAttempted = caCorrectCore + caIncorrectCore + caCorrectCortex +
          caIncorrectCortex + caCorrectCapsule + caIncorrectCapsule;

      correctAnswerCount += correctAnsweredCount * 100;
      totalAnswerCount += totalAttempted;
      ssData[name] = totalAttempted !== 0 ?
          Math.round((correctAnsweredCount / totalAttempted) * 100) :
          0;
    });

    return {
      conceptCircleData: [
        caIncorrectCoreSum !== 0 ? caCorrectCoreSum / caIncorrectCoreSum : 0,
        caIncorrectCortexSum !== 0 ?
            caCorrectCortexSum / caIncorrectCortexSum :
            0,
        caIncorrectCapsuleSum !== 0 ?
            caCorrectCapsuleSum / caIncorrectCapsuleSum :
            0,
      ],
      guessMeterData: totalGuess !== 0 ?
          Math.round(correctGuess / totalGuess) :
          0,
      ssAnalysisData: ssData,
      correctAnswerCount: Math.round(correctAnswerCount / 100),
      totalAnswerCount,
    };
  };

  const {conceptCircleData, guessMeterData, ssAnalysisData, correctAnswerCount, totalAnswerCount} = generateGraphData();

  return (
      <div className="main-content-block-transparent">
        <Row gutter={16} className="mc-mt-30">
          <Col xs={24} sm={8}>
            <UserMedalsCard data={medal_count}/>
          </Col>
          <Col xs={24} sm={8}>
            <UserLevelCard data={level}/>
          </Col>
          <Col xs={24} sm={8}>
            <UserTrophiesCard
                trophyCount={trophy_count}
                trophyStatus={trophy_status}
            />
          </Col>
          <Col xs={24} sm={8}>
            <UserWalletCard data={wallet}/>
          </Col>
          <Col xs={24} sm={16}>
            <UserChallengePrepMedCountCard  challengesCount={challengesCount} testsCount={testsCount}/>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={8}>
            <ConceptBaseAnalysisCard data={conceptCircleData}/>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <GuessMeterCard data={guessMeterData}/>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <UserWeekTargetsCard data={trophy_status}/>
          </Col>
          <Col span={24}>
            <ChallengesComparativeAnalysisCard data={comparative_analysis}/>
          </Col>
          <Col span={24}>
            <SystemSubjectAnalysisCard
                data={ssAnalysisData}
                totalQuestions={totalAnswerCount}
                totalQuestionsCorrect={correctAnswerCount}
                handleClick={handleChangeSubject}
            />
          </Col>
          {
            investigations && (
                <Col span={24}>
                  <SubjectWiseAnalysisCard
                      data={investigations}
                      showSubject={selectedSubject}
                  />
                </Col>
            )
          }
        </Row>
      </div>
  );
};

export {UserMedBook};
