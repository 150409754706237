import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Empty, Pagination, Row, Tag} from 'antd';
import moment from 'moment';
import {post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';

class PrepMed extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('prepmed-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('prepmed-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let testStatus = getLocalData('prepmed-status', 1);
    testStatus = testStatus !== '' ? parseInt(testStatus) : 0;

    this.state = {
      testList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      testStatus,
      testSubject: getLocalData('prepmed-subject', 1),
      testSystem: getLocalData('prepmed-system', 1),
      testSearchTerm: getLocalData('prepmed-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }),
        () => {
          this.getTestList();
        },
    );
  }

  getTestList = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      testSubject,
      testSystem,
      testStatus,
      testSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: testSubject,
      system: testSystem,
      status: parseInt(testStatus),
      search_term: testSearchTerm,
    };

    post('/api/tests/tests', data).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        if (resData.hasOwnProperty('tests')) {
          this.setState({
            totalItems: resData.count,
            testList: resData.tests,
          });
        } else {
          this.setState({
            totalItems: 0,
            testList: [],
          });
        }
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('prepmed-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getTestList();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('prepmed-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          testStatus: value,
        },
        () => {
          this.getTestList();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('prepmed-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          testSubject: value,
        },
        () => {
          this.getTestList();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('prepmed-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          testSystem: value,
        },
        () => {
          this.getTestList();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('prepmed-search-term', value, 1);
    this.setState({
      testSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getTestList();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('prepmed-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getTestList();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          testStatus: 0,
          testSubject: '',
          testSystem: '',
          testSearchTerm: '',
        },
        () => {
          setLocalData('prepmed-items-per-page', 25, 1);
          setLocalData('prepmed-page-number', 1, 1);
          setLocalData('prepmed-status', 0, 1);
          setLocalData('prepmed-subject', '', 1);
          setLocalData('prepmed-system', '', 1);
          setLocalData('prepmed-search-term', '', 1);
          this.getTestList();
        },
    );
  };

  redirectToAddQuestionsPage = id => {
    this.props.history.push(`/dashboard/prepmed/add-questions/${id}`);
  };

  getStatusBadge = (status) => {
    switch (status) {
      case 4:
        return (
            <Tag color="#FF9800">Published</Tag>
        );
      case 2:
        return (
            <Tag color="#28a745">Published</Tag>
        );
      case 1:
        return (
            <Tag color="gray">Draft</Tag>
        );
      default:
        return (
            <Tag color="#17a2b8">Saved</Tag>
        );
    }
  };

  render() {
    const {
      testList,
      currentPageNumber,
      itemsPerPage,
      testStatus,
      testSubject,
      testSystem,
      testSearchTerm,
      totalItems,
      paginationStart,
    } = this.state;

    const getTestBasis = function(num) {
      let basis = '';
      switch (num) {
        case 1:
          basis = 'NEET PG';
          break;
        case 2:
          basis = 'AIIMS PG';
          break;
        case 3:
          basis = 'USMLE STEP I';
          break;
        case 4:
          basis = 'USMLE STEP II';
          break;
        case 5:
          basis = 'PGI CHANDIGARH';
          break;
        case 6:
          basis = 'JIPMER';
          break;
        case 7:
          basis = 'NIMHANS';
          break;
        case 8:
          basis = 'Custom';
          break;
        case 9:
          basis = 'UPSC CMS';
          break;
        case 10:
          basis = 'Subject Wise Tests';
          break;
        default:
          basis = '';
          break;
      }
      return basis;
    };

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>PrepMed</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/prepmed/create-test'}>Create Test</Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/prepmed/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={testStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={testSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={testSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={testSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {
              testList !== undefined && testList.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table notes-table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Updated At</th>
                            <th>Attempted Users</th>
                          </tr>
                          </thead>
                          <tbody>
                          {testList.map((test, index) => (
                              <tr key={test.test_id}>
                                <td>{paginationStart + index}</td>
                                <td
                                    onClick={() =>
                                        this.redirectToAddQuestionsPage(
                                            test.test_id,
                                        )
                                    }
                                >
                                  {test.test_title}
                                </td>
                                <td>
                                  {getTestBasis(test.test_basis)}
                                </td>
                                <td>
                                  {this.getStatusBadge(test.status)}
                                </td>
                                <td>
                                  {moment.unix(test.updated_at).
                                      format('DD/MM/YYYY')}
                                </td>
                                <td>
                                  {
                                    test.test_completed === undefined ||
                                    test.test_completed === null ?
                                        0 :
                                        test.test_completed
                                  }
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No test found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

export {PrepMed};
