import React from 'react';
import {
  Col,
  Divider,
  Empty,
  Icon,
  message,
  Pagination,
  Popconfirm,
  Row,
  Spin,
} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {patch, post} from '../../../components/mc_axios/McAxios';

class MasterNotesTrash extends React.Component {
  state = {
    masterNotesList: [],
    currentPageNumber: 1,
    itemsPerPage: 10,
    totalItems: 0,
    paginationStart: 1,
    loading: false,
  };

  componentDidMount() {
    this.getMasterNotesList();
  }

  getMasterNotesList = () => {
    this.setState({loading: true});
    const {itemsPerPage, currentPageNumber} = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: '',
      status: 3,
      search_term: '',
    };

    post('/api/master-notes/master-notes', data).then(response => {
      if (response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          loading: false,
          totalItems: resData.count,
          masterNotesList: resData.master_notes,
        });
      }
    });
  };

  confirm(actionType, masterNoteId) {
    if (actionType === 1) {
      patch('/api/master-notes/master-notes/restore', {
        master_note_id: masterNoteId,
      }).then(response => {
        if (response.data.status === true) {
          message.success('Note has been restored successfully');
          this.getMasterNotesList();
        } else {
          message.error('Something went wrong. Try again.');
        }
      });
    } else {
      post('/api/master-notes/master-notes/delete', {
        master_note_id: masterNoteId,
      }).then(response => {
        if (response.data.status === true) {
          message.success('Note has been deleted successfully');
          this.getMasterNotesList();
        } else {
          message.error('Something went wrong. Try again.');
        }
      });
    }
  }

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getMasterNotesList();
        },
    );
  };

  render() {
    const {
      masterNotesList,
      currentPageNumber,
      itemsPerPage,
      paginationStart,
      totalItems,
      loading,
    } = this.state;
    const antIcon = <Icon type="loading" style={{fontSize: 30}} spin/>;

    return (
        <div className="main-content-block">
          <Row gutter={16}>
            <Col span={24}>
              <div className="page-header">
                <Link to={'/dashboard/notes/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Trash
              </div>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
          </Row>
          {masterNotesList !== undefined && masterNotesList.length > 0 ? (
              <Row className="mc-mt-10">
                <Col xs={24}>
                  <Spin indicator={antIcon} spinning={loading}>
                    <div className="mc-table-responsive">
                      <table className="mc-table">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Updated At</th>
                          <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {masterNotesList.map((masterNote, index) => (
                            <tr key={masterNote.master_note_id}>
                              <td>{paginationStart + index}</td>
                              <td>
                                <Link
                                    to={`/dashboard/master-notes/edit/${
                                        masterNote.master_note_id
                                    }`}
                                >
                                  {masterNote.master_title}
                                </Link>
                              </td>
                              <td>
                                {moment.unix(masterNote.updated_at).
                                    format('DD/MM/YYYY')}
                              </td>
                              <td className="actions-icons">
                                <Popconfirm
                                    title="Are you sure restore this master note?"
                                    onConfirm={() =>
                                        this.confirm(1,
                                            masterNote.master_note_id)
                                    }
                                    onCancel={() =>
                                        message.error(
                                            'You have canceled the restore operation',
                                        )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                >
                                  <Icon type="undo" title="Restore"/>
                                </Popconfirm>
                                <Popconfirm
                                    title="Are you sure delete this master note?"
                                    onConfirm={() =>
                                        this.confirm(2,
                                            masterNote.master_note_id)
                                    }
                                    onCancel={() =>
                                        message.error(
                                            'You have canceled the delete operation',
                                        )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                >
                                  <Icon type="delete" title="Delete"/>
                                </Popconfirm>
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                        className="mc-mt-15"
                        current={currentPageNumber}
                        pageSize={itemsPerPage}
                        total={totalItems}
                        onChange={this.onPageChange}
                    />
                  </Spin>
                </Col>
              </Row>
          ) : (
              <Empty
                  className="mc-mtb-100"
                  image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                  description={<span>No deleted notes found</span>}
              />
          )}
        </div>
    );
  }
}

export {MasterNotesTrash};
