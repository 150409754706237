import React, {useEffect, useReducer} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Avatar, Button, Col, Divider, Empty, Icon, message, Row} from 'antd';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import {get, post} from '../../../components/mc_axios/McAxios';
import {getAvatarUrl} from '../../../components/util';
import '../../users/user_detailed/UserDetailed.css';

const emailCopiedAlert = () => {
  message.success('Email copied to clipboard');
};

const mobileNumberCopiedAlert = () => {
  message.success('Mobile number copied to clipboard');
};

const initialState = {
  meducatorData: null,
  meducatorDataLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_user_data':
      return {
        ...state,
        meducatorData: action.payload,
        meducatorDataLoading: false,
      };
    case 'update_user_data_loading_status':
      return {
        ...state,
        meducatorDataLoading: action.payload,
      };
    default:
      return state;
  }
};

const MeducatorDetailed = () => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const {meducatorId} = useParams();

  useEffect(() => {
    getUserData();
  }, [meducatorId]);

  const getUserData = () => {
    get('/api/user-manager/admin/instructors/' + meducatorId).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('user_info')) {
          dispatch({
            type: 'update_user_data',
            payload: resData.user_info,
          });
        } else dispatch({
          type: 'update_user_data',
          payload: null,
        });
      } else dispatch({
        type: 'update_user_data_loading_status',
        payload: false,
      });
    }).catch(() => {
      dispatch({
        type: 'update_user_data_loading_status',
        payload: false,
      });
    });
  };

  const {meducatorData, meducatorDataLoading} = state;

  if (meducatorDataLoading) return (
      <Row>
        <Col span={24}>
          <h3 className='text-center mc-mt-100'><b><Icon type="loading"
                                                         className="mc-mr-15"/> Loading...</b>
          </h3>
        </Col>
      </Row>
  );

  if (meducatorData === null) {
    return (
        <div className="main-content-block">
          <Row gutter={16}>
            <Col span={24}>
              <Empty
                  className="mc-mtb-100"
                  image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                  description={<span>User data not found</span>}
              />
            </Col>
          </Row>
        </div>
    );
  }

  const {name, user_name, mca_register_id = '--', address = '--', deactivate = false, avatar, email = '--', email_verified, contact_number, country_data: {dial_code, name: countryName = '--'}, state: userState = '--', qualification = '--', specialization = '--', university = '--', mbbs_percentage = '--', created_at} = meducatorData;

  const mobileNumberText = contact_number ? '+' + dial_code + '-' + contact_number : '--';
  const registeredDate = created_at ? moment.unix(created_at).format('DD MMMM, YYYY') : '--';

  const handleActivateDeactivate = () => {
    post('/api/user-manager/admin/deactivate-instructor', {
      instructor_id: meducatorId,
      deactivate: !deactivate,
    }).then(response => {
      getUserData();
    }).catch(() => {
      message.error('Something went wrong');
    });
  };

  return (
      <div className="main-content-block">
        <Row gutter={32}>
          <Col span={12}>
            <div className="page-header">
              <Link to={'/dashboard/meducators/'}>
                <Icon type="arrow-left" title={'Back'}/>
              </Link>
              <Divider type="vertical"/> Meducator Information
            </div>
          </Col>
          <Col span={12} className="text-right">
            {
              deactivate === true ? (
                  <Button
                      type="primary"
                      onClick={handleActivateDeactivate}
                  >Activate</Button>
              ) : (
                  <Button
                      type="danger"
                      onClick={handleActivateDeactivate}
                  >Deactivate</Button>
              )
            }
          </Col>
          <Col span={24}>
            <hr className="mc-mt-10"/>
          </Col>
        </Row>
        <div className="userInfo">
          <Row gutter={16}>
            <Col xs={24} sm={10} className="text-center">
              <Avatar size={100} src={getAvatarUrl(avatar)}/>
              <p className="userInfo__userFullName"
                 title="Full Name">{name}</p>
              <p className="userInfo__userName"
                 title="Username">{user_name}</p>
            </Col>
            <Col xs={24} sm={14}>
              <Row gutter={32}>
                <Col span={6}>
                  <h4>Email Id</h4>
                </Col>
                <Col span={18}>
                  <h4 className="ww-bw">:
                    <span className="mc-ml-15">
                      {email}
                      {
                        email_verified && (
                            <Icon
                                className="blue-text mc-ml-5"
                                title="Verified"
                                type="check-circle"
                            />
                        )
                      }
                      {
                        email !== '--' && (
                            <CopyToClipboard
                                text={email}
                                onCopy={emailCopiedAlert}
                            >
                              <Icon
                                  type="copy"
                                  className="blue-text mc-ml-5"
                              />
                            </CopyToClipboard>
                        )
                      }
                    </span>
                  </h4>
                </Col>
              </Row>
              <Row gutter={32} className="mc-mt-10">
                <Col span={6}>
                  <h4>Mobile Number</h4>
                </Col>
                <Col span={18}>
                  <h4 className="ww-bw">:
                    <span className="mc-ml-15">
                      {mobileNumberText}
                      {
                        contact_number !== undefined && (
                            <CopyToClipboard
                                text={mobileNumberText}
                                onCopy={mobileNumberCopiedAlert}
                            >
                              <Icon
                                  type="copy"
                                  className="blue-text mc-ml-5"
                              />
                            </CopyToClipboard>
                        )
                      }
                    </span>
                  </h4>
                </Col>
              </Row>
              <Row gutter={32} className="mc-mt-10">
                <Col span={6}>
                  <h4>Qualification</h4>
                </Col>
                <Col span={18}>
                  <h4>: <span className="mc-ml-15">{qualification}</span></h4>
                </Col>
              </Row>
              {
                qualification === 'MBBS' && (
                    <Row gutter={32} className="mc-mt-10">
                      <Col span={6}>
                        <h4>University</h4>
                      </Col>
                      <Col span={18}>
                        <h4>: <span className="mc-ml-15">{university}</span>
                        </h4>
                      </Col>
                    </Row>
                )
              }
              {
                qualification === 'Post MBBS' && (
                    <Row gutter={32} className="mc-mt-10">
                      <Col span={6}>
                        <h4>MCA Register Id</h4>
                      </Col>
                      <Col span={18}>
                        <h4>: <span
                            className="mc-ml-15">{mca_register_id}</span></h4>
                      </Col>
                    </Row>
                )
              }
              {
                qualification === 'MD/MS/DNB' || qualification === 'DM/DCH' &&
                (
                    <Row gutter={32} className="mc-mt-10">
                      <Col span={6}>
                        <h4>Specialization</h4>
                      </Col>
                      <Col span={18}>
                        <h4>: <span
                            className="mc-ml-15">{specialization}</span>
                        </h4>
                      </Col>
                    </Row>
                )
              }
              <Row gutter={32} className="mc-mt-10">
                <Col span={6}>
                  <h4>Address</h4>
                </Col>
                <Col span={18}>
                  <h4>: <span className="mc-ml-15">{address}</span></h4>
                </Col>
              </Row>
              <Row gutter={32} className="mc-mt-10">
                <Col span={6}>
                  <h4>Registered Date</h4>
                </Col>
                <Col span={18}>
                  <h4>: <span className="mc-ml-15">{registeredDate}</span>
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
  );
};

export {MeducatorDetailed};
