import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Upload,
} from 'antd';
import {get, post} from '../../../components/mc_axios/McAxios';

const Dragger = Upload.Dragger;

class EditSlideForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.slideId,
      desktopImageUrl: '',
      mobileImageUrl: '',
      altText: '',
      referenceUrl: '',
      createdAt: 0,
    };
  }

  componentDidMount() {
    this.getSlideData();
  }

  getSlideData = () => {
    const {id} = this.state;
    get('/api/coordinates/coordinates/sliders/' + id).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data.slider;
        this.setState({
          desktopImageUrl: resData.desktop_image === null ||
          resData.desktop_image === undefined ? '' : resData.desktop_image,
          mobileImageUrl: resData.mobile_image === null ||
          resData.mobile_image === undefined ? '' : resData.mobile_image,
          altText: resData.alt_text === null || resData.alt_text === undefined ?
              '' :
              resData.alt_text,
          referenceUrl: resData.reference === null || resData.reference ===
          undefined ? '' : resData.reference,
          createdAt: resData.createdat === null || resData.createdat ===
          undefined ? 0 : resData.createdat,
        });
      } else message.error('Something went wrong! Try again.');
    });
  };

  handleDesktopImageChange = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      message.success(`${info.file.name} file uploaded successfully`);
      const {data} = info.file.response;
      this.setState({
        desktopImageUrl: data.file_url,
        altText: data.alt_text,
      });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleMobileImageChange = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      message.success(`${info.file.name} file uploaded successfully`);
      let data = info.file.response.data;
      this.setState({
        mobileImageUrl: data.file_url,
        altText: data.alt_text,
      });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeImage = (type) => {
    if (type === 'desktop') {
      this.setState({
        desktopImageUrl: '',
      });
    } else {
      this.setState({
        mobileImageUrl: '',
      });
    }
  };

  handleRefUrlChange = e => {
    this.setState({
      referenceUrl: e.target.value,
    });
  };

  handleUpdateSlide = status => {
    const {id, desktopImageUrl, mobileImageUrl, altText, referenceUrl, createdAt} = this.state;
    if (desktopImageUrl === '' || mobileImageUrl === '' || referenceUrl ===
        '') {
      message.warning('Web, Mobile image and reference links are mandatory');
    } else {
      const data = {
        slider_id: id,
        desktop_image: desktopImageUrl,
        mobile_image: mobileImageUrl,
        alt_text: altText,
        reference: referenceUrl,
        status,
        created_at: createdAt,
      };

      post('/api/coordinates/coordinates/sliders/update', data).
          then(response => {
            if (response.data.status === true && response.data.code === 200) {
              if (status === 0) {
                message.success('Slide has been created successfully');
                this.props.history.push('/dashboard/slider/');
              } else {
                this.handleAddToSlider();
              }
            } else {
              message.error('Something went wrong! Try again.');
            }
          });
    }
  };

  handleAddToSlider = () => {
    post('/api/coordinates/coordinates/sliders/add_to_homepage', {
      slider_id: this.state.id,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Slide has been added successfully');
        this.props.history.push('/dashboard/slider/');
      } else message.error('Something went wrong! Try again.');
    });
  };

  handleDeleteSlide = () => {
    const {id} = this.state;
    post('/api/coordinates/coordinates/sliders/delete', {slider_id: id}).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            message.success('Slide has been deleted successfully');
            this.props.history.push('/dashboard/slider/');
          } else {
            message.error('Something went wrong! Try again.');
          }
        });
  };

  render() {
    const {desktopImageUrl, mobileImageUrl, altText, referenceUrl} = this.state;
    const isDisabled = desktopImageUrl === '' || mobileImageUrl === '' ||
        referenceUrl === '';

    return (<Row className="main-content-block" gutter={16}>
      <Col span={24}>
        <Form>
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/slider/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Slide
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Popconfirm
                  title="Are you sure delete this task?"
                  onConfirm={this.handleDeleteSlide}
                  okText="Yes"
                  cancelText="No"
              >
                <Button
                    htmlType="button"
                    className="mc-mr-5"
                    type={'danger'}
                >
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  className="mc-mr-5"
                  onClick={() => this.handleUpdateSlide(0)}
              >
                Update
              </Button>
              <Button
                  htmlType="button"
                  className="mc-mr-5"
                  disabled={isDisabled}
                  onClick={() => this.handleUpdateSlide(1)}
              >
                Add to Slider
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Desktop Image">
                {
                  desktopImageUrl === '' ? (
                      <Dragger
                          name="file"
                          accept=".png, .jpg, .jpeg"
                          action="/api/media/upload"
                          data={{product_type: 2}}
                          listType="picture"
                          onChange={this.handleDesktopImageChange}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                  ) : (<>
                    <img
                        alt={altText}
                        src={desktopImageUrl}
                        className="responsive-image"
                    />
                    <Button
                        type="danger"
                        htmlType={'button'}
                        className="mc-mt-10"
                        onClick={() => this.removeImage('desktop')}
                    >Remove</Button>
                  </>)
                }
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mobile Image">
                {
                  mobileImageUrl === '' ? (
                      <Dragger
                          name="file"
                          accept=".png, .jpg, .jpeg"
                          action="/api/media/upload"
                          data={{product_type: 2}}
                          listType="picture"
                          onChange={this.handleMobileImageChange}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                  ) : (<>
                    <img
                        alt={altText}
                        src={mobileImageUrl}
                        className="responsive-image"
                    />
                    <Button
                        type="danger"
                        htmlType={'button'}
                        className="mc-mt-10"
                        onClick={() => this.removeImage('mobile')}
                    >Remove</Button>
                  </>)
                }
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item label="Reference Link">
                <Input
                    placeholder={'https://www.xyz.com/'}
                    size={'large'}
                    value={referenceUrl}
                    onChange={this.handleRefUrlChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>);
  }
}

const EditSlide = withRouter(Form.create({name: 'edit_slide'})(EditSlideForm));

export {EditSlide};
