import React, {PureComponent} from 'react';
import {Button, Form, Input, message, Select} from 'antd';
import {CreateCategoryModal} from '../module_components/med_video/CreateCategoryModal';
import {post} from '../mc_axios/McAxios';

const InputGroup = Input.Group;
const Option = Select.Option;

class Categories extends PureComponent {
  state = {
    categoryList: [],
    showAddCategoryPopup: false,
  };

  showCCModal = () => {
    this.setState({
      showAddCategoryPopup: true,
    });
  };

  saveCCModalFormRef = formRef => {
    this.ccModalFormRef = formRef;
  };

  handleCC = () => {
    const {form} = this.ccModalFormRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      post('/api/coordinates/coordinates/category/add', {
        category: values.category_name,
      }).then(response => {
        if (response.hasOwnProperty('data') && response.data.status === true) {
          const {id, category} = response.data.data.category;
          message.success('Category has been created');
          form.resetFields();
          this.props.onCreateCategory({
            key: id,
            label: category,
          });
          this.setState({
            showAddCategoryPopup: false,
          });
        } else {
          message.error('Something went wrong');
        }
      });
    });
  };

  handleCCModalCancel = () => {
    this.setState({
      showAddCategoryPopup: false,
    });
  };

  handleCategorySearch = value => {
    if (value.length > 2) {
      post('/api/coordinates/coordinates/category/search', {category: value}).
          then(response => {
            if (response.data.status === true) {
              const categories = response.data.data.categories;
              if (categories !== undefined) {
                this.setState({
                  categoryList: categories,
                });
              }
            }
          });
    }
  };

  render() {
    const {value, onChange} = this.props;
    const {categoryList, showAddCategoryPopup} = this.state;
    return (<>
      <Form.Item label="Category">
        <InputGroup compact>
          <Select
              showSearch
              allowClear
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              labelInValue
              placeholder="Enter Category"
              value={value}
              onSearch={this.handleCategorySearch}
              onChange={onChange}
              size={'large'}
              className="category-input"
          >
            {categoryList.map(d => (
                <Option key={d.id}>{d.category}</Option>
            ))}
          </Select>
          <Button
              htmlType="button"
              size="large"
              style={{width: 70}}
              onClick={this.showCCModal}
          >Add</Button>
        </InputGroup>
      </Form.Item>
      <CreateCategoryModal
          wrappedComponentRef={this.saveCCModalFormRef}
          visible={showAddCategoryPopup}
          onCreate={this.handleCC}
          onCancel={this.handleCCModalCancel}
      />
    </>);
  }
}

export {Categories};
