import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import './SystemSubjectWisePerformanceCard.css';
import {isMobile} from 'react-device-detect';

const SystemSubjectWisePerformanceCard = (props) => {
  const {score, data, test, subject, shadow} = props;
  const formatter1 = 'Correct\n{a|' + data[0] + '}';
  const formatter2 = 'Wrong\n{a|' + data[1] + '}';
  const options = test
      ? {
        backgroundColor: '#fff',
        title: {
          text: Math.round(score),
          subtext: 'Your Score',
          x: 'center',
          y: 'center',
          textStyle: {
            color: 'black',
            fontSize: 20,
            fontWeight: 'normal',
          },
          subtextStyle: {
            color: 'black',
            fontSize: 14,
            fontWeight: 'normal',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)',
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '65%'],
            avoidLabelOverlap: true,
            label: {
              show: !isMobile,
              // emphasis: {
              //     show: isMobile,
              //     textStyle: {
              //         fontSize: "12",
              //         fontWeight: "bold"
              //     }
              // }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: data[1],
                name: 'InCorrect',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: '#D02559',
                    },
                    {
                      offset: 1,
                      color: '#FF457E',
                    },
                  ]),
                },
              },
              {
                value: data[2],
                name: 'Unanswered',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: '#9F9F9F',
                    },
                    {
                      offset: 1,
                      color: '#DFDFDF',
                    },
                  ]),
                },
              },
              {
                value: data[0],
                name: 'Correct',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: '#00A55F',
                    },
                    {
                      offset: 1,
                      color: '#3CEFA3',
                    },
                  ]),
                },
              },
            ],
          },
        ],
      }
      : {
        backgroundColor: '#fff',
        title: {
          text: Math.round(score),
          subtext: subject ? 'Percentage' : 'Your Score',
          x: 'center',
          y: 'center',
          textStyle: {
            color: 'black',
            fontSize: 30,
            fontWeight: 'normal',
          },
          subtextStyle: {
            color: 'black',
            fontSize: 14,
            fontWeight: 'normal',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        legend: {
          x: 'center',
          y: 'bottom',
          data: ['rose3', 'rose5', 'rose6', 'rose7', 'rose8'],
        },
        calculable: true,
        series: [
          {
            name: '',
            type: 'pie',
            radius: [110, 120],
            center: ['50%', '50%'],
            data: [
              {
                value: Math.round(data[1]),
                name: 'Wrong',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: '#f6e3a1',
                    },
                    {
                      offset: 1,
                      color: '#ff4236',
                    },
                  ]),
                },
                label: {
                  color: 'rgba(255,255,255,.45)',
                  fontSize: 14,
                  formatter: formatter2,
                  rich: {
                    a: {
                      color: 'black',
                      fontSize: 20,
                      lineHeight: 30,
                    },
                  },
                },
              },
              {
                value: Math.round(data[0]),
                name: 'Correct',
                itemStyle: {
                  color: 'transparent',
                },
              },
            ],
          },
          {
            // name: 'Wrong',
            type: 'pie',
            radius: [100, 130],
            center: ['50%', '50%'],
            data: [
              {
                value: Math.round(data[1]),
                name: 'Wrong',
                itemStyle: {
                  color: 'transparent',
                },
              },
              {
                value: Math.round(data[0]),
                name: 'Correct',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: '#348fe6',
                    },
                    {
                      offset: 1,
                      color: '#625bef',
                    },
                  ]),
                },
                label: {
                  color: 'rgba(255,255,255,.45)',
                  fontSize: 14,
                  formatter: formatter1,
                  rich: {
                    a: {
                      color: 'black',
                      fontSize: 20,
                      lineHeight: 30,
                    },
                  },
                },
              },
            ],
          },
        ],
      };

  return (
      <div className={shadow === undefined ?
          'pc-section' :
          'pc-section no-shadow'}>
        <Row>
          <Col span={24}>
            <ReactEcharts option={options}/>
          </Col>
        </Row>
      </div>
  );
};

export default SystemSubjectWisePerformanceCard;
