import React, {Component} from 'react';
import {Form, Select} from 'antd';
import {getSystemList} from '../util';

const Option = Select.Option;

export default class Systems extends Component {
  state = {
    systems: [],
  };

  componentDidMount() {
    getSystemList(systems => {
      this.setState({
        systems,
      });
    });
  }

  render() {

    const {value, onChange} = this.props;
    const {systems} = this.state;

    return (
        <Form.Item label="System">
          <Select
              mode="multiple"
              placeholder="Select system"
              size={'large'}
              value={value}
              onChange={onChange}
          >
            {systems.map(function(system, index) {
              return (
                  <Option value={system} key={index}>
                    {system}
                  </Option>
              );
            })}
          </Select>
        </Form.Item>
    );
  }
}
