import {message} from 'antd';
import {
  ADD_TEST_QUESTIONS,
  GET_TEST_DETAILS,
  REMOVE_QUESTION_FROM_CHALLENZ,
  RESET_TEST_DATA,
  SORT_TEST_QUESTIONS_LIST,
  TEST_BASIS_CHANGE,
  TEST_CORRECT_QUESTION_MARKS_CHANGE,
  TEST_DESCRIPTION_CHANGE,
  TEST_END_DATE_CHANGE,
  TEST_FEATURED_IMAGE_CHANGE,
  TEST_IS_PREMIUM_CHANGE,
  TEST_MODE_CHANGE,
  TEST_SEO_META_DESCRIPTION_CHANGE,
  TEST_SEO_META_KEYWORDS_CHANGE,
  TEST_SEO_META_TITLE_CHANGE,
  TEST_SEO_SLUG_CHANGE,
  TEST_SERUM_CHANGE,
  TEST_START_DATE_CHANGE,
  TEST_TITLE_CHANGE,
  TEST_TOTAL_QUESTIONS_CHANGE,
  TEST_TOTAL_TIME_CHANGE,
  TEST_WRONG_QUESTION_MARKS_CHANGE,
} from '../types';
import {removeArrayItemByIndex, slugify} from '../../components/util';
import {get, post} from '../../components/mc_axios/McAxios';

export function getTestData(testId) {
  return async dispatch => {
    get('/api/tests/tests/' + testId).then(response => {
      if (response.data.status === true) {
        dispatch({
          type: GET_TEST_DETAILS,
          payload: {
            test: response.data.data.test,
            masterNoteName: response.data.data.master_note,
            questions: response.data.data.questions !== null ?
                response.data.data.questions :
                [],
          },
        });
      } else {
        message.error('Something went wrong.');
      }
    });
  };
}

export function handleEditTestFieldChanges(fieldName, data) {
  return async (dispatch, getState) => {
    switch (fieldName) {
      case 'title':
        dispatch({
          type: TEST_TITLE_CHANGE,
          payload: {
            title: data,
            metaTitle: data,
            slug: slugify(data),
          },
        });
        break;
      case 'premium':
        dispatch({
          type: TEST_IS_PREMIUM_CHANGE,
          payload: data,
        });
        break;
      case 'basis':
        dispatch({
          type: TEST_BASIS_CHANGE,
          payload: data,
        });
        break;
      case 'mode':
        dispatch({
          type: TEST_MODE_CHANGE,
          payload: data,
        });
        break;
      case 'total-questions':
        dispatch({
          type: TEST_TOTAL_QUESTIONS_CHANGE,
          payload: data,
        });
        break;
      case 'total-time':
        dispatch({
          type: TEST_TOTAL_TIME_CHANGE,
          payload: data,
        });
        break;
      case 'cqm':
        dispatch({
          type: TEST_CORRECT_QUESTION_MARKS_CHANGE,
          payload: data,
        });
        break;
      case 'wqm':
        dispatch({
          type: TEST_WRONG_QUESTION_MARKS_CHANGE,
          payload: data,
        });
        break;
      case 'start-date':
        dispatch({
          type: TEST_START_DATE_CHANGE,
          payload: data,
        });
        break;
      case 'end-date':
        dispatch({
          type: TEST_END_DATE_CHANGE,
          payload: data,
        });
        break;
      case 'serum':
        dispatch({
          type: TEST_SERUM_CHANGE,
          payload: data,
        });
        break;
      case 'description':
        dispatch({
          type: TEST_DESCRIPTION_CHANGE,
          payload: data,
        });
        break;
      case 'featured-image':
        dispatch({
          type: TEST_FEATURED_IMAGE_CHANGE,
          payload: {
            imgUrl: data.imgUrl,
            imgAltText: data.imgAltText,
          },
        });
        break;
      case 'remove-featured-image':
        dispatch({
          type: TEST_FEATURED_IMAGE_CHANGE,
          payload: {
            imgUrl: '',
            imgAltText: '',
          },
        });
        break;
      case 'sort-questions-list':
        dispatch({
          type: SORT_TEST_QUESTIONS_LIST,
          payload: data,
        });
        break;
      case 'remove-question-from-test':
        const {id, basis, questionIds, questionsList} = getState().prepmed.editTest;
        const currQuestionId = questionIds[data];
        post('/api/tests/tests/remove_question', {
          'test_id': id,
          'question_id': currQuestionId,
          'test_basis': basis,
        }).then(response => {
          if (response.data.status === true) {
            dispatch({
              type: REMOVE_QUESTION_FROM_CHALLENZ,
              payload: {
                questionIds: removeArrayItemByIndex(questionIds, data),
                questionsList: removeArrayItemByIndex(questionsList, data),
              },
            });
          } else {
            message.error('Something went wrong. Please tr again.');
          }
        });
        break;
      default:
        break;
    }
  };
}

export function handleSeoDataChange(field, value) {
  return async dispatch => {
    switch (field) {
      case 'seo-meta-title':
        dispatch({
          type: TEST_SEO_META_TITLE_CHANGE,
          payload: value,
        });
        break;
      case 'seo-meta-slug':
        dispatch({
          type: TEST_SEO_SLUG_CHANGE,
          payload: value,
        });
        break;
      case 'seo-meta-description':
        dispatch({
          type: TEST_SEO_META_DESCRIPTION_CHANGE,
          payload: value,
        });
        break;
      case 'seo-meta-keywords':
        dispatch({
          type: TEST_SEO_META_KEYWORDS_CHANGE,
          payload: value,
        });
        break;
      default:
        break;
    }
  };
}

export function addTestQuestions(quesId, quesTitle, quesStatus, quesSubject) {
  return async (dispatch, getState) => {
    const {id, basis, questionIds, questionsList, totalQuestions} = getState().prepmed.editTest;
    const found = questionIds.some(el => el === quesId);
    if (found) {
      message.warning('Question is already added.');
      dispatch({
        type: ADD_TEST_QUESTIONS,
      });
    } else if (totalQuestions <= questionIds.length) {
      message.warning('Can\'t add new questions. Limit exceeded.');
    } else if (quesSubject !== undefined && quesSubject.length > 0) {
      let data = {
        'test_id': id,
        'question_id': quesId,
        'test_basis': basis,
        'subject': quesSubject,
      };

      post('/api/tests/tests/add_question', data).then(response => {
        if (response.data.code === 200) {
          let ids = questionIds;
          let questions = questionsList === '' ? [] : questionsList;
          ids.push(quesId);
          questions.push(
              {title: quesTitle, question_id: quesId, status: quesStatus});
          message.success('Question added successfully');
          dispatch({
            type: ADD_TEST_QUESTIONS,
            payload: {
              ids: ids,
              questions: questions,
            },
          });
        } else {
          message.warning('Question limit is exceeded in this subject.');
        }
      });
    } else {
      message.warning('Question doesn\'t assigned to any subject.');
    }
  };
}

export function handleEditTestFormReset() {
  return {
    type: RESET_TEST_DATA,
  };
}
