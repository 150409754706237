import {GET_NOTE_DATA, RESET_NOTE_DATA} from '../../../actions/types';

const initialState = {
  visible: false,
  title: '',
  explanation: '',
  tags: [],
  reference: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTE_DATA:
      let data = action.payload;
      return {
        ...state,
        visible: true,
        title: data.title,
        explanation: data.explanation,
        tags: data.tags,
        reference: data.reference,
      };
    case RESET_NOTE_DATA:
      return {
        ...state,
        visible: false,
      };
    default:
      return initialState;
  }
};
