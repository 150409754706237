import React from 'react';
import '../UserMedBook.css';

const UserChallengePrepMedCountCard = (props) => {

  const {challengesCount = 0, testsCount = 0} = props;

  return (
      <div className="wallet-card lite-green-bg">
        <div>
          <p>Completed Challenges</p>
          <p>{challengesCount}</p>
        </div>
        <div>
          <p>Completed Tests</p>
          <p>{testsCount}</p>
        </div>
      </div>
  );
};

export {UserChallengePrepMedCountCard};
