import React from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Pagination,
  Row,
} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  addPWChallengeQuestions,
  getPWChallengeData,
  handleEditPWChallengeFormReset,
} from '../../../actions/pw_challenge_actions/EditPWChallengeActions';
import {showQuestionPreview} from '../../../actions/qbank_actions/PreviewQuestionActions';
import {post} from '../../../components/mc_axios/McAxios';
import {
  createMarkup,
  redirectToEditQuestionPage,
} from '../../../components/util';
import {SortablePWChallengeQuestions} from '../../../components/sortable_questions/SortPWChallengeQuestions';

class AddPWChallengeQuestionsForm extends React.Component {
  state = {
    showQBankList: false,
    searchedQuestionsList: [],
    currentPageNumber: 1,
    paginationStart: 1,
    searchTerm: '',
    totalQBankQuestions: 0,
  };

  componentDidMount() {
    const {match: {params: {challengeId}}, getPWChallengeData} = this.props;
    getPWChallengeData(challengeId);
  }

  componentWillUnmount() {
    this.props.handleEditPWChallengeFormReset();
  }

  searchQBankQuestions = () => {
    const {currentPageNumber, searchTerm} = this.state;

    let pData = {
      count: 25,
      page_number: currentPageNumber,
      search_term: searchTerm,
      status: 0,
      subject: '',
      pattern: 10,
    };

    post('/api/cosmos/questions/pattern_questions', pData).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        if (resData.hasOwnProperty('list') === true) {
          this.setState({
            totalQBankQuestions: resData.count,
            searchedQuestionsList: resData.list,
          });
        } else {
          this.setState({
            totalQBankQuestions: 0,
            searchedQuestionsList: [],
          });
        }
      }
    });
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * 25;
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.searchQBankQuestions();
        },
    );
  };

  handleSubmit = (currStatus) => {
    const {editPWChallenge} = this.props;

    let data = {
      play_id: editPWChallenge.id,
      play_name: editPWChallenge.title,
      status: currStatus === 2 ? editPWChallenge.status : currStatus,
      number_of_questions: parseInt(editPWChallenge.totalQuestions),
      start_time: moment(editPWChallenge.startDate, 'DD/MM/YYYY HH:mm:ss').
          unix(),
      description: editPWChallenge.description,
      featured_image_url: editPWChallenge.featuredImageUrl,
      featured_image_alt_text: editPWChallenge.featuredImageAltText,
      featured_image_link: editPWChallenge.featuredImageLink,
      thumbnail_image_url: editPWChallenge.thumbnailImageUrl,
      thumbnail_image_alt_text: editPWChallenge.thumbnailImageAltText,
      question_ids: editPWChallenge.questionIds,
      sponsored_add: editPWChallenge.sponsorAdMediaUrl,
      sponsor_link: editPWChallenge.sponsorAdLink,
      reward_amount: editPWChallenge.rewardAmount,
      meta_title: editPWChallenge.seoMetaTitle,
      slug: editPWChallenge.seoSlug,
      meta_description: editPWChallenge.seoMetaDescription,
      meta_keywords: editPWChallenge.seoMetaKeywords,
      created_at: editPWChallenge.createdAt,
      updated_at: editPWChallenge.updatedAt,
    };

    post('/api/challenzes/plays/update', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        if (currStatus === 2) {
          this.publishTest(editPWChallenge.id);
        } else message.success('Challenge has been updated');
        this.setState({
          showQBankList: false,
        });
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  publishTest = id => {
    post('/api/challenzes/plays/publish', {play_id: id}).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Challenge has been published');
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  showHideQbankQuestionsList = () => {
    this.setState(prevState => ({
      showQBankList: !prevState.showQBankList,
    }));
  };

  redirectToCreateQuestionPage = () => {
    window.open('/dashboard/qbank/create-question/', '_blank');
  };

  render() {
    const {
      editPWChallenge,
      addPWChallengeQuestions,
      showQuestionPreview,
    } = this.props;

    const {
      showQBankList,
      currentPageNumber,
      paginationStart,
      totalQBankQuestions,
      searchedQuestionsList,
    } = this.state;

    const Search = Input.Search;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/play-and-win-challenges/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Add Questions
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Update
              </Button>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
                  className="mc-mr-5"
              >
                Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col span={16}>
              <h2>{editPWChallenge.title}</h2>
              <Row>
                <Col span={12}>
                  <p>Total Questions:</p>
                  <p>Start Date:</p>
                </Col>
                <Col span={12}>
                  <p>{editPWChallenge.totalQuestions}</p>
                  <p>{editPWChallenge.startDate}</p>
                </Col>
                <Col span={24}>
                  <Link
                      to={`/dashboard/play-and-win-challenges/edit/${editPWChallenge.id}`}>
                    <Button htmlType={'button'} type="primary">Edit</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <img
                  src={editPWChallenge.featuredImageUrl}
                  alt={editPWChallenge.featuredImageAltText}
                  style={{width: '100%'}}
              />
            </Col>
            <Col span={24}>
              <Card
                  type="inner"
                  title="Questions"
                  className={'mc-mt-20 mc-mb-20'}
                  extra={
                    <>
                      <Button
                          htmlType="button"
                          className="mc-mr-10"
                          onClick={this.showHideQbankQuestionsList}
                      >
                        {showQBankList === false ? 'Add from QBank' : 'Back'}
                      </Button>
                      <Button
                          htmlType="button"
                          onClick={this.redirectToCreateQuestionPage}
                      >CreateQuestion</Button>
                    </>
                  }
              >
                {
                  showQBankList === true ? (
                      <Row>
                        <Col span={24}>
                          <Search
                              placeholder="Search for questions"
                              onSearch={value =>
                                  this.setState({searchTerm: value}, () => {
                                    this.searchQBankQuestions();
                                  })
                              }
                              enterButton
                              size="large"
                              className="mc-mb-10"
                              style={{
                                width: '100%',
                                maxWidth: 500,
                              }}
                          />
                        </Col>
                        {
                          searchedQuestionsList.length > 0 ? (
                              <>
                                <Col span={24}>
                                  <div className="mc-table-responsive">
                                    <table className="mc-table">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Verified</th>
                                        <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {
                                        searchedQuestionsList.map(
                                            (question, index) => (
                                                <tr key={question.question_id}>
                                                  <td>{paginationStart +
                                                  index}</td>
                                                  <td onClick={() => redirectToEditQuestionPage(
                                                      question.question_id)}>
                                                    <div
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            question.title,
                                                        )}
                                                    />
                                                  </td>
                                                  <td width={100}>
                                                    {
                                                      question.status === 2 ? (
                                                          <Icon
                                                              type="check-circle"
                                                              title={'Verified'}
                                                              theme="filled"
                                                              className="mc-icon active"
                                                          />
                                                      ) : (
                                                          <Icon
                                                              type="close-circle"
                                                              title={'Not Verified'}
                                                              className="mc-icon"
                                                          />
                                                      )

                                                    }
                                                  </td>
                                                  <td width={120}>
                                                    <Icon
                                                        type="eye"
                                                        className="mc-icon-large mc-mr-10"
                                                        title="Preview"
                                                        onClick={() =>
                                                            showQuestionPreview(
                                                                `${question.question_id}`,
                                                            )
                                                        }
                                                    />
                                                    <Icon
                                                        type="plus-circle"
                                                        title="Add question"
                                                        className="mc-icon"
                                                        onClick={() =>
                                                            addPWChallengeQuestions(
                                                                question.question_id,
                                                                question.title,
                                                                question.status,
                                                            )
                                                        }
                                                    />
                                                  </td>
                                                </tr>
                                            ))
                                      }
                                      </tbody>
                                    </table>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <Pagination
                                      className="mc-mt-15"
                                      showQuickJumper
                                      current={currentPageNumber}
                                      pageSize={25}
                                      total={totalQBankQuestions}
                                      onChange={this.onPageChange}
                                  />
                                </Col>
                              </>
                          ) : (
                              <Col span={24}>
                                <Alert
                                    message="Empty List"
                                    type="info"
                                    className="text-center"
                                />
                              </Col>
                          )
                        }
                      </Row>
                  ) : (
                      <>
                        {
                          editPWChallenge.questionsList.length > 0 ? (
                              <SortablePWChallengeQuestions/>
                          ) : (
                              <Alert
                                  message="Empty List"
                                  type="info"
                                  className="text-center"
                              />
                          )
                        }
                      </>
                  )
                }
              </Card>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return state.pwchallenge;
};

const AddPWChallengeQuestions = withRouter(
    connect(
        mapStateToProps,
        {
          getPWChallengeData,
          addPWChallengeQuestions,
          handleEditPWChallengeFormReset,
          showQuestionPreview,
        },
    )(Form.create({name: 'add_pw_questions'})(AddPWChallengeQuestionsForm)),
);

export {AddPWChallengeQuestions};
