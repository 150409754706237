import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import Tooltip from 'antd/es/tooltip';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import './GuessMeterCard.css';

const calculateData = (data) => {
  if (data <= 20) {
    return {
      dotArray: [20],
      color_percent0: 'rgba(255,0,36,1)',
      color_percent100: 'rgba(255,0,36,.3)',
    };
  } else if (data > 20 && data <= 40) {
    return {
      dotArray: [20, 20],
      color_percent0: 'rgba(255,0,36,1)',
      color_percent100: 'rgba(255,0,36,.3)',
    };
  } else if (data > 40 && data <= 60) {
    return {
      dotArray: [20, 20, 20],
      color_percent0: 'rgba(255,123,0,1)',
      color_percent100: 'rgba(255,123,0,.3)',
    };
  } else if (data > 60 && data <= 80) {
    return {
      dotArray: [20, 20, 20, 20],
      color_percent0: 'rgba(12,255,0,1)',
      color_percent100: 'rgba(12,255,0,.3)',
    };
  } else {
    return {
      dotArray: [20, 20, 20, 20, 20],
      color_percent0: 'rgba(12,255,0,1)',
      color_percent100: 'rgba(12,255,0,.3)',
    };
  }
};

const GuessMeterCard = ({data}) => {
  const calculatedData = calculateData(data);
  const gmOptions = {
    xAxis: {
      show: false,
      min: function(value) {
        return value.min - 7;
      },
      max: function(value) {
        return value.max + 7;
      },
      splitLine: {
        lineStyle: {
          show: true,
          type: 'dashed',
        },
      },
      'axisLabel': {
        'interval': 0,
        rotate: 40,
        textStyle: {
          fontSize: 12,
          color: '#000',
        },
      },
      data: ['1', '2', '3', '4', '5'],
    },
    yAxis: {
      show: false,
      name: '万元',
      max: 200,
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [
      {
        'name': '',
        'type': 'pie',
        'radius': ['50%', '70%'],
        'avoidLabelOverlap': true,
        'startAngle': 225,
        'color': [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0.4,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: calculatedData.color_percent0,
              }, {
                offset: 1,
                color: calculatedData.color_percent100,
              }],
            globalCoord: false,
          }, 'none'],
        'hoverAnimation': false,
        'legendHoverLink': false,
        'label': {
          'normal': {
            'show': false,
            'position': 'center',
          },
          'emphasis': {
            'show': true,
            'textStyle': {
              'fontSize': '30',
              'fontWeight': 'bold',
            },
          },
        },
        'labelLine': {
          'normal': {
            'show': false,
          },
        },
        'data': [
          {
            'value': 75,
            'name': '1',
          }, {
            'value': 25,
            'name': '2',
          }],
      }, {
        'name': ' ',
        'type': 'pie',
        'radius': ['48%', '47%'],
        'avoidLabelOverlap': true,
        'startAngle': 225,
        'hoverAnimation': false,
        'legendHoverLink': false,
        'label': {
          'normal': {
            'show': false,
            'position': 'center',
          },
          'emphasis': {
            'show': true,
            'textStyle': {
              'fontSize': '30',
              'fontWeight': 'bold',
            },
          },
        },
        'labelLine': {
          'normal': {
            'show': false,
          },
        },
        'data': [
          {
            'value': 75,
            'name': '1',
          }, {
            'value': 25,
            'name': '2',
          }],
      }, {
        'name': '',
        'type': 'pie',
        'radius': ['50%', '70%'],
        'avoidLabelOverlap': true,
        'startAngle': 315,
        'color': ['rgba(34,34,34,.9)', '#ff7a00', 'transparent'],
        'hoverAnimation': false,
        'legendHoverLink': false,
        'clockwise': false,
        'itemStyle': {
          'normal': {
            'borderColor': 'transparent',
            'borderWidth': '20',
          },
          'emphasis': {
            'borderColor': 'transparent',
            'borderWidth': '20',
          },
        },
        'z': 10,
        'label': {
          'normal': {
            'show': false,
            'position': 'center',
          },
        },
        'labelLine': {
          'normal': {
            'show': false,
          },
        },
        'data': [
          {
            'value': (100 - data) * 270 / 360,
            'label': {
              normal: {
                formatter: data + '%',
                position: 'center',
                show: true,
                textStyle: {
                  fontSize: '20',
                  fontWeight: 'normal',
                  color: 'black',
                },
              },
            },
            'name': '',
          }, {
            'value': 1,
            'name': '',
          }, {
            'value': 100 - (100 - data) * 270 / 360,
            'name': '',
          }],
      },
      {
        name: '',
        symbolOffset: ['0', '0'],
        type: 'scatter',
        data: [20, 20, 20, 20, 20],
      },
      {
        name: '',
        type: 'scatter',
        symbolOffset: ['0', '0'],
        color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
          {
            offset: 0,
            color: calculatedData.color_percent0,
          }, {
            offset: 1,
            color: calculatedData.color_percent100,
          }]),
        data: calculatedData.dotArray,
      },
    ],
  };

  return (
      <div className="gm-card">
        <Row>
          <Col span={24}>
            <h3>Guess Meter</h3>
            <Tooltip
                title="Guess meter helps you in identifying your guessing potential to help you make a proper guess according to subject of your strength in turn avoiding negative marking.">
              <Icon className="info-icon" type="info-circle"/>
            </Tooltip>
          </Col>
          <Col span={24}>
            <ReactEcharts option={gmOptions}/>
          </Col>
        </Row>
      </div>
  );
};

export {GuessMeterCard};
