import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal, Popconfirm,
  Row,
  Select,
  Upload,
} from 'antd';
import {get, post} from '../../../components/mc_axios/McAxios';

const Dragger = Upload.Dragger;
const {Option} = Select;
const {TextArea} = Input;

class EditNotificationPre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationId: props.match.params.notificationId,
      notificationImageUrl: '',
      status: 1,
      sentAt: 0,
      createdAt: 0,
      showModal: false,
      testUserValue: 0,
    };
  }

  componentDidMount() {
    this.getNotificationData();
  }

  getNotificationData = () => {
    get(`/api/notifications/notifications/${this.state.notificationId}`).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            const resData = response.data.data;
            if (resData.hasOwnProperty('notification')) {
              const {title, body, click_action, image_url, priority, status, sent_at, created_at} = resData.notification;
              this.props.form.setFieldsValue({
                title,
                body,
                click_action: click_action || '',
                priority: priority || 0,
              });
              this.setState({
                notificationImageUrl: image_url || '',
                status: status || 1,
                sentAt: sent_at || 0,
                createdAt: created_at,
              });
            } else message.error('Something went wrong');
          } else message.error('Something went wrong');
        }).
        catch(() => message.error('Something went wrong'));
  };

  handleEditNotification = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {title, body, click_action, image_url, priority} = values;
        const {notificationId, notificationImageUrl, status, sentAt, createdAt} = this.state;
        const pictureData = image_url && image_url.length > 0 ?
            image_url[0].response.data.file_url :
            notificationImageUrl;

        const data = {
          notification_id: notificationId,
          title,
          body,
          click_action,
          image_url: pictureData,
          priority,
          status,
          sent_at: sentAt,
          created_at: createdAt,
        };

        post('/api/notifications/notifications/update', data).
            then(response => {
              if (response.data.status === true && response.data.code === 200) {
                message.success('Notification is updated successfully');
                this.props.history.push('/dashboard/notifications/');
              } else message.error('Something went wrong. Please try again');
            }).
            catch(
                () => message.error('Something went wrong. Please try again'));
      }
    });
  };

  handleSendNotification = () => {
    const data = {
      notification_id: this.state.notificationId,
      all: true
    };
    post('/api/notifications/notifications/send', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Notification is sent successfully');
      }
    }).catch(() => message.error('Something went wrong'));
  };

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  sendTestNotification = () => {
    const {notificationId, testUserValue} = this.state;
    const data = {
      notification_id: notificationId,
      test_users: testUserValue,
    };
    post('/api/notifications/notifications/test', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Test notification is sent successfully');
        this.setState({
          showModal: false,
        });
      }
    }).catch(() => message.error('Something went wrong'));
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  removeImage = () => {
    this.setState({
      notificationImageUrl: '',
    });
  };

  handleTestUserChange = value => {
    this.setState({
      testUserValue: value,
    });
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const {props: {form: {getFieldDecorator}}, state: {notificationImageUrl, showModal, testUserValue}} = this;

    return (
        <>
          <Row className="main-content-block" gutter={16}>
            <Col span={24}>
              <Form onSubmit={this.handleEditNotification}>
                <Row gutter={32}>
                  <Col xs={24} sm={12}>
                    <div className="page-header">
                      <Link to={'/dashboard/notifications/'}>
                        <Icon type="arrow-left" title={'Back'}/>
                      </Link>
                      <Divider type="vertical"/> Edit Notification
                    </div>
                  </Col>
                  <Col xs={24} sm={12} className="text-right">
                    <Button
                        htmlType="submit"
                        className="mc-mr-5"
                    >
                      Update
                    </Button>
                    <Button
                        htmlType="button"
                        type="primary"
                        className="mc-mr-5"
                        onClick={this.showModal}
                    >Send Test Notification</Button>
                    <Popconfirm
                        title="Send notification to all users. Are you sure?"
                        onConfirm={this.handleSendNotification}
                        okText="Yes"
                        cancelText="No"
                    >
                    <Button
                        htmlType="button"
                        type="danger"
                    >Send Notification</Button>
                    </Popconfirm>
                  </Col>
                  <Col span={24}>
                    <hr className="mc-mt-10"/>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col xs={24} lg={12}>
                    <Row gutter={32}>
                      <Col xs={24} md={12}>
                        <Form.Item label="Title">
                          {
                            getFieldDecorator('title', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please notification title!',
                                },
                              ],
                            })(
                                <Input
                                    placeholder={'Ex: NEET PG 2020'}
                                    size={'large'}
                                />,
                            )
                          }
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item label="Priority">
                          {
                            getFieldDecorator('priority', {
                              initialValue: 0,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select priority type!',
                                },
                              ],
                            })(
                                <Select
                                    size={'large'}
                                    placeholder="Select priority"
                                >
                                  <Option value={0}>Normal</Option>
                                  <Option value={1}>High</Option>
                                </Select>,
                            )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Redirect URL">
                          {
                            getFieldDecorator('click_action', {
                              initialValue: '',
                            })(
                                <Input
                                    placeholder={'Ex: https://www.medcampus.io/'}
                                    size={'large'}
                                />,
                            )
                          }
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {
                          notificationImageUrl !== '' ? (
                              <Form.Item label="User Picture">
                                <img
                                    src={notificationImageUrl}
                                    alt="Notification Image"
                                    width={300}
                                />
                                <br/>
                                <br/>
                                <Button type="primary"
                                        onClick={this.removeImage}>Remove
                                  Image</Button>
                              </Form.Item>
                          ) : (
                              <Form.Item label="Notification Image">
                                {
                                  getFieldDecorator('image_url', {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: this.normFile,
                                  })(
                                      <Dragger
                                          accept=".png, .jpg, .jpeg"
                                          action="/api/media/upload"
                                          data={{product_type: 0}}
                                          listType="picture"
                                      >
                                        <p className="ant-upload-drag-icon">
                                          <Icon
                                              type="inbox"/></p>
                                        <p className="ant-upload-text">Click or
                                          drag file to this area to upload</p>
                                      </Dragger>,
                                  )
                                }
                              </Form.Item>
                          )
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Message">
                      {
                        getFieldDecorator('body', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter message!',
                            },
                          ],
                        })(<TextArea rows={11}/>)
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Modal
              title="Whom to Send"
              visible={showModal}
              okText="Send"
              onOk={this.sendTestNotification}
              onCancel={this.handleCancel}
              width={300}
          >
            <Form.Item label={'Users'}>
              <Select
                  className="full-width"
                  value={testUserValue}
                  onChange={this.handleTestUserChange}
              >
                <Option value={0}>All</Option>
                <Option value={1}>Dingu</Option>
                <Option value={2}>Naveen</Option>
                <Option value={5}>Rekha</Option>
                <Option value={3}>Sukesh</Option>
                <Option value={4}>Sudeep</Option>
              </Select>
            </Form.Item>
          </Modal>
        </>
    );
  };
}

const EditNotification = withRouter(
    Form.create({name: 'edit_notification'})(EditNotificationPre));

export {EditNotification};
