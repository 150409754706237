import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Empty, Pagination, Row} from 'antd';
import {post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import './Medpixx.css';

class Medpixx extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('medpixx-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('medpixx-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let medpixxStatus = getLocalData('medpixx-status', 1);
    medpixxStatus = medpixxStatus !== '' ? parseInt(medpixxStatus) : 0;

    this.state = {
      medPixxList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      medpixxStatus,
      medpixxSubject: getLocalData('medpixx-subject', 1),
      medpixxSystem: getLocalData('medpixx-system', 1),
      medpixxSearchTerm: getLocalData('medpixx-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }),
        () => {
          this.getMedpixxList();
        },
    );
  }

  getMedpixxList = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      medpixxSubject,
      medpixxSystem,
      medpixxStatus,
      medpixxSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: medpixxSubject,
      system: medpixxSystem,
      status: parseInt(medpixxStatus),
      search_term: medpixxSearchTerm,
    };

    post('/api/medpixx/images', data).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          totalItems: resData.count,
          medPixxList: resData.list,
        });
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('medpixx-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getMedpixxList();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('medpixx-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medpixxStatus: value,
        },
        () => {
          this.getMedpixxList();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('medpixx-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medpixxSubject: value,
        },
        () => {
          this.getMedpixxList();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('medpixx-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medpixxSystem: value,
        },
        () => {
          this.getMedpixxList();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('medpixx-search-term', value, 1);
    this.setState({
      medpixxSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getMedpixxList();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('medpixx-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getMedpixxList();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          medpixxStatus: 0,
          medpixxSubject: '',
          medpixxSystem: '',
          medpixxSearchTerm: '',
        },
        () => {
          setLocalData('medpixx-items-per-page', 25, 1);
          setLocalData('medpixx-page-number', 1, 1);
          setLocalData('medpixx-status', 0, 1);
          setLocalData('medpixx-subject', '', 1);
          setLocalData('medpixx-system', '', 1);
          setLocalData('medpixx-search-term', '', 1);
          this.getMedpixxList();
        },
    );
  };

  render() {
    const {
      medPixxList,
      currentPageNumber,
      itemsPerPage,
      medpixxStatus,
      medpixxSubject,
      medpixxSystem,
      medpixxSearchTerm,
      totalItems,
    } = this.state;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>medPixx</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/medpixx/create'}>Add Image</Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/medpixx/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={medpixxStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={medpixxSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={medpixxSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={medpixxSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {medPixxList !== undefined && medPixxList.length > 0 ? (
                <Row gutter={16} className="mc-mt-10 medpixx-grid">
                  {medPixxList.map((data, index) => (
                      <Col span={6} key={index}>
                        <Link to={`/dashboard/medpixx/edit/${data.medpixx_id}`}>
                          <div className="medpixx-image">
                            <img
                                src={data.medpixx_image_url}
                                alt="Medpixx"
                            />
                          </div>
                        </Link>
                      </Col>
                  ))}
                  <Col span={24}>
                    <br/>
                    <Pagination
                        current={currentPageNumber}
                        pageSize={itemsPerPage}
                        total={totalItems}
                        onChange={this.onPageChange}
                    />
                  </Col>
                </Row>
            ) : (
                <Row gutter={16}>
                  <Col span={24}>
                    <Empty
                        className="mc-mtb-100"
                        image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                        description={<span>No images found</span>}
                    />
                  </Col>
                </Row>
            )}
          </div>
        </>
    );
  }
}

export {Medpixx};
