import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Button,
  Cascader,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from 'antd';
import {McCkEditor, SearchTags} from '../../../components';
import {post} from '../../../components/mc_axios/McAxios';
import {getUserRoleReselector} from '../../../redux/selectors';
import {
  getSubjectList,
  getSystemList,
  SYSTEM_BLOCK_OPTIONS_LIST,
} from '../../../components/util';
import './CreateQuestion.css';

const Option = Select.Option;
const Dragger = Upload.Dragger;
const ButtonGroup = Button.Group;

class CreateQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: '',
      isVerifiedQuestion: false,
      optionsCount: 4,
      optionsContent: ['', '', '', ''],
      explanation: '',
      videoExplanationPrime: false,
      subjectsList: [],
      systemsList: [],
      tagsList: [],
      segmentBlocks: [],
      notesData: [],
      notesList: [],
      fetchingNotes: false,
      notes_prime: false,
      reference: '',
      optionsToolbarVisible: false,
      referenceToolbarVisible: false,
    };
  }

  componentDidMount() {
    getSubjectList(subjects => {
      this.setState({
        subjectsList: subjects,
      });
    });

    getSystemList(systems => {
      this.setState({
        systemsList: systems,
      });
    });
  }

  getNotesList = value => {
    this.setState({notesList: [], fetchingNotes: true});
    post('/api/notes/notes', {
      count: 100,
      page_number: 1,
      search_term: value,
    }).then(response => {
      if (response.data.status === true) {
        if (response.data.data.notes !== undefined) {
          const notesList = response.data.data.notes.map(note => ({
            text: note.title,
            value: note.note_id,
          }));
          this.setState({notesList, fetchingNotes: false});
        }
      }
    });
  };

  handleNotesChange = (value, option) => {
    for (let i = 0; value.length > i; i++) {
      value[i].value = option[i].props['note-id'];
    }
    this.setState({
      notesData: value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values);
      if (err) {
        return;
      }

      const {
        question,
        isVerifiedQuestion,
        optionsContent,
        explanation,
        explanation_type,
        videoExplanationPrime,
        tagsList,
        segmentBlocks,
        notes_prime,
        reference,
        notesData,
      } = this.state;

      values.title = question;
      if (isVerifiedQuestion) {
        values.status = 2;
      } else {
        values.status = 1;
      }
      let tList = [];
      if (tagsList.length > 0) {
        for (let j = 0; j < tagsList.length; j++) {
          tList.push(tagsList[j].key);
        }
      }
      values.tags = tList;

      let mediaUrls = [];
      let questionBasis = 1;
      if (values.question_media !== undefined &&
          values.question_media.fileList.length > 0) {
        let media = values.question_media;
        let mediaType = values.question_media.file.type.split('/')[0];
        switch (mediaType) {
          case 'image':
            questionBasis = 2;
            break;
          case 'audio':
            questionBasis = 3;
            break;
          case 'video':
            questionBasis = 4;
            break;
          default:
            questionBasis = 1;
            break;
        }
        for (let l = 0; l < media.fileList.length; l++) {
          mediaUrls.push(media.fileList[l].response.data.file_url);
        }
      }
      values.question_media = mediaUrls;
      values.question_basis = questionBasis;
      values.options = optionsContent;
      values.explanation = explanation;
      values.explanation_type = explanation_type;
      values.video_explanation_prime = videoExplanationPrime;
      values.concept_level = parseInt(values.concept_level);
      let difficulty = values.concept_level - 1;
      values.difficulty = difficulty > 0 ? difficulty : 0;
      values.segment = segmentBlocks[0];
      values.block = segmentBlocks[1];
      let nList = [];
      let notes = notesData;
      for (let k = 0; k < notes.length; k++) {
        nList.push(notes[k].value);
      }
      values.notes_ids = nList;
      values.notes_prime = notes_prime;
      values.reference = reference;

      post('/api/cosmos/questions/create', values).then(response => {
        if (response.data.code === 200 && response.data.status === true) {
          message.success('Question has been created.');
          // const {history} = this.props;
          // history.push("/dashboard/qbank/");
        } else message.error('Something went wrong.');
      });
    });
  };

  optionFields = () => {
    let children = [];
    for (let i = 0; i < this.state.optionsCount; i++) {
      children.push(
          <McCkEditor
              key={i}
              type={2}
              className="mc-mb-20"
              content={this.state.optionsContent[i]}
              onMcEditorChange={evt =>
                  this.storeOptionData(evt.editor.getData(), i)
              }
          />,
      );
    }
    return children;
  };

  questionOptionsList = () => {
    let optionsList = [];
    for (let i = 1; i <= this.state.optionsCount; i++) {
      optionsList.push(
          <Option key={i} value={i}>
            {i}
          </Option>,
      );
    }
    return optionsList;
  };

  addOptionField = () => {
    let optionsContentList = this.state.optionsContent;
    optionsContentList.push('');
    let newOptionsCount = this.state.optionsCount + 1;

    this.setState({
      optionsCount: newOptionsCount,
      optionsContent: optionsContentList,
    });
  };

  removeOptionField = () => {
    let newOptionsCount = this.state.optionsCount - 1;
    let optionsContentList = this.state.optionsContent.slice(0, -1);
    this.setState({
      optionsCount: newOptionsCount,
      optionsContent: optionsContentList,
    });
  };

  storeOptionData = (content, index) => {
    let optionsContentList = this.state.optionsContent;
    optionsContentList[index] = content;
    this.setState({
      optionsContent: optionsContentList,
    });
  };

  handleVerify = value => {
    this.setState({
      isVerifiedQuestion: value,
    });
  };

  handleQuestionTitle = evt => {
    this.setState({
      question: evt.editor.getData(),
    });
  };

  handleOptionsToolbarVisible = checked => {
    this.setState({
      optionsToolbarVisible: checked,
    });
  };

  handleQuestionExplanation = evt => {
    this.setState({
      explanation: evt.editor.getData(),
    });
  };

  handleExplanationVideoPrimeStatus = checked => {
    this.setState({
      videoExplanationPrime: checked,
    });
  };

  handleTagSelect = value => {
    this.setState({
      tagsList: value,
    });
  };

  handleSegmentBlockChange = value => {
    this.setState({
      segmentBlocks: value,
    });
  };

  handleNotesPrimeStatus = value => {
    this.setState({notes_prime: value});
  };

  handleReferenceToolbarVisible = checked => {
    this.setState({
      referenceToolbarVisible: checked,
    });
  };

  handleReferenceChange = evt => {
    this.setState({
      reference: evt.editor.getData(),
    });
  };

  render() {
    const {form: {getFieldDecorator}, userRole} = this.props;
    const {
      subjectsList,
      systemsList,
      tagsList,
      notesList,
      fetchingNotes,
      question,
      explanation,
      videoExplanationPrime,
      optionsToolbarVisible,
      referenceToolbarVisible,
      reference,
      notes_prime,
    } = this.state;

    const isRestricted = userRole !== 2;

    const fileUploadProps = {
      name: 'file',
      action: '/api/media/upload',
      data: {
        product_type: 5,
      },
      onChange(info) {
        if (info.file.status === 'done' && info.file.response.status === true) {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16} className="main-content-block">
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/qbank/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create Question
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={17}>
              <Row gutter={32}>
                <Col span={24}>
                  <div className="mc-db mc-mt-5">
                    Question:
                    <span className="pull-right">
                      <span
                          className="mc-mr-10">Verified</span>
                      <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={this.handleVerify}
                          className={'qbank-switch-bar'}
                      />
                      <Divider type="vertical"/>
                      <span className="mc-mr-10">Qstock</span>
                      {getFieldDecorator('q_stock')(
                          <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              className={'qbank-switch-bar'}
                          />,
                      )}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <McCkEditor
                      content={question}
                      onMcEditorChange={this.handleQuestionTitle}
                      className="mc-mt-10"
                  />
                  <br/>
                </Col>
                <Col span={24}>
                  <Form.Item label={'Media'}>
                    {getFieldDecorator('question_media')(
                        <Dragger
                            {...fileUploadProps}
                            listType="picture"
                            multiple
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly
                            prohibit
                            from uploading company data or other band files
                          </p>
                        </Dragger>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <p>Options:</p>
                </Col>
                <Col xs={18} className="text-right">
                  <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={this.handleOptionsToolbarVisible}
                  />
                  <Divider type={'vertical'}/>
                  <ButtonGroup size="small">
                    <Button htmlType="button" onClick={this.addOptionField}>
                      +
                    </Button>
                    <Button htmlType="button" onClick={this.removeOptionField}>
                      -
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col
                    xs={24}
                    className={
                      optionsToolbarVisible === false
                          ? 'options-list-block active'
                          : 'options-list-block'
                    }
                >
                  {this.optionFields()}
                </Col>
                <Col span={24}>
                  <Form.Item label="Correct Answer">
                    {getFieldDecorator('correct_answer', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select any answer!',
                        },
                      ],
                    })(
                        <Select
                            placeholder="Select correct answer"
                            size={'large'}
                            mode="multiple"
                        >
                          {this.questionOptionsList()}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Explanation">
                    <McCkEditor
                        content={explanation}
                        onMcEditorChange={this.handleQuestionExplanation}
                        className="mc-mt-10"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {/*<p>*/}
                  {/*  mVideo Id:*/}
                  {/*  {*/}
                  {/*    !isRestricted && (*/}
                  {/*        <Switch*/}
                  {/*            className={'pull-right'}*/}
                  {/*            checked={videoExplanationPrime}*/}
                  {/*            checkedChildren="Paid"*/}
                  {/*            unCheckedChildren="Free"*/}
                  {/*            onChange={this.handleExplanationVideoPrimeStatus}*/}
                  {/*        />*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*</p>*/}
                  <Form.Item label="mVideo Id">
                    {
                      getFieldDecorator('video_explanation_url')(
                          <Input placeholder="8d5w9b26g9e7d4s6" size="large"/>,
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={7}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Question Type">
                    {getFieldDecorator('question_type', {
                      rules: [
                        {
                          required: true,
                          message: 'Question type is required',
                        }],
                    })(
                        <Select placeholder="Select question type"
                                size={'large'}>
                          <Option value={1}>Single best answer</Option>
                          <Option value={2}>Multiple true-false</Option>
                          <Option value={3}>Match the following</Option>
                          <Option value={4}>Sequential arrangement</Option>
                          <Option value={5}>Multiple completion</Option>
                          <Option value={6}>Reason assertion</Option>
                          <Option value={7}>Extended matching
                            items/question</Option>
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Subject">
                    {getFieldDecorator('subject')(
                        <Select
                            mode="multiple"
                            placeholder="Select subject"
                            size={'large'}
                            showSearch
                            optionFilterProp="children"
                        >
                          {subjectsList.map(function(subject, index) {
                            return (
                                <Option value={subject} key={index}>
                                  {subject}
                                </Option>
                            );
                          })}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="System">
                    {getFieldDecorator('system')(
                        <Select
                            mode="multiple"
                            placeholder="Select system"
                            size={'large'}
                            showSearch
                            optionFilterProp="children"
                        >
                          {systemsList.map(function(system, index) {
                            return (
                                <Option value={system} key={index}>
                                  {system}
                                </Option>
                            );
                          })}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Tags">
                    <SearchTags
                        selectedTags={tagsList}
                        onSelectTag={this.handleTagSelect}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Concept Level">
                    {getFieldDecorator('concept_level', {
                      initialValue: 1,
                    })(
                        <Select size={'large'}>
                          <Option value={1}>Capsule</Option>
                          <Option value={2}>Cortex</Option>
                          <Option value={3}>Core</Option>
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={'Segments/Blocks'}>
                    <Cascader
                        options={SYSTEM_BLOCK_OPTIONS_LIST}
                        expandTrigger="hover"
                        onChange={this.handleSegmentBlockChange}
                        placeholder="Please select"
                        size="large"
                    />
                  </Form.Item>
                </Col>
                {
                  !isRestricted && (
                      <Col
                          span={24}
                          className={
                            referenceToolbarVisible === false
                                ? 'reference-block active'
                                : 'reference-block'
                          }
                      >
                        <p>
                          Notes:
                          <Switch
                              checked={notes_prime}
                              className="pull-right"
                              checkedChildren="Paid"
                              unCheckedChildren="Free"
                              onChange={this.handleNotesPrimeStatus}
                          />
                        </p>
                        <Select
                            mode="multiple"
                            labelInValue
                            placeholder="Select notes"
                            notFoundContent={fetchingNotes &&
                            <Spin size="small"/>}
                            filterOption={true}
                            onSearch={this.getNotesList}
                            onChange={this.handleNotesChange}
                            size={'large'}
                            className="mc-mb-20"
                        >
                          {notesList.map(note => (
                              <Option key={note.text} note-id={note.value}>
                                {note.text}
                              </Option>
                          ))}
                        </Select>
                      </Col>
                  )
                }
                <Col
                    span={24}
                    className={
                      referenceToolbarVisible === false
                          ? 'reference-block active'
                          : 'reference-block'
                    }
                >
                  <p>
                    Reference:
                    <Switch
                        className="pull-right"
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={this.handleReferenceToolbarVisible}
                    />
                  </p>
                  <McCkEditor
                      type={1}
                      content={reference}
                      removeToolbar={referenceToolbarVisible}
                      onMcEditorChange={this.handleReferenceChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    userRole: getUserRoleReselector(state),
  };
};

const CreateQuestion = withRouter(
    connect(
        mapStateToProps,
        null,
    )(Form.create({name: 'create-qbank-question'})(CreateQuestionForm)),
);

export {CreateQuestion};
