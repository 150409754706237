import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Col, Empty, message, Pagination, Row, Tag} from 'antd';
import moment from 'moment';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import {post} from '../../components/mc_axios/McAxios';

class MedPassPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('medpass-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('medpass-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let medPassStatus = getLocalData('medpass-status', 1);
    medPassStatus = medPassStatus !== '' ? parseInt(medPassStatus) : 0;

    this.state = {
      offers: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      medPassStatus,
      medPassSearchTerm: getLocalData('medpass-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }), () => this.getOffers(),
    );
  }

  getOffers = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      medPassStatus,
      medPassSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      status: parseInt(medPassStatus),
      search_term: medPassSearchTerm,
    };

    post('/api/medpasses/medpasses', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('medpasses')) {
          this.setState({
            offers: resData.medpasses,
            totalItems: resData.total_count,
          });
        }
      } else message.error('Something went wrong! Try again.');
    });
  };

  handleItemsCount = value => {
    setLocalData('medpass-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getOffers();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('medpass-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medPassStatus: value,
        },
        () => {
          this.getOffers();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('medpass-search-term', value, 1);
    this.setState({
      medPassSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getOffers();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('medpass-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getOffers();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          medPassStatus: 0,
          medPassSearchTerm: '',
        },
        () => {
          setLocalData('medpass-items-per-page', 25, 1);
          setLocalData('medpass-page-number', 1, 1);
          setLocalData('medpass-status', 0, 1);
          setLocalData('medpass-search-term', '', 1);
          this.getOffers();
        },
    );
  };

  redirectToEditOfferPage = id => {
    this.props.history.push('/dashboard/medpass/edit-medpass/' + id);
  };

  getStatusBadge = (status) => {
    if (status === 2) {
      return (
          <Tag color="#28a745">Published</Tag>
      );
    } else return (
        <Tag color="#17a2b8">Saved</Tag>
    );
  };

  render() {
    const {
      offers,
      currentPageNumber,
      itemsPerPage,
      paginationStart,
      medPassStatus,
      medPassSearchTerm,
      totalItems,
    } = this.state;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>MedPass</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/medpass/create-medpass/'}>Create
                    MedPass</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={medPassStatus}
                onStatusChange={this.handleStatusChange}
                searchTermValue={medPassSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {
              offers && offers.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table notes-table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Purchase Count</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            offers.map((offer, index) => {
                              const {id, title, status, offer_start, offer_valid, purchase_count} = offer;
                              return (
                                  <tr key={id}>
                                    <td>{paginationStart + index}</td>
                                    <td onClick={() => this.redirectToEditOfferPage(
                                        id)}>
                                      {title}
                                    </td>
                                    <td>
                                      {this.getStatusBadge(status ? status : 1)}
                                    </td>
                                    <td>
                                      {moment.unix(offer_start).
                                          format('DD/MM/YYYY')}
                                    </td>
                                    <td>
                                      {moment.unix(offer_valid).
                                          format('DD/MM/YYYY')}
                                    </td>
                                    <td>{purchase_count ?
                                        purchase_count :
                                        0}</td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No offers found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

const MedPass = withRouter(MedPassPre);

export {MedPass};
