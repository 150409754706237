import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import Radio from 'antd/es/radio';
import Tooltip from 'antd/es/tooltip';
import Select from 'antd/es/select';
import echarts from 'echarts';
import ReactCharts from 'echarts-for-react';
import {isMobile} from 'react-device-detect';
import {Alert} from 'antd';
import './SystemSubjectAnalysisCard.css';
import {isEmpty} from '../../util';

const {Option} = Select;

class SystemSubjectAnalysisCard extends React.Component {
  state = {
    activeCategory: 1,
    activeSubCategory: 1,
    systemsData: {
      list: [
        'Renal System',
        'Blood & Lymphoreticular System',
        'Cardiovascular System',
        'Musculoskeletal System',
        'Nervous System',
        'Reproductive System',
        'Endocrine System',
        'Respiratory System',
        'Gastrointestinal System',
        'Skin & Subcutaneous Tissue'],
      incorrectQuestions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    subjectsData: {
      list: [
        'General Anatomy',
        'Embryology',
        'Histology',
        'Molecular & Cell Biology',
        'Physiology',
        'Biochemistry',
        'Fluids, electrolytes & nutrition',
        'Pathology',
        'Pharmacology',
        'Forensic Medicine',
        'Toxicology',
        'Microbiology',
        'ENT',
        'Ophthalmology',
        'General Medicine',
        'General Surgery',
        'Anesthesia',
        'Behavioural Health',
        'Paediatrics',
        'Obstetrics',
        'Gynaecology',
        'Dermatology',
        'Radiology',
        'Orthopedic Surgery',
        'Genetics',
        'Epidemiology/Population Health',
        'Biostatistics',
        'Oncology',
        'Infectious Diseases',
        'Nuclear Medicine',
        'Emergency Medicine',
        'Medical Ethics'],
      correctQuestions: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0],
      incorrectQuestions: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0],
    },
    newSubjectsData: {
      1: {
        list: [
          'General Anatomy',
          'Embryology',
          'Histology',
          'Molecular & Cell Biology',
          'Physiology',
          'Biochemistry',
          'Fluids, electrolytes & nutrition',
          'Pathology',
          'Pharmacology',
          'Forensic Medicine',
          'Toxicology',
          'Microbiology',
          'Genetics'],
        correctQuestions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        incorrectQuestions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      2: {
        list: [
          'ENT',
          'Ophthalmology',
          'General Medicine',
          'General Surgery',
          'Paediatrics',
          'Obstetrics',
          'Gynaecology',
          'Orthopedic Surgery',
          'Epidemiology/Population Health',
          'Biostatistics',
          'Medical ethics'],
        correctQuestions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        incorrectQuestions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      3: {
        list: [
          'Oncology',
          'Infectious Diseases',
          'Nuclear Medicine',
          'Anesthesia',
          'Dermatology',
          'Radiology',
          'Emergency Medicine',
          'Behavioural Health'],
        correctQuestions: [0, 0, 0, 0, 0, 0, 0, 0],
        incorrectQuestions: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    },
  };

  componentDidMount() {
    const {data} = this.props;
    if (!isEmpty(data)) {
      this.prepareData(data);
    }
  }

  componentWillReceiveProps(nextProps) {
    const data = nextProps.data;
    if (!isEmpty(data)) {
      this.prepareData(data);
    }
  }

  changeCategory = e => {
    this.setState({
      activeCategory: e.target.value,
      activeSubCategory: 1,
    });
  };

  changeSubCategory = value => {
    this.setState({
      activeSubCategory: value,
    });
  };

  removeSystemSpace = array => {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes('&')) {
        newArray.push(array[i].split(' ')[0]);
      } else {
        newArray.push(array[i].replace(' System', ''));
      }
    }
    return newArray;
  };

  prepareData = (data) => {
    const {systemsData, newSubjectsData} = this.state;
    let sysData = {list: [], correctQuestions: [], incorrectQuestions: []};
    let subData1 = {list: [], correctQuestions: [], incorrectQuestions: []};
    let subData2 = {list: [], correctQuestions: [], incorrectQuestions: []};
    let subData3 = {list: [], correctQuestions: [], incorrectQuestions: []};

    if (Object.keys(data).length !== 0) {
      for (let i = 0, len = systemsData.list.length; i < len; i++) {
        if (data[systemsData.list[i]] !== undefined) {

          if (systemsData.list[i] === 'Blood & Lymphoreticular System') {
            sysData.list.push({
              value: 'Hematology',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else if (systemsData.list[i] === 'Skin & Subcutaneous Tissue') {
            sysData.list.push({
              value: 'Immune System',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else {
            sysData.list.push({
              value: systemsData.list[i],
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          }

          sysData.correctQuestions.push({
            value: Math.round(data[systemsData.list[i]]),
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: (data[systemsData.list[i]].toFixed(2) < 55) ?
                      '#c0392b' :
                      (75 > data[systemsData.list[i]].toFixed(2) &&
                      data[systemsData.list[i]].toFixed(2) >= 55 ?
                          '#7f8c8d' :
                          '#94e043'),
                },
                {
                  offset: 1,
                  color: (data[systemsData.list[i]].toFixed(2) < 55) ?
                      '#e74c3c' :
                      (75 > data[systemsData.list[i]].toFixed(2) &&
                      data[systemsData.list[i]].toFixed(2) >= 55 ?
                          '#95a5a6' :
                          '#45d843'),
                },
              ]),
            },
          });

          sysData.incorrectQuestions.push(Math.round(100 - data[systemsData.list[i]]));
        }
      }

      newSubjectsData[1].list.forEach(element => {
        if (data[element] !== undefined) {
          if (element === 'Molecular & Cell Biology') {
            subData1.list.push({
              value: 'Cell Biology',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else if (element === 'Fluids, electrolytes & nutrition') {
            subData1.list.push({
              value: 'Nutrition',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else {
            subData1.list.push({
              value: element,
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          }
          subData1.correctQuestions.push({
            value: data[element].toFixed(2), itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: (data[element].toFixed(2) < 55) ?
                      '#c0392b' :
                      (75 > data[element].toFixed(2) &&
                      data[element].toFixed(2) >= 55 ? '#7f8c8d' : '#94e043'),
                },
                {
                  offset: 1,
                  color: (data[element].toFixed(2) < 55) ?
                      '#e74c3c' :
                      (75 > data[element].toFixed(2) &&
                      data[element].toFixed(2) >= 55 ? '#95a5a6' : '#45d843'),
                },
              ]),
            },
          });
          subData1.incorrectQuestions.push((100 - data[element]).toFixed(2));
        }
      });

      newSubjectsData[2].list.forEach(element => {
        if (data[element] !== undefined) {
          if (element === 'Orthopedic Surgery') {
            subData2.list.push({
              value: 'Orthopedics',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else if (element === 'Epidemiology/Population Health') {
            subData2.list.push({
              value: 'Preventive Medicine',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else if (element === 'Paediatrics') {
            subData2.list.push({
              value: 'Pediatrics',
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          } else {
            subData2.list.push({
              value: element,
              textStyle: {color: isMobile ? 'white' : 'black'},
            });
          }
          subData2.correctQuestions.push({
            value: data[element].toFixed(2), itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: (data[element].toFixed(2) < 55) ?
                      '#c0392b' :
                      (75 > data[element].toFixed(2) &&
                      data[element].toFixed(2) >= 55 ? '#7f8c8d' : '#94e043'),
                },
                {
                  offset: 1,
                  color: (data[element].toFixed(2) < 55) ?
                      '#e74c3c' :
                      (75 > data[element].toFixed(2) &&
                      data[element].toFixed(2) >= 55 ? '#95a5a6' : '#45d843'),
                },
              ]),
            },
          });
          subData2.incorrectQuestions.push((100 - data[element]).toFixed(2));
        }
      });

      newSubjectsData[3].list.forEach(element => {
        if (data[element] !== undefined) {
          subData3.list.push({
            value: element,
            textStyle: {color: isMobile ? 'white' : 'black'},
          });
          subData3.correctQuestions.push({
            value: data[element].toFixed(2), itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: (data[element].toFixed(2) < 55) ?
                      '#c0392b' :
                      (75 > data[element].toFixed(2) &&
                      data[element].toFixed(2) >= 55 ? '#7f8c8d' : '#94e043'),
                },
                {
                  offset: 1,
                  color: (data[element].toFixed(2) < 55) ?
                      '#e74c3c' :
                      (75 > data[element].toFixed(2) &&
                      data[element].toFixed(2) >= 55 ? '#95a5a6' : '#45d843'),
                },
              ]),
            },
          });
          subData3.incorrectQuestions.push((100 - data[element]).toFixed(2));
        }
      });
    }

    this.setState({
      systemsData: (sysData.list.length > 0) ? sysData : systemsData,
      newSubjectsData: {
        1: (subData1.list.length > 0) ? subData1 : newSubjectsData[1],
        2: subData2.list.length > 0 ? subData2 : newSubjectsData[2],
        3: subData3.list.length > 0 ? subData3 : newSubjectsData[3],
      },
    });
  };

  goToSubject = (e) => {
    const {activeCategory, activeSubCategory, systemsData, newSubjectsData} = this.state;
    let name = '';
    if (activeCategory === 1) {
      name = systemsData.list[e.dataIndex].value;
      if (name === 'Hematology') {
        name = 'Blood & Lymphoreticular System';
      } else if (name === 'Immune System') {
        name = 'Skin & Subcutaneous Tissue';
      }
    } else {
      name = newSubjectsData[activeSubCategory].list[e.dataIndex].value;
      if (name === 'Cell Biology') {
        name = 'Molecular & Cell Biology';
      } else if (name === 'Nutrition') {
        name = 'Fluids, electrolytes & nutrition';
      } else if (name === 'Orthopedics') {
        name = 'Orthopedic Surgery';
      } else if (name === 'Preventive Medicine') {
        name = 'Epidemiology/Population Health';
      } else if (name === 'Pediatrics') {
        name = 'Paediatrics';
      }
    }
    this.props.handleClick && this.props.handleClick([name, activeCategory]);
  };

  render() {
    const {activeCategory, activeSubCategory, newSubjectsData, systemsData} = this.state;
    const {totalQuestions, totalQuestionsCorrect, data} = this.props;

    if (!data) return '';

    const getChartOptions = () => {
      if (activeCategory === 1) {
        return {
          tooltip: {
            trigger: 'item',
            position: ['50%', '50%'],
            textStyle: {
              fontSize: 12,
              color: '#fff',
              fontFamily: 'Microsoft YaHei',
            },
          },

          angleAxis: {
            type: 'category',
            data: systemsData.list,
            z: 10,
          },
          radiusAxis: {
            max: 100,
            min: 0,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },

            splitLine: {
              show: true,
            },
            splitArea: {
              areaStyle: {
                color: 'transparent',
              },
            },
          },
          polar: {},
          series: [
            {
              type: 'bar',
              data: systemsData.correctQuestions,
              coordinateSystem: 'polar',
              name: 'Correct Percentage',
              stack: 'a',
              splitLine: {
                show: false,
                // lineStyle: {
                //     color: '#aaa',
                // }
              },
            }, {
              type: 'bar',
              data: systemsData.incorrectQuestions,
              coordinateSystem: 'polar',
              name: 'Incorrect Percentage',
              stack: 'a',
              itemStyle: {
                color: 'transparent',
              },
            }],

        };
      } else {
        return {
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 12,
              color: '#fff',
              fontFamily: 'Microsoft YaHei',
            },
          },
          angleAxis: {
            type: 'category',
            data: newSubjectsData[activeSubCategory].list,
            z: 10,
          },
          radiusAxis: {
            max: 100,
            min: 0,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },

            splitLine: {
              show: true,
            },
            splitArea: {
              areaStyle: {
                color: 'transparent',
              },
            },
          },
          polar: {},
          series: [
            {
              type: 'bar',
              data: newSubjectsData[activeSubCategory].correctQuestions,
              coordinateSystem: 'polar',
              name: 'Correct Percentage',
              stack: 'a',
            }, {
              type: 'bar',
              data: newSubjectsData[activeSubCategory].incorrectQuestions,
              coordinateSystem: 'polar',
              name: 'Incorrect Percentage',
              stack: 'a',
              itemStyle: {
                color: 'transparent',
              },
            }],
        };
      }
    };

    let onEvents = {
      'click': (e) => this.goToSubject(e),
    };

    let onMobileEvents = {
      'dblclick': (e) => this.goToSubject(e),
    };

    return (
        <div className='ssa-card'>
          <Row>
            <Col span={24}>
              <h3>
                <img
                    alt="analyzer"
                    src="https://assets.medcampus.io/assets/images/medpass-icon.png"
                    width={20}
                    className="mc-mt-15 mc-mb-20 mc-mr-10"
                /> Analyzer</h3>
              <Tooltip
                  title="The Progress wheel is partitioned into segments, each segment is indicative of either a subject or system. The green gradient is representation of percentage greater than 75,grey gradient represents percentage between 55 and 75 and red gradient represents percentage less than 55 from that particular subject/system.">
                <Icon className="info-icon" type="info-circle"/>
              </Tooltip>
            </Col>
            <Col span={24} sm={12}>
              <Radio.Group
                  defaultValue={activeCategory}
                  buttonStyle="solid"
                  onChange={this.changeCategory}
              >
                <Radio.Button value={1}>System</Radio.Button>
                <Radio.Button value={2}>Subject</Radio.Button>
              </Radio.Group>
              {
                activeCategory === 2 && (
                    <Select
                        defaultValue={1}
                        style={{width: 120, marginLeft: 10}}
                        onChange={this.changeSubCategory}
                    >
                      <Option value={1}>Preclinicals</Option>
                      <Option value={2}>Clinicals</Option>
                      <Option value={3}>Specialties</Option>
                    </Select>
                )
              }
            </Col>
            <Col xs={24} sm={12}>
              <h3 className="text-right">Questions
                Answered: {totalQuestionsCorrect + '/' + totalQuestions}</h3>
            </Col>
            <Col span={24}>
              {
                getChartOptions() !== null ? (
                    <ReactCharts
                        option={getChartOptions()}
                        style={{height: 500}}
                        onEvents={isMobile ? onMobileEvents : onEvents}
                    />
                ) : (
                    <>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <Alert
                          className="text-center"
                          message={'No Analysis from this section'}
                          type={'info'}
                      />
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </>
                )
              }
            </Col>
          </Row>
        </div>
    );
  }
}

export {SystemSubjectAnalysisCard};
