import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import Tooltip from 'antd/es/tooltip';
import ReactEcharts from 'echarts-for-react';
import './ConceptBaseAnalysisCard.css';

const ConceptBaseAnalysisCard = ({data}) => {

  if (!data) return null;

  const cbaOptions = {
    color: ['#8ED45F', '#4682DC', '#E84E67'],
    textStyle: {
      fontSize: 16,
      color: '#40E7F4 ',
    },
    tooltip: {
      trigger: 'item',
      formatter: (a) => {
        if (a.dataIndex === 0) {
          return a.seriesName + ':' + a.percent + '%';
        } else {
          return '';
        }
      },
    },
    legend: {
      show: true,
      data: ['Capsule', 'Cortex', 'Core'],
      textStyle: {
        color: '#000',
        fontSize: 14,
      },
      formatter(name) {
        return name;
      },
      itemGap: 16,
    },
    series: [
      {
        name: 'Capsule',
        type: 'pie',
        radius: ['56%', '65%'],
        label: true,
        startAngle: 90,
        clockWise: true,

        data: [
          {
            value: Math.round(data[2]),
            hoverAnimation: true,
            hoverOffset: 8,
            itemStyle: {
              normal: {
                color: '#8ED45F',
              },
            },
          },
          {
            value: Math.round(100 - data[2]),
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: '#E8F3DF',
              },
            },

          },
        ],
      }, {
        name: 'Cortex',
        type: 'pie',
        radius: ['46%', '55%'],
        label: true,
        startAngle: 90,
        clockWise: true,
        data: [
          {
            hoverAnimation: true,
            hoverOffset: 3,
            value: Math.round(data[1]),
            itemStyle: {
              color: '#4682DC',

            },
          },
          {
            value: Math.round(100 - data[1]),
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: '#E7EDF6',
              },
            },
          },
        ],
      }, {
        name: 'Core',
        type: 'pie',
        radius: ['35%', '45%'],
        label: true,
        startAngle: 90,
        clockWise: true,

        data: [
          {
            value: Math.round(data[0]),
            hoverAnimation: true,
            hoverOffset: 3,
            itemStyle: {
              color: '#E84E67',

            },
          },
          {
            value: Math.round(100 - data[0]),
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: '#FCF1F3',
              },
            },
          },
        ],
      }],
  };

  return (
      <div className="cba-card">
        <Row>
          <Col span={24}>
            <h3>Concept Circle</h3>
            <Tooltip title="Concept circle is indicative of your overall performance in various domains of conceptual understanding. Capsule focuses on basic medical concepts, Cortex on multi-system approach and logical questions, Core on current trends and in depth concepts in medical sciences.">
              <Icon className="info-icon" type="info-circle"/>
            </Tooltip>
          </Col>
          <Col span={24}>
            <ReactEcharts option={cbaOptions}/>
          </Col>
        </Row>
      </div>
  );
};

export {ConceptBaseAnalysisCard};
