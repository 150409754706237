import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Input, Row, Select} from 'antd';
import moment from 'moment';
import {getSubjectList, getSystemList} from '../util';

const {RangePicker} = DatePicker;
const Option = Select.Option;
const Search = Input.Search;

const fixedDateRanges = {
  Today: [moment(), moment()],
  'Last 7 days': [moment().subtract(7, 'days'), moment()],
  'Last 30 days': [moment().subtract(30, 'days'), moment()],
  'Last 90 days': [moment().subtract(90, 'days'), moment()],
};

const Filters = props => {
  const [subjectsList, setSubjectsList] = useState([]);
  const [systemsList, setSystemsList] = useState([]);

  useEffect(() => {
    const {subjectValue, onSubjectChange, systemValue, onSystemChange} = props;
    if (subjectValue !== undefined && onSubjectChange !== undefined) {
      getSubjectList(subjects => {
        setSubjectsList(subjects);
      });
    }

    if (systemValue !== undefined && onSystemChange !== undefined) {
      getSystemList(systems => {
        setSystemsList(systems);
      });
    }
  }, []);

  const {
    countValue,
    onItemsCountChange,
    statusValue,
    onStatusChange,
    verifyStatusValue,
    onVerifyStatusChange,
    dateRangeValue,
    onDateRangeChange,
    subjectValue,
    onSubjectChange,
    searchTermValue,
    onSystemChange,
    systemValue,
    onSearchTermChange,
    onSearchTermSubmit,
    onFiltersReset,
  } = props;

  return (
      <Row gutter={16}>
        <Col xs={12} lg={2}>
          <Select
              className="width-fluid mc-mt-5"
              size="large"
              value={countValue}
              onChange={onItemsCountChange}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </Col>
        {
          statusValue !== undefined && onStatusChange !== undefined ? (
              <Col xs={12} lg={5}>
                <Select
                    size="large"
                    className="width-fluid mc-mt-5"
                    value={statusValue}
                    onChange={onStatusChange}
                >
                  <Option value={0}>Select status</Option>
                  <Option value={1}>Draft</Option>
                  <Option value={2}>Published</Option>
                </Select>
              </Col>
          ) : null
        }
        {
          verifyStatusValue !== undefined && onVerifyStatusChange !==
          undefined ? (
              <Col xs={12} lg={5}>
                <Select
                    size="large"
                    className="width-fluid mc-mt-5"
                    value={verifyStatusValue}
                    onChange={onVerifyStatusChange}
                >
                  <Option value={0}>Select verify status</Option>
                  <Option value={1}>Verified</Option>
                  <Option value={2}>Unverified</Option>
                </Select>
              </Col>
          ) : null
        }
        {
          onDateRangeChange !== undefined && (
              <Col xs={12} lg={5}>
                <RangePicker
                    className="width-fluid mc-mt-5"
                    size="large"
                    format="DD-MM-YYYY"
                    ranges={fixedDateRanges}
                    value={dateRangeValue}
                    onChange={onDateRangeChange}
                />
              </Col>
          )
        }
        {
          subjectValue !== undefined && onSubjectChange !== undefined ? (
              <Col xs={12} lg={5}>
                <Select
                    showSearch
                    optionFilterProp="children"
                    value={subjectValue}
                    className="width-fluid mc-mt-5"
                    size="large"
                    onChange={onSubjectChange}
                >
                  <Option value="">Select subject</Option>
                  {
                    subjectsList.map((subject, index) => {
                      return (
                          <Option value={subject} key={index}>
                            {subject}
                          </Option>
                      );
                    })
                  }
                </Select>
              </Col>
          ) : null
        }
        {
          systemValue !== undefined && onSystemChange !== undefined ? (
              <Col xs={12} lg={5}>
                <Select
                    showSearch
                    optionFilterProp="children"
                    value={systemValue}
                    className="width-fluid mc-mt-5"
                    size="large"
                    onChange={onSystemChange}
                >
                  <Option value="">Select system</Option>
                  {
                    systemsList.map((system, index) => {
                      return (
                          <Option value={system} key={index}>
                            {system}
                          </Option>
                      );
                    })
                  }
                </Select>
              </Col>
          ) : null
        }
        <Col xs={12} lg={5}>
          <Search
              placeholder="input search text"
              size="large"
              className="mc-mt-5"
              value={searchTermValue}
              onSearch={onSearchTermSubmit}
              onChange={onSearchTermChange}
          />
        </Col>
        <Col xs={12} lg={2}>
          <Button
              type="primary"
              block
              size="large"
              onClick={onFiltersReset}
              htmlType="button"
              className="mc-mt-5"
          >
            Reset
          </Button>
        </Col>
      </Row>
  );
};

export {Filters};
