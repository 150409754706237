import React from 'react';
import {Button, Col, message, Row} from 'antd';
import {post} from '../../components/mc_axios/McAxios';

const AccountDeactivated = () => {

  const logOut = () => {
    post('/api/user-manager/admin/logout', '').
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            localStorage.clear();
            window.location.href = '/';
          }
        }).catch(() => message.error('Something went wrong'));
  };

  return (
      <Row>
        <Col span={24} className="text-center mc-mtb-100">
          <h1>Account Deactivated</h1>
          <p>Your account deactivated. Please contact the admin.</p>
          <p>Email id: <a href="mailto:support@medcampus.io">support@medcampus.io</a></p>
          <p>Contact Number: <a href="tel:+918978444188">+91-8978444188</a></p>
          <Button type="link" onClick={logOut}>Logout</Button>
        </Col>
      </Row>
  );
};

export {AccountDeactivated};
