import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
} from 'antd';
import {McCkEditor, SearchTags} from '../../../components';
import {get, patch, post} from '../../../components/mc_axios/McAxios';

class EditNoteForm extends React.Component {
  constructor(props) {
    super(props);
    let noteId = props.match.params.noteId;
    this.state = {
      noteId: noteId,
      title: '',
      explanation: '',
      tags: [],
      reference: '',
      createdAt: '',
    };
  }

  componentDidMount() {
    this.getNoteDetails();
  }

  getNoteDetails = () => {
    get('/api/notes/notes/' + this.state.noteId).then(response => {
      if (response.data.status === true) {
        const noteData = response.data.data.note;

        let list = [];
        if (noteData.tags !== undefined && noteData.tags.length > 0) {
          let tags = noteData.tags;
          for (let i = 0; i < tags.length; i++) {
            list.push({
              key: tags[i],
              label: tags[i],
            });
          }
        }

        this.setState({
          title: noteData.title,
          explanation: noteData.explanation,
          tags: list,
          reference: noteData.reference,
          createdAt: noteData.created_at,
        });
      } else message.error('Something went wrong.');
    });
  };

  handleTitleChange = e => {
    this.setState({
      title: e.target.value,
    });
  };

  handleExplanationChange = evt => {
    this.setState({
      explanation: evt.editor.getData(),
    });
  };

  handleTagsChange = tags => {
    this.setState({
      tags,
    });
  };

  handleReferenceChange = evt => {
    this.setState({
      reference: evt.editor.getData(),
    });
  };

  updateNote = () => {
    this.handleSubmit(0);
  };

  updateAndVerifyNote = () => {
    this.handleSubmit(2);
  };

  handleSubmit = saveType => {
    const {title, explanation, tags, reference, noteId, createdAt} = this.state;
    if (title !== '') {
      let tagsList = [];
      if (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          tagsList.push(tags[i].key);
        }
      }

      let data = {
        note_id: noteId,
        title,
        explanation,
        tags: tagsList,
        reference,
        status: saveType,
        created_at: createdAt,
      };

      post('/api/notes/notes/update', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          message.success('Note has been updated');
          this.props.history.push('/dashboard/notes/');
        } else message.error('Something went wrong.');
      });
    } else message.warning('Title should not be empty');
  };

  confirmDelete = () => {
    patch('/api/notes/notes/delete', {
      note_id: this.state.noteId,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Notes deleted successfully');
        this.props.history.push('/dashboard/notes/');
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  cancelDelete = () => {
    message.error('You have canceled the delete');
  };

  render() {
    const {title, explanation, tags, reference} = this.state;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/notes/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Note
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  onCancel={this.cancelDelete}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  className="mc-mr-5"
                  onClick={this.updateNote}
              >
                Update
              </Button>
              <Button
                  type="primary"
                  htmlType="button"
                  onClick={this.updateAndVerifyNote}
              >
                Update & Verify
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    <Input
                        placeholder="Enter article title"
                        size={'large'}
                        value={title}
                        onChange={this.handleTitleChange}
                    />,
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Explanation">
                    <McCkEditor
                        content={explanation}
                        onMcEditorChange={this.handleExplanationChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Tags">
                    <SearchTags
                        selectedTags={tags}
                        onSelectTag={this.handleTagsChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reference">
                    <McCkEditor
                        type={1}
                        content={reference}
                        onMcEditorChange={this.handleReferenceChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const EditNote = withRouter(Form.create({name: 'edit_note'})(EditNoteForm));

export {EditNote};
