import moment from 'moment';
import {
  ADD_PW_CHALLENGE_QUESTIONS,
  GET_PW_CHALLENGE_DETAILS,
  PW_CHALLENGE_DESCRIPTION_CHANGE,
  PW_CHALLENGE_FEATURED_IMAGE_CHANGE,
  PW_CHALLENGE_FEATURED_IMAGE_LINK_CHANGE,
  PW_CHALLENGE_REWARD_AMOUNT_CHANGE,
  PW_CHALLENGE_SEO_META_DESCRIPTION_CHANGE,
  PW_CHALLENGE_SEO_META_KEYWORDS_CHANGE,
  PW_CHALLENGE_SEO_META_TITLE_CHANGE,
  PW_CHALLENGE_SEO_SLUG_CHANGE,
  PW_CHALLENGE_SPONSOR_ADD_MEDIA_CHANGE,
  PW_CHALLENGE_SPONSOR_LINK_CHANGE,
  PW_CHALLENGE_START_DATE_CHANGE,
  PW_CHALLENGE_THUMBNAIL_IMAGE_CHANGE,
  PW_CHALLENGE_TITLE_CHANGE,
  PW_CHALLENGE_TOTAL_QUESTIONS_CHANGE,
  REMOVE_QUESTION_FROM_PW_CHALLENGE,
  RESET_PW_CHALLENGE_DATA,
  SORT_PW_CHALLENGE_QUESTIONS_LIST,
} from '../../../actions/types';

const initialState = {
  id: '',
  title: '',
  totalQuestions: 0,
  startDate: '',
  description: '',
  featuredImageUrl: '',
  featuredImageAltText: '',
  featuredImageLink: '',
  thumbnailImageUrl: '',
  thumbnailImageAltText: '',
  questionIds: [],
  questionsList: [],
  rewardAmount: 0,
  sponsorAdMediaUrl: '',
  sponsorAdLink: '',
  seoMetaTitle: '',
  seoSlug: '',
  seoMetaDescription: '',
  seoMetaKeywords: [],
  createdAt: '',
  updatedAt: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PW_CHALLENGE_DETAILS:
      let data = action.payload.pwChallenge;
      return {
        ...state,
        id: data.play_id,
        title: data.play_name,
        totalQuestions: data.number_of_questions === undefined ?
            0 :
            data.number_of_questions,
        startDate: data.start_time !== undefined ?
            moment.unix(data.start_time).format('DD/MM/YYYY HH:mm:ss') :
            '',
        description: data.description !== undefined ? data.description : '',
        featuredImageUrl: data.featured_image_url !== undefined ?
            data.featured_image_url :
            '',
        featuredImageAltText: data.featured_image_alt_text !== undefined ?
            data.featured_image_alt_text :
            '',
        featuredImageLink: data.featured_image_link !== undefined ?
            data.featured_image_link :
            '',
        thumbnailImageUrl: data.thumbnail_image_url !== undefined ?
            data.thumbnail_image_url :
            '',
        thumbnailImageAltText: data.thumbnail_image_alt_text !== undefined ?
            data.thumbnail_image_alt_text :
            '',
        questionIds: data.question_ids !== undefined ? data.question_ids : [],
        questionsList: action.payload.questions !== undefined ?
            action.payload.questions :
            [],
        rewardAmount: data.reward_amount !== undefined ? data.reward_amount : 0,
        sponsorAdMediaUrl: data.sponsored_add !== undefined ?
            data.sponsored_add :
            '',
        sponsorAdLink: data.sponsor_link !== undefined ? data.sponsor_link : '',
        seoMetaTitle: data.meta_title,
        seoSlug: data.slug,
        seoMetaDescription: data.meta_description,
        seoMetaKeywords: data.meta_keywords !== undefined ?
            data.meta_keywords :
            [],
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      };
    case PW_CHALLENGE_TITLE_CHANGE:
      let payload = action.payload;
      return {
        ...state,
        title: payload !== undefined ? payload.title : '',
        seoMetaTitle: payload !== undefined ? payload.metaTitle : '',
        seoSlug: payload !== undefined ? payload.slug : '',
      };

    case PW_CHALLENGE_TOTAL_QUESTIONS_CHANGE:
      return {
        ...state,
        totalQuestions: action.payload,
      };
    case PW_CHALLENGE_START_DATE_CHANGE:
      return {
        ...state,
        startDate: action.payload,
      };

    case PW_CHALLENGE_DESCRIPTION_CHANGE:
      return {
        ...state,
        description: action.payload,
      };
    case PW_CHALLENGE_FEATURED_IMAGE_CHANGE:
      return {
        ...state,
        featuredImageUrl: action.payload === undefined ?
            '' :
            action.payload.imgUrl,
        featuredImageAltText: action.payload === undefined ?
            '' :
            action.payload.imgAltText,
      };
    case PW_CHALLENGE_FEATURED_IMAGE_LINK_CHANGE:
      return {
        ...state,
        featuredImageLink: action.payload === undefined ? '' : action.payload,
      };
    case PW_CHALLENGE_THUMBNAIL_IMAGE_CHANGE:
      return {
        ...state,
        thumbnailImageUrl: action.payload === undefined ?
            '' :
            action.payload.imgUrl,
        thumbnailImageAltText: action.payload === undefined ?
            '' :
            action.payload.imgAltText,
      };
    case PW_CHALLENGE_SPONSOR_ADD_MEDIA_CHANGE:
      return {
        ...state,
        sponsorAdMediaUrl: action.payload,
      };
    case PW_CHALLENGE_SPONSOR_LINK_CHANGE:
      return {
        ...state,
        sponsorAdLink: action.payload,
      };
    case PW_CHALLENGE_REWARD_AMOUNT_CHANGE:
      return {
        ...state,
        rewardAmount: action.payload,
      };
    case PW_CHALLENGE_SEO_META_TITLE_CHANGE:
      return {
        ...state,
        seoMetaTitle: action.payload,
      };
    case PW_CHALLENGE_SEO_SLUG_CHANGE:
      return {
        ...state,
        seoSlug: action.payload,
      };
    case PW_CHALLENGE_SEO_META_DESCRIPTION_CHANGE:
      return {
        ...state,
        seoMetaDescription: action.payload,
      };
    case PW_CHALLENGE_SEO_META_KEYWORDS_CHANGE:
      return {
        ...state,
        seoMetaKeywords: action.payload,
      };
    case ADD_PW_CHALLENGE_QUESTIONS:
      if (action.payload !== undefined) {
        return {
          ...state,
          questionsIds: action.payload.ids,
          questionsList: action.payload.questions,
        };
      } else {
        return {
          ...state,
        };
      }
    case SORT_PW_CHALLENGE_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.payload.questionsList,
        questionIds: action.payload.questionIds,
      };
    case REMOVE_QUESTION_FROM_PW_CHALLENGE:
      return {
        ...state,
        questionsList: action.payload.questionsList,
        questionIds: action.payload.questionIds,
      };
    case RESET_PW_CHALLENGE_DATA:
      return {
        ...state,
        id: '',
        title: '',
        totalQuestions: 0,
        startDate: '',
        description: '',
        featuredImageUrl: '',
        featuredImageAltText: '',
        thumbnailImageUrl: '',
        thumbnailImageAltText: '',
        questionIds: [],
        questionsList: [],
        rewardAmount: 0,
        sponsorAdMediaUrl: '',
        sponsorAdLink: '',
        seoMetaTitle: '',
        seoSlug: '',
        seoMetaKeywords: [],
      };
    default:
      return state;
  }
};
