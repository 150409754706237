import {CHANGE_USER_ROLE, STORE_USER_ID} from '../types';

export const storeUserId = userId => {
  return {
    type: STORE_USER_ID,
    payload: userId,
  };
};

export const setUserRole = role => {
  return {
    type: CHANGE_USER_ROLE,
    payload: role,
  };
};
