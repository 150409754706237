import React from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Pagination,
  Row,
  Switch,
  Typography,
  Upload,
} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  addChallenzQuestions,
  getChallenzData,
  handleEditChallengeFormReset,
  handleEditChallenzFieldChanges,
  handleSeoDataChange,
} from '../../../actions/medchallenge_actions/EditChallengeActions';
import {showQuestionPreview} from '../../../actions/qbank_actions/PreviewQuestionActions';
import {showMasterNotePreview} from '../../../actions/master_notes_actions/PreviewMasterNoteActions';
import {get, post} from '../../../components/mc_axios/McAxios';
import {
  createMarkup,
  redirectToEditQuestionPage,
} from '../../../components/util';
import {
  SearchMasterNotesInput,
  SortChallengeQuestions,
} from '../../../components';

const Search = Input.Search;
const Dragger = Upload.Dragger;
const {Paragraph} = Typography;

class AddChallengeQuestionsForm extends React.Component {
  state = {
    showQbankList: false,
    searchedQuestionsList: [],
    currentPageNumber: 1,
    paginationStart: 1,
    searchTerm: '',
    totalQbankQuestions: 0,
  };

  componentDidMount() {
    const {match: {params: {challengeId}}, getChallenzData} = this.props;
    getChallenzData(challengeId);
  }

  componentWillUnmount() {
    this.props.handleEditChallengeFormReset();
  }

  searchQbankQuestions = () => {
    const {currentPageNumber, searchTerm} = this.state;
    const {editChallenz} = this.props;

    let pData = {
      count: 25,
      page_number: currentPageNumber,
      search_term: searchTerm,
      status: 2,
      subject: '',
      question_basis: editChallenz.basis === 1 ? 0 : editChallenz.basis,
    };

    post('/api/cosmos/questions', pData).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        if (resData.hasOwnProperty('list') === true) {
          this.setState({
            totalQbankQuestions: resData.count,
            searchedQuestionsList: resData.list,
          });
        } else {
          this.setState({
            totalQbankQuestions: 0,
            searchedQuestionsList: [],
          });
        }
      }
    });
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * 25;
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.searchQbankQuestions();
        },
    );
  };

  handleSubmit = (status) => {
    const {editChallenz} = this.props;

    let data = {
      challenz_id: editChallenz.id,
      challenz_name: editChallenz.title,
      status: status,
      prime: editChallenz.isPremium,
      subject: editChallenz.subjects,
      system: editChallenz.systems,
      tags: editChallenz.tags.map(item => item.key),
      challenz_basis: parseInt(editChallenz.basis),
      number_of_questions: parseInt(editChallenz.totalQuestions),
      start_time: moment(editChallenz.startDate, 'DD/MM/YYYY').unix(),
      end_time: moment(editChallenz.endDate, 'DD/MM/YYYY').unix(),
      description: editChallenz.description,
      featured_image_url: editChallenz.featuredImageUrl,
      featured_image_alt_text: editChallenz.featuredImageAltText,
      question_ids: editChallenz.questionIds,
      video_explanation: editChallenz.videoExplanationUrl,
      video_explanation_alt_text: editChallenz.videoExplanationAltText,
      video_explanation_prime: editChallenz.isVideoExplanationPremium,
      master_note_id: editChallenz.masterNoteId,
      master_note_prime: editChallenz.isMasterNotePremium,
      meta_title: editChallenz.seoMetaTitle,
      slug: editChallenz.seoSlug,
      meta_description: editChallenz.seoMetaDescription,
      meta_keywords: editChallenz.seoMetaKeywords,
      created_at: editChallenz.createdAt,
      updated_at: editChallenz.updatedAt,
    };

    post('/api/challenzes/challenzes/update', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('MedChallenz has been updated');
        this.setState({
          showQbankList: false,
        });
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  showHideQbankQuestionsList = () => {
    this.setState(prevState => ({
      showQbankList: !prevState.showQbankList,
    }));
  };

  onUploadChange = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      let fileData = info.file.response.data;
      message.success(`${info.file.name} file uploaded successfully`);
      let data = {
        videoUrl: fileData.file_url,
        videoAltText: fileData.alt_text,
      };
      this.props.handleEditChallenzFieldChanges('add-video-explanation', data);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  getNoteData = noteId => {
    if (noteId !== undefined) {
      get(`/api/notes/notes/${noteId}`).then(response => {
        if (response.data.status === true) {
          let noteData = response.data.data.note;
          this.setState({
            noteContent: noteData.explanation,
          });
        } else {
          this.setState({
            noteContent: '',
          });
          message.error('Something went wrong. Try again.');
        }
      });
    }
  };

  redirectToCreateQuestionPage = () => {
    window.open('/dashboard/qbank/create-question/', '_blank');
  };

  render() {
    const {
      editChallenz,
      addChallenzQuestions,
      handleEditChallenzFieldChanges,
      showQuestionPreview,
      showMasterNotePreview,
    } = this.props;

    const {
      showQbankList,
      currentPageNumber,
      paginationStart,
      totalQbankQuestions,
      searchedQuestionsList,
    } = this.state;

    const challenzBasis = function() {
      let basis = '';
      switch (editChallenz.basis) {
        case 1:
          basis = 'Normal';
          break;
        case 2:
          basis = 'Image';
          break;
        case 3:
          basis = 'Audio';
          break;
        case 4:
          basis = 'Video';
          break;
        default:
          basis = 'Normal';
          break;
      }
      return basis;
    };

    const uploaderProps = {
      name: 'file',
      accept: '.mp4',
      action: '/api/media/upload',
      data: {
        product_type: 4,
      },
    };
    const challengeFullUrl = 'https://www.medcampus.io/challenges/' +
        editChallenz.seoSlug + '-' + editChallenz.id;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/medchallenz/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Add Questions
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Update
              </Button>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
                  className="mc-mr-5"
              >
                Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col span={16}>
              <h2>{editChallenz.title}</h2>
              <Row>
                <Col span={8}>
                  <p>Basis:</p>
                  <p>Total Questions:</p>
                  <p>Start Date:</p>
                  <p>End Date:</p>
                  <p>Url:</p>
                </Col>
                <Col span={16}>
                  <p>{challenzBasis()}</p>
                  <p>{editChallenz.totalQuestions}</p>
                  <p>{editChallenz.startDate}</p>
                  <p>{editChallenz.endDate}</p>
                  <Paragraph className="mc-mb-0"
                             copyable>{challengeFullUrl}</Paragraph>
                </Col>
                <Col span={24}>
                  <Link to={`/dashboard/medchallenz/edit/${editChallenz.id}`}>
                    <Button htmlType={'button'} type="primary">
                      Edit
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <img
                  src={editChallenz.featuredImageUrl}
                  alt={editChallenz.featuredImageAltText}
                  style={{width: '100%'}}
              />
            </Col>
            <Col span={24}>
              <Card
                  type="inner"
                  title="Questions"
                  className={'mc-mt-20 mc-mb-20'}
                  extra={
                    <>
                      <Button
                          htmlType="button"
                          className="mc-mr-10"
                          onClick={this.showHideQbankQuestionsList}
                      >
                        {showQbankList === false ? 'Add from QBank' : 'Back'}
                      </Button>
                      <Button htmlType="button"
                              onClick={this.redirectToCreateQuestionPage}>Create
                        Question</Button>
                    </>
                  }
              >
                {
                  showQbankList === true ? (
                      <Row>
                        <Col span={24}>
                          <Search
                              placeholder="Search for questions"
                              onSearch={value =>
                                  this.setState({searchTerm: value}, () => {
                                    this.searchQbankQuestions();
                                  })
                              }
                              enterButton
                              size="large"
                              className="mc-mb-10"
                              style={{
                                width: '100%',
                                maxWidth: 500,
                              }}
                          />
                        </Col>
                        {
                          searchedQuestionsList.length > 0 ? (
                              <>
                                <Col span={24}>
                                  <div className="mc-table-responsive">
                                    <table className="mc-table">
                                      <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Verified</th>
                                        <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {
                                        searchedQuestionsList.map(
                                            (question, index) => (
                                                <tr key={question.question_id}>
                                                  <td>{paginationStart +
                                                  index}</td>
                                                  <td onClick={() => redirectToEditQuestionPage(
                                                      question.question_id)}>
                                                    <div
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            question.title,
                                                        )}
                                                    />
                                                  </td>
                                                  <td width={100}>
                                                    {question.status === 2 ? (
                                                        <Icon
                                                            type="check-circle"
                                                            title={'Verified'}
                                                            theme="filled"
                                                            className="mc-icon active"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            type="close-circle"
                                                            title={'Not Verified'}
                                                            className="mc-icon"
                                                        />
                                                    )}
                                                  </td>
                                                  <td width={120}>
                                                    <Icon
                                                        type="eye"
                                                        className="mc-icon-large mc-mr-10"
                                                        title="Preview"
                                                        onClick={() =>
                                                            showQuestionPreview(
                                                                `${question.question_id}`,
                                                            )
                                                        }
                                                    />
                                                    <Icon
                                                        type="plus-circle"
                                                        title="Add question"
                                                        className="mc-icon"
                                                        onClick={() =>
                                                            addChallenzQuestions(
                                                                question.question_id,
                                                                question.title,
                                                                question.status,
                                                            )
                                                        }
                                                    />
                                                  </td>
                                                </tr>
                                            ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <Pagination
                                      className="mc-mt-15"
                                      showQuickJumper
                                      current={currentPageNumber}
                                      pageSize={25}
                                      total={totalQbankQuestions}
                                      onChange={this.onPageChange}
                                  />
                                </Col>
                              </>
                          ) : (
                              <Col span={24}>
                                <Alert
                                    message="Empty List"
                                    type="info"
                                    className="text-center"
                                />
                              </Col>
                          )
                        }
                      </Row>
                  ) : (
                      editChallenz.questionsList.length > 0 ?
                          <SortChallengeQuestions/> :
                          <Alert message="Empty List" type="info"
                                 className="text-center"/>
                  )}
              </Card>
            </Col>
            <Col span={12}>
              <p className={'mc-mt-10'}>
                Explanation Video:
                <span className="pull-right">
                                Premium
                                <Switch
                                    className="mc-ml-15"
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={editChallenz.isVideoExplanationPremium}
                                    onChange={checked =>
                                        handleEditChallenzFieldChanges(
                                            'is-premium-video-explanation',
                                            checked,
                                        )
                                    }
                                    style={{width: 60}}
                                />
                              </span>
              </p>
              {
                editChallenz.videoExplanationUrl === '' ? (
                    <Dragger
                        {...uploaderProps}
                        onChange={info => {
                          this.onUploadChange(info);
                        }}
                        listType="picture"
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox"/>
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                ) : (
                    <>
                      <video
                          controls
                          src={editChallenz.videoExplanationUrl}
                          className="width-fluid"
                      />
                      <Button
                          className={'mc-mt-5'}
                          htmlType={'button'}
                          type={'primary'}
                          onClick={() =>
                              handleEditChallenzFieldChanges(
                                  'remove-video-explanation',
                                  '',
                              )
                          }
                      >
                        Remove
                      </Button>
                    </>
                )
              }
            </Col>
            <Col span={12}>
              <p className={'mc-mt-10'}>
                Master Note:
                <span className="pull-right">
                                Premium
                                <Switch
                                    className="mc-ml-15"
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={editChallenz.isMasterNotePremium}
                                    onChange={checked =>
                                        handleEditChallenzFieldChanges(
                                            'is-premium-master-note',
                                            checked,
                                        )
                                    }
                                    style={{width: 60}}
                                />
                            </span>
              </p>
              {
                editChallenz.masterNoteId === '' ? (
                    <SearchMasterNotesInput
                        onSearchMasterNotesInputChange={option =>
                            handleEditChallenzFieldChanges('master-note',
                                option.props)
                        }
                    />
                ) : (
                    <>
                      <Input
                          size={'large'}
                          value={editChallenz.masterNoteName}
                          disabled={true}
                      />
                      <Button
                          htmlType={'button'}
                          type={'primary'}
                          className="mc-mr-10"
                          onClick={() => showMasterNotePreview(
                              `${editChallenz.masterNoteId}`)}
                      >
                        View
                      </Button>
                      <Button
                          htmlType={'button'}
                          type={'primary'}
                          className="mc-mt-10"
                          onClick={() => handleEditChallenzFieldChanges(
                              'remove-master-note', '')}
                      >
                        Remove
                      </Button>
                    </>
                )
              }
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return state.medchallenge;
};

const AddChallengeQuestions = withRouter(
    connect(
        mapStateToProps,
        {
          getChallenzData,
          handleEditChallenzFieldChanges,
          handleSeoDataChange,
          handleEditChallengeFormReset,
          addChallenzQuestions,
          showQuestionPreview,
          showMasterNotePreview,
        },
    )(Form.create({name: 'add_questions'})(AddChallengeQuestionsForm)),
);

export {AddChallengeQuestions};
