import moment from 'moment';
import {
  ADD_CHALLENZ_QUESTIONS,
  CHALLENZ_BASIS_CHANGE,
  CHALLENZ_DESCRIPTION_CHANGE,
  CHALLENZ_END_DATE_CHANGE,
  CHALLENZ_FEATURED_IMAGE_CHANGE,
  CHALLENZ_IS_PREMIUM_CHANGE,
  CHALLENZ_IS_PREMIUM_MASTER_NOTE_CHANGE,
  CHALLENZ_IS_VIDEO_EXPLANATION_PREMIUM_CHANGE,
  CHALLENZ_MASTER_NOTE_CHANGE,
  CHALLENZ_SEO_META_DESCRIPTION_CHANGE,
  CHALLENZ_SEO_META_KEYWORDS_CHANGE,
  CHALLENZ_SEO_META_TITLE_CHANGE,
  CHALLENZ_SEO_SLUG_CHANGE,
  CHALLENZ_START_DATE_CHANGE,
  CHALLENZ_SUBJECTS_CHANGE,
  CHALLENZ_SYSTEMS_CHANGE,
  CHALLENZ_TAGS_CHANGE,
  CHALLENZ_TITLE_CHANGE,
  CHALLENZ_TOTAL_QUESTIONS_CHANGE,
  CHALLENZ_VIDEO_EXPLANATION_CHANGE,
  GET_CHALLENZ_DETAILS,
  REMOVE_QUESTION_FROM_CHALLENZ,
  RESET_CHALLENZ_DATA,
  SORT_CHALLENZ_QUESTIONS_LIST,
} from '../../../actions/types';

const initialState = {
  id: '',
  title: '',
  isPremium: false,
  subjects: [],
  systems: [],
  tags: [],
  basis: 0,
  totalQuestions: 0,
  startDate: '',
  endDate: '',
  description: '',
  featuredImageUrl: '',
  featuredImageAltText: '',
  questionIds: [],
  questionsList: [],
  videoExplanationUrl: '',
  videoExplanationAltText: '',
  isVideoExplanationPremium: true,
  masterNoteId: '',
  isMasterNotePremium: true,
  seoMetaTitle: '',
  seoSlug: '',
  seoMetaDescription: '',
  seoMetaKeywords: [],
  createdAt: '',
  updatedAt: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHALLENZ_DETAILS:
      let masterNoteName = action.payload.masterNoteName;
      let data = action.payload.challenz;
      let prevTags = [];
      if (data.tags !== undefined && data.tags.length > 0) {
        prevTags = data.tags.map(tag => ({
          key: tag,
          label: tag,
        }));
      }
      return {
        ...state,
        id: data.challenz_id,
        title: data.challenz_name,
        isPremium: data.prime === undefined ? false : data.prime,
        subjects: data.subject === undefined ? [] : data.subject,
        systems: data.system === undefined ? [] : data.system,
        tags: prevTags,
        basis: data.challenz_basis === undefined ? 0 : data.challenz_basis,
        totalQuestions:
            data.number_of_questions === undefined ?
                0 :
                data.number_of_questions,
        startDate:
            data.start_time !== undefined
                ? moment.unix(data.start_time).format('DD/MM/YYYY')
                : '',
        endDate:
            data.end_time !== undefined
                ? moment.unix(data.end_time).format('DD/MM/YYYY')
                : '',
        description: data.description !== undefined ? data.description : '',
        featuredImageUrl:
            data.featured_image_url !== undefined ?
                data.featured_image_url :
                '',
        featuredImageAltText:
            data.featured_image_alt_text !== undefined
                ? data.featured_image_alt_text
                : '',
        questionIds: data.question_ids !== undefined ? data.question_ids : [],
        questionsList:
            action.payload.questions !== undefined
                ? action.payload.questions
                : [],
        videoExplanationUrl:
            data.video_explanation !== undefined ? data.video_explanation : '',
        videoExplanationAltText:
            data.video_explanation_alt_text !== undefined
                ? data.video_explanation_alt_text
                : '',
        isVideoExplanationPremium: data.video_explanation_prime,
        masterNoteId:
            data.master_note_id !== undefined ? data.master_note_id : '',
        masterNoteName: masterNoteName === undefined ? '' : masterNoteName,
        isMasterNotePremium: data.master_note_prime,
        seoMetaTitle: data.meta_title,
        seoSlug: data.slug,
        seoMetaDescription: data.meta_description,
        seoMetaKeywords:
            data.meta_keywords !== undefined ? data.meta_keywords : [],
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      };
    case CHALLENZ_TITLE_CHANGE:
      let payload = action.payload;
      return {
        ...state,
        title: payload !== undefined ? payload.title : '',
        seoMetaTitle: payload !== undefined ? payload.metaTitle : '',
        seoSlug: payload !== undefined ? payload.slug : '',
      };
    case CHALLENZ_IS_PREMIUM_CHANGE:
      return {
        ...state,
        isPremium: action.payload,
      };
    case CHALLENZ_SUBJECTS_CHANGE:
      return {
        ...state,
        subjects: action.payload,
      };
    case CHALLENZ_SYSTEMS_CHANGE:
      return {
        ...state,
        systems: action.payload,
      };
    case CHALLENZ_TAGS_CHANGE:
      return {
        ...state,
        tags: action.payload,
      };
    case CHALLENZ_BASIS_CHANGE:
      return {
        ...state,
        basis: action.payload,
      };
    case CHALLENZ_TOTAL_QUESTIONS_CHANGE:
      return {
        ...state,
        totalQuestions: action.payload,
      };
    case CHALLENZ_START_DATE_CHANGE:
      return {
        ...state,
        startDate: action.payload,
      };
    case CHALLENZ_END_DATE_CHANGE:
      return {
        ...state,
        endDate: action.payload,
      };
    case CHALLENZ_DESCRIPTION_CHANGE:
      return {
        ...state,
        description: action.payload,
      };
    case CHALLENZ_FEATURED_IMAGE_CHANGE:
      return {
        ...state,
        featuredImageUrl:
            action.payload === undefined ? '' : action.payload.imgUrl,
        featuredImageAltText:
            action.payload === undefined ? '' : action.payload.imgAltText,
      };
    case CHALLENZ_VIDEO_EXPLANATION_CHANGE:
      return {
        ...state,
        videoExplanationUrl:
            action.payload === undefined ? '' : action.payload.videoUrl,
        videoExplanationAltText:
            action.payload === undefined ? '' : action.payload.videoAltText,
      };
    case CHALLENZ_IS_VIDEO_EXPLANATION_PREMIUM_CHANGE:
      return {
        ...state,
        isVideoExplanationPremium: action.payload,
      };
    case CHALLENZ_MASTER_NOTE_CHANGE:
      return {
        ...state,
        masterNoteId: action.payload.id,
        masterNoteName: action.payload.name,
      };
    case CHALLENZ_IS_PREMIUM_MASTER_NOTE_CHANGE:
      return {
        ...state,
        isMasterNotePremium: action.payload,
      };
    case CHALLENZ_SEO_META_TITLE_CHANGE:
      return {
        ...state,
        seoMetaTitle: action.payload,
      };
    case CHALLENZ_SEO_SLUG_CHANGE:
      return {
        ...state,
        seoSlug: action.payload,
      };
    case CHALLENZ_SEO_META_DESCRIPTION_CHANGE:
      return {
        ...state,
        seoMetaDescription: action.payload,
      };
    case CHALLENZ_SEO_META_KEYWORDS_CHANGE:
      return {
        ...state,
        seoMetaKeywords: action.payload,
      };
    case ADD_CHALLENZ_QUESTIONS:
      if (action.payload !== undefined) {
        return {
          ...state,
          questionsIds: action.payload.ids,
          questionsList: action.payload.questions,
        };
      } else {
        return {
          ...state,
        };
      }
    case SORT_CHALLENZ_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.payload.questionsList,
        questionIds: action.payload.questionIds,
      };
    case REMOVE_QUESTION_FROM_CHALLENZ:
      return {
        ...state,
        questionsList: action.payload.questionsList,
        questionIds: action.payload.questionIds,
      };
    case RESET_CHALLENZ_DATA:
      return {
        ...state,
        id: '',
        title: '',
        isPremium: true,
        basis: 0,
        totalQuestions: 0,
        startDate: '',
        endDate: '',
        description: '',
        featuredImageUrl: '',
        featuredImageAltText: '',
        questionIds: [],
        questionsList: [],
        videoExplanationUrl: '',
        videoExplanationAltText: '',
        isVideoExplanationPremium: true,
        masterNoteId: '',
        masterNoteName: '',
        isMasterNotePremium: true,
        seoMetaTitle: '',
        seoSlug: '',
        seoMetaKeywords: [],
      };
    default:
      return state;
  }
};
