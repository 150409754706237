import React from 'react';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Icon,
  Menu,
  message,
  Popover,
  Row,
  Upload,
} from 'antd';
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import {connect} from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {post} from '../mc_axios/McAxios';
import {
  AddChallengeQuestions,
  AddPWChallengeQuestions,
  AddTestQuestions,
  ChangePassword,
  CommentDetailed,
  Comments,
  ContactDetailed,
  Contacts,
  CreateInfomed,
  CreateMasterNote,
  CreateMedChallenge,
  CreateMedPass,
  CreateMedpixx,
  CreateMedvideo,
  CreateNote,
  CreateNotification,
  CreatePWChallenge,
  CreateQuestion,
  CreateSlide,
  CreateTest,
  CreateTestimonial,
  EditInfomed,
  EditMasterNote,
  EditMedChallenge,
  EditMedPass,
  EditMedpixx,
  EditMedvideo,
  EditNote,
  EditNotification,
  EditPWChallenge,
  EditQuestion,
  EditSlide,
  EditTest,
  EditTestimonial,
  Home,
  HomepageSlider,
  Infomed,
  InfomedTrash,
  MasterNotes,
  MasterNotesTrash,
  MedChallenge,
  MedChallenzTrash,
  MedPass,
  Medpixx,
  MedpixxTrash, MeducatorDetailed,
  Meducators,
  Medvideo,
  MedvideoTrash,
  Notes,
  NotesTrash,
  Notifications,
  PrepMed,
  PrepMedTrash,
  PublishedTestimonials,
  PWChallenges,
  QBank,
  QBankTrash,
  Slider,
  Testimonials,
  UpscVideos,
  UserDetailed,
  Users,
} from '../../pages';
import {PreviewMasterNote, PreviewNote, PreviewQuestion} from '..';
import {changeProductType} from '../../actions/util_actions/UtilActions';
import './BaseLayout.css';
import {
  getUserRoleReselector,
  getUtilDataReselector,
} from '../../redux/selectors';

class BaseLayoutPre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: false,
      popoverVisible: false,
      fileUrl: '',
    };
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    const {location: {pathname}, changeProductType} = this.props;
    changeProductType(pathname);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {pathname: prevPathName} = prevProps.location;
    const {location: {pathname}, changeProductType} = this.props;
    if (prevPathName !== pathname) {
      changeProductType(pathname);
    }
  }

  logOut() {
    post('/api/user-manager/admin/logout', '').then(response => {
      if (response.data.status === true && response.data.code === 200) {
        localStorage.clear();
        window.location.href = '/';
      }
    });
  }

  showHideSidebar = () => {
    this.setState(prevState => ({showSidebar: !prevState.showSidebar}));
  };

  handleVisibleChange = visible => {
    this.setState({popoverVisible: visible});
  };

  hideSidebar = () => {
    if (window.innerWidth < 768) {
      this.setState({showSidebar: false});
    }
  };

  handleUpload = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      message.success('File uploaded successfully');
      this.setState({
        fileUrl: info.file.response.data.file_url,
      });
    } else if (info.file.status === 'error') {
      message.error('File upload failed.');
    }
  };

  render() {
    const {state: {showSidebar, fileUrl, popoverVisible}, props: {util: {productType}, userRole}} = this;

    const uploaderProps = {
      name: 'file',
      action: '/api/media/upload',
      data: {
        product_type: productType,
      },
    };

    return (
        <div className="wrapper">
          <div className={showSidebar ? 'left-panel active' : 'left-panel'}>
            <Row className="navbar-brand">
              <Col span={24}>
                <img
                    src={'https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2018/07/31115228/MC_Logo.png'}
                    alt="medcampus"
                    width="150"
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ul className="navbar-list">
                  {
                    userRole === 2 ? (
                        <>
                          <li>
                            <NavLink
                                to={'/dashboard/home/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="dashboard"/>Dashboard</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/mnotes/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="file-text"/>mNotes</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/medpixx/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="picture"/>MedPixx</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/medvideo/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="video-camera"/>MedVideo</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/qbank/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="bank"/>QBank</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/notes/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="read"/>Notes</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/master-notes/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="read"/>Master Notes</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/medchallenz/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="form"/>Challenges</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/play-and-win-challenges/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="form"/>P&W Challenges</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/prepmed/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="form"/>PrepMed</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/slider/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="desktop"/>Slider</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/medpass/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="desktop"/>MedPass</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/contacts/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="contacts"/>Contacts</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/testimonials/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="message"/>Testimonials</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/comments/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="message"/>Comments</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/notifications/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon
                                  type="notification"/>Notifications</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/users/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="user"/>Users</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/meducators/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="book"/>Meducators</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/upsc/'}
                                onClick={this.hideSidebar}
                            >
                              <span><Icon type="desktop"/>Upsc</span>
                            </NavLink>
                          </li>
                        </>
                    ) : (
                        <>
                          <li>
                            <NavLink
                                to={'/dashboard/qbank/'}
                                onClick={this.hideSidebar}
                            >
                        <span>
                          <Icon type="bank"/>
                          QBank
                        </span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/prepmed/'}
                                onClick={this.hideSidebar}
                            >
                        <span>
                          <Icon type="form"/>
                          PrepMed
                        </span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                                to={'/dashboard/medvideo/'}
                                onClick={this.hideSidebar}
                            >
                        <span>
                          <Icon type="video-camera"/>
                          MedVideo
                        </span>
                            </NavLink>
                          </li>
                        </>
                    )
                  }
                </ul>
              </Col>
            </Row>
          </div>
          <div className="right-panel">
            <Row className="main-header">
              <Col span={12}>
                <div className="header-icons">
                  <div className="header-icons-div">
                    <Icon
                        type="menu-fold"
                        className="mc-icon-small"
                        onClick={this.showHideSidebar}
                    />
                  </div>
                  <div className="header-icons-div">
                    <Popover
                        content={
                          <>
                            <Upload
                                {...uploaderProps}
                                onChange={this.handleUpload}
                                className="mc-mb-10 width-fluid"
                            >
                              <Button htmlType={'button'} block>
                                <Icon type="upload"/> File
                              </Button>
                            </Upload>
                            <Divider dashed={true} style={{width: 150}}>
                              After
                            </Divider>
                            <CopyToClipboard text={fileUrl}>
                              <Button htmlType={'button'} type={'dashed'} block>
                                Copy to clipboard
                              </Button>
                            </CopyToClipboard>
                          </>
                        }
                        title="Upload file"
                        trigger="click"
                        visible={popoverVisible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                      <Button
                          htmlType={'button'}
                          type="primary"
                          shape="circle"
                          icon="upload"
                          size={'small'}
                          className="file-upload-btn"
                      />
                    </Popover>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="0">
                          <Link to={'/dashboard/change-password/'}>Change
                            Password</Link>
                        </Menu.Item>
                        <Menu.Item key="1" onClick={this.logOut}>
                          <span>Logout</span>
                        </Menu.Item>
                      </Menu>
                    }
                >
                  <Avatar className="user-avatar" icon="user"/>
                </Dropdown>
              </Col>
            </Row>
            <Row className="main-content">
              <Col span={24}>
                <Switch>
                  {
                    userRole === 2 ? (
                        <>
                          <Route
                              path="/dashboard/home/"
                              exact
                              component={Home}
                          />
                          <Route
                              path="/dashboard/mnotes/"
                              exact
                              component={Infomed}
                          />
                          <Route
                              path="/dashboard/mnotes/create/"
                              exact
                              component={CreateInfomed}
                          />
                          <Route
                              path="/dashboard/mnotes/edit/"
                              exact
                              render={() => <Redirect to="/dashboard/mnotes/"/>}
                          />
                          <Route
                              path="/dashboard/mnotes/trash/"
                              exact
                              component={InfomedTrash}
                          />
                          <Route
                              path="/dashboard/mnotes/edit/:infomedId"
                              component={EditInfomed}
                          />
                          <Route
                              path="/dashboard/medpixx/"
                              exact
                              component={Medpixx}
                          />
                          <Route
                              path="/dashboard/medpixx/create/"
                              exact
                              component={CreateMedpixx}
                          />
                          <Route
                              path="/dashboard/medpixx/edit/"
                              exact
                              render={() => <Redirect to="/dashboard/medpixx"/>}
                          />
                          <Route
                              path="/dashboard/medpixx/trash/"
                              exact
                              component={MedpixxTrash}
                          />
                          <Route
                              path="/dashboard/medpixx/edit/:medpixxId"
                              component={EditMedpixx}
                          />
                          <Route
                              path="/dashboard/medvideo/"
                              exact
                              component={Medvideo}
                          />
                          <Route
                              path="/dashboard/medvideo/create/"
                              exact
                              component={CreateMedvideo}
                          />
                          <Route
                              path="/dashboard/medvideo/edit/"
                              exact
                              render={() => <Redirect to="/medvideo/"/>}
                          />
                          <Route
                              path="/dashboard/medvideo/edit/:medvideoId"
                              component={EditMedvideo}
                          />
                          <Route
                              path="/dashboard/medvideo/trash/"
                              exact
                              component={MedvideoTrash}
                          />
                          <Route
                              path="/dashboard/qbank/"
                              exact
                              component={QBank}
                          />
                          <Route
                              path="/dashboard/qbank/create-question/"
                              exact
                              component={CreateQuestion}
                          />
                          <Route
                              path="/dashboard/qbank/edit-question/"
                              exact
                              render={() => <Redirect to="/dashboard/qbank/"/>}
                          />
                          <Route
                              path="/dashboard/qbank/edit-question/:questionId"
                              component={EditQuestion}
                          />
                          <Route
                              path="/dashboard/qbank/trash/"
                              exact
                              component={QBankTrash}
                          />
                          <Route path="/dashboard/notes/" exact
                                 component={Notes}/>
                          <Route
                              path="/dashboard/notes/create/"
                              exact
                              component={CreateNote}
                          />
                          <Route
                              path="/dashboard/notes/edit"
                              exact
                              render={() => <Redirect to="/notes/"/>}
                          />
                          <Route
                              path="/dashboard/notes/edit/:noteId"
                              component={EditNote}
                          />
                          <Route path="/dashboard/notes/trash/"
                                 component={NotesTrash}/>
                          <Route
                              path="/dashboard/master-notes/"
                              exact
                              component={MasterNotes}
                          />
                          <Route
                              path="/dashboard/master-notes/create/"
                              exact
                              component={CreateMasterNote}
                          />
                          <Route
                              path="/dashboard/master-notes/edit"
                              exact
                              render={() => <Redirect to="/notes/"/>}
                          />
                          <Route
                              path="/dashboard/master-notes/edit/:masterNoteId"
                              component={EditMasterNote}
                          />
                          <Route
                              path="/dashboard/master-notes/trash/"
                              component={MasterNotesTrash}
                          />
                          <Route
                              path="/dashboard/medchallenz/"
                              exact
                              component={MedChallenge}
                          />
                          <Route
                              path="/dashboard/medchallenz/create/"
                              exact
                              component={CreateMedChallenge}
                          />
                          <Route
                              path="/dashboard/medchallenz/edit/:challengeId"
                              component={EditMedChallenge}
                          />
                          <Route
                              path="/dashboard/medchallenz/add-questions/:challengeId"
                              component={AddChallengeQuestions}
                          />
                          <Route
                              path="/dashboard/medchallenz/trash/"
                              component={MedChallenzTrash}
                          />
                          <Route
                              path="/dashboard/play-and-win-challenges/"
                              exact
                              component={PWChallenges}
                          />
                          <Route
                              path="/dashboard/play-and-win-challenges/create/"
                              exact
                              component={CreatePWChallenge}
                          />
                          <Route
                              path="/dashboard/play-and-win-challenges/edit/:challengeId"
                              exact
                              component={EditPWChallenge}
                          />
                          <Route
                              path="/dashboard/play-and-win-challenges/add-questions/:challengeId"
                              exact
                              component={AddPWChallengeQuestions}
                          />
                          <Route
                              path="/dashboard/prepmed/"
                              exact
                              component={PrepMed}
                          />
                          <Route
                              path="/dashboard/prepmed/create-test/"
                              exact
                              component={CreateTest}
                          />
                          <Route
                              path="/dashboard/prepmed/edit-test/:testId"
                              component={EditTest}
                          />
                          <Route
                              path="/dashboard/prepmed/add-questions/:testId"
                              component={AddTestQuestions}
                          />
                          <Route
                              path="/dashboard/prepmed/trash/"
                              component={PrepMedTrash}
                          />
                          <Route
                              exact
                              path="/dashboard/slider/"
                              component={Slider}
                          />
                          <Route
                              exact
                              path="/dashboard/slider/create-slide/"
                              component={CreateSlide}
                          />
                          <Route
                              exact
                              path="/dashboard/slider/edit/:slideId"
                              component={EditSlide}
                          />
                          <Route
                              exact
                              path="/dashboard/slider/homepage-slider/"
                              component={HomepageSlider}
                          />
                          <Route
                              exact
                              path="/dashboard/upsc/"
                              component={UpscVideos}
                          />
                          <Route
                              exact
                              path="/dashboard/medpass/"
                              component={MedPass}
                          />
                          <Route
                              exact
                              path="/dashboard/medpass/create-medpass/"
                              component={CreateMedPass}
                          />
                          <Route
                              exact
                              path="/dashboard/medpass/edit-medpass/:medPassId"
                              component={EditMedPass}
                          />
                          <Route
                              exact
                              path="/dashboard/contacts/"
                              component={Contacts}
                          />
                          <Route
                              exact
                              path="/dashboard/contacts/contact-detailed/:contactId"
                              component={ContactDetailed}
                          />
                          <Route
                              exact
                              path="/dashboard/change-password/"
                              component={ChangePassword}
                          />
                          <Route
                              exact
                              path="/dashboard/testimonials/"
                              component={Testimonials}
                          />
                          <Route
                              exact
                              path="/dashboard/testimonials/create-testimonial/"
                              component={CreateTestimonial}
                          />
                          <Route
                              exact
                              path={'/dashboard/testimonials/edit/:testimonialId'}
                              component={EditTestimonial}
                          />
                          <Route
                              exact
                              path="/dashboard/testimonials/published/"
                              component={PublishedTestimonials}
                          />
                          <Route
                              exact
                              path="/dashboard/comments/"
                              component={Comments}
                          />
                          <Route
                              exact
                              path="/dashboard/comments/comment-:commentId"
                              component={CommentDetailed}
                          />
                          <Route
                              exact
                              path="/dashboard/notifications/"
                              component={Notifications}
                          />
                          <Route
                              exact
                              path="/dashboard/notifications/create-notification/"
                              component={CreateNotification}
                          />
                          <Route
                              exact
                              path="/dashboard/notifications/edit-notification/:notificationId"
                              component={EditNotification}
                          />
                          <Route
                              exact
                              path="/dashboard/users/"
                              component={Users}
                          />
                          <Route
                              exact
                              path="/dashboard/users/:userId"
                              component={UserDetailed}
                          />
                          <Route
                              exact
                              path="/dashboard/meducators"
                              component={Meducators}
                          />
                          <Route
                              exact
                              path="/dashboard/meducators/:meducatorId"
                              component={MeducatorDetailed}
                          />
                        </>
                    ) : (
                        <>
                          <Route
                              exact
                              path="/dashboard/change-password/"
                              component={ChangePassword}
                          />
                          <Route
                              path="/dashboard/qbank/"
                              exact
                              component={QBank}
                          />
                          <Route
                              path="/dashboard/qbank/create-question/"
                              exact
                              component={CreateQuestion}
                          />
                          <Route
                              path="/dashboard/qbank/edit-question/"
                              exact
                              render={() => <Redirect to="/dashboard/qbank/"/>}
                          />
                          <Route
                              path="/dashboard/qbank/edit-question/:questionId"
                              component={EditQuestion}
                          />
                          <Route
                              path="/dashboard/qbank/trash/"
                              exact
                              component={QBankTrash}
                          />
                          <Route
                              path="/dashboard/prepmed/"
                              exact
                              component={PrepMed}
                          />
                          <Route
                              path="/dashboard/prepmed/create-test/"
                              exact
                              component={CreateTest}
                          />
                          <Route
                              path="/dashboard/prepmed/edit-test/:testId"
                              component={EditTest}
                          />
                          <Route
                              path="/dashboard/prepmed/add-questions/:testId"
                              component={AddTestQuestions}
                          />
                          <Route
                              path="/dashboard/prepmed/trash/"
                              component={PrepMedTrash}
                          />
                          <Route
                              path="/dashboard/medvideo/"
                              exact
                              component={Medvideo}
                          />
                          <Route
                              path="/dashboard/medvideo/create/"
                              exact
                              component={CreateMedvideo}
                          />
                          <Route
                              path="/dashboard/medvideo/edit/"
                              exact
                              render={() => <Redirect to="/medvideo/"/>}
                          />
                          <Route
                              path="/dashboard/medvideo/edit/:medvideoId"
                              component={EditMedvideo}
                          />
                          <Route
                              path="/dashboard/medvideo/trash/"
                              exact
                              component={MedvideoTrash}
                          />
                        </>
                    )
                  }
                </Switch>
              </Col>
            </Row>
            <PreviewQuestion/>
            <PreviewNote/>
            <PreviewMasterNote/>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    util: getUtilDataReselector(state),
    userRole: getUserRoleReselector(state),
  };
};

const BaseLayout = withRouter(
    connect(mapStateToProps, {changeProductType})(BaseLayoutPre));

export {BaseLayout};
