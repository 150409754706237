import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import {post} from '../../../components/mc_axios/McAxios';

const Dragger = Upload.Dragger;
const {Option} = Select;
const {TextArea} = Input;

const CreateTestimonialPre = props => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleCreateTestimonial = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const {user_name, user_picture, user_message, user_info, display_page} = values;
        const pictureData = user_picture && user_picture.length > 0 ? user_picture[0].response.data : {file_url: '', alt_text: ''};

        const data = {
          name: user_name,
          message: user_message,
          user_info,
          testimony_page: display_page,
          picture: pictureData.file_url,
          alt_text: pictureData.alt_text,
          status: 1,
        };

        post('/api/coordinates/coordinates/testimonies/create', data).
            then(response => {
              if (response.data.status === true && response.data.code === 200) {
                message.success('Testimonial is created successfully');
                props.history.push('/dashboard/testimonials/');
              } else message.error('Something went wrong. Please try again');
            }).
            catch(() => message.error('Something went wrong. Please try again'));
      }
    });
  };

  const {getFieldDecorator} = props.form;

  return (
      <Row className="main-content-block" gutter={16}>
        <Col span={24}>
          <Form onSubmit={handleCreateTestimonial}>
            <Row gutter={32}>
              <Col xs={24} sm={12}>
                <div className="page-header">
                  <Link to={'/dashboard/testimonials/'}>
                    <Icon type="arrow-left" title={'Back'}/>
                  </Link>
                  <Divider type="vertical"/> Create Testimonial
                </div>
              </Col>
              <Col xs={24} sm={12} className="text-right">
                <Button
                    htmlType="submit"
                    className="mc-mr-5"
                >
                  Create
                </Button>
              </Col>
              <Col span={24}>
                <hr className="mc-mt-10"/>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={24} lg={12}>
                <Row gutter={32}>
                  <Col xs={24} md={12}>
                    <Form.Item label="User Name">
                      {
                        getFieldDecorator('user_name', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input username!',
                            },
                          ],
                        })(
                            <Input
                                placeholder={'Ex: Sudeep Kumar'}
                                size={'large'}
                            />,
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="User Information">
                      {
                        getFieldDecorator('user_info')(
                            <Input
                                placeholder={'Ex: GMC Final Year'}
                                size={'large'}
                            />,
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Display">
                      {
                        getFieldDecorator('display_page', {
                          rules: [
                            {
                              required: true,
                              message: 'Please select pages!',
                            },
                          ],
                        })(
                            <Select
                                size={'large'}
                                placeholder="Select display page"
                            >
                              <Option value={1}>Home</Option>
                              <Option value={2}>MedPass</Option>
                            </Select>,
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="User Picture">
                      {
                        getFieldDecorator('user_picture', {
                          valuePropName: 'fileList',
                          getValueFromEvent: normFile,
                        })(
                            <Dragger
                                accept=".png, .jpg, .jpeg"
                                action="/api/media/upload"
                                data={{product_type: 7}}
                                listType="picture"
                            >
                              <p className="ant-upload-drag-icon"><Icon
                                  type="inbox"/></p>
                              <p className="ant-upload-text">Click or drag file
                                to this area to upload</p>
                            </Dragger>,
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="Message">
                  {
                    getFieldDecorator('user_message', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter message!',
                        },
                      ],
                    })(<TextArea rows={11}/>)
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
  );
};

const CreateTestimonial = Form.create({name: 'create_testimonial'})(
    CreateTestimonialPre);

export {CreateTestimonial};
