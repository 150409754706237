import React, {memo} from 'react';
import {Col, Progress, Row} from 'antd';
import './FeedbackCard.css';

const FeedbackCard = memo(({title = 'Title', great = 0, normal = 0, disappointed = 0}) => {

      const totalFeedbacks = great + normal + disappointed;
      const greatFeedbackPercentage = parseFloat(((great / totalFeedbacks) * 100).toFixed(1));
      const normalFeedbackPercentage = parseFloat(((normal / totalFeedbacks) * 100).toFixed(1));
      const disappointedFeedbackPercentage = parseFloat(((disappointed / totalFeedbacks) * 100).toFixed(1));

      return (
          <div className="feedback-card">
            <p className="feedback-card__feedback-title">{title}</p>
            <Row gutter={16}>
              <Col span={8}>
                <p>Good</p>
              </Col>
              <Col span={16}>
                <Progress
                    strokeColor="#42DEA8"
                    percent={greatFeedbackPercentage}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <p>Could be better</p>
              </Col>
              <Col span={16}>
                <Progress
                    strokeColor="#24AEFC"
                    percent={normalFeedbackPercentage}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <p>I'm disappointed</p>
              </Col>
              <Col span={16}>
                <Progress
                    strokeColor="#F7228C"
                    percent={disappointedFeedbackPercentage}
                />
              </Col>
            </Row>
          </div>
      );
    });

export {FeedbackCard};
