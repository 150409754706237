import {CHANGE_PRODUCT_TYPE} from '../types';

export function changeProductType(pathName) {
  let splittedPath = pathName.split('/')[2];
  let prodType = 0;

  switch (splittedPath) {
    case 'mnotes':
      prodType = 1;
      break;
    case 'medpixx':
      prodType = 2;
      break;
    case 'medvideo':
      prodType = 3;
      break;
    case 'medchallenz':
    case 'play-and-win-challenges':
      prodType = 4;
      break;
    case 'qbank':
      prodType = 5;
      break;
    case 'notes':
    case 'master-notes':
      prodType = 6;
      break;
    case 'prepmed':
      prodType = 8;
      break;
    case 'upsc':
      prodType = 9;
      break;
    default:
      prodType = 0;
      break;
  }

  return {
    type: CHANGE_PRODUCT_TYPE,
    payload: prodType,
  };
}
