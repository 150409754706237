import React from 'react';
import {connect} from 'react-redux';
import {Col, Modal, Row} from 'antd';
import {createMarkup} from '../util';
import {resetNoteData} from '../../actions/notes_actions/PreviewNoteActions';
import './PreviewNote.css';

function PreviewNotePre(props) {
  const {previewNote: {visible, explanation, title, reference}, resetNoteData} = props;

  return (
      <Modal
          title="Preview Note"
          centered
          width={1200}
          visible={visible}
          onCancel={() => resetNoteData()}
          footer={null}
          className="preview-note-modal"
      >
        <div className="watermark-layer"/>
        <Row>
          <Col span={24}>
            <h4>
              <u>Title:</u>
            </h4>
            <p>{title}</p>
          </Col>
          {
            (explanation !== undefined && explanation !== '') && (
                <Col span={24}>
                  <h4><u>Explanation:</u></h4>
                  <div
                      dangerouslySetInnerHTML={createMarkup(explanation)}
                  />
                </Col>
            )
          }
          {
            (reference !== undefined && reference !== '') && (
                <Col span={24}>
                  <hr/>
                  <h4><u>Reference:</u></h4>
                  <div
                      dangerouslySetInnerHTML={createMarkup(reference)}
                  />
                </Col>
            )
          }
        </Row>
      </Modal>
  );
}

const mapStateToProps = state => {
  return state.notes;
};

const PreviewNote = connect(mapStateToProps, {resetNoteData})(PreviewNotePre);

export {PreviewNote};
