import {
  ADD_PW_CHALLENGE_QUESTIONS,
  GET_PW_CHALLENGE_DETAILS,
  PW_CHALLENGE_DESCRIPTION_CHANGE,
  PW_CHALLENGE_FEATURED_IMAGE_CHANGE,
  PW_CHALLENGE_FEATURED_IMAGE_LINK_CHANGE,
  PW_CHALLENGE_REWARD_AMOUNT_CHANGE,
  PW_CHALLENGE_SEO_META_DESCRIPTION_CHANGE,
  PW_CHALLENGE_SEO_META_KEYWORDS_CHANGE,
  PW_CHALLENGE_SEO_META_TITLE_CHANGE,
  PW_CHALLENGE_SEO_SLUG_CHANGE,
  PW_CHALLENGE_SPONSOR_ADD_MEDIA_CHANGE,
  PW_CHALLENGE_SPONSOR_LINK_CHANGE,
  PW_CHALLENGE_START_DATE_CHANGE,
  PW_CHALLENGE_THUMBNAIL_IMAGE_CHANGE,
  PW_CHALLENGE_TITLE_CHANGE,
  PW_CHALLENGE_TOTAL_QUESTIONS_CHANGE,
  REMOVE_QUESTION_FROM_PW_CHALLENGE,
  RESET_PW_CHALLENGE_DATA,
  SORT_PW_CHALLENGE_QUESTIONS_LIST,
} from '../types';
import {removeArrayItemByIndex, slugify} from '../../components/util';
import {get, post} from '../../components/mc_axios/McAxios';
import {message} from 'antd';

export function getPWChallengeData(pwChallengeId) {
  return async dispatch => {
    get('/api/challenzes/plays/' + pwChallengeId).then(response => {
      if (response.data.status === true) {
        const pwChallengeData = response.data.data;
        dispatch({
          type: GET_PW_CHALLENGE_DETAILS,
          payload: {
            pwChallenge: pwChallengeData.play,
            questions: pwChallengeData.questions !== null || true ?
                response.data.data.questions :
                [],
          },
        });
      } else {
        message.error('Something went wrong.');
      }
    });
  };
}

export function handleEditPWChallengeFieldChanges(fieldName, data) {
  return async (dispatch, getState) => {
    switch (fieldName) {
      case 'title':
        dispatch({
          type: PW_CHALLENGE_TITLE_CHANGE,
          payload: {
            title: data,
            metaTitle: data,
            slug: slugify(data),
          },
        });
        break;
      case 'total-questions':
        dispatch({
          type: PW_CHALLENGE_TOTAL_QUESTIONS_CHANGE,
          payload: data,
        });
        break;
      case 'start-date':
        dispatch({
          type: PW_CHALLENGE_START_DATE_CHANGE,
          payload: data,
        });
        break;
      case 'description':
        dispatch({
          type: PW_CHALLENGE_DESCRIPTION_CHANGE,
          payload: data,
        });
        break;
      case 'featured-image':
        dispatch({
          type: PW_CHALLENGE_FEATURED_IMAGE_CHANGE,
          payload: {
            imgUrl: data.imgUrl,
            imgAltText: data.imgAltText,
          },
        });
        break;
      case 'remove-featured-image':
        dispatch({
          type: PW_CHALLENGE_FEATURED_IMAGE_CHANGE,
          payload: {
            imgUrl: '',
            imgAltText: '',
          },
        });
        break;
      case 'featured-image-link':
        dispatch({
          type: PW_CHALLENGE_FEATURED_IMAGE_LINK_CHANGE,
          payload: data,
        });
        break;
      case 'thumbnail-image':
        dispatch({
          type: PW_CHALLENGE_THUMBNAIL_IMAGE_CHANGE,
          payload: {
            imgUrl: data.imgUrl,
            imgAltText: data.imgAltText,
          },
        });
        break;
      case 'remove-thumbnail-image':
        dispatch({
          type: PW_CHALLENGE_THUMBNAIL_IMAGE_CHANGE,
          payload: {
            imgUrl: '',
            imgAltText: '',
          },
        });
        break;
      case 'sponsor-media':
        dispatch({
          type: PW_CHALLENGE_SPONSOR_ADD_MEDIA_CHANGE,
          payload: data,
        });
        break;
      case 'remove-sponsor-media':
        dispatch({
          type: PW_CHALLENGE_SPONSOR_ADD_MEDIA_CHANGE,
          payload: '',
        });
        break;
      case 'sponsor-link':
        dispatch({
          type: PW_CHALLENGE_SPONSOR_LINK_CHANGE,
          payload: data,
        });
        break;
      case 'reward-amount':
        dispatch({
          type: PW_CHALLENGE_REWARD_AMOUNT_CHANGE,
          payload: data,
        });
        break;
      case 'sort-questions-list':
        dispatch({
          type: SORT_PW_CHALLENGE_QUESTIONS_LIST,
          payload: data,
        });
        break;
      case 'remove-question':
        const {questionIds, questionsList} = getState().pwchallenge.editPWChallenge;
        dispatch({
          type: REMOVE_QUESTION_FROM_PW_CHALLENGE,
          payload: {
            questionIds: removeArrayItemByIndex(questionIds, data),
            questionsList: removeArrayItemByIndex(questionsList, data),
          },
        });
        break;
      case 'seo-meta-title':
        dispatch({
          type: PW_CHALLENGE_SEO_META_TITLE_CHANGE,
          payload: data,
        });
        break;
      case 'seo-meta-slug':
        dispatch({
          type: PW_CHALLENGE_SEO_SLUG_CHANGE,
          payload: data,
        });
        break;
      case 'seo-meta-description':
        dispatch({
          type: PW_CHALLENGE_SEO_META_DESCRIPTION_CHANGE,
          payload: data,
        });
        break;
      case 'seo-meta-keywords':
        dispatch({
          type: PW_CHALLENGE_SEO_META_KEYWORDS_CHANGE,
          payload: data,
        });
        break;
      default:
        break;
    }
  };
}

export function addPWChallengeQuestions(questionId, title, status) {
  return async (dispatch, getState) => {
    const {id, questionIds, questionsList, totalQuestions} = getState().pwchallenge.editPWChallenge;
    const found = questionIds.some(el => el === questionId);
    if (found) {
      message.warning('Question is already added.');
      dispatch({
        type: ADD_PW_CHALLENGE_QUESTIONS,
      });
    } else if (totalQuestions === questionIds.length) {
      message.warning('Can\'t add new questions. Limit exceeded.');
    } else {
      let data = {
        play_id: id,
        question_id: questionId,
      };

      post('/api/challenzes/plays/add_question', data).then(response => {
        if (response.data.code === 200) {
          let ids = questionIds;
          let questions = questionsList === '' ? [] : questionsList;
          ids.push(questionId);
          questions.push({title, question_id: questionId, status});
          message.success('Question added successfully');
          dispatch({
            type: ADD_PW_CHALLENGE_QUESTIONS,
            payload: {
              ids: ids,
              questions: questions,
            },
          });
        } else {
          message.warning('Something went wrong.');
        }
      });
    }
  };
}

export function handleEditPWChallengeFormReset() {
  return {
    type: RESET_PW_CHALLENGE_DATA,
  };
}
