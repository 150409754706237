import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import {post} from '../../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../../components/util';

const Option = Select.Option;
const {TextArea} = Input;

class CreateMedPass extends React.Component {
  handlers = {};

  constructor(props) {
    super(props);

    const subscriptionModel = getLocalData('create-medpass-subscriptionModel',
        3) || 0;
    const subscriptionType = getLocalData('create-medpass-subscriptionType',
        3) || 0;
    const numOfSubscriptionTypes = getLocalData(
        'create-medpass-numOfSubscriptionTypes', 3) || 0;
    const amount = getLocalData('create-medpass-amount', 3) || 0;
    const offerAmount = getLocalData('create-medpass-offerAmount', 3) || 0;

    this.state = {
      title: getLocalData('create-medpass-title', 1),
      description: getLocalData('create-medpass-description', 1),
      startDate: getLocalData('create-medpass-startDate', 1),
      endDate: getLocalData('create-medpass-endDate', 1),
      subscriptionModel,
      subscriptionType,
      numOfSubscriptionTypes,
      amount,
      offerAmount,
    };
  }

  // New methods
  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const {value} = event.target;
        setLocalData('create-medpass-' + name, value, 1);
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        setLocalData('create-medpass-' + name, value, 2);
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleDateChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = (date, dateString) => {
        setLocalData('create-medpass-' + name, dateString, 1);
        this.setState({
          [name]: dateString,
        });
      };
    }
    return this.handlers[name];
  };

  handleCreateOffer = () => {
    if (this.state.title !== '') {
      const {
        title,
        subscriptionModel,
        subscriptionType,
        numOfSubscriptionTypes,
        description,
        amount,
        offerAmount,
        startDate,
        endDate,
      } = this.state;

      const data = {
        title,
        description,
        offer_start: moment(startDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        offer_valid: moment(endDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        subscription_model: subscriptionModel,
        subscription_type: subscriptionType,
        number_of_subscription_type: numOfSubscriptionTypes,
        amount,
        offer_amount: offerAmount,
        status: 1,
      };

      post('/api/medpasses/medpasses/create', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          this.handleCreateOfferFormReset();
          message.success('Offer has been created');
          this.props.history.push('/dashboard/medpass/');
        } else message.error('Something went wrong! Try again.');
      });
    } else message.warning('Please enter title');
  };

  handleCreateOfferFormReset = () => {
    setLocalData('create-medpass-title', '', 1);
    setLocalData('create-medpass-subscriptionModel', '', 1);
    setLocalData('create-medpass-subscriptionType', '', 1);
    setLocalData('create-medpass-numOfSubscriptionTypes', '', 1);
    setLocalData('create-medpass-description', '', 1);
    setLocalData('create-medpass-amount', '', 1);
    setLocalData('create-medpass-offerAmount', '', 1);
    setLocalData('create-medpass-startDate', '', 1);
    setLocalData('create-medpass-endDate', '', 1);
  };

  render() {
    const {
      title,
      subscriptionModel,
      subscriptionType,
      numOfSubscriptionTypes,
      description,
      amount,
      offerAmount,
      startDate,
      endDate,
    } = this.state;

    const startDateNew = startDate !== '' ? moment(startDate, 'DD/MM/YYYY HH:mm:ss') : undefined;
    const endDateNew = endDate !== '' ? moment(endDate, 'DD/MM/YYYY HH:mm:ss') : undefined;

    return (
        <Row className="main-content-block" gutter={16}>
          <Col span={24}>
            <Form>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <div className="page-header">
                    <Link to={'/dashboard/medpass/'}>
                      <Icon type="arrow-left" title={'Back'}/>
                    </Link>
                    <Divider type="vertical"/> Create MedPass
                  </div>
                </Col>
                <Col xs={24} sm={12} className="text-right">
                  <Button
                      htmlType="button"
                      className="mc-mr-5"
                      onClick={this.handleCreateOffer}
                  >
                    Create
                  </Button>
                </Col>
                <Col span={24}>
                  <hr className="mc-mt-10"/>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} lg={15}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="Title">
                        <Input
                            size={'large'}
                            value={title}
                            onChange={this.handleChangeByEvent('title')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Description">
                        <TextArea
                            rows={7}
                            value={description}
                            onChange={this.handleChangeByEvent('description')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="Start Date">
                        <DatePicker
                            size={'large'}
                            className="width-fluid"
                            showTime
                            format="DD/MM/YYYY HH:mm:ss"
                            value={startDateNew}
                            onChange={this.handleDateChange('startDate')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="End Date">
                        <DatePicker
                            size={'large'}
                            className="width-fluid"
                            showTime
                            format="DD/MM/YYYY HH:mm:ss"
                            value={endDateNew}
                            onChange={this.handleDateChange('endDate')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={9}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="Subscription Type">
                        <Select
                            size={'large'}
                            value={subscriptionModel}
                            onChange={this.handleChangeByValue('subscriptionModel')}
                        >
                          <Option value={0}>Select Type</Option>
                          <Option value={1}>All</Option>
                          <Option value={2}>MVideos</Option>
                          <Option value={3}>Challenges & PrepMed</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Subscription Duration">
                        <Row gutter={8}>
                          <Col span={8}>
                            <Select
                                size={'large'}
                                value={subscriptionType}
                                onChange={this.handleChangeByValue('subscriptionType')}
                            >
                              <Option value={0}>Days</Option>
                              <Option value={1}>Months</Option>
                              <Option value={2}>Years</Option>
                              {/*<Option value={3}>Serum</Option>*/}
                            </Select>
                          </Col>
                          <Col span={16}>
                            <InputNumber
                                min={0}
                                value={numOfSubscriptionTypes}
                                className="width-fluid"
                                size={'large'}
                                onChange={this.handleChangeByValue(
                                    'numOfSubscriptionTypes')}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Price">
                        <InputNumber
                            min={0}
                            value={amount}
                            className="width-fluid"
                            size={'large'}
                            onChange={this.handleChangeByValue('amount')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Offer Price">
                        <InputNumber
                            min={0}
                            value={offerAmount}
                            className="width-fluid"
                            size={'large'}
                            onChange={this.handleChangeByValue('offerAmount')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    );
  }
}

export {CreateMedPass};
