import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Upload,
} from 'antd';
import {get, post} from '../../../components/mc_axios/McAxios';

const Dragger = Upload.Dragger;
const {Option} = Select;
const {TextArea} = Input;

class EditTestimonialPre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonialId: props.match.params.testimonialId,
      userPictureUrl: '',
      userPictureAltText: '',
      status: 1,
      createdAt: 0,
    };
  }

  componentDidMount() {
    this.getTestimonialData();
  }

  getTestimonialData = () => {
    get(`/api/coordinates/coordinates/testimonies/${this.state.testimonialId}`).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            const {name, message, user_info, testimony_page, picture, alt_text, updatedat, status} = response.data.data.testimony;
            this.props.form.setFieldsValue({
              user_name: name,
              user_message: message,
              user_info,
              display_page: testimony_page,
            });
            this.setState({
              userPictureUrl: picture || '',
              userPictureAltText: alt_text || '',
              status: status || 1,
              createdAt: updatedat,
            });
          }
        }).
        catch(() => message.error('Something went wrong'));
  };

  handleEditTestimonial = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {user_name, user_picture, user_message, user_info, display_page} = values;
        const {testimonialId, userPictureUrl, userPictureAltText, status, createdAt} = this.state;
        const pictureData = user_picture && user_picture.length > 0 ? user_picture[0].response.data : {file_url: userPictureUrl, alt_text: userPictureAltText};

        const data = {
          testimony_id: testimonialId,
          name: user_name,
          message: user_message,
          user_info,
          testimony_page: display_page,
          picture: pictureData.file_url,
          alt_text: pictureData.alt_text,
          status,
          createdat: createdAt,
        };

        post('/api/coordinates/coordinates/testimonies/update', data).
            then(response => {
              if (response.data.status === true && response.data.code === 200) {
                message.success('Testimonial is updated successfully');
                this.props.history.push('/dashboard/testimonials/');
              } else message.error('Something went wrong. Please try again');
            }).
            catch(
                () => message.error('Something went wrong. Please try again'));
      }
    });
  };

  removeImage = () => {
    this.setState({
      userPictureUrl: '',
      userPictureAltText: '',
    });
  };

  handleDeleteTestimonial = () => {
    post('/api/coordinates/coordinates/testimonies/delete',
        {testimony_id: this.state.testimonialId}).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            message.success('Testimonial has been deleted successfully');
            this.props.history.push('/dashboard/testimonials/');
          } else message.error('Something went wrong! Try again.');
        }).catch(() => message.error('Something went wrong! Try again.'));
  };

  publishTestimonial = () => {
    post('/api/coordinates/coordinates/testimonies/add_to_testimonies',
        {testimony_id: this.state.testimonialId}).
        then(response => {
          if (response.data.status === true && response.data.code === 200) {
            message.success('Testimonial published successfully');
            this.props.history.push('/dashboard/testimonials/');
          } else message.error('Something went wrong! Try again.');
        }).catch(() => message.error('Something went wrong! Try again.'));
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {userPictureUrl, userPictureAltText} = this.state;

    return (
        <Row className="main-content-block" gutter={16}>
          <Col span={24}>
            <Form onSubmit={this.handleEditTestimonial}>
              <Row gutter={32}>
                <Col xs={24} sm={12}>
                  <div className="page-header">
                    <Link to={'/dashboard/testimonials/'}>
                      <Icon type="arrow-left" title={'Back'}/>
                    </Link>
                    <Divider type="vertical"/> Edit Testimonial
                  </div>
                </Col>
                <Col xs={24} sm={12} className="text-right">
                  <Popconfirm
                      title="Are you sure delete this task?"
                      onConfirm={this.handleDeleteTestimonial}
                      okText="Yes"
                      cancelText="No"
                  >
                    <Button
                        htmlType="button"
                        className="mc-mr-5"
                        type={'danger'}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                  <Button
                      htmlType="submit"
                      className="mc-mr-5"
                  >
                    Update
                  </Button>
                  <Button
                      htmlType="button"
                      className="mc-mr-5"
                      onClick={this.publishTestimonial}
                  >
                    Publish
                  </Button>
                </Col>
                <Col span={24}>
                  <hr className="mc-mt-10"/>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} lg={12}>
                  <Row gutter={32}>
                    <Col xs={24} md={12}>
                      <Form.Item label="User Name">
                        {
                          getFieldDecorator('user_name', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input username!',
                              },
                            ],
                          })(
                              <Input
                                  placeholder={'Ex: Sudeep Kumar'}
                                  size={'large'}
                              />,
                          )
                        }
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="User Information">
                        {
                          getFieldDecorator('user_info')(
                              <Input
                                  placeholder={'Ex: GMC Final Year'}
                                  size={'large'}
                              />,
                          )
                        }
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Display">
                        {
                          getFieldDecorator('display_page', {
                            rules: [
                              {
                                required: true,
                                message: 'Please select pages!',
                              },
                            ],
                          })(
                              <Select
                                  size={'large'}
                                  placeholder="Select display page"
                              >
                                <Option value={1}>Home</Option>
                                <Option value={2}>MedPass</Option>
                              </Select>,
                          )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      {
                        userPictureUrl !== '' ? (
                            <Form.Item label="User Picture">
                              <img
                                  src={userPictureUrl}
                                  alt={userPictureAltText}
                                  width={300}
                              />
                              <br/>
                              <br/>
                              <Button type="primary" onClick={this.removeImage}>Remove
                                Image</Button>
                            </Form.Item>
                        ) : (
                            <Form.Item label="User Picture">
                              {
                                getFieldDecorator('user_picture', {
                                  valuePropName: 'fileList',
                                  getValueFromEvent: this.normFile,
                                })(
                                    <Dragger
                                        accept=".png, .jpg, .jpeg"
                                        action="/api/media/upload"
                                        data={{product_type: 7}}
                                        listType="picture"
                                    >
                                      <p className="ant-upload-drag-icon">
                                        <Icon type="inbox"/>
                                      </p>
                                      <p className="ant-upload-text">Click or
                                        drag
                                        file to this area to upload</p>
                                    </Dragger>,
                                )
                              }
                            </Form.Item>
                        )
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item label="Message">
                    {
                      getFieldDecorator('user_message', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter message!',
                          },
                        ],
                      })(<TextArea rows={11}/>)
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    );
  };
}

const EditTestimonial = withRouter(
    Form.create({name: 'edit_testimonial'})(EditTestimonialPre));

export {EditTestimonial};
