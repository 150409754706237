import {combineReducers} from 'redux';
// import MedChallengeReducer from "./medchallenge_reducer/MedChallengeReducer";
import EditMedChallenzReducer
  from './edit_medchallenge_reducer/EditMedChallengeReducer';

export default combineReducers({
  // challenge: MedChallengeReducer,
  //createChallenz: CreateMedChallenzReducer,
  editChallenz: EditMedChallenzReducer,
});
