import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
} from 'antd';
import {McCkEditor, SearchNotes} from '../../../components';
import {showNotePreview} from '../../../actions/notes_actions/PreviewNoteActions';
import {get, patch, post} from '../../../components/mc_axios/McAxios';

class EditMasterNoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterNoteId: this.props.match.params.masterNoteId,
      masterNoteTitle: '',
      masterNoteExplanation: '',
      masterNoteCreatedAt: 0,
      notesFieldCount: 1,
      notesFieldContent: [
        {
          note_id: '',
          title: '',
        },
      ],
    };

    this.removeAssignedNote = this.removeAssignedNote.bind(this);
  }

  componentDidMount() {
    this.getMasterNoteDetails();
  }

  getMasterNoteDetails = () => {
    get('/api/master-notes/master-notes/' + this.state.masterNoteId).then(
        response => {
          if (response.data.status === true) {
            let masterNoteData = response.data.data.master_note;

            this.setState({
              masterNoteTitle: masterNoteData.master_title,
              masterNoteIntroduction: masterNoteData.introduction,
              masterNoteCreatedAt: masterNoteData.created_at,
              notesFieldCount: masterNoteData.notes.length,
              notesFieldContent: masterNoteData.notes,
            });
          } else {
            message.error('Something went wrong.');
          }
        },
    );
  };

  getTagList = value => {
    this.setState({searchedTags: [], fetching: true});
    post('/api/coordinates/coordinates/tags', {tag: value}).then(response => {
      if (response.data.status === true) {
        if (response.data.data.tags !== undefined) {
          const tagsList = response.data.data.tags.map(tag => ({
            text: tag,
            value: tag,
          }));
          this.setState({searchedTags: tagsList, fetching: false});
        }
      }
    });
  };

  handleNoteTitleChange = (e, index) => {
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent[index].title = e.target.value;

    this.setState({
      notesFieldContent,
    });
  };

  handleNotesChange = (value, option, index) => {
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent[index].note_id = option.props['note-id'];

    this.setState({
      notesFieldContent,
    });
  };

  removeAssignedNote = index => {
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent[index].note_id = '';

    this.setState({
      notesFieldContent,
    });
  };

  buildNoteFields = () => {
    const {notesFieldContent, notesFieldCount} = this.state;
    const {showNotePreview} = this.props;
    let children = [];
    for (let i = 0; notesFieldCount > i; i++) {
      children.push(
          <Row gutter={16} key={i} className={'mc-mb-10'}>
            <Col span={10}>
              <Input
                  placeholder={'Title'}
                  size={'large'}
                  value={notesFieldContent[i].title}
                  onChange={e => this.handleNoteTitleChange(e, i)}
              />
            </Col>
            <Col span={14}>
              {notesFieldContent[i].note_id === '' ? (
                  <SearchNotes
                      fieldIndex={i}
                      onSearchNotesChange={this.handleNotesChange}
                  />
              ) : (
                  <>
                    <Button
                        type={'dashed'}
                        size="large"
                        className={'mc-mr-10'}
                        htmlType={'button'}
                        onClick={() =>
                            showNotePreview(`${notesFieldContent[i].note_id}`)
                        }
                    >
                      View Note
                    </Button>
                    <Button
                        type={'danger'}
                        size="large"
                        htmlType={'button'}
                        onClick={() => this.removeAssignedNote(i)}
                    >
                      Remove Note
                    </Button>
                  </>
              )}
            </Col>
          </Row>,
      );
    }
    return children;
  };

  addNotesField = () => {
    let newNoteFieldsCount = this.state.notesFieldCount + 1;
    let notesFieldContent = this.state.notesFieldContent;
    notesFieldContent.push({
      title: '',
      note_id: '',
    });

    this.setState({
      notesFieldCount: newNoteFieldsCount,
      notesFieldContent,
    });
  };

  removeNotesField = () => {
    let newNoteFieldsCount = this.state.notesFieldCount - 1;
    let notesFieldContent = this.state.notesFieldContent.slice(0, -1);

    this.setState({
      notesFieldCount: newNoteFieldsCount,
      notesFieldContent,
    });
  };

  handleMasterTitleChange = e => {
    let title = e.target.value;
    this.setState({
      masterNoteTitle: title,
    });
  };

  handleMasterIntroductionChange = evt => {
    let introduction = evt.editor.getData();
    this.setState({
      masterNoteIntroduction: introduction,
    });
  };

  handleSubmit = saveType => {
    const {
      masterNoteId,
      masterNoteTitle,
      masterNoteIntroduction,
      masterNoteCreatedAt,
      notesFieldContent,
    } = this.state;

    let data = {
      master_note_id: masterNoteId,
      master_title: masterNoteTitle,
      introduction: masterNoteIntroduction,
      created_at: masterNoteCreatedAt,
      status: saveType,
      notes: notesFieldContent,
    };

    post('/api/master-notes/master-notes/update', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        localStorage.clear();
        message.success('Master Note has been updated');
        this.props.history.push('/dashboard/master-notes/');
      } else {
        message.error('Something went wrong.');
      }
    });
  };

  confirmDelete = () => {
    patch('/api/master-notes/master-notes/delete', {
      master_note_id: this.state.masterNoteId,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Master Note deleted successfully');
        this.props.history.push('/dashboard/master-notes');
      } else {
        message.error('Something went wrong. Try again.');
      }
    });
  };

  render() {
    const {masterNoteTitle, masterNoteIntroduction} = this.state;
    const ButtonGroup = Button.Group;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/master-notes/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Master Note
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  onCancel={() => message.error('You have canceled the delete')}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Update
              </Button>
              <Button
                  type={'primary'}
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
              >
                Update & Verify
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    <Input
                        placeholder="Enter title"
                        size={'large'}
                        value={masterNoteTitle}
                        onChange={this.handleMasterTitleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Introduction">
                    <McCkEditor
                        content={masterNoteIntroduction}
                        onMcEditorChange={evt =>
                            this.handleMasterIntroductionChange(evt)
                        }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <p>Add Notes :</p>
                    </Col>
                    <Col span={18} className="text-right">
                      <ButtonGroup size="small">
                        <Button htmlType="button" onClick={this.addNotesField}>
                          +
                        </Button>
                        <Button htmlType="button"
                                onClick={this.removeNotesField}>
                          -
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  {this.buildNoteFields()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const EditMasterNote = withRouter(connect(
    null,
    {showNotePreview},
)(Form.create({name: 'edit_master_note'})(EditMasterNoteForm)));

export {EditMasterNote};
