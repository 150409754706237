import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import {get, post} from '../../../components/mc_axios/McAxios';

const Option = Select.Option;
const {TextArea} = Input;

class EditMedPassPre extends React.Component {
  handlers = {};
  state = {
    title: '',
    subscriptionModel: 0,
    subscriptionType: 0,
    numOfSubscriptionTypes: 0,
    description: '',
    amount: 0,
    offerAmount: 0,
    startDate: '',
    endDate: '',
    createdAt: 0,
    appleProductId: '',
    applePrice: 0,
  };

  componentDidMount() {
    this.getMedPassDetails();
  }

  getMedPassDetails = () => {
    const {medPassId} = this.props.match.params;
    get('/api/medpasses/medpasses/' + medPassId).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const {
          title = '',
          description = '',
          subscription_model: subscriptionModel = 0,
          subscription_type: subscriptionType = 0,
          NumberOfSubscriptionType: numOfSubscriptionTypes = 0,
          amount = 0,
          offer_amount: offerAmount = 0,
          offer_start,
          offer_valid,
          created_at: createdAt = 0,
          apple_product_price: applePrice = 0,
          apple_product_id: appleProductId = '',
        } = response.data.data;

        this.setState({
          title,
          subscriptionModel,
          subscriptionType,
          numOfSubscriptionTypes,
          description,
          amount,
          offerAmount,
          startDate: offer_start ? moment.unix(offer_start).format('DD/MM/YYYY HH:mm:ss') : "",
          endDate: offer_valid ? moment.unix(offer_valid).format('DD/MM/YYYY HH:mm:ss') : "",
          createdAt,
          applePrice,
          appleProductId,
        });
      } else message.error('Something went wrong! Try again.');
    });
  };

  // New methods
  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const {value} = event.target;
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleDateChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = (date, dateString) => {
        this.setState({
          [name]: dateString,
        });
      };
    }
    return this.handlers[name];
  };

  handleEditMedPass = status => {
    if (this.state.title !== '') {
      const {
        title,
        subscriptionModel,
        subscriptionType,
        numOfSubscriptionTypes,
        description,
        amount,
        offerAmount,
        startDate,
        endDate,
        createdAt,
        appleProductId,
        applePrice,
      } = this.state;
      const {medPassId} = this.props.match.params;

      const data = {
        id: medPassId,
        title,
        description,
        offer_start: moment(startDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        offer_valid: moment(endDate, 'DD/MM/YYYY HH:mm:ss').unix(),
        subscription_model: subscriptionModel,
        subscription_type: subscriptionType,
        number_of_subscription_type: numOfSubscriptionTypes,
        amount,
        offer_amount: offerAmount,
        status,
        created_at: createdAt,
        apple_product_id: appleProductId,
        apple_product_price: applePrice,
      };

      post('/api/medpasses/medpasses/update', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          message.success('MedPass has been updated');
          this.props.history.push('/dashboard/medpass/');
        } else message.error('Something went wrong! Try again.');
      });
    } else message.warning('Please enter title');
  };

  render() {
    const {
      title,
      subscriptionModel,
      subscriptionType,
      numOfSubscriptionTypes,
      description,
      amount,
      offerAmount,
      startDate,
      endDate,
      applePrice,
      appleProductId,
    } = this.state;

    const startDateNew = startDate !== '' ?
        moment(startDate, 'DD/MM/YYYY HH:mm:ss') :
        undefined;
    const endDateNew = endDate !== '' ?
        moment(endDate, 'DD/MM/YYYY HH:mm:ss') :
        undefined;

    return (
        <Row className="main-content-block" gutter={16}>
          <Col span={24}>
            <Form>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <div className="page-header">
                    <Link to={'/dashboard/medpass/'}>
                      <Icon type="arrow-left" title={'Back'}/>
                    </Link>
                    <Divider type="vertical"/> Update MedPass
                  </div>
                </Col>
                <Col xs={24} sm={12} className="text-right">
                  <Button
                      htmlType="button"
                      onClick={() => this.handleEditMedPass(1)}
                      className="mc-mr-5"
                  >
                    Update
                  </Button>
                  <Button
                      htmlType="button"
                      onClick={() => this.handleEditMedPass(2)}
                      className="mc-mr-5"
                  >
                    Publish
                  </Button>
                </Col>
                <Col span={24}>
                  <hr className="mc-mt-10"/>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} lg={15}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="Title">
                        <Input
                            size={'large'}
                            value={title}
                            onChange={this.handleChangeByEvent('title')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Description">
                        <TextArea
                            rows={7}
                            value={description}
                            onChange={this.handleChangeByEvent('description')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="Start Date">
                        <DatePicker
                            size={'large'}
                            className="width-fluid"
                            showTime
                            format={'DD/MM/YYYY HH:mm:ss'}
                            value={startDateNew}
                            onChange={this.handleDateChange('startDate')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="End Date">
                        <DatePicker
                            size={'large'}
                            className="width-fluid"
                            showTime
                            format={'DD/MM/YYYY HH:mm:ss'}
                            value={endDateNew}
                            onChange={this.handleDateChange('endDate')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={9}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="Subscription Type">
                        <Select
                            size={'large'}
                            value={subscriptionModel}
                            onChange={this.handleChangeByValue(
                                'subscriptionModel')}
                            disabled
                        >
                          <Option value={0}>Select Type</Option>
                          <Option value={1}>All</Option>
                          <Option value={2}>MVideos</Option>
                          <Option value={3}>Challenges & PrepMed</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Subscription Duration">
                        <Row gutter={8}>
                          <Col span={8}>
                            <Select
                                size={'large'}
                                value={subscriptionType}
                                onChange={this.handleChangeByValue('subscriptionType')}
                            >
                              <Option value={0}>Days</Option>
                              <Option value={1}>Months</Option>
                              <Option value={2}>Years</Option>
                              {/*<Option value={3}>Serum</Option>*/}
                            </Select>
                          </Col>
                          <Col span={16}>
                            <InputNumber
                                min={0}
                                value={numOfSubscriptionTypes}
                                className="width-fluid"
                                size={'large'}
                                onChange={this.handleChangeByValue(
                                    'numOfSubscriptionTypes')}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="Price">
                        <InputNumber
                            min={0}
                            value={amount}
                            className="width-fluid"
                            size={'large'}
                            onChange={this.handleChangeByValue('amount')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="Offer Price">
                        <InputNumber
                            min={0}
                            value={offerAmount}
                            className="width-fluid"
                            size={'large'}
                            onChange={this.handleChangeByValue('offerAmount')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Apple Product Id">
                        <Input
                            value={appleProductId}
                            size={'large'}
                            onChange={this.handleChangeByEvent(
                                'appleProductId')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Apple Product Price">
                        <InputNumber
                            min={0}
                            value={applePrice}
                            className="width-fluid"
                            size={'large'}
                            onChange={this.handleChangeByValue('applePrice')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    );
  }
}

const EditMedPass = withRouter(EditMedPassPre);

export {EditMedPass};
