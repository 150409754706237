import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import Tooltip from 'antd/es/tooltip';
import ReactEcharts from 'echarts-for-react';
import {isMobile} from 'react-device-detect';
import './ChallengesComparativeAnalysisCard.css';

const ChallengesComparativeAnalysisCard = ({data}) => {

  if (!data) return null;

  const {topperAnalysis, userAnalysis} = data;

  let lastFiveChallengesNames = new Array(7).fill('');
  let lastFiveChallengesUserScore = new Array(7).fill(0);
  let lastFiveChallengesTopperScore = new Array(7).fill(0);

  userAnalysis && userAnalysis.forEach((data, index) => {
    const {name, percentage} = data;
    lastFiveChallengesNames[index] = name.split(' ')[0];
    lastFiveChallengesUserScore[index] = percentage > 0 ? Math.round(percentage) : 0;
  });

  topperAnalysis && topperAnalysis.forEach((data, index) => {
    const {percentage} = data;
    lastFiveChallengesTopperScore[index] = percentage > 0 ? Math.round(percentage) : 0;
  });

  let count = 0;

  lastFiveChallengesNames.forEach(data => data === '' && count++);

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} : {c}%({b})',
    },
    legend: {
      show: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: 2,
      axisLabel: {
        show: true,
        rotate: 25,
      },
      data: isMobile ?
          lastFiveChallengesNames.slice(0, 3) :
          lastFiveChallengesNames,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%',
      },
    },
    series: [
      {
        name: 'Topper',
        type: 'line',
        lineStyle: {
          width: 4,
        },
        data: isMobile ?
            lastFiveChallengesTopperScore.slice(0, Math.min(7 - count, 3)) :
            lastFiveChallengesTopperScore.slice(0, 7 - count),
        itemStyle: {
          color: '#D052EE',
        },
      },
      {
        name: 'You',
        type: 'line',
        lineStyle: {
          width: 4,
        },
        data: isMobile ?
            lastFiveChallengesUserScore.slice(0, Math.min(7 - count, 3)) :
            lastFiveChallengesUserScore.slice(0, 7 - count),
        itemStyle: {
          color: '#00B4DB',
        },
      },
    ],
  };

  return (
      <div className="ca-card">
        <Row>
          <Col span={24}>
            <h3 className="mc-mb-30">Comparative Analysis</h3>
            <Tooltip title={'In comparative analysis, your last seven challenge performances (Percentage scores) are compared with the topper of each challenge.'}>
              <Icon className="info-icon" type="info-circle"/>
            </Tooltip>
          </Col>
          <Col span={24}>
            <ReactEcharts option={option}/>
          </Col>
        </Row>
      </div>
  );
};

export {ChallengesComparativeAnalysisCard};
