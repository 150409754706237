import React from 'react';
import '../UserMedBook.css';

const UserWalletCard = ({data}) => {

  const {serum = 0, cash = 0} = data;

  return (
      <div className="wallet-card lite-gray-bg">
        <div>
          <p>Serum</p>
          <p>{serum}</p>
        </div>
        <div>
          <p>Money</p>
          <p>{cash}</p>
        </div>
      </div>
  );
};

export {UserWalletCard};
