import React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Upload,
} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {McCkEditor} from '../../../components';
import {
  getPWChallengeData,
  handleEditPWChallengeFieldChanges,
  handleEditPWChallengeFormReset,
} from '../../../actions/pw_challenge_actions/EditPWChallengeActions';
import {patch, post} from '../../../components/mc_axios/McAxios';

class EditPWChallengeForm extends React.Component {
  handlers = {};

  componentDidMount() {
    const {match: {params: {challengeId}}, getPWChallengeData} = this.props;
    getPWChallengeData(challengeId);
  }

  componentWillUnmount() {
    this.props.handleEditPWChallengeFormReset();
  }

  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.props.handleEditPWChallengeFieldChanges(name, event.target.value);
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        this.props.handleEditPWChallengeFieldChanges(name, value);
      };
    }
    return this.handlers[name];
  };

  handleChangeByDateValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = (moment, dateString) => {
        this.props.handleEditPWChallengeFieldChanges(name, dateString);
      };
    }
    return this.handlers[name];
  };

  handleEditorChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const data = event.editor.getData();
        this.props.handleEditPWChallengeFieldChanges(name, data);
      };
    }
    return this.handlers[name];
  };

  handleMediaUpload = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = info => {
        if (info.file !== undefined && info.file.status === 'done' &&
            info.file.response.status === true) {
          const {handleEditPWChallengeFieldChanges} = this.props;
          const {file_url, alt_text} = info.file.response.data;
          message.success('file uploaded successfully');
          if (name === 'featuredImageUrl') {
            handleEditPWChallengeFieldChanges('featured-image', {
              imgUrl: file_url,
              imgAltText: alt_text,
            });
          } else if (name === 'thumbnailImageUrl') {
            handleEditPWChallengeFieldChanges('thumbnail-image', {
              imgUrl: file_url,
              imgAltText: alt_text,
            });
          } else handleEditPWChallengeFieldChanges('sponsor-media', file_url);
        } else if (info.file !== undefined && info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      };
    }
    return this.handlers[name];
  };

  handleMediaRemoval = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = () => {
        const {handleEditPWChallengeFieldChanges} = this.props;
        if (name === 'featuredImageUrl') {
          handleEditPWChallengeFieldChanges('remove-featured-image', '');
        } else if (name === 'thumbnailImageUrl') {
          handleEditPWChallengeFieldChanges('remove-thumbnail-image', '');
        } else handleEditPWChallengeFieldChanges('remove-sponsor-media', '');
      };
    }
    return this.handlers[name];
  };

  handleSubmit = (currStatus) => {
    if (this.props.editPWChallenge.title !== '') {
      const {editPWChallenge, handleEditPWChallengeFormReset, history} = this.props;
      let data = {
        play_id: editPWChallenge.id,
        play_name: editPWChallenge.title,
        status: currStatus === 2 ? editPWChallenge.status : currStatus,
        number_of_questions: parseInt(editPWChallenge.totalQuestions),
        start_time: moment(editPWChallenge.startDate, 'DD/MM/YYYY HH:mm:ss').
            unix(),
        description: editPWChallenge.description,
        featured_image_url: editPWChallenge.featuredImageUrl,
        featured_image_alt_text: editPWChallenge.featuredImageAltText,
        featured_image_link: editPWChallenge.featuredImageLink,
        thumbnail_image_url: editPWChallenge.thumbnailImageUrl,
        thumbnail_image_alt_text: editPWChallenge.thumbnailImageAltText,
        sponsored_add: editPWChallenge.sponsorAdMediaUrl,
        sponsor_link: editPWChallenge.sponsorAdLink,
        reward_amount: editPWChallenge.rewardAmount,
        question_ids: editPWChallenge.questionIds,
        meta_title: editPWChallenge.seoMetaTitle,
        slug: editPWChallenge.seoSlug,
        meta_description: editPWChallenge.seoMetaDescription,
        meta_keywords: editPWChallenge.seoMetaKeywords,
        created_at: editPWChallenge.createdAt,
        updated_at: editPWChallenge.updatedAt,
      };

      post('/api/challenzes/plays/update', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          if (currStatus === 2) {
            this.publishTest(editPWChallenge.id);
          } else {
            handleEditPWChallengeFormReset();
            message.success('Challenge has been updated');
            history.push(
                `/dashboard/play-and-win-challenges/add-questions/${response.data.data.play_id}`);
          }
        } else {
          message.error('Something went wrong! Try again.');
        }
      });
    } else message.warning('Please enter title');
  };

  publishTest = id => {
    post('/api/challenzes/plays/publish', {play_id: id}).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        handleEditPWChallengeFormReset();
        message.success('Challenge has been published');
        this.props.history.push(
            `/dashboard/play-and-win-challenges/add-questions/${id}`);
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  confirmDelete = () => {
    patch('/api/challenzes/play/delete', {
      play_id: this.props.editPWChallenge.id,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Challenge deleted successfully');
        this.props.history.push('/dashboard/play-and-win-challenges/');
      } else message.error('Something went wrong. Try again.');
    });
  };

  render() {
    const {TextArea} = Input;
    const Dragger = Upload.Dragger;
    const {title, totalQuestions, startDate, description, featuredImageUrl, featuredImageAltText, featuredImageLink, thumbnailImageUrl, thumbnailImageAltText, sponsorAdMediaUrl, sponsorAdLink, rewardAmount, seoMetaTitle, seoSlug, seoMetaDescription, seoMetaKeywords} = this.props.editPWChallenge;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/play-and-win-challenges/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Challenge
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  onCancel={() => message.error('You have canceled the delete')}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Update
              </Button>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
                  className="mc-mr-5"
              >
                Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    <Input
                        placeholder="Enter title"
                        size={'large'}
                        value={title}
                        onChange={this.handleChangeByEvent('title')}
                        className="mc-mb-15"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Start Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        showTime
                        format="DD/MM/YYYY HH:mm:ss"
                        value={startDate !== '' ?
                            moment(startDate, 'DD/MM/YYYY HH:mm:ss') :
                            undefined}
                        onChange={this.handleChangeByDateValue('start-date')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Total Questions">
                    <InputNumber
                        min={0}
                        value={totalQuestions}
                        onChange={this.handleChangeByValue('total-questions')}
                        className="width-fluid"
                        size={'large'}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={this.handleEditorChange(
                            'description')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {
                    thumbnailImageUrl === '' ? (
                        <Form.Item label="Thumbnail Image">
                          <Dragger
                              name="file"
                              accept=".png, .jpg, .jpeg"
                              action="/api/media/upload"
                              data={{product_type: 4}}
                              listType="picture"
                              onChange={this.handleMediaUpload(
                                  'thumbnailImageUrl')}
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox"/>
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                        </Form.Item>
                    ) : (
                        <Row className={'mc-mb-10'}>
                          <Col span={24}>
                            <p>Thumbnail Image:</p>
                            <img
                                src={thumbnailImageUrl}
                                alt={thumbnailImageAltText}
                                style={{width: '100%', maxWidth: 400}}
                            />
                          </Col>
                          <Col span={24}>
                            <Button
                                className={'mc-mt-10'}
                                htmlType={'button'}
                                type={'primary'}
                                size={'small'}
                                onClick={this.handleMediaRemoval(
                                    'thumbnailImageUrl')}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                    )
                  }
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      {
                        featuredImageUrl === '' ? (
                            <Form.Item label="Featured Image">
                              <Dragger
                                  name="file"
                                  accept=".png, .jpg, .jpeg"
                                  action="/api/media/upload"
                                  data={{product_type: 4}}
                                  listType="picture"
                                  onChange={this.handleMediaUpload(
                                      'featuredImageUrl')}
                              >
                                <p className="ant-upload-drag-icon">
                                  <Icon type="inbox"/>
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                              </Dragger>
                            </Form.Item>
                        ) : (
                            <Row className={'mc-mb-10'}>
                              <Col span={24}>
                                <p>Featured Image:</p>
                                <img
                                    src={featuredImageUrl}
                                    alt={featuredImageAltText}
                                    style={{width: '100%', maxWidth: 400}}
                                />
                              </Col>
                              <Col span={24}>
                                <Button
                                    className={'mc-mt-10'}
                                    htmlType={'button'}
                                    type={'primary'}
                                    size={'small'}
                                    onClick={this.handleMediaRemoval(
                                        'featuredImageUrl')}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                        )
                      }
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Featured Image Link">
                        <Input
                            placeholder="Enter link"
                            size={'large'}
                            value={featuredImageLink}
                            className="mc-mb-15"
                            onChange={this.handleChangeByEvent(
                                'featured-image-link')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <div className={'seo-section'}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <h3>Sponsor</h3>
                        <hr/>
                      </Col>
                      <Col span={24}>
                        {
                          sponsorAdMediaUrl === '' ? (
                              <Form.Item label="Sponsor Ad">
                                <Dragger
                                    name="file"
                                    accept=".png, .jpg, .jpeg"
                                    action="/api/media/upload"
                                    data={{product_type: 4}}
                                    listType="picture"
                                    onChange={this.handleMediaUpload(
                                        'sponsorAdMediaUrl')}
                                >
                                  <p className="ant-upload-drag-icon">
                                    <Icon type="inbox"/>
                                  </p>
                                  <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                  </p>
                                </Dragger>
                              </Form.Item>
                          ) : (
                              <Row className={'mc-mb-10'}>
                                <Col span={24}>
                                  <p>Sponsor Ad:</p>
                                  <img
                                      src={sponsorAdMediaUrl}
                                      alt={'Sponsor'}
                                      style={{width: '100%', maxWidth: 400}}
                                  />
                                </Col>
                                <Col span={24}>
                                  <Button
                                      className={'mc-mt-10'}
                                      htmlType={'button'}
                                      type={'primary'}
                                      size={'small'}
                                      onClick={this.handleMediaRemoval(
                                          'sponsorAdMediaUrl')}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                          )
                        }
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Sponsor Link">
                          <Input
                              placeholder="Enter link"
                              size={'large'}
                              className="mc-mb-15"
                              value={sponsorAdLink}
                              onChange={this.handleChangeByEvent(
                                  'sponsor-link')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Reward Amount">
                          <InputNumber
                              min={0}
                              className="width-fluid"
                              size={'large'}
                              value={rewardAmount}
                              onChange={this.handleChangeByValue(
                                  'reward-amount')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <div className={'seo-section'}>
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      <Input
                          size={'large'}
                          value={seoMetaTitle}
                          onChange={this.handleChangeByEvent('seo-meta-title')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      <Input
                          size={'large'}
                          value={seoSlug}
                          onChange={this.handleChangeByEvent('seo-meta-slug')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      <TextArea
                          rows={4}
                          value={seoMetaDescription}
                          onChange={this.handleChangeByEvent(
                              'seo-meta-description')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      <Select
                          mode="tags"
                          size={'large'}
                          value={seoMetaKeywords}
                          onChange={this.handleChangeByValue(
                              'seo-meta-keywords')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return state.pwchallenge;
};

const EditPWChallenge = withRouter(
    connect(
        mapStateToProps,
        {
          getPWChallengeData,
          handleEditPWChallengeFieldChanges,
          handleEditPWChallengeFormReset,
        },
    )(Form.create({name: 'edit_pw_challenge'})(EditPWChallengeForm)),
);

export {EditPWChallenge};
