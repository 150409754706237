import React from 'react';
import {connect} from 'react-redux';
import {Col, Modal, Row, Tag} from 'antd';
import {createMarkup} from '../util';
import {resetQuestionData} from '../../actions/qbank_actions/PreviewQuestionActions';
import './PreviewQuestion.css';

function PreviewQuestionPre(props) {
  const {previewQuestion: {visible, title, media, basis, options, assignedTests, assignedChallenges, assignedArenaChallenges, correctAnswer, explanation, explanationVideoUrl, reference}, resetQuestionData} = props;

  return (
      <Modal
          title="Preview Question"
          centered
          width={800}
          visible={visible}
          footer={null}
          onCancel={() => resetQuestionData()}
          className="preview-question-modal"
      >
        <div className="watermark-layer"/>
        <Row>
          <Col span={24}>
            <h4>
              <u>Question:</u>
            </h4>
            <div
                dangerouslySetInnerHTML={createMarkup(title)}
            />
          </Col>
          {
            (media !== undefined && media.length > 0 && basis !== 1) && (
                <Col span={24} className="media-list">
                  {
                    media.map((data, index) => {
                      let html = '';
                      switch (basis) {
                        case 2:
                          html = <img src={data} key={index} alt=""/>;
                          break;
                        case 3:
                          html = <audio src={data} controls key={index}/>;
                          break;
                        case 4:
                          html = <video src={data} controls key={index}/>;
                          break;
                        default:
                          break;
                      }
                      return html;
                    })
                  }
                </Col>
            )
          }
          <Col span={24}>
            <h4 className="mc-mr-10">
              <u>Options:</u>
            </h4>
            {
              options.map((option, index) => (
                  <Row key={index}>
                    <Col span={1}>{index + 1}.</Col>
                    <Col span={23}>
                      <div dangerouslySetInnerHTML={createMarkup(option)}/>
                    </Col>
                  </Row>
              ))
            }
          </Col>
          <Col span={24}>
            <h4>
              <u>Answer:</u>
            </h4>
            {
              correctAnswer.map((answer, index) => (
                  <Row key={index}>
                    <Col span={1}>{answer}.</Col>
                    <Col span={23}>
                      <div
                          dangerouslySetInnerHTML={createMarkup(
                              options[answer - 1])}/>
                    </Col>
                  </Row>
              ))
            }
          </Col>
          {
            explanation !== undefined && (
                <Col span={24}>
                  <h4>
                    <u>Explanation:</u>
                  </h4>
                  <div
                      dangerouslySetInnerHTML={createMarkup(
                          explanation,
                      )}
                  />
                </Col>
            )
          }
          {
            explanationVideoUrl !== undefined && (
                <Col span={24}>
                  <h4>
                    <u>Explanation Video:</u>
                  </h4>
                  <video
                      controls
                      src={explanationVideoUrl}
                      style={{width: '100%'}}
                  />
                </Col>
            )
          }
          {
            reference !== undefined && (
                <Col span={24}>
                  <hr/>
                  <h4>Reference:</h4>
                  <div dangerouslySetInnerHTML={createMarkup(reference)}/>
                </Col>
            )
          }
          {
            assignedChallenges.length > 0 && (
                <Col span={24}>
                  <hr/>
                  <h4>Assigned Challenges:</h4>
                  {
                    assignedChallenges.map(challenge => <Tag
                            key={challenge.challenz_id}
                        >
                          {challenge.challenz_title}
                        </Tag>,
                    )
                  }
                </Col>
            )
          }
          {
            assignedTests.length > 0 && (
                <Col span={24}>
                  <hr/>
                  <h4>Assigned Tests:</h4>
                  {
                    assignedTests.map(test => <Tag
                            key={test.test_id}
                        >
                          {test.test_title}
                        </Tag>,
                    )
                  }
                </Col>
            )
          }
          {
            assignedArenaChallenges.length > 0 && (
                <Col span={24}>
                  <hr/>
                  <h4>Assigned Arena Challenges:</h4>
                  {
                    assignedArenaChallenges.map(challenge => <Tag
                            key={challenge.play_id}
                        >
                          {challenge.play_name}
                        </Tag>,
                    )
                  }
                </Col>
            )
          }
        </Row>
      </Modal>
  );
}

const mapStateToProps = state => {
  return state.qbank;
};

const PreviewQuestion = connect(mapStateToProps, {resetQuestionData})(
    PreviewQuestionPre);

export {PreviewQuestion};
