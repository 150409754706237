import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Col, Icon, Row} from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {handleEditPWChallengeFieldChanges} from '../../actions/pw_challenge_actions/EditPWChallengeActions';
import {showQuestionPreview} from '../../actions/qbank_actions/PreviewQuestionActions';
import {createMarkup, redirectToEditQuestionPage} from '../util';
import './SortableQuestions.css';

class SortablePWChallengeQuestionsPre extends React.Component {
  onSortEnd = ({oldIndex, newIndex}) => {
    const {editPWChallenge, handleEditPWChallengeFieldChanges} = this.props;
    let sortedQuestionsList = arrayMove(
        editPWChallenge.questionsList,
        oldIndex,
        newIndex,
    );
    let sortedQuestionIds = arrayMove(
        editPWChallenge.questionIds,
        oldIndex,
        newIndex,
    );
    handleEditPWChallengeFieldChanges('sort-questions-list', {
      questionsList: sortedQuestionsList,
      questionIds: sortedQuestionIds,
    });
  };

  render() {
    const {editPWChallenge, showQuestionPreview, handleEditPWChallengeFieldChanges} = this.props;
    const DragHandle = sortableHandle(({position}) => <td width={100}>
      <span><Icon className={'mc-mr-5'} type="menu"/> {position + 1}</span>
    </td>);
    const SortableItem = sortableElement(({value, position}) => <tr>
      <DragHandle position={position}/>
      <td onClick={() => redirectToEditQuestionPage(value.question_id)}>
        <div dangerouslySetInnerHTML={createMarkup(value.title)}/>
      </td>
      <td>
        {
          value.status === 2 ? <Icon
              type="check-circle"
              title={'Verified'}
              theme="filled"
              className="mc-icon active"
          /> : <Icon
              type="check-circle"
              title={'Not Verified'}
              className="mc-icon"
          />
        }
      </td>
      <td width={120}>
        <Icon
            type="eye"
            className="mc-icon mc-mr-10"
            title="Preview"
            onClick={() => showQuestionPreview(`${value.question_id}`)}
        />
        <Icon
            type="delete"
            className="mc-icon"
            title="Remove"
            onClick={() => handleEditPWChallengeFieldChanges('remove-question',
                position)}
        />
      </td>
    </tr>);

    const SortableContainer = sortableContainer(({children}) => {
      return (
          <div className="mc-table-responsive">
            <table className="mc-table">
              <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Verified</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
      );
    });

    return (
        <Row>
          <Col span={24}>
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
              {
                editPWChallenge.questionsList.map(
                    (value, index) => <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                        position={index}
                    />)
              }
            </SortableContainer>
          </Col>
        </Row>
    );
  }
}

const mapStateToProps = state => {
  return state.pwchallenge;
};

const SortablePWChallengeQuestions = withRouter(connect(mapStateToProps, {
      handleEditPWChallengeFieldChanges,
      showQuestionPreview,
    })(SortablePWChallengeQuestionsPre),
);

export {SortablePWChallengeQuestions};
