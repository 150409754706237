import React from 'react';
import {Button, Col, Divider, Form, Icon, Input, message, Row} from 'antd';
import {get, post} from '../../../components/mc_axios/McAxios';
import {withRouter} from 'react-router';
import moment from 'moment';
import {Link} from 'react-router-dom';

const TextArea = Input.TextArea;

class ContactDetailedPre extends React.Component {

  state = {
    contactData: null,
    reply: '',
    subject: '',
  };

  componentDidMount() {
    this.getContactData();
  }

  getContactData = () => {
    const {contactId} = this.props.match.params;
    get('/api/support/contact/' + contactId).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        this.setState({
          contactData: resData.contact,
        });
      } else message.error('Something went wrong! Try again.');
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  handleReplyChange = e => {
    const reply = e.target.value;
    this.setState({
      reply,
    });
  };

  handleSubjectChange = e => {
    const subject = e.target.value;
    this.setState({
      subject,
    });
  };

  sendReply = () => {
    const {reply, subject, contactData: {contact_id}} = this.state;
    if (reply !== '' && subject !== '') {
      const data = {
        contact_id,
        message: reply,
        subject,
      };

      post('/api/support/contact/reply_message', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          message.success('Message sent successfully');
        } else message.error('Something went wrong! Try again.');
      }).catch(() => message.error('Something went wrong! Try again.'));
    } else message.error('Subject and Reply message are mandatory.');
  };

  markAsCompleted = () => {
    const {contact_id} = this.state.contactData;
    const data = {
      contact_id,
    };
    post('/api/support/contact/mark_as_completed', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Status changed successfully');
      } else message.error('Something went wrong! Try again.');
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  goToQuestion = () => {
    const questionId = this.state.contactData.go_to_url;
    window.open('/dashboard/qbank/edit-question/' + questionId, '_blank');
  };

  render() {
    const {contactData, subject, reply} = this.state;

    if (contactData === null) return null;

    const {email, stored, message, go_to_url} = contactData;

    return (
        <Row className="main-content-block" gutter={16}>
          <Col span={24}>
            <Form>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <div className="page-header">
                    <Link to={'/dashboard/contacts/'}>
                      <Icon type="arrow-left" title={'Back'}/>
                    </Link>
                    <Divider type="vertical"/> Contact
                  </div>
                </Col>
                <Col xs={24} sm={12} className="text-right">
                  {
                    go_to_url && (
                        <Button
                            htmlType="button"
                            className="mc-mr-5"
                            onClick={this.goToQuestion}
                        >Go to question</Button>
                    )
                  }
                  <Button
                      htmlType="button"
                      className="mc-mr-5"
                      onClick={this.markAsCompleted}
                  >Mark as Completed</Button>
                  <Button
                      htmlType="button"
                      onClick={this.sendReply}
                  >Send</Button>
                </Col>
                <Col span={24}>
                  <hr className="mc-mt-10"/>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Email Id">
                    <Input
                        placeholder="Email"
                        size="large"
                        value={email}
                        disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Date">
                    <Input
                        placeholder="Date"
                        size="large"
                        value={moment.unix(stored).format('DD/MM/YYYY')}
                        disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Message">
                    <TextArea rows={4} value={message} disabled/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Subject">
                    <Input
                        placeholder="Subject"
                        size="large"
                        value={subject}
                        onChange={this.handleSubjectChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reply">
                    <TextArea
                        rows={6}
                        value={reply}
                        onChange={this.handleReplyChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    );
  }
}

const ContactDetailed = withRouter(ContactDetailedPre);

export {ContactDetailed};
