import {
  GET_MASTER_NOTE_DATA,
  MASTER_NOTE_ACTIVE_NOTE_CONTENT,
  RESET_MASTER_NOTE_DATA,
} from '../../../actions/types';

const initialState = {
  visible: false,
  title: '',
  introduction: '',
  notesList: [],
  activeNoteContent: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MASTER_NOTE_DATA:
      let data = action.payload;
      return {
        ...state,
        visible: true,
        title: data.master_title,
        introduction: data.introduction,
        notesList: data.notes,
      };
    case MASTER_NOTE_ACTIVE_NOTE_CONTENT:
      return {
        ...state,
        activeNoteContent: action.payload.explanation,
      };
    case RESET_MASTER_NOTE_DATA:
      return {
        ...state,
        visible: false,
      };
    default:
      return initialState;
  }
};
