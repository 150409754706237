import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import {post} from '../../../components/mc_axios/McAxios';

const Dragger = Upload.Dragger;
const {Option} = Select;
const {TextArea} = Input;

const CreateNotificationPre = props => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleCreateNotification = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        let data = {...values};
        data.image_url = data.image_url && data.image_url.length > 0 ? data.image_url[0].response.data.file_url : "";

        post('/api/notifications/notifications/create', data).
            then(response => {
              if (response.data.status === true && response.data.code === 200) {
                message.success('Notification is created successfully');
                props.history.push('/dashboard/notifications/');
              } else message.error('Something went wrong. Please try again');
            }).
            catch(() => message.error('Something went wrong. Please try again'));
      }
    });
  };

  const {getFieldDecorator} = props.form;

  return (
      <Row className="main-content-block" gutter={16}>
        <Col span={24}>
          <Form onSubmit={handleCreateNotification}>
            <Row gutter={32}>
              <Col xs={24} sm={12}>
                <div className="page-header">
                  <Link to={'/dashboard/testimonials/'}>
                    <Icon type="arrow-left" title={'Back'}/>
                  </Link>
                  <Divider type="vertical"/> Create Notification
                </div>
              </Col>
              <Col xs={24} sm={12} className="text-right">
                <Button htmlType="submit">Create</Button>
              </Col>
              <Col span={24}>
                <hr className="mc-mt-10"/>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={24} lg={12}>
                <Row gutter={32}>
                  <Col xs={24} md={12}>
                    <Form.Item label="Title">
                      {
                        getFieldDecorator('title', {
                          rules: [
                            {
                              required: true,
                              message: 'Please notification title!',
                            },
                          ],
                        })(
                            <Input
                                placeholder={'Ex: NEET PG 2020'}
                                size={'large'}
                            />,
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Priority">
                      {
                        getFieldDecorator('priority', {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: 'Please select priority type!',
                            },
                          ],
                        })(
                            <Select
                                size={'large'}
                                placeholder="Select priority"
                            >
                              <Option value={0}>Normal</Option>
                              <Option value={1}>High</Option>
                            </Select>,
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Redirect URL">
                      {
                        getFieldDecorator('click_action', {
                          initialValue: '',
                        })(
                            <Input
                                placeholder={'Ex: https://www.medcampus.io/'}
                                size={'large'}
                            />,
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Notification Image">
                      {
                        getFieldDecorator('image_url', {
                          valuePropName: 'fileList',
                          getValueFromEvent: normFile,
                        })(
                            <Dragger
                                accept=".png, .jpg, .jpeg"
                                action="/api/media/upload"
                                data={{product_type: 0}}
                                listType="picture"
                            >
                              <p className="ant-upload-drag-icon"><Icon type="inbox"/></p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>,
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="Message">
                  {
                    getFieldDecorator('body', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter message!',
                        },
                      ],
                    })(<TextArea rows={11}/>)
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
  );
};

const CreateNotification = Form.create({name: 'create_notification'})(CreateNotificationPre);

export {CreateNotification};
