import React from 'react';
import {Progress} from 'antd';
import '../UserMedBook.css';

const UserLevelCard = ({data}) => {

  const {current_level_xp = 0, level = 0, points_for_next_level = 0} = data;

  const levelPercentage = current_level_xp / points_for_next_level * 100;

  return (
      <div className="stats-card pink-bg">
        <div>
          <Progress
              type="circle"
              format={() => {
                return <img
                    src={'https://assets.medcampus.io/assets/images/level-icon.png'}
                    width={25}
                    alt={'Level'}
                />;
              }}
              percent={levelPercentage}
              width={60}
          />
        </div>
        <div>
          <p>LEVEL</p>
          <p>{level}</p>
        </div>
      </div>
  );
};

export {UserLevelCard};
