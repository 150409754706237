import {get} from '../../components/mc_axios/McAxios';
import {GET_QUESTION_DATA, RESET_QUESTION_DATA} from '../types';
import {message} from 'antd';

export function showQuestionPreview(id) {
  return async dispatch => {
    get('/api/cosmos/questions/' + id).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        dispatch({
          type: GET_QUESTION_DATA,
          payload: response.data.data,
        });
      } else message.error('Something went wrong.');
    });
  };
}

export function resetQuestionData() {
  return {
    type: RESET_QUESTION_DATA,
  };
}
