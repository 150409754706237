import React from 'react';
import {Button, Col, Divider, Form, Icon, Input, message, Row} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import {McCkEditor, SearchTags} from '../../../components';
import {post} from '../../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../../components/util';

class CreateNoteForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: getLocalData('create-note-title', 1),
      explanation: getLocalData('create-note-explanation', 1),
      tags: getLocalData('create-note-tags', 2),
      reference: getLocalData('create-note-reference', 1),
    };
  }

  handleTitleChange = e => {
    const title = e.target.value;
    setLocalData('create-note-title', title, 1);
    this.setState({
      title,
    });
  };

  handleExplanationChange = evt => {
    const explanation = evt.editor.getData();
    setLocalData('create-note-explanation', explanation, 1);
    this.setState({
      explanation,
    });
  };

  handleTagsChange = tags => {
    setLocalData('create-note-tags', tags, 2);
    this.setState({
      tags,
    });
  };

  handleReferenceChange = evt => {
    const reference = evt.editor.getData();
    setLocalData('create-note-reference', reference, 1);
    this.setState({
      reference,
    });
  };

  saveNote = () => {
    this.handleSubmit(0);
  };

  saveAndVerifyNote = () => {
    this.handleSubmit(2);
  };

  handleSubmit = saveType => {
    const {title, explanation, tags, reference} = this.state;

    if (title !== '') {

      let tagsList = [];
      if (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          tagsList.push(tags[i].key);
        }
      }

      let data = {
        title,
        explanation,
        tags: tagsList,
        reference,
        status: saveType,
      };

      post('/api/notes/notes/create', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          setLocalData('create-note-title', '', 1);
          setLocalData('create-note-explanation', '', 1);
          setLocalData('create-note-tags', [], 2);
          setLocalData('create-note-reference', '', 1);
          message.success('Note has been created');
          this.props.history.push('/dashboard/notes/');
        } else message.error('Something went wrong.');
      });
    } else message.warning('Title should not be empty');
  };

  render() {
    const {title, explanation, tags, reference} = this.state;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/notes/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create Note
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={this.saveNote}
                  className="mc-mr-5"
              >
                Save
              </Button>
              <Button
                  type={'primary'}
                  htmlType="button"
                  onClick={this.saveAndVerifyNote}
              >
                Save & Verify
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Title">
                    <Input
                        placeholder="Enter title"
                        size={'large'}
                        value={title}
                        onChange={this.handleTitleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Explanation">
                    <McCkEditor
                        content={explanation}
                        onMcEditorChange={this.handleExplanationChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Tags">
                    <SearchTags
                        selectedTags={tags}
                        onSelectTag={this.handleTagsChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Reference">
                    <McCkEditor
                        type={1}
                        content={reference}
                        onMcEditorChange={this.handleReferenceChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const CreateNote = withRouter(
    Form.create({name: 'create_note'})(CreateNoteForm));

export {CreateNote};
