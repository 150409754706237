import {
  ADD_CHALLENZ_QUESTIONS,
  CHALLENZ_BASIS_CHANGE,
  CHALLENZ_DESCRIPTION_CHANGE,
  CHALLENZ_END_DATE_CHANGE,
  CHALLENZ_FEATURED_IMAGE_CHANGE,
  CHALLENZ_IS_PREMIUM_CHANGE,
  CHALLENZ_IS_PREMIUM_MASTER_NOTE_CHANGE,
  CHALLENZ_IS_VIDEO_EXPLANATION_PREMIUM_CHANGE,
  CHALLENZ_MASTER_NOTE_CHANGE,
  CHALLENZ_SEO_META_DESCRIPTION_CHANGE,
  CHALLENZ_SEO_META_KEYWORDS_CHANGE,
  CHALLENZ_SEO_META_TITLE_CHANGE,
  CHALLENZ_SEO_SLUG_CHANGE,
  CHALLENZ_START_DATE_CHANGE,
  CHALLENZ_SUBJECTS_CHANGE,
  CHALLENZ_SYSTEMS_CHANGE,
  CHALLENZ_TAGS_CHANGE,
  CHALLENZ_TITLE_CHANGE,
  CHALLENZ_TOTAL_QUESTIONS_CHANGE,
  CHALLENZ_VIDEO_EXPLANATION_CHANGE,
  GET_CHALLENZ_DETAILS,
  REMOVE_QUESTION_FROM_CHALLENZ,
  RESET_CHALLENZ_DATA,
  SORT_CHALLENZ_QUESTIONS_LIST,
} from '../types';
import {
  arrayConcat,
  removeArrayItemByIndex,
  slugify,
} from '../../components/util';
import {get, post} from '../../components/mc_axios/McAxios';
import {message} from 'antd';

export function getChallenzData(challenzId) {
  return async dispatch => {
    get('/api/challenzes/challenzes/' + challenzId).then(response => {
      if (response.data.status === true) {
        dispatch({
          type: GET_CHALLENZ_DETAILS,
          payload: {
            challenz: response.data.data.challenz,
            masterNoteName: response.data.data.master_note,
            questions:
                response.data.data.questions !== null
                    ? response.data.data.questions
                    : [],
          },
        });
      } else {
        message.error('Something went wrong.');
      }
    });
  };
}

export function handleEditChallenzFieldChanges(fieldName, data) {
  return async (dispatch, getState) => {
    switch (fieldName) {
      case 'title':
        dispatch({
          type: CHALLENZ_TITLE_CHANGE,
          payload: {
            title: data,
            metaTitle: data,
            slug: slugify(data),
          },
        });
        break;
      case 'premium':
        dispatch({
          type: CHALLENZ_IS_PREMIUM_CHANGE,
          payload: data,
        });
        break;
      case 'subjects':
        dispatch({
          type: CHALLENZ_SUBJECTS_CHANGE,
          payload: data,
        });
        break;
      case 'systems':
        dispatch({
          type: CHALLENZ_SYSTEMS_CHANGE,
          payload: data,
        });
        break;
      case 'tags':
        dispatch({
          type: CHALLENZ_TAGS_CHANGE,
          payload: data,
        });
        break;
      case 'tagsToMetaTags':
        const keywords = data.map(d => d.key);
        const metaKeywords = getState().medchallenge.editChallenz.seoMetaKeywords;
        const newArr = arrayConcat(keywords, metaKeywords);
        dispatch({
          type: CHALLENZ_SEO_META_KEYWORDS_CHANGE,
          payload: newArr,
        });
        break;
      case 'basis':
        dispatch({
          type: CHALLENZ_BASIS_CHANGE,
          payload: data,
        });
        break;
      case 'total-questions':
        dispatch({
          type: CHALLENZ_TOTAL_QUESTIONS_CHANGE,
          payload: data,
        });
        break;
      case 'start-date':
        dispatch({
          type: CHALLENZ_START_DATE_CHANGE,
          payload: data,
        });
        break;
      case 'end-date':
        dispatch({
          type: CHALLENZ_END_DATE_CHANGE,
          payload: data,
        });
        break;
      case 'description':
        dispatch({
          type: CHALLENZ_DESCRIPTION_CHANGE,
          payload: data,
        });
        break;
      case 'featured-image':
        dispatch({
          type: CHALLENZ_FEATURED_IMAGE_CHANGE,
          payload: {
            imgUrl: data.imgUrl,
            imgAltText: data.imgAltText,
          },
        });
        break;
      case 'remove-featured-image':
        dispatch({
          type: CHALLENZ_FEATURED_IMAGE_CHANGE,
          payload: {
            imgUrl: '',
            imgAltText: '',
          },
        });
        break;
      case 'add-video-explanation':
        dispatch({
          type: CHALLENZ_VIDEO_EXPLANATION_CHANGE,
          payload: {
            videoUrl: data.videoUrl,
            videoAltText: data.videoAltText,
          },
        });
        break;
      case 'remove-video-explanation':
        dispatch({
          type: CHALLENZ_VIDEO_EXPLANATION_CHANGE,
          payload: {
            videoUrl: '',
            videoAltText: '',
          },
        });
        break;
      case 'is-premium-video-explanation':
        dispatch({
          type: CHALLENZ_IS_VIDEO_EXPLANATION_PREMIUM_CHANGE,
          payload: data,
        });
        break;
      case 'master-note':
        dispatch({
          type: CHALLENZ_MASTER_NOTE_CHANGE,
          payload: {
            id: data['master-note-id'],
            name: data.children,
          },
        });
        break;
      case 'remove-master-note':
        dispatch({
          type: CHALLENZ_MASTER_NOTE_CHANGE,
          payload: {
            id: '',
            name: '',
          },
        });
        break;
      case 'is-premium-master-note':
        dispatch({
          type: CHALLENZ_IS_PREMIUM_MASTER_NOTE_CHANGE,
          payload: data,
        });
        break;
      case 'sort-questions-list':
        dispatch({
          type: SORT_CHALLENZ_QUESTIONS_LIST,
          payload: data,
        });
        break;
      case 'remove-question-from-challenz':
        let ids = getState().medchallenge.editChallenz.questionIds;
        let questions = getState().medchallenge.editChallenz.questionsList;

        dispatch({
          type: REMOVE_QUESTION_FROM_CHALLENZ,
          payload: {
            questionIds: removeArrayItemByIndex(ids, data),
            questionsList: removeArrayItemByIndex(questions, data),
          },
        });
        break;
      case 'seo-meta-title':
        dispatch({
          type: CHALLENZ_SEO_META_TITLE_CHANGE,
          payload: data,
        });
        break;
      case 'seo-meta-slug':
        dispatch({
          type: CHALLENZ_SEO_SLUG_CHANGE,
          payload: data,
        });
        break;
      case 'seo-meta-description':
        dispatch({
          type: CHALLENZ_SEO_META_DESCRIPTION_CHANGE,
          payload: data,
        });
        break;
      case 'seo-meta-keywords':
        dispatch({
          type: CHALLENZ_SEO_META_KEYWORDS_CHANGE,
          payload: data,
        });
        break;
      default:
        break;
    }
  };
}

export function handleSeoDataChange(field, value) {
  return async dispatch => {
    switch (field) {
      case 'seo-meta-title':
        dispatch({
          type: CHALLENZ_SEO_META_TITLE_CHANGE,
          payload: value,
        });
        break;
      case 'seo-meta-slug':
        dispatch({
          type: CHALLENZ_SEO_SLUG_CHANGE,
          payload: value,
        });
        break;
      case 'seo-meta-description':
        dispatch({
          type: CHALLENZ_SEO_META_DESCRIPTION_CHANGE,
          payload: value,
        });
        break;
      case 'seo-meta-keywords':
        dispatch({
          type: CHALLENZ_SEO_META_KEYWORDS_CHANGE,
          payload: value,
        });
        break;
      default:
        break;
    }
  };
}

export function addChallenzQuestions(questionId, title, status) {
  return async (dispatch, getState) => {
    const {id, questionIds, questionsList, totalQuestions} = getState().medchallenge.editChallenz;
    const found = questionIds.some(el => el === questionId);
    if (found) {
      message.warning('Question is already added.');
      dispatch({
        type: ADD_CHALLENZ_QUESTIONS,
      });
    } else if (totalQuestions === questionIds.length) {
      message.warning('Can\'t add new questions. Limit exceeded.');
    } else {
      let data = {
        challenz_id: id,
        question_id: questionId,
      };

      post('/api/challenzes/challenzes/add_question', data).then(response => {
        if (response.data.code === 200) {
          let ids = questionIds;
          let questions = questionsList === '' ? [] : questionsList;
          ids.push(questionId);
          questions.push({title, question_id: questionId, status});
          message.success('Question added successfully');
          dispatch({
            type: ADD_CHALLENZ_QUESTIONS,
            payload: {
              ids: ids,
              questions: questions,
            },
          });
        } else {
          message.warning('Something went wrong.');
        }
      });
    }
  };
}

export function handleEditChallengeFormReset() {
  return {
    type: RESET_CHALLENZ_DATA,
  };
}
