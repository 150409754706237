import React, {Component} from 'react';
import {Form, Select} from 'antd';
import {getSubjectList} from '../util';

const Option = Select.Option;

export default class Subjects extends Component {
  state = {
    subjects: [],
  };

  componentDidMount() {
    getSubjectList(subjects => {
      this.setState({
        subjects,
      });
    });
  }

  render() {
    const {value, onChange} = this.props;
    const {subjects} = this.state;

    return (
        <Form.Item label="Subject">
          <Select
              mode="multiple"
              placeholder="Select subject"
              size={'large'}
              value={value}
              onChange={onChange}
          >
            {subjects.map(function(subject, index) {
              return (
                  <Option value={subject} key={index}>
                    {subject}
                  </Option>
              );
            })}
          </Select>
        </Form.Item>
    );
  }
}
