import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import axios from 'axios';
import App from './App';
import './index.css';

axios.interceptors.response.use(response => {
  if (
      response.data.status === false &&
      response.data.message === 'http: named cookie not present'
  ) {
    window.location.href = '/';
  }
  return response;
});

ReactDOM.render(
    <CookiesProvider>
      <Router>
        <Provider store={store}>
          <App/>
        </Provider>
      </Router>
    </CookiesProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
