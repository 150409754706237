import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Typography,
} from 'antd';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  addTestQuestions,
  getTestData,
  handleEditTestFieldChanges,
  handleEditTestFormReset,
  handleSeoDataChange,
} from '../../../actions/prepmed_actions/EditTestActions';
import {showQuestionPreview} from '../../../actions/qbank_actions/PreviewQuestionActions';
import {showMasterNotePreview} from '../../../actions/master_notes_actions/PreviewMasterNoteActions';
import {post} from '../../../components/mc_axios/McAxios';
import {
  convertToSeconds,
  createMarkup,
  getSubjectList,
  redirectToEditQuestionPage,
} from '../../../components/util';
import {SortTestQuestions} from '../../../components';

const Search = Input.Search;
const {Option} = Select;
const {Paragraph} = Typography;

class AddQuestionsForm extends React.Component {
  state = {
    subjectsList: [],
    subjectValue: '',
    showQbankList: false,
    searchedQuestionsList: [],
    currentPageNumber: 1,
    paginationStart: 1,
    searchTerm: '',
    totalQbankQuestions: 0,
    questionDistribution: [],
    visibleQDModal: false,
  };

  componentDidMount() {
    const {match, getTestData} = this.props;
    let testId = match.params.testId;
    getSubjectList(subjects => {
      this.setState({
        subjectsList: subjects,
      });
    });
    getTestData(testId);
  }

  componentWillUnmount() {
    this.props.handleEditTestFormReset();
  }

  getQuestionDistributionData = (testId) => {
    post('/api/tests/tests/question_distribution', {'test_id': testId}).
        then(response => {
          let data = response.data.data;
          if (response.data.code === 200 &&
              data.hasOwnProperty('distribution')) {
            this.setState({
              questionDistribution: data,
              visibleQDModal: true,
            });
          }
        });
  };

  handleSubjectChange = value => {
    this.setState({
      subjectValue: value,
    }, () => {
      this.searchQbankQuestions();
    });
  };

  searchQbankQuestions = () => {
    const {basis} = this.props.editTest;
    const {subjectValue, currentPageNumber, searchTerm} = this.state;

    let pData = {
      count: 25,
      page_number: currentPageNumber,
      search_term: searchTerm,
      status: 2,
      subject: subjectValue,
      question_basis: 0,
      pattern: parseInt(basis, 10),
    };

    post('/api/cosmos/questions/pattern_questions', pData).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        if (resData.hasOwnProperty('list') === true) {
          this.setState({
            totalQbankQuestions: resData.count,
            searchedQuestionsList: resData.list,
          });
        } else {
          this.setState({
            totalQbankQuestions: 0,
            searchedQuestionsList: [],
          });
        }
      }
    });
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * 25;
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.searchQbankQuestions();
        },
    );
  };

  handleSubmit = (status) => {
    const {totalQuestions, questionIds, featuredImageUrl} = this.props.editTest;
    if (status === 2) {
      if (totalQuestions > questionIds.length) {
        message.warning(
            'You can\'t publish the test without adding required questions.');
      } else this.updateTest(status);
    } else if (status === 4) {
      if (featuredImageUrl === '') {
        message.warning('Featured image is required. Please add an image.');
      } else this.updateTest(status);
    } else this.updateTest(status);
  };

  updateTest = (currStatus) => {
    const {id, title, isPremium, basis, mode, totalQuestions, totalTime, status, questionIds, correctQuestionMarks, wrongQuestionMarks, startDate, endDate, serum, description, featuredImageUrl, featuredImageAltText, seoMetaTitle, seoSlug, seoMetaDescription, seoMetaKeywords, createdAt, updatedAt} = this.props.editTest;

    let data = {
      test_id: id,
      test_title: title,
      status: currStatus === 2 ? status : currStatus,
      prime: isPremium,
      test_basis: parseInt(basis),
      mode: parseInt(mode),
      number_of_questions: parseInt(totalQuestions),
      total_time: convertToSeconds(totalTime),
      correct_question_marks: parseInt(correctQuestionMarks),
      wrong_question_marks: parseInt(wrongQuestionMarks),
      start_time: moment(startDate, 'DD/MM/YYYY HH:mm:ss').unix(),
      end_time: moment(endDate, 'DD/MM/YYYY HH:mm:ss').unix(),
      serum: parseInt(serum),
      description: description,
      featured_image_url: featuredImageUrl,
      featured_image_alt_text: featuredImageAltText,
      question_ids: questionIds,
      meta_title: seoMetaTitle,
      slug: seoSlug,
      meta_description: seoMetaDescription,
      meta_keywords: seoMetaKeywords,
      created_at: createdAt,
      updated_at: updatedAt,
    };

    post('/api/tests/tests/update', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        if (currStatus === 2) {
          this.publishTest(id);
        } else message.success('Test has been updated');
        this.setState({
          showQbankList: false,
        });
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  publishTest = id => {
    post('/api/tests/tests/publish', {test_id: id}).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Test has been published');
      } else {
        message.error('Something went wrong! Try again.');
      }
    });
  };

  showHideQbankQuestionsList = () => {
    this.setState(prevState => ({
      showQbankList: !prevState.showQbankList,
    }));
  };

  showQDModal = () => {
    const {match} = this.props;
    this.getQuestionDistributionData(match.params.testId);
  };

  hideQDModal = () => {
    this.setState({
      visibleQDModal: false,
    });
  };

  getCount = subject => {
    const {questionDistribution: {distribution}} = this.state;
    let count = 0;
    for (let i = 0; i < distribution.length; i++) {
      if (subject === distribution[i].subject) {
        count = distribution[i].count;
        break;
      }
    }
    return count;
  };

  render() {
    const {
      editTest,
      addTestQuestions,
      showQuestionPreview,
    } = this.props;

    const {
      subjectsList,
      subjectValue,
      showQbankList,
      currentPageNumber,
      paginationStart,
      totalQbankQuestions,
      searchedQuestionsList,
      questionDistribution,
      visibleQDModal,
    } = this.state;

    const testBasis = function() {
      let basis = '';
      switch (editTest.basis) {
        case 1:
          basis = 'NEET PG';
          break;
        case 2:
          basis = 'AIIMS PG';
          break;
        case 3:
          basis = 'USMLE STEP I';
          break;
        case 4:
          basis = 'USMLE STEP II';
          break;
        case 5:
          basis = 'PGI CHANDIGARH';
          break;
        case 6:
          basis = 'JIPMER';
          break;
        case 7:
          basis = 'NIMHANS';
          break;
        case 8:
          basis = 'Custom';
          break;
        case 9:
          basis = 'UPSC CMS';
          break;
        case 10:
          basis = 'Subject Wise Test';
          break;
        default:
          basis = '';
          break;
      }
      return basis;
    };
    const testFullUrl = 'https://www.medcampus.io/prepmed/test/' +
        editTest.seoSlug + '-' + editTest.id;

    return (
        <>
          <Form>
            <Row className="main-content-block" gutter={16}>
              <Col xs={24} sm={12}>
                <div className="page-header">
                  <Link to={'/dashboard/prepmed/'}>
                    <Icon type="arrow-left" title={'Back'}/>
                  </Link>
                  <Divider type="vertical"/> Add Questions
                </div>
              </Col>
              <Col xs={24} sm={12} className="text-right">
                <Button
                    htmlType="button"
                    onClick={() => this.handleSubmit(0)}
                    className="mc-mr-5"
                >
                  Update
                </Button>
                <Button
                    htmlType="button"
                    onClick={() => this.handleSubmit(4)}
                    className="mc-mr-5"
                >
                  Partial Publish
                </Button>
                <Button
                    htmlType="button"
                    onClick={() => this.handleSubmit(2)}
                    className="mc-mr-5"
                >
                  Publish
                </Button>
              </Col>
              <Col span={24}>
                <hr className="mc-mt-10"/>
              </Col>
              <Col span={16}>
                <h2>{editTest.title}</h2>
                <Row>
                  <Col span={8}>
                    <p>Type:</p>
                    <p>Total Questions:</p>
                    <p>Questions Added:</p>
                    <p>url:</p>
                  </Col>
                  <Col span={16}>
                    <p>{testBasis()}</p>
                    <p>{editTest.totalQuestions}</p>
                    <p>{editTest.questionIds.length}</p>
                    <Paragraph className="mc-mb-0" copyable>{testFullUrl}</Paragraph>
                  </Col>
                  <Col span={24}>
                    <Link to={`/dashboard/prepmed/edit-test/${editTest.id}`}>
                      <Button htmlType={'button'} type="primary">Edit</Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col span={8} className="text-center">
                <img
                    src={editTest.featuredImageUrl}
                    alt={editTest.featuredImageAltText}
                    style={{width: '100%'}}
                />
              </Col>
              <Col span={24}>
                <Card
                    type="inner"
                    title="Questions"
                    className={'mc-mt-20 mc-mb-20'}
                    extra={
                      <>
                        <Button
                            htmlType="button"
                            className="mc-mr-10"
                            onClick={this.showHideQbankQuestionsList}
                        >
                          {showQbankList === false ? 'Add from QBank' : 'Back'}
                        </Button>
                        <Button
                            htmlType="button"
                            onClick={this.showQDModal}
                        >View Question Distribution</Button>
                      </>
                    }
                >
                  {showQbankList === true ?
                      (
                          <Row>
                            <Col span={24}>
                              <Select
                                  showSearch
                                  optionFilterProp="children"
                                  className="mc-mr-10 mc-mb-10"
                                  size="large"
                                  style={{width: 200}}
                                  defaultValue={subjectValue}
                                  onChange={this.handleSubjectChange}
                              >
                                <Option value="">Select subject</Option>
                                {subjectsList.map(function(subject, index) {
                                  return (
                                      <Option value={subject} key={index}>
                                        {subject}
                                      </Option>
                                  );
                                })}
                              </Select>
                              <Search
                                  className="mc-mb-10 mc-mt-5"
                                  placeholder="Search for questions"
                                  onSearch={value =>
                                      this.setState({searchTerm: value}, () => {
                                        this.searchQbankQuestions();
                                      })
                                  }
                                  enterButton
                                  size="large"
                                  style={{
                                    width: '100%',
                                    maxWidth: 500,
                                  }}
                              />
                            </Col>
                            {searchedQuestionsList.length > 0 ? (
                                <>
                                  <Col span={24}>
                                    <div className="mc-table-responsive">
                                      <table className="mc-table">
                                        <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Title</th>
                                          <th>Verified</th>
                                          <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {searchedQuestionsList.map(
                                            (question, index) => (
                                                <tr key={question.question_id}>
                                                  <td>{paginationStart +
                                                  index}</td>
                                                  <td onClick={() => redirectToEditQuestionPage(
                                                      question.question_id)}>
                                                    <div
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            question.title,
                                                        )}
                                                    />
                                                  </td>
                                                  <td width={100}>
                                                    {question.status === 2 ? (
                                                        <Icon
                                                            type="check-circle"
                                                            title={'Verified'}
                                                            theme="filled"
                                                            className="mc-icon active"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            type="close-circle"
                                                            title={'Not Verified'}
                                                            className="mc-icon"
                                                        />
                                                    )}
                                                  </td>
                                                  <td width={120}>
                                                    <Icon
                                                        type="eye"
                                                        className="mc-icon-large mc-mr-10"
                                                        title="Preview"
                                                        onClick={() =>
                                                            showQuestionPreview(
                                                                `${question.question_id}`,
                                                            )
                                                        }
                                                    />
                                                    <Icon
                                                        type="plus-circle"
                                                        title="Add question"
                                                        className="mc-icon"
                                                        onClick={() =>
                                                            addTestQuestions(
                                                                question.question_id,
                                                                question.title,
                                                                question.status,
                                                                question.subject,
                                                            )
                                                        }
                                                    />
                                                  </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                  <Col span={24}>
                                    <Pagination
                                        className="mc-mt-15"
                                        showQuickJumper
                                        current={currentPageNumber}
                                        pageSize={25}
                                        total={totalQbankQuestions}
                                        onChange={this.onPageChange}
                                    />
                                  </Col>
                                </>
                            ) : (
                                <Col span={24}>
                                  <Alert
                                      message="Empty List"
                                      type="info"
                                      className="text-center"
                                  />
                                </Col>
                            )}
                          </Row>
                      ) :
                      (editTest.questionsList.length > 0 ?
                          <SortTestQuestions/> :
                          <Alert message="Empty List" type="info"
                                 className="text-center"/>)}
                </Card>
              </Col>
            </Row>
          </Form>
          <Modal
              title="Question Distribution"
              visible={visibleQDModal}
              width={700}
              onOk={this.showQDModal}
              onCancel={this.hideQDModal}
              footer={null}
          >
            {
              questionDistribution.distribution !== undefined ? (
                  questionDistribution.distribution.length > 0 ? (
                      <div className="mc-table-responsive">
                        <table>
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Subject</th>
                            <th>Questions assigned</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            questionDistribution.distribution.map(
                                (data, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{data.subject}</td>
                                      <td>{data.count}</td>
                                    </tr>
                                ))
                          }
                          </tbody>
                        </table>
                      </div>
                  ) : <p>No added questions found</p>) : ''
            }
          </Modal>
        </>
    );
  }
}

const mapStateToProps = state => {
  return state.prepmed;
};

const AddTestQuestions = withRouter(
    connect(
        mapStateToProps,
        {
          getTestData,
          handleEditTestFieldChanges,
          handleSeoDataChange,
          handleEditTestFormReset,
          addTestQuestions,
          showQuestionPreview,
          showMasterNotePreview,
        },
    )(Form.create({name: 'add_questions'})(AddQuestionsForm)),
);

export {AddTestQuestions};
