import React from 'react';
import {Button, Col, Form, Icon, Input, message, Row} from 'antd';
import {post} from '../../components/mc_axios/McAxios';
import './ChangePassword.css';

const ChangePasswordPre = props => {

  const compareToFirstPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else callback();
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const {old_password, new_password} = values;
        if (old_password === new_password) {
          message.warning(
              'Old password and new password shouldn\'t be the same');
        } else post('/api/user-manager/admin/change-password', values).
            then(response => {
              const responseData = response.data;
              if (responseData.status === true && responseData.code === 200) {
                message.success('Password successfully changed');
                props.form.resetFields();
              } else if (responseData.status === false && responseData.code === 400) {
                message.error(responseData.message);
              } else message.error('Something went wrong. Please try again.');
            }).
            catch(() => message.error('Something went wrong. Please try again.'));
      }
    });
  };

  const {getFieldDecorator} = props.form;

  return (
      <>
        <div className="secondary-header">
          <Row>
            <Col span={24} className="secondary-header-left">
              <p>Change Password</p>
            </Col>
          </Row>
        </div>
        <div className="change-password-section">
          <Form onSubmit={handleSubmit} className="change-password-section">
            <Form.Item>
              {
                getFieldDecorator('old_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your old password!',
                    }],
                })(
                    <Input.Password
                        prefix={<Icon
                            type="lock"
                            className="change-password-section-icon"
                        />}
                        size="large"
                        placeholder="Old password"
                    />,
                )
              }
            </Form.Item>
            <Form.Item>
              {
                getFieldDecorator('new_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your new password!',
                    },
                    {
                      min: 8,
                      message: 'Password should contain minimum of 8 letters',
                    },
                    {
                      pattern: '[0-9]',
                      message: 'Password should contain at least 1 number',
                    },
                  ],
                })(
                    <Input.Password
                        prefix={<Icon type="lock"/>}
                        size="large"
                        placeholder="New password"
                    />,
                )
              }
            </Form.Item>
            <Form.Item>
              {
                getFieldDecorator('confirm_new_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your new password!',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                })(
                    <Input.Password
                        prefix={<Icon type="lock"/>}
                        size="large"
                        placeholder="Confirm new password"
                    />,
                )
              }
            </Form.Item>
            <Form.Item>
              <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="change-password-section-button"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
  );
};

const ChangePassword = Form.create({name: 'change_password'})(
    ChangePasswordPre);

export {ChangePassword};
