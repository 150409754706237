import React from 'react';
import {Button, Col, Input, Row, Select} from 'antd';
import {getSubjectList} from '../util';

const Option = Select.Option;
const Search = Input.Search;

class FiltersModified extends React.Component {
  state = {
    subjectsList: [],
  };

  componentDidMount() {
    const {subjectValue, onSubjectChange} = this.props;
    if (subjectValue !== undefined && onSubjectChange !== undefined) {
      getSubjectList(subjects => {
        this.setState({
          subjectsList: subjects,
        });
      });
    }
  }

  render() {
    const {
      countValue,
      onItemsCountChange,
      statusValue,
      onStatusChange,
      verifiedValue,
      onVerifyStatusChange,
      subjectValue,
      onSubjectChange,
      searchTermValue,
      onSearchTermChange,
      onSearchTermSubmit,
      onFiltersReset,
    } = this.props;

    const {subjectsList} = this.state;

    return (
        <Row gutter={16}>
          <Col xs={12} lg={2}>
            <Select
                className="width-fluid mc-mt-5"
                size="large"
                value={countValue}
                onChange={onItemsCountChange}
            >
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
          </Col>
          {
            statusValue !== undefined && onStatusChange !== undefined ? (
                <Col xs={12} lg={5}
                >
                  <Select
                      size="large"
                      className="width-fluid mc-mt-5"
                      value={statusValue}
                      onChange={onStatusChange}
                  >
                    <Option value={0}>Select status</Option>
                    <Option value={1}>Draft</Option>
                    <Option value={2}>Published</Option>
                  </Select>
                </Col>
            ) : null
          }
          {
            verifiedValue !== undefined && onVerifyStatusChange !== undefined ?
                (
                    <Col xs={12} lg={5}
                    >
                      <Select
                          size="large"
                          className="width-fluid mc-mt-5"
                          value={verifiedValue}
                          onChange={onVerifyStatusChange}
                      >
                        <Option value={0}>Select verify status</Option>
                        <Option value={1}>Verified</Option>
                        <Option value={2}>Unverified</Option>
                      </Select>
                    </Col>
                ) : null
          }
          {
            subjectValue !== undefined && onSubjectChange !== undefined ? (
                <Col xs={12} lg={5}>
                  <Select
                      showSearch
                      optionFilterProp="children"
                      value={subjectValue}
                      className="width-fluid mc-mt-5"
                      size="large"
                      onChange={onSubjectChange}
                  >
                    <Option value="">Select subject</Option>
                    {
                      subjectsList.map((subject, index) => {
                        return (
                            <Option value={subject} key={index}>
                              {subject}
                            </Option>
                        );
                      })
                    }
                  </Select>
                </Col>
            ) : null
          }
          <Col xs={12} lg={10}>
            <Search
                placeholder="input search text"
                size="large"
                className="mc-mt-5"
                value={searchTermValue}
                onSearch={onSearchTermSubmit}
                onChange={onSearchTermChange}
            />
          </Col>
          <Col xs={12} lg={2}>
            <Button
                type="primary"
                block
                size="large"
                onClick={onFiltersReset}
                htmlType="button"
                className="mc-mt-5"
            >
              Reset
            </Button>
          </Col>
        </Row>
    );
  }
}

export {FiltersModified};
