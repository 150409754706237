import {
  FETCH_SEARCH_DATA,
  FETCH_SEARCH_FAILURE,
  FETCH_SEARCH_SUCCESS,
  RESET_SEARCH_DATA,
} from '../../../actions/types';

const initialState = {
  tags: [],
  isLoading: false,
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        isLoading: false,
      };

    case FETCH_SEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case RESET_SEARCH_DATA:
      return {...state, ...initialState};
    default:
      return state;
  }
};
