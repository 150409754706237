import React, {Component} from 'react';
import {Form, Select} from 'antd';
import {post} from '../mc_axios/McAxios';
import {debounce} from '../util';

const Option = Select.Option;

class Tags extends Component {
  state = {
    tagsList: [],
  };

  handleTagSearch = debounce(value => {
    this.setState({tagsList: []});
    post('/api/coordinates/coordinates/tags', {tag: value}).then(response => {
      if (response.status === 200) {
        const tempTags = response.data.data.tags ?? [];
        const uniqueTags = [...new Set(tempTags)];
        const tagsList = uniqueTags.map(tag => ({
          text: tag,
          value: tag,
        }));
        this.setState({tagsList});
      }
    });
  }, 1000);

  render() {
    const {value, onChange, onBlur} = this.props;
    const {tagsList} = this.state;

    return (<Form.Item label="Tags">
      <Select
          mode="tags"
          labelInValue
          placeholder="Enter tags"
          filterOption={true}
          value={value}
          onSearch={this.handleTagSearch}
          onChange={onChange}
          onBlur={onBlur}
          size={'large'}
      >
        {tagsList.map(d => (
            <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
    </Form.Item>);
  }
}

export {Tags};
