import React from 'react';
import {Select, Spin} from 'antd';
import {post} from '../mc_axios/McAxios';

const Option = Select.Option;

class SearchNotes extends React.Component {
  state = {
    notesData: [],
    notesList: [],
    fetchingNotes: false,
  };

  handleSearch = value => {
    this.setState({notesList: [], fetchingNotes: true});
    post('/api/notes/notes', {
      count: 100,
      page_number: 1,
      search_term: value,
    }).then(response => {
      if (response.data.status === true) {
        if (response.data.data.notes !== undefined) {
          const notesList = response.data.data.notes.map(note => ({
            text: note.title,
            value: note.note_id,
          }));
          this.setState({notesList, fetchingNotes: false});
        }
      }
    });
  };

  render() {
    const {notesList, fetchingNotes} = this.state;
    const {onSearchNotesChange, fieldIndex} = this.props;
    const notes = notesList.map(note => (
        <Option key={note.text} note-id={note.value}>
          {note.text}
        </Option>
    ));

    return (
        <Select
            showSearch
            showArrow={false}
            labelInValue
            placeholder="Select notes"
            notFoundContent={fetchingNotes ? <Spin size="small"/> : null}
            filterOption={true}
            onSearch={this.handleSearch}
            onChange={(value, option) =>
                onSearchNotesChange(value, option, fieldIndex)
            }
            size={'large'}
        >
          {notes}
        </Select>
    );
  }
}

export {SearchNotes};
