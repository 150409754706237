import React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
  Upload,
} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {McCkEditor, Tags} from '../../../components';
import {post} from '../../../components/mc_axios/McAxios';
import Subjects from '../../../components/subjects/Subjects';
import Systems from '../../../components/systems/Systems';
import {
  arrayConcat,
  getLocalData,
  setLocalData,
  slugify,
} from '../../../components/util';

const {TextArea} = Input;
const Option = Select.Option;
const Dragger = Upload.Dragger;

class CreateMedChallengeForm extends React.Component {
  handlers = {};
  state = {
    title: '',
    isPremium: false,
    subjects: [],
    systems: [],
    tags: [],
    basis: 0,
    totalQuestions: 0,
    startDate: '',
    endDate: '',
    description: '',
    featuredImageUrl: '',
    featuredImageAltText: '',
    metaTitle: '',
    slug: '',
    metaDescription: '',
    metaKeywords: [],
  };

  componentDidMount() {
    let isPremium = getLocalData('create-challenge-isPremium', 1);
    isPremium = isPremium !== '' && isPremium === 'true';

    let challengeBasis = getLocalData('create-challenge-basis', 1);
    challengeBasis = challengeBasis !== '' ? challengeBasis : 0;

    let totalQuestions = getLocalData('create-challenge-totalQuestions', 1);
    totalQuestions = totalQuestions !== '' ? totalQuestions : 0;

    this.setState({
      title: getLocalData('create-challenge-title', 1),
      isPremium,
      subjects: getLocalData('create-challenge-subjects', 2),
      systems: getLocalData('create-challenge-systems', 2),
      tags: getLocalData('create-challenge-tags', 2),
      basis: challengeBasis,
      totalQuestions: totalQuestions,
      startDate: getLocalData('create-challenge-startDate', 1),
      endDate: getLocalData('create-challenge-endDate', 1),
      description: getLocalData('create-challenge-description', 1),
      featuredImageUrl: getLocalData('create-challenge-featuredImageUrl', 1),
      featuredImageAltText: getLocalData(
          'create-challenge-featuredImageAltText', 1),
      metaTitle: getLocalData('create-challenge-metaTitle', 1),
      slug: getLocalData('create-challenge-slug', 1),
      metaDescription: getLocalData('create-challenge-metaDescription', 1),
      metaKeywords: getLocalData('create-challenge-metaKeywords', 2),
    });
  };

  // New methods
  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const {value} = event.target;
        setLocalData('create-challenge-' + name, value, 1);
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        setLocalData('create-challenge-' + name, value, 2);
        this.setState({
          [name]: value,
        });
      };
    }
    return this.handlers[name];
  };

  //title
  handleFillMetaTitleSlug = e => {
    const {value} = e.target;
    const slugifiedTitle = slugify(value);
    setLocalData('create-challenge-metaTitle', value, 1);
    setLocalData('create-challenge-slug', slugifiedTitle, 1);
    this.setState({
      metaTitle: value,
      slug: slugifiedTitle,
    });
  };

  //Tags methods
  handleMetaKeywordsFill = value => {
    const keywords = value.map(d => d.key);
    const newArr = arrayConcat(keywords, this.state.metaKeywords);
    setLocalData('create-challenge-metaKeywords', newArr, 2);
    this.setState({
      metaKeywords: newArr,
    });
  };

  //Date methods
  handleDateChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = (date, dateString) => {
        setLocalData('create-challenge-' + name, dateString, 1);
        this.setState({
          [name]: dateString,
        });
      };
    }
    return this.handlers[name];
  };

  //Editor methods
  handleEditorChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const data = event.editor.getData();
        setLocalData('create-challenge-' + name, data, 1);
        this.setState({
          [name]: data,
        });
      };
    }
    return this.handlers[name];
  };

  handleFeaturedImageChange = info => {
    if (info.file.status === 'done' && info.file.response.status === true) {
      const {file_url, alt_text} = info.file.response.data;
      message.success(`${info.file.name} file uploaded successfully`);
      setLocalData('create-challenge-featuredImageUrl', file_url, 1);
      setLocalData('create-challenge-featuredImageAltText', alt_text, 1);
      this.setState({
        featuredImageUrl: file_url,
        featuredImageAltText: alt_text,
      });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleFeaturedImageRemove = () => {
    setLocalData('create-challenge-featuredImageUrl', '', 1);
    setLocalData('create-challenge-featuredImageAltText', '', 1);
    this.setState({
      featuredImageUrl: '',
      featuredImageAltText: '',
    });
  };

  handleSubmit = () => {
    if (this.state.title !== '') {
      const {title, isPremium, subjects, systems, tags, basis, totalQuestions, startDate, endDate, description, featuredImageUrl, featuredImageAltText, metaTitle, slug, metaDescription, metaKeywords} = this.state;

      let data = {
        challenz_name: title,
        status: 0,
        prime: isPremium,
        subject: subjects,
        system: systems,
        tags: tags.map(item => item.key),
        challenz_basis: parseInt(basis),
        number_of_questions: parseInt(totalQuestions),
        start_time: moment(startDate, 'DD/MM/YYYY').unix(),
        end_time: moment(endDate, 'DD/MM/YYYY').unix(),
        description: description,
        featured_image_url: featuredImageUrl,
        featured_image_alt_text: featuredImageAltText,
        meta_title: metaTitle,
        slug: slug,
        meta_description: metaDescription,
        meta_keywords: metaKeywords,
      };
      post('/api/challenzes/challenzes/create', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          this.handleCreateChallengeFormReset();
          message.success('Challenge has been created');
          this.props.history.push(
              `/dashboard/medchallenz/add-questions/${response.data.data.challenz_id}`);
        } else {
          message.error('Something went wrong! Try again.');
        }
      });
    } else message.warning('Please enter title');
  };

  handleCreateChallengeFormReset = () => {
    setLocalData('create-challenge-title', '', 1);
    setLocalData('create-challenge-isPremium', false, 1);
    setLocalData('create-challenge-subjects', [], 2);
    setLocalData('create-challenge-systems', [], 2);
    setLocalData('create-challenge-tags', [], 2);
    setLocalData('create-challenge-basis', '', 1);
    setLocalData('create-challenge-totalQuestions', '', 1);
    setLocalData('create-challenge-startDate', '', 1);
    setLocalData('create-challenge-endDate', '', 1);
    setLocalData('create-challenge-description', '', 1);
    setLocalData('create-challenge-featuredImageUrl', '', 1);
    setLocalData('create-challenge-featuredImageAltText', '', 1);
    setLocalData('create-challenge-metaTitle', '', 1);
    setLocalData('create-challenge-slug', '', 1);
    setLocalData('create-challenge-metaDescription', '', 1);
    setLocalData('create-challenge-metaKeywords', [], 2);
  };

  render() {
    const {title, isPremium, subjects, systems, tags, basis, totalQuestions, startDate, endDate, description, featuredImageUrl, featuredImageAltText, metaTitle, slug, metaDescription, metaKeywords} = this.state;

    const startDateNew = startDate !== '' ?
        moment(startDate, 'DD/MM/YYYY') :
        undefined;
    const endDateNew = endDate !== '' ?
        moment(endDate, 'DD/MM/YYYY') :
        undefined;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/medchallenz/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Create Challenge
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Button
                  htmlType="button"
                  onClick={this.handleSubmit}
                  className="mc-mr-5"
              >
                Create
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <p className={'mc-mt-10'}>
                    Title:
                    <span className="pull-right">
                                        Premium
                                        <Switch
                                            className="mc-ml-15"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={isPremium}
                                            onChange={this.handleChangeByValue(
                                                'isPremium')}
                                            style={{width: 60}}
                                        />
                                      </span>
                  </p>
                </Col>
                <Col span={24}>
                  <Input
                      placeholder="Enter title"
                      size={'large'}
                      value={title}
                      onChange={this.handleChangeByEvent('title')}
                      onBlur={this.handleFillMetaTitleSlug}
                      className="mc-mb-15"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Subjects
                      value={subjects}
                      onChange={this.handleChangeByValue('subjects')}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <Systems
                      value={systems}
                      onChange={this.handleChangeByValue('systems')}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <Tags
                      value={tags}
                      onChange={this.handleChangeByValue('tags')}
                      onBlur={this.handleMetaKeywordsFill}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Type">
                    <Select
                        size={'large'}
                        value={parseInt(basis)}
                        onChange={this.handleChangeByValue('basis')}
                    >
                      <Option value={0}>Select basis</Option>
                      <Option value={1}>Normal</Option>
                      <Option value={2}>Image</Option>
                      <Option value={3}>Audio</Option>
                      <Option value={4}>Video</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Total Questions">
                    <InputNumber
                        min={0}
                        value={totalQuestions}
                        onChange={this.handleChangeByValue('totalQuestions')}
                        className="width-fluid"
                        size={'large'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Start Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        format={'DD/MM/YYYY'}
                        value={startDateNew}
                        onChange={this.handleDateChange('startDate')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="End Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        format={'DD/MM/YYYY'}
                        value={endDateNew}
                        onChange={this.handleDateChange('endDate')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={description}
                        onMcEditorChange={this.handleEditorChange(
                            'description')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {featuredImageUrl === '' ? (
                      <Form.Item label="Featured Image">
                        <Dragger
                            listType="picture"
                            name="file"
                            accept=".png, .jpg, .jpeg"
                            action="/api/media/upload"
                            data={{
                              product_type: 4,
                            }}
                            onChange={this.handleFeaturedImageChange}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <p className="mc-mt-20">Featured Image:</p>
                        </Col>
                        <Col span={24}>
                          <img
                              src={featuredImageUrl}
                              alt={featuredImageAltText}
                              style={{width: '100%', maxWidth: 400}}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className={'mc-mt-10'}
                              htmlType={'button'}
                              type={'primary'}
                              onClick={this.handleFeaturedImageRemove}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <div className={'seo-section'}>
                <Row gutter={16}>
                  <Col span={24}>
                    <h3>SEO</h3>
                    <hr/>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Title">
                      <Input
                          size={'large'}
                          value={metaTitle}
                          onChange={this.handleChangeByEvent('metaTitle')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Slug">
                      <Input
                          size={'large'}
                          value={slug}
                          onChange={this.handleChangeByEvent('slug')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Description">
                      <TextArea
                          rows={4}
                          value={metaDescription}
                          onChange={this.handleChangeByEvent('metaDescription')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Meta Keywords">
                      <Select
                          mode="tags"
                          size={'large'}
                          value={metaKeywords}
                          onChange={this.handleChangeByValue('metaKeywords')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

const CreateMedChallenge = Form.create({name: 'create_challenge'})(
    CreateMedChallengeForm);

export {CreateMedChallenge};
