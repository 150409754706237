import React, {Component} from 'react';
import {Button, Col, Empty, Pagination, Row, Tag} from 'antd';
import {Link} from 'react-router-dom';
import {post} from '../../components/mc_axios/McAxios';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import moment from 'moment';

class MedChallenge extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('medchallenge-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('medchallenge-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let challenzStatus = getLocalData('medchallenge-status', 1);
    challenzStatus = challenzStatus !== '' ? parseInt(challenzStatus) : 0;

    this.state = {
      challenzesList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      challenzStatus,
      challenzSubject: getLocalData('medchallenge-subject', 1),
      challenzSystem: getLocalData('medchallenge-system', 1),
      challenzSearchTerm: getLocalData('medchallenge-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }),
        () => {
          this.getchallenzesList();
        },
    );
  }

  getchallenzesList = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      challenzSubject,
      challenzSystem,
      challenzStatus,
      challenzSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: challenzSubject,
      system: challenzSystem,
      status: parseInt(challenzStatus),
      search_term: challenzSearchTerm,
    };

    post('/api/challenzes/challenzes', data).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data;
        this.setState({
          totalItems: resData.count,
          challenzesList: resData.challenzes,
        });
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('medchallenge-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getchallenzesList();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('medchallenge-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          challenzStatus: value,
        },
        () => {
          this.getchallenzesList();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('medchallenge-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          challenzSubject: value,
        },
        () => {
          this.getchallenzesList();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('medchallenge-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          challenzSystem: value,
        },
        () => {
          this.getchallenzesList();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('medchallenge-search-term', value, 1);
    this.setState({
      challenzSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getchallenzesList();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('medchallenge-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getchallenzesList();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          challenzStatus: 0,
          challenzSubject: '',
          challenzSystem: '',
          challenzSearchTerm: '',
        },
        () => {
          setLocalData('medchallenge-items-per-page', 25, 1);
          setLocalData('medchallenge-page-number', 1, 1);
          setLocalData('medchallenge-status', 0, 1);
          setLocalData('medchallenge-subject', '', 1);
          setLocalData('medchallenge-system', '', 1);
          setLocalData('medchallenge-search-term', '', 1);
          this.getchallenzesList();
        },
    );
  };

  redirectToAddQuestionsPage = id => {
    this.props.history.push(`/dashboard/medchallenz/add-questions/${id}`);
  };

  getStatusBadge = (status) => {
    switch (status) {
      case 2:
        return (
            <Tag color="#28a745">Published</Tag>
        );
      case 1:
        return (
            <Tag color="gray">Draft</Tag>
        );
      default:
        return (
            <Tag color="#17a2b8">Saved</Tag>
        );
    }
  };

  render() {
    const {
      challenzesList,
      currentPageNumber,
      itemsPerPage,
      challenzStatus,
      challenzSubject,
      challenzSystem,
      challenzSearchTerm,
      totalItems,
      paginationStart,
    } = this.state;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>Challenges</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/medchallenz/create'}>
                    Create Challenge
                  </Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/medchallenz/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={challenzStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={challenzSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={challenzSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={challenzSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {challenzesList !== undefined && challenzesList.length > 0 ? (
                <Row className="mc-mt-10">
                  <Col xs={24}>
                    <div className="mc-table-responsive">
                      <table className="mc-table notes-table">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Updated At</th>
                          <th>Attempted Users</th>
                        </tr>
                        </thead>
                        <tbody>
                        {challenzesList.map((challenz, index) => (
                            <tr key={challenz.challenz_id}>
                              <td>{paginationStart + index}</td>
                              <td
                                  onClick={() =>
                                      this.redirectToAddQuestionsPage(
                                          challenz.challenz_id,
                                      )
                                  }
                              >
                                {challenz.challenz_name}
                              </td>
                              <td>
                                {this.getStatusBadge(challenz.status)}
                              </td>
                              <td>
                                {moment.unix(challenz.updated_at).
                                    format('DD/MM/YYYY')}
                              </td>
                              <td>
                                {
                                  challenz.challenz_completed === undefined ||
                                  challenz.challenz_completed === null ?
                                      0 :
                                      challenz.challenz_completed
                                }
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                        className="mc-mt-15"
                        current={currentPageNumber}
                        pageSize={itemsPerPage}
                        total={totalItems}
                        onChange={this.onPageChange}
                    />
                  </Col>
                </Row>
            ) : (
                <Row gutter={16}>
                  <Col span={24}>
                    <Empty
                        className="mc-mtb-100"
                        image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                        description={<span>No challenges found</span>}
                    />
                  </Col>
                </Row>
            )}
          </div>
        </>
    );
  }
}

export {MedChallenge};
