import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Col, Empty, message, Pagination, Row, Tag} from 'antd';
import moment from 'moment';
import {Filters} from '../../components';
import {post} from '../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../components/util';

const styles = {
  row: {
    cursor: 'pointer',
  },
  firstCol: {
    minWidth: 70,
  },
  secondCol: {
    minWidth: 150,
  },
  thirdCol: {
    minWidth: 200,
    textAlign: 'left',
  },
  fourthCol: {
    minWidth: 120,
  },
  fifthCol: {
    minWidth: 150,
  },
  sixthCol: {
    minWidth: 150,
  },
};

class NotificationsPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('notifications-page-number', 1);
    lastVisitedPageNum = lastVisitedPageNum !== '' ?
        parseInt(lastVisitedPageNum) :
        1;

    let itemsPerPage = getLocalData('notifications-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    this.state = {
      notifications: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      contactsSearchTerm: getLocalData('notifications-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(state => ({
      paginationStart: state.currentPageNumber > 0 ?
          (state.currentPageNumber - 1) * state.itemsPerPage + 1 :
          1,
    }), () => this.getNotifications());
  }

  getNotifications = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      contactsSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      search_term: contactsSearchTerm,
    };

    post('/api/notifications/notifications', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('notifications')) {
          this.setState({
            notifications: resData.notifications,
            totalItems: resData.total_count,
          });
        }
      } else message.error('Something went wrong! Try again.');
    }).catch(() => message.error('Something went wrong! Try again.'));
  };

  handleItemsCount = value => {
    setLocalData('notifications-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getNotifications();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('notifications-search-term', value, 1);
    this.setState({
      contactsSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getNotifications();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('notifications-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getNotifications();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          contactsSearchTerm: '',
        },
        () => {
          setLocalData('notifications-items-per-page', 25, 1);
          setLocalData('notifications-page-number', 1, 1);
          setLocalData('notifications-search-term', '', 1);
          this.getNotifications();
        },
    );
  };

  redirectToEditOfferPage = id => {
    this.props.history.push('/dashboard/notifications/contact-detailed/' + id);
  };

  getStatusBadge = (status) => {
    if (status === 1) {
      return <Tag color="#28a745">Approved</Tag>;
    } else return <Tag color="#17a2b8">Disapproved</Tag>;
  };

  getProductType = prodType => {
    switch (prodType) {
      case 1:
        return 'infoMed';
      case 2:
        return 'medPixx';
      case 3:
        return 'mVideo';
      case 4:
        return 'challenges';
      case 5:
        return 'qBank';
      case 6:
        return 'notes';
      case 7:
        return 'prepMed';
      default:
        return 'Unknown';
    }
  };

  render() {
    const {
      state: {
        notifications,
        currentPageNumber,
        itemsPerPage,
        paginationStart,
        contactsSearchTerm,
        totalItems,
      }, props: {history},
    } = this;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>Notifications</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" htmlType="button">
                  <Link to={'/dashboard/notifications/create-notification/'}>Create
                    Notification</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                searchTermValue={contactsSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {
              notifications.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table">
                          <thead>
                          <tr>
                            <th style={styles.firstCol}>#</th>
                            <th style={styles.secondCol}>Title</th>
                            <th style={styles.thirdCol}>Message</th>
                            <th style={styles.fourthCol}>Priority</th>
                            <th style={styles.fifthCol}>Create Date</th>
                            <th style={styles.sixthCol}>Sent Date</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            notifications.map((notification, index) => {
                              const {notification_id, title, body, priority, created_at, sent_at} = notification;

                              const createdDate = moment.unix(created_at).format('DD MMMM, YYYY');
                              const sentDateText = sent_at === undefined ? 'Not Sent' : moment.unix(sent_at).format('DD MMMM, YYYY');

                              const redirectToEditOfferPage = () => {
                                history.push('/dashboard/notifications/edit-notification/' + notification_id);
                              };

                              return (
                                  <tr key={notification_id} style={styles.row} onClick={redirectToEditOfferPage}>
                                    <td>{paginationStart + index}</td>
                                    <td className="text-left">{title}</td>
                                    <td className="text-left">{body}</td>
                                    <td>{priority ? 'High' : 'Normal'}</td>
                                    <td>{createdDate}</td>
                                    <td>{sentDateText}</td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No notifications found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

const Notifications = withRouter(NotificationsPre);

export {Notifications};
