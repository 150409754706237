import React from 'react';
import {Col, Row} from 'antd';
import '../UserMedBook.css';

const UserMedalsCard = ({data}) => {

  const {gold = 0, silver = 0, bronze = 0} = data;

  return (
      <div className="stats-medal-card dark-blue-bg">
        <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="text-center"
        >
          <Col span={8}>
            <img
                src={'https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/05/07151825/gold-medal.png'}
                width={30}
                alt={'Medal'}
            />
            <p className="mc-mt-5">{gold}</p>
          </Col>
          <Col span={8}>
            <img
                src={'https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/05/07151826/silver-medal.png'}
                width={30}
                alt={'Medal'}
            />
            <p className="mc-mt-5">{silver}</p>
          </Col>
          <Col span={8}>
            <img
                src={'https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/05/07151827/brownz-medal.png'}
                width={30}
                alt={'Medal'}
            />
            <p className="mc-mt-5">{bronze}</p>
          </Col>
        </Row>
      </div>
  );
};

export {UserMedalsCard};
