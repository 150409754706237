import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Button,
  Cascader,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Upload,
} from 'antd';
import {McCkEditor, SearchTags} from '../../../components';
import {get, patch, post} from '../../../components/mc_axios/McAxios';
import {getUserRoleReselector} from '../../../redux/selectors';
import {
  getSubjectList,
  getSystemList,
  SYSTEM_BLOCK_OPTIONS_LIST,
} from '../../../components/util';
import './EditQuestion.css';

const Option = Select.Option;
const InputGrp = Input.Group;
const Dragger = Upload.Dragger;
const ButtonGroup = Button.Group;

class EditQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionId: props.match.params.questionId,
      question: '',
      questionMedia: [],
      isQStockQuestion: false,
      isVerifiedQuestion: false,
      optionsCount: 4,
      optionsContent: ['', '', '', ''],
      explanation: '',
      videoExplanationUrl: '',
      videoExplanationPrime: false,
      subjectsList: [],
      systemsList: [],
      tagsList: [],
      difficulty_level: '',
      segmentBlocks: [],
      notesData: [],
      notesList: [],
      fetchingNotes: false,
      notesPrime: false,
      challenzesList: [],
      assignChallenzData: {
        challenz_id: '',
        challenz_title: '',
      },
      assignedChallenzes: [],
      fetchingChallenzes: false,
      testsList: [],
      assignTestData: {
        test_id: '',
        test_title: '',
        test_basis: 0,
        subject: [],
      },
      assignedTests: [],
      fetchingTests: false,
      pwChallengesList: [],
      assignPWChallengeData: {
        play_id: '',
        play_name: '',
      },
      assignedPWChallenges: [],
      fetchingPWChallenges: false,
      reference: '',
      optionsToolbarVisible: false,
      referenceToolbarVisible: false,
    };
  }

  componentDidMount() {
    getSubjectList(subjects => {
      this.setState({
        subjectsList: subjects,
      });
    });

    getSystemList(systems => {
      this.setState({
        systemsList: systems,
      });
    });

    setTimeout(() => {
      this.getQuestionData();
    }, 2000);
  }

  // Notes
  getNotesList = value => {
    this.setState({fetchingNotes: true});
    post('/api/notes/notes', {
      count: 100,
      page_number: 1,
      search_term: value,
    }).then(response => {
      if (response.data.status === true) {
        if (response.data.data.notes !== undefined) {
          const notesList = response.data.data.notes.map(note => ({
            text: note.title,
            value: note.note_id,
          }));
          this.setState({notesList, fetchingNotes: false});
        }
      }
    });
  };

  handleNotesChange = (value, option) => {
    for (let i = 0; value.length > i; i++) {
      value[i].value = option[i].props['note-id'];
    }
    this.setState({
      notesData: value,
    });
  };

  // Challenge
  handleChallenzChange = (value, option) => {
    let challenzId = option.props['challenz-id'];
    let challenzTitle = option.props['challenz-title'];
    this.setState({
      assignChallenzData: {
        challenz_id: challenzId,
        challenz_title: challenzTitle,
      },
    });
  };

  assignToChallenge = () => {
    const {questionId, assignChallenzData: {challenz_id, challenz_title}} = this.state;
    if (challenz_id && challenz_id !== '') {
      post('/api/challenzes/challenzes/add_question', {
        challenz_id: challenz_id,
        question_id: questionId,
      }).then(response => {
        if (response.data.status === true) {
          message.success('Question has been successfully added to challenge');
          let arr = this.state.assignedChallenzes;
          arr.push({
            challenz_id,
            challenz_title,
          });
          this.setState({
            assignedChallenzes: arr,
          });
        } else {
          message.error('Something went wrong. Try again.');
        }
      });
    } else message.warning('Please select the challenge');
  };

  getChallenzesList = value => {
    if (value.length > 2) {
      this.setState({fetchingChallenzes: true});
      post('/api/challenzes/challenzes', {
        count: 100,
        page_number: 1,
        search_term: value,
      }).then(response => {
        if (response.data.status === true) {
          if (response.data.data.challenzes !== undefined) {
            const challenzesList = response.data.data.challenzes.map(
                challenz => ({
                  text: challenz.challenz_name,
                  value: challenz.challenz_id,
                }));
            this.setState({challenzesList, fetchingChallenzes: false});
          }
        }
      });
    }
  };

  // Test
  handleTestChange = (value, option) => {
    const {form} = this.props;
    let subjects = form.getFieldValue('subject');
    let testId = option.props['test-id'];
    let testTitle = option.props['test-title'];
    let basis = option.props['test-basis'];
    this.setState({
      assignTestData: {
        test_id: testId,
        test_title: testTitle,
        test_basis: basis,
        subject: subjects,
      },
    });
  };

  assignToTest = () => {
    const {assignTestData: {subject, test_id, test_title, test_basis}, questionId} = this.state;
    if (test_id && test_id !== '') {
      let data = {
        test_id,
        question_id: questionId,
        test_basis,
        subject,
      };
      post('/api/tests/tests/add_question', data).then(response => {
        if (response.data.status === true) {
          message.success('Question has been successfully added to Test');
          let arr = this.state.assignedTests;
          arr.push({
            test_id,
            test_title,
          });
          this.setState({
            assignedTests: arr,
          });
        } else {
          message.error('Something went wrong. Try again.');
        }
      });
    } else message.warning('Please select the test');
  };

  getTestsList = value => {
    if (value.length > 2) {
      this.setState({fetchingTests: true});
      post('/api/tests/tests', {
        count: 100,
        page_number: 1,
        search_term: value,
      }).then(response => {
        if (response.data.status === true) {
          if (response.data.data.tests !== undefined) {
            const testsList = response.data.data.tests.map(test => ({
              text: test.test_title,
              value: test.test_id,
              basis: test.test_basis,
            }));
            this.setState({testsList, fetchingTests: false});
          }
        }
      });
    }
  };

  // Play & Win
  handlePWChallengeChange = (value, option) => {
    const playId = option.props['play-id'];
    const playName = option.props['play-name'];
    this.setState({
      assignPWChallengeData: {
        play_id: playId,
        play_name: playName,
      },
    });
  };

  assignToPWChallenge = () => {
    const {questionId, assignPWChallengeData: {play_id, play_name}} = this.state;
    if (play_id && play_id !== '') {
      post('/api/challenzes/plays/add_question', {
        play_id,
        question_id: questionId,
      }).then(response => {
        if (response.data.status === true) {
          message.success('Question has been successfully added to challenge');
          let arr = this.state.assignedPWChallenges;
          arr.push({
            play_id,
            play_name,
          });
          this.setState({
            assignedPWChallenges: arr,
          });
        } else message.error('Something went wrong. Try again.');
      });
    } else message.warning('Please select the Play & Win');
  };

  getPWChallengesList = value => {
    if (value.length > 2) {
      this.setState({
        fetchingPWChallenges: true,
      });

      post('/api/challenzes/plays', {
        count: 100,
        page_number: 1,
        search_term: value,
      }).then(response => {
        if (response.data.status === true && response.data.data.plays !==
            undefined) {
          const pwChallengesList = response.data.data.plays.map(play => ({
            text: play.play_name,
            value: play.play_id,
          }));
          this.setState({
            pwChallengesList,
            fetchingPWChallenges: false,
          });
        }
      });
    }
  };

  getQuestionData = () => {
    get('/api/cosmos/questions/' + this.state.questionId).then(response => {
      if (response.data.code === 200 && response.data.status === true) {
        let resData = response.data.data.question;

        let assignedChallenzes = response.data.data.challenges === null ? [] : response.data.data.challenges;
        let assignedTests = response.data.data.tests === null ? [] : response.data.data.tests;
        let assignedPWChallenges = response.data.data.plays === null ? [] : response.data.data.plays;

        let verified = resData.status === 2;

        let segmentBlock = [];
        if (resData.segment !== undefined) {
          segmentBlock.push(resData.segment);
          segmentBlock.push(resData.block);
        }

        let list = [];
        if (resData.tags !== undefined && resData.tags.length > 0) {
          let tags = resData.tags;
          for (let i = 0; i < tags.length; i++) {
            list.push({
              key: tags[i],
              label: tags[i],
            });
          }
        }

        let nList = [];
        let selectedNotes = [];
        if (resData.notes_ids !== undefined && resData.notes_ids.length > 0) {
          let notes = response.data.data.notes;
          for (let j = 0; j < resData.notes_ids.length; j++) {
            nList.push({
              text: notes[j].title,
              value: notes[j].note_id,
            });
            selectedNotes.push({
              label: notes[j].title,
              key: notes[j].title,
              value: notes[j].note_id,
            });
          }
        }

        this.setState({
          question: resData.title,
          questionBasis: resData.question_basis,
          questionMedia: resData.question_media !== undefined ? resData.question_media : [],
          createdAt: resData.created_at,
          isVerifiedQuestion: verified,
          isQStockQuestion: resData.q_stock,
          optionsCount: resData.options.length,
          optionsContent: resData.options,
          explanation: resData.explanation,
          videoExplanationPrime: resData.video_explanation_prime,
          tagsList: list,
          segmentBlocks: segmentBlock,
          notesData: selectedNotes,
          notesList: nList,
          notesPrime: resData.notes_prime,
          reference: resData.reference,
          assignedChallenzes,
          assignedTests,
          assignedPWChallenges,
        });

        this.props.form.setFieldsValue({
          question_type: resData.question_type,
          correct_answer: resData.correct_answer,
          video_explanation_url: resData.video_explanation_url || "",
          hard_time: resData.hard_time,
          subject: resData.subject,
          system: resData.system,
          concept_level: resData.concept_level !== undefined ? resData.concept_level : 0,
          notes_ids: selectedNotes,
        });
      }
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const {
        questionId,
        createdAt,
        question,
        isVerifiedQuestion,
        isQStockQuestion,
        questionMedia,
        questionBasis,
        optionsContent,
        explanation,
        videoExplanationUrl,
        videoExplanationPrime,
        tagsList,
        segmentBlocks,
        notesData,
        notesPrime,
        reference,
      } = this.state;

      values.question_id = questionId;
      values.created_at = createdAt;
      values.title = question;
      if (isVerifiedQuestion === true) {
        values.status = 2;
      } else {
        values.status = 1;
      }
      values.q_stock = isQStockQuestion;

      let qBasis = 1;
      let mediaUrls = [];

      if (
          values.question_media !== undefined &&
          values.question_media.fileList.length > 0
      ) {
        let media = values.question_media;
        let mediaType = values.question_media.file.type.split('/')[0];
        switch (mediaType) {
          case 'image':
            qBasis = 2;
            break;
          case 'audio':
            qBasis = 3;
            break;
          case 'video':
            qBasis = 4;
            break;
          default:
            qBasis = 1;
            break;
        }

        for (let i = 0; media.fileList.length > i; i++) {
          mediaUrls[i] = media.fileList[i].response.data.file_url;
        }
        values.question_media = mediaUrls;
      } else {
        values.question_media = questionMedia;
        questionMedia.length === 0 ? (qBasis = 1) : (qBasis = questionBasis);
      }

      values.question_basis = qBasis;
      values.concept_level = parseInt(values.concept_level);
      let difficulty = values.concept_level - 1;
      values.difficulty = difficulty > 0 ? difficulty : 0;
      values.options = optionsContent;
      values.explanation = explanation;

      let list = [];
      for (let j = 0; j < tagsList.length; j++) {
        list.push(tagsList[j].key);
      }

      values.tags = list;
      values.video_explanation_prime = videoExplanationPrime;
      values.segment = segmentBlocks[0];
      values.block = segmentBlocks[1];
      let nList = [];
      let notes = notesData;
      for (let k = 0; k < notes.length; k++) {
        nList.push(notes[k].value);
      }
      values.notes_ids = nList;
      values.notes_prime = notesPrime;
      values.reference = reference;

      post('/api/cosmos/questions/update', values).then(response => {
        if (response.data.code === 200 && response.data.status === true) {
          message.success('Question has been updated.');
          // const {history} = this.props;
          // history.push("/dashboard/qbank/");
        } else {
          message.error('Something went wrong.');
        }
      });
    });
  };

  optionFields = () => {
    let optionsCount = this.state.optionsCount;
    let children = [];
    for (let i = 0; i < optionsCount; i++) {
      children.push(
          <McCkEditor
              type={2}
              className="mc-mb-20"
              content={this.state.optionsContent[i]}
              onMcEditorChange={evt =>
                  this.storeOptionData(evt.editor.getData(), i)
              }
              key={i}
          />,
      );
    }
    return children;
  };

  questionOptionsList = () => {
    let optionsList = [];
    for (let i = 1; i <= this.state.optionsCount; i++) {
      optionsList.push(
          <Option key={i} value={i}>
            {i}
          </Option>,
      );
    }
    return optionsList;
  };

  addOptionField = () => {
    let optionsContentList = this.state.optionsContent;
    let newOptionsCount = this.state.optionsCount + 1;
    optionsContentList.push('');

    this.setState({
      optionsCount: newOptionsCount,
      optionsContent: optionsContentList,
    });
  };

  removeOptionField = () => {
    let newOptionsCount = this.state.optionsCount - 1;
    let optionsContentList = this.state.optionsContent.slice(0, -1);
    this.setState({
      optionsCount: newOptionsCount,
      optionsContent: optionsContentList,
    });
  };

  storeOptionData = (content, index) => {
    let optionsContentList = this.state.optionsContent;
    optionsContentList[index] = content;
    this.setState({
      optionsContent: optionsContentList,
    });
  };

  confirmDelete = () => {
    const {assignedChallenzes, assignedTests} = this.state;
    if (assignedTests.length === 0 && assignedChallenzes.length === 0) {
      patch('/api/cosmos/questions/delete', {
        question_id: this.state.questionId,
      }).then(response => {
        if (response.data.status === true) {
          message.success('Question deleted successfully');
          const {history} = this.props;
          history.push('/dashboard/qbank');
        } else {
          message.error('Something went wrong');
        }
      });
    } else {
      message.warning('You can\'t delete already assigned question.');
    }
  };

  cancelDelete = () => {
    message.error('You have canceled the delete');
  };

  handleVerify = value => {
    this.setState({
      isVerifiedQuestion: value,
    });
  };

  handleIsQStockQuestion = value => {
    this.setState({
      isQStockQuestion: value,
    });
  };

  handleQuestionTitle = evt => {
    this.setState({
      question: evt.editor.getData(),
    });
  };

  removeQuestionMedia = () => {
    this.setState({
      questionMedia: [],
      questionBasis: 1,
    });
  };

  handleOptionsToolbarVisible = checked => {
    this.setState({
      optionsToolbarVisible: checked,
    });
  };

  handleQuestionExplanation = evt => {
    this.setState({
      explanation: evt.editor.getData(),
    });
  };

  handleExplanationVideoPrimeStatus = checked => {
    this.setState({
      videoExplanationPrime: checked,
    });
  };

  removeExplanationVideo = () => {
    this.setState(
        {videoExplanationUrl: ''});
  };

  handleTagSelect = value => {
    this.setState({
      tagsList: value,
    });
  };

  handleSegmentBlockChange = value => {
    this.setState({
      segmentBlocks: value,
    });
  };

  handleNotesPrimeStatus = value => {
    this.setState({notesPrime: value});
  };

  handleReferenceToolbarVisible = checked => {
    this.setState({
      referenceToolbarVisible: checked,
    });
  };

  handleReferenceChange = evt => {
    this.setState({
      reference: evt.editor.getData(),
    });
  };

  render() {
    const {form: {getFieldDecorator}, userRole} = this.props;

    const {
      subjectsList,
      systemsList,
      tagsList,
      notesList,
      fetchingNotes,
      challenzesList,
      fetchingChallenzes,
      testsList,
      fetchingTests,
      isVerifiedQuestion,
      isQStockQuestion,
      question,
      questionMedia,
      questionBasis,
      optionsToolbarVisible,
      explanation,
      videoExplanationUrl,
      videoExplanationPrime,
      segmentBlocks,
      notesPrime,
      referenceToolbarVisible,
      reference,
      assignedChallenzes,
      assignedTests,
      pwChallengesList,
      fetchingPWChallenges,
      assignedPWChallenges,
    } = this.state;

    const isRestricted = userRole !== 2;

    const props = {
      name: 'file',
      action: '/api/media/upload',
      data: {
        product_type: 5,
      },
      onChange(info) {
        if (info.file.status === 'done' && info.file.response.status === true) {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    const questionMediaHtml = (
        <Col span={24}>
          <Form.Item label={'Media'}>
            {
              getFieldDecorator('question_media')(
                  <Dragger {...props} listType="picture" multiple>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox"/>
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files
                    </p>
                  </Dragger>,
              )
            }
          </Form.Item>
        </Col>
    );

    const challenzesOptions = challenzesList.map(challenz => (
        <Option
            key={challenz.value}
            challenz-id={challenz.value}
            challenz-title={challenz.text}
        >
          {challenz.text}
        </Option>
    ));

    return (
        <Form>
          <Row gutter={16} className="main-content-block">
            <Col span={12}>
              <div className="page-header">
                <Link to={'/dashboard/qbank/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Question
              </div>
            </Col>
            <Col span={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  onCancel={this.cancelDelete}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  type="primary"
                  htmlType="button"
                  onClick={this.handleSubmit}
              >
                Update
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={17}>
              <Row gutter={32}>
                <Col span={24}>
                  <div className="mc-db mc-mt-5">
                    Question:
                    <span className="pull-right">
                      <span className="mc-mr-10">Verified</span>
                      <Switch
                          checked={isVerifiedQuestion}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={this.handleVerify}
                          className={'qbank-switch-bar'}
                      />
                      <Divider type="vertical"/>
                      <span className="mc-mr-10">QStock</span>
                      <Switch
                          checked={isQStockQuestion}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={this.handleIsQStockQuestion}
                          className={'qbank-switch-bar'}
                      />
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <McCkEditor
                      content={question}
                      onMcEditorChange={this.handleQuestionTitle}
                      className="mc-mt-10"
                  />
                  <br/>
                </Col>
                {
                  questionMedia !== undefined && questionMedia.length > 0 ? (
                      <Col span={24} className="media-list">
                        {
                          questionMedia.map((data, index) => {
                            if (questionBasis === 2) {
                              return (
                                  <Row key={index}>
                                    <Col span={24}>
                                      <img
                                          src={data}
                                          alt={index}
                                          key={index}
                                          className="media-item"
                                      />
                                    </Col>
                                    <Col span={24}>
                                      <Button
                                          htmlType={'button'}
                                          type={'primary'}
                                          size={'small'}
                                          className="mc-mb-20"
                                          onClick={this.removeQuestionMedia}
                                      >
                                        Remove media
                                      </Button>
                                    </Col>
                                  </Row>
                              );
                            } else if (questionBasis === 3) {
                              return (
                                  <Row key={index}>
                                    <Col span={24}>
                                      <audio
                                          controls
                                          src={data}
                                          key={index}
                                          className="media-item"
                                      />
                                    </Col>
                                    <Col span={24}>
                                      <Button
                                          htmlType={'button'}
                                          type={'primary'}
                                          size={'small'}
                                          className="mc-mb-20"
                                          onClick={this.removeQuestionMedia}
                                      >
                                        Remove media
                                      </Button>
                                    </Col>
                                  </Row>
                              );
                            } else if (questionBasis === 4) {
                              return (
                                  <Row key={index}>
                                    <Col span={24}>
                                      <video
                                          controls
                                          src={data}
                                          key={index}
                                          className="media-item"
                                      />
                                    </Col>
                                    <Col span={24}>
                                      <Button
                                          htmlType={'button'}
                                          type={'primary'}
                                          size={'small'}
                                          className="mc-mb-20"
                                          onClick={this.removeQuestionMedia}
                                      >
                                        Remove media
                                      </Button>
                                    </Col>
                                  </Row>
                              );
                            } else return questionMediaHtml;
                          })
                        }
                      </Col>
                  ) : questionMediaHtml
                }
                <Col xs={6}>
                  <p>Options:</p>
                </Col>
                <Col xs={18} className="text-right">
                  <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={this.handleOptionsToolbarVisible}
                  />
                  <Divider type={'vertical'}/>
                  <ButtonGroup size="small">
                    <Button htmlType="button" onClick={this.addOptionField}>
                      +
                    </Button>
                    <Button htmlType="button" onClick={this.removeOptionField}>
                      -
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col
                    xs={24}
                    className={
                      optionsToolbarVisible === false
                          ? 'options-list-block active'
                          : 'options-list-block'
                    }
                >
                  {this.optionFields()}
                </Col>
                <Col span={24}>
                  <Form.Item label="Correct Answer">
                    {getFieldDecorator('correct_answer', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select any answer!',
                        },
                      ],
                    })(
                        <Select
                            placeholder="Select correct answer"
                            size={'large'}
                            mode="multiple"
                        >
                          {this.questionOptionsList()}
                        </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Explanation" className="mc-mt-10">
                    <McCkEditor
                        content={explanation}
                        onMcEditorChange={this.handleQuestionExplanation}
                        className="mc-mt-10"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {/*<p>*/}
                  {/*  Explanation Video:*/}
                  {/*  {*/}
                  {/*    !isRestricted && (*/}
                  {/*        <Switch*/}
                  {/*            className={'pull-right'}*/}
                  {/*            checked={videoExplanationPrime}*/}
                  {/*            checkedChildren="Paid"*/}
                  {/*            unCheckedChildren="Free"*/}
                  {/*            onChange={this.handleExplanationVideoPrimeStatus}*/}
                  {/*        />*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*</p>*/}
                  {/*{*/}
                  {/*  videoExplanationUrl === '' || videoExplanationUrl === undefined ? (*/}
                  {/*      <Form.Item>*/}
                  {/*        {*/}
                  {/*          getFieldDecorator('file')(*/}
                  {/*              <Dragger*/}
                  {/*                  {...props}*/}
                  {/*                  listType="picture"*/}
                  {/*                  accept=".mp4, .MP4 .mkv"*/}
                  {/*              >*/}
                  {/*                <p className="ant-upload-drag-icon">*/}
                  {/*                  <Icon type="inbox"/>*/}
                  {/*                </p>*/}
                  {/*                <p className="ant-upload-text">*/}
                  {/*                  Click or drag file to this area to upload*/}
                  {/*                </p>*/}
                  {/*                <p className="ant-upload-hint">*/}
                  {/*                  Support for a single or bulk upload.*/}
                  {/*                  Strictly prohibit*/}
                  {/*                  from uploading company data or other band*/}
                  {/*                  files*/}
                  {/*                </p>*/}
                  {/*              </Dragger>,*/}
                  {/*          )*/}
                  {/*        }*/}
                  {/*      </Form.Item>*/}
                  {/*  ) : (*/}
                  {/*      <>*/}
                  {/*        <Video*/}
                  {/*            videoSrc={videoExplanationUrl}*/}
                  {/*            videoTitle="Explanation Video"*/}
                  {/*        />*/}
                  {/*        <Button*/}
                  {/*            htmlType={'button'}*/}
                  {/*            type="primary"*/}
                  {/*            className="mc-mt-10"*/}
                  {/*            onClick={this.removeExplanationVideo}*/}
                  {/*        >*/}
                  {/*          Remove*/}
                  {/*        </Button>*/}
                  {/*      </>*/}
                  {/*  )*/}
                  {/*}*/}
                  <Form.Item label="mVideo Id">
                    {
                      getFieldDecorator('video_explanation_url')(
                          <Input placeholder="8d5w9b26g9e7d4s6" size="large"/>,
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={7}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Question Type">
                    {
                      getFieldDecorator('question_type', {
                        rules: [
                          {
                            required: true,
                            message: 'Question type is required',
                          }],
                      })(
                          <Select
                              placeholder="Select question type"
                              size={'large'}
                          >
                            <Option value={1}>Single best answer</Option>
                            <Option value={2}>Multiple true-false</Option>
                            <Option value={3}>Match the following</Option>
                            <Option value={4}>Sequential arrangement</Option>
                            <Option value={5}>Multiple completion</Option>
                            <Option value={6}>Reason assertion</Option>
                            <Option value={7}>Extended matching
                              items/question</Option>
                          </Select>,
                      )
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Time">
                    {
                      getFieldDecorator('hard_time', {initialValue: 30})(
                          <Select size={'large'}>
                            <Option value={30}>30 Seconds</Option>
                            <Option value={45}>45 Seconds</Option>
                            <Option value={60}>1 Minutes</Option>
                            <Option value={75}>1 Min 15 Sec</Option>
                            <Option value={90}>1 Min 30 Sec</Option>
                            <Option value={105}>1 Min 45 Seconds</Option>
                            <Option value={120}>2 Minutes</Option>
                            <Option value={135}>2 Min 15 Sec</Option>
                            <Option value={150}>2 Min 30 Sec</Option>
                            <Option value={165}>2 Min 45 Sec</Option>
                            <Option value={180}>3 Minutes</Option>
                            <Option value={195}>3 Min 15 Sec</Option>
                            <Option value={210}>3 Min 30 Sec</Option>
                            <Option value={225}>3 Min 45 Sec</Option>
                            <Option value={240}>4 Minutes</Option>
                            <Option value={255}>4 Min 15 Sec</Option>
                            <Option value={270}>4 Min 30 Sec</Option>
                            <Option value={285}>4 Min 45 Sec</Option>
                            <Option value={300}>5 Minutes</Option>
                          </Select>,
                      )
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Subject">
                    {
                      getFieldDecorator('subject')(
                          <Select
                              mode="multiple"
                              placeholder="Select subject"
                              size={'large'}
                          >
                            {
                              subjectsList.map((subject, index) => {
                                return (
                                    <Option value={subject} key={index}>
                                      {subject}
                                    </Option>
                                );
                              })
                            }
                          </Select>,
                      )
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="System">
                    {
                      getFieldDecorator('system')(
                          <Select
                              mode="multiple"
                              placeholder="Select system"
                              size={'large'}
                          >
                            {systemsList.map(function(system, index) {
                              return (
                                  <Option value={system} key={index}>
                                    {system}
                                  </Option>
                              );
                            })}
                          </Select>,
                      )
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Tags">
                    <SearchTags
                        selectedTags={tagsList}
                        onSelectTag={this.handleTagSelect}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Concept Level">
                    {
                      getFieldDecorator('concept_level', {
                        initialValue: 0,
                      })(
                          <Select size={'large'}>
                            <Option value={0}>Select any</Option>
                            <Option value={1}>Capsule</Option>
                            <Option value={2}>Cortex</Option>
                            <Option value={3}>Core</Option>
                          </Select>,
                      )
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={'Segments/Blocks'}>
                    <Cascader
                        value={segmentBlocks}
                        options={SYSTEM_BLOCK_OPTIONS_LIST}
                        expandTrigger="hover"
                        onChange={this.handleSegmentBlockChange}
                        placeholder="Please select"
                        size="large"
                    />
                  </Form.Item>
                </Col>
                {
                  !isRestricted && (
                      <Col span={24} className={'mc-pr'}>
                        <Switch
                            className={'qbank-notes-switch'}
                            checked={notesPrime}
                            checkedChildren="Paid"
                            unCheckedChildren="Free"
                            onChange={this.handleNotesPrimeStatus}
                        />
                        <Form.Item label={'Notes'}>
                          {
                            getFieldDecorator('notes_ids')(
                                <Select
                                    mode="multiple"
                                    labelInValue
                                    placeholder="Select notes"
                                    notFoundContent={fetchingNotes &&
                                    <Spin size="small"/>}
                                    filterOption={true}
                                    onSearch={this.getNotesList}
                                    onChange={this.handleNotesChange}
                                    size={'large'}
                                >
                                  {
                                    notesList.map(note => (
                                        <Option key={note.text}
                                                note-id={note.value}>
                                          {note.text}
                                        </Option>
                                    ))
                                  }
                                </Select>,
                            )
                          }
                        </Form.Item>
                      </Col>
                  )
                }
                <Col
                    span={24}
                    className={referenceToolbarVisible === false ?
                        'reference-block active' :
                        'reference-block'}
                >
                  <p>
                    Reference:
                    <Switch
                        className="pull-right"
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={this.handleReferenceToolbarVisible}
                    />
                  </p>
                  <McCkEditor
                      type={1}
                      content={reference}
                      onMcEditorChange={this.handleReferenceChange}
                  />
                </Col>
                {
                  !isRestricted && (
                      <>
                        <Col span={24}>
                          <Form.Item label={'Assign to Challenge'}>
                            <InputGrp compact>
                              <Select
                                  showSearch={true}
                                  showArrow={false}
                                  defaultActiveFirstOption={false}
                                  filterOption={false}
                                  labelInValue
                                  placeholder="Select Challenge"
                                  notFoundContent={fetchingChallenzes &&
                                  <Spin size="small"/>}
                                  onSearch={this.getChallenzesList}
                                  onChange={this.handleChallenzChange}
                                  size={'large'}
                                  className={'qbank-assign-challenge-test-input'}
                              >
                                {challenzesOptions}
                              </Select>
                              <Button
                                  type={'Primary'}
                                  size={'large'}
                                  className={'qbank-assign-challenge-test-btn'}
                                  onClick={this.assignToChallenge}
                              >Add</Button>
                            </InputGrp>
                          </Form.Item>
                        </Col>
                        {
                          assignedChallenzes !== null && (
                              <Col span={24} className={'mc-mt--10 mc-mb-10'}>
                                {
                                  assignedChallenzes.map(challenz => (
                                          <Tag key={challenz.challenz_id}>
                                            <Link
                                                to={'/dashboard/medchallenz/add-questions/' +
                                                challenz.challenz_id}
                                            >{challenz.challenz_title}</Link>
                                          </Tag>
                                      ),
                                  )
                                }
                              </Col>
                          )
                        }
                      </>
                  )
                }
                <Col span={24}>
                  <Form.Item label={'Assign to Test'}>
                    <InputGrp compact>
                      <Select
                          showSearch={true}
                          showArrow={false}
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          labelInValue
                          placeholder="Select Test"
                          notFoundContent={fetchingTests &&
                          <Spin size="small"/>}
                          onSearch={this.getTestsList}
                          onChange={this.handleTestChange}
                          size={'large'}
                          className={'qbank-assign-challenge-test-input'}
                      >
                        {
                          testsList.map(test => (
                              <Option
                                  key={test.value}
                                  test-id={test.value}
                                  test-title={test.text}
                                  test-basis={test.basis}
                              >
                                {test.text}
                              </Option>
                          ))
                        }
                      </Select>
                      <Button
                          type={'Primary'}
                          size={'large'}
                          className={'qbank-assign-challenge-test-btn'}
                          onClick={this.assignToTest}
                      >Add</Button>
                    </InputGrp>
                  </Form.Item>
                </Col>
                {
                  assignedTests !== null && (
                      <Col span={24} className={'mc-mt--10 mc-mb-10'}>
                        {
                          assignedTests.map(test => (
                                  <Tag key={test.test_id}>
                                    <Link
                                        to={'/dashboard/prepmed/add-questions/' +
                                        test.test_id}
                                    >{test.test_title}</Link>
                                  </Tag>
                              ),
                          )
                        }
                      </Col>
                  )
                }
                {
                  !isRestricted && (
                      <>
                        <Col span={24}>
                          <Form.Item label={'Assign to Play & Win'}>
                            <InputGrp compact>
                              <Select
                                  showSearch={true}
                                  showArrow={false}
                                  defaultActiveFirstOption={false}
                                  filterOption={false}
                                  labelInValue
                                  placeholder="Select Play & Win"
                                  notFoundContent={fetchingPWChallenges &&
                                  <Spin size="small"/>}
                                  onSearch={this.getPWChallengesList}
                                  onChange={this.handlePWChallengeChange}
                                  size={'large'}
                                  className={'qbank-assign-challenge-test-input'}
                              >
                                {
                                  pwChallengesList.map(play => (
                                      <Option
                                          key={play.value}
                                          play-id={play.value}
                                          play-name={play.text}
                                      >
                                        {play.text}
                                      </Option>
                                  ))
                                }
                              </Select>
                              <Button
                                  type={'Primary'}
                                  size={'large'}
                                  className={'qbank-assign-challenge-test-btn'}
                                  onClick={this.assignToPWChallenge}
                              >Add</Button>
                            </InputGrp>
                          </Form.Item>
                        </Col>
                        {
                          assignedPWChallenges !== null && (
                              <Col span={24} className={'mc-mt--10 mc-mb-10'}>
                                {
                                  assignedPWChallenges.map(play => (
                                          <Tag key={play.play_id}>
                                            <Link
                                                to={'/dashboard/prepmed/add-questions/' +
                                                play.play_id}>{play.play_name}</Link>
                                          </Tag>
                                      ),
                                  )
                                }
                              </Col>
                          )
                        }
                      </>
                  )
                }
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    userRole: getUserRoleReselector(state),
  };
};

const EditQuestion = withRouter(
    connect(
        mapStateToProps,
        null,
    )(Form.create({name: 'edit-qbank-question'})(EditQuestionForm)),
);

export {EditQuestion};
