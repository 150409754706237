import React from 'react';
import {Select, Spin} from 'antd';
import {connect} from 'react-redux';
import {fetchSearchData} from '../../actions/search_actions/FetchSearchData';
import {debounce} from '../util';

class SearchTagsRedux extends React.Component {
  handleTagSearch = debounce(value => this.props.fetchSearchData(value), 1000) ;

  render() {
    const {
      onSelectTag,
      selectedTags,
      tagSearch,
    } = this.props;

    const Option = Select.Option;

    const searchedTagsOptions = tagSearch.tags.map(tag => (
        <Option key={tag.value}>{tag.text}</Option>
    ));

    return (
        <Select
            mode="tags"
            labelInValue
            placeholder="Enter tags"
            notFoundContent={tagSearch.isLoading ? <Spin size="small"/> : null}
            filterOption={true}
            onSearch={this.handleTagSearch}
            onChange={onSelectTag}
            value={selectedTags}
            size={'large'}
        >
          {searchedTagsOptions}
        </Select>
    );
  }
}

const mapStateToProps = state => {
  return state.search;
};

const SearchTags = connect(
    mapStateToProps,
    {fetchSearchData},
)(SearchTagsRedux);

export {SearchTags};
