import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, Col, Empty, Pagination, Row, Typography} from 'antd';
import {Filters} from '../../components';
import {post} from '../../components/mc_axios/McAxios';
import {getLocalData, setLocalData} from '../../components/util';
import './Medvideo.css';

const {Paragraph} = Typography;

class MedvideoPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('medvideo-page-number', 1);
    lastVisitedPageNum = lastVisitedPageNum !== '' ?
        parseInt(lastVisitedPageNum) :
        1;

    let itemsPerPage = getLocalData('medvideo-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    let medvideoStatus = getLocalData('medvideo-status', 1);
    medvideoStatus = medvideoStatus !== '' ? parseInt(medvideoStatus) : 0;

    this.state = {
      medVideoList: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      medvideoStatus,
      medvideoSubject: getLocalData('medvideo-subject', 1),
      medvideoSystem: getLocalData('medvideo-system', 1),
      medvideoSearchTerm: getLocalData('medvideo-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(
        state => ({
          paginationStart:
              state.currentPageNumber > 0
                  ? (state.currentPageNumber - 1) * state.itemsPerPage + 1
                  : 1,
        }),
        () => {
          this.getMedVideos();
        },
    );
  }

  getMedVideos = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      medvideoSubject,
      medvideoSystem,
      medvideoStatus,
      medvideoSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      subject: medvideoSubject,
      system: medvideoSystem,
      status: parseInt(medvideoStatus),
      search_term: medvideoSearchTerm,
    };

    post('/api/television/videos', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        let medvideoData = response.data.data;
        this.setState({
          medVideoList: medvideoData.list,
          totalItems: medvideoData.count,
        });
      }
    });
  };

  handleItemsCount = value => {
    setLocalData('medvideo-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getMedVideos();
        },
    );
  };

  handleStatusChange = value => {
    setLocalData('medvideo-status', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medvideoStatus: value,
        },
        () => {
          this.getMedVideos();
        },
    );
  };

  handleSubjectChange = value => {
    setLocalData('medvideo-subject', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medvideoSubject: value,
        },
        () => {
          this.getMedVideos();
        },
    );
  };

  handleSystemChange = value => {
    setLocalData('medvideo-system', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          medvideoSystem: value,
        },
        () => {
          this.getMedVideos();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('medvideo-search-term', value, 1);
    this.setState({
      medvideoSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getMedVideos();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('medvideo-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getMedVideos();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          medvideoStatus: 0,
          medvideoSubject: '',
          medvideoSystem: '',
          medvideoSearchTerm: '',
        },
        () => {
          setLocalData('medvideo-items-per-page', 25, 1);
          setLocalData('medvideo-page-number', 1, 1);
          setLocalData('medvideo-status', 0, 1);
          setLocalData('medvideo-subject', '', 1);
          setLocalData('medvideo-system', '', 1);
          setLocalData('medvideo-search-term', '', 1);
          this.getMedVideos();
        },
    );
  };

  redirectToEditPage = id => {
    const {history} = this.props;
    history.push('/dashboard/medvideo/edit/' + id);
  };

  render() {
    const {
      medVideoList,
      currentPageNumber,
      itemsPerPage,
      medvideoStatus,
      medvideoSubject,
      medvideoSystem,
      medvideoSearchTerm,
      totalItems,
    } = this.state;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={12} className="secondary-header-left">
                <p>medVideo</p>
              </Col>
              <Col span={12} className="secondary-header-right">
                <Button type="primary" className="mc-mr-10" htmlType="button">
                  <Link to={'/dashboard/medvideo/create'}>Add Video</Link>
                </Button>
                <Button htmlType="button" type="dashed">
                  <Link to={'/dashboard/medvideo/trash'}>Trash</Link>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                statusValue={medvideoStatus}
                onStatusChange={this.handleStatusChange}
                subjectValue={medvideoSubject}
                onSubjectChange={this.handleSubjectChange}
                systemValue={medvideoSystem}
                onSystemChange={this.handleSystemChange}
                searchTermValue={medvideoSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {medVideoList !== undefined && medVideoList.length > 0 ? (
                <Row gutter={16} className="medvideo-list mc-mt-10">
                  {medVideoList.map(data => (
                      <Col span={6} key={data.video_id}>
                        <Card
                            actions={[
                              <Paragraph title={data.title}
                                         onClick={() => this.redirectToEditPage(
                                             data.video_id)}
                                         ellipsis={{rows: 1, expandable: false}}
                                         className="mvideo-title">{data.title}</Paragraph>]}
                            className="mc-mb-10"
                        >
                          <video
                              poster={data.video_thumbnail}
                              src={data.video_url}
                              controls
                              preload="metadata"
                              style={{height: 150, width: '100%'}}
                          />
                        </Card>
                      </Col>
                  ))}
                  <Col span={24}>
                    <Pagination
                        className="mc-mt-15"
                        current={currentPageNumber}
                        pageSize={itemsPerPage}
                        total={totalItems}
                        onChange={this.onPageChange}
                    />
                  </Col>
                </Row>
            ) : (
                <Empty
                    className="mc-mtb-100"
                    image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                    description={<span>No videos found</span>}
                />
            )}
          </div>
        </>
    );
  }
}

const Medvideo = withRouter(MedvideoPre);

export {Medvideo};
