import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Empty, message, Pagination, Row, Tag} from 'antd';
import {Filters} from '../../components';
import {getLocalData, setLocalData} from '../../components/util';
import {post} from '../../components/mc_axios/McAxios';
import moment from 'moment';

const styles = {
  firstCol: {
    minWidth: 70,
  },
  secondCol: {
    minWidth: 200,
  },
  thirdCol: {
    textAlign: 'left',
  },
  fourthCol: {
    minWidth: 120,
  },
  fifthCol: {
    minWidth: 150,
  },
};

class ContactsPre extends Component {
  constructor(props) {
    super(props);

    let lastVisitedPageNum = getLocalData('contacts-page-number', 1);
    lastVisitedPageNum =
        lastVisitedPageNum !== '' ? parseInt(lastVisitedPageNum) : 1;

    let itemsPerPage = getLocalData('contacts-items-per-page', 1);
    itemsPerPage = itemsPerPage !== '' ? parseInt(itemsPerPage) : 25;

    this.state = {
      contacts: [],
      currentPageNumber: lastVisitedPageNum,
      itemsPerPage,
      totalItems: 0,
      paginationStart: 1,
      contactsSearchTerm: getLocalData('contacts-search-term', 1),
    };
  }

  componentDidMount() {
    this.setState(state => ({
      paginationStart: state.currentPageNumber > 0 ?
          (state.currentPageNumber - 1) * state.itemsPerPage + 1 :
          1,
    }), () => this.getOffers());
  }

  getOffers = () => {
    const {
      itemsPerPage,
      currentPageNumber,
      contactsSearchTerm,
    } = this.state;

    let data = {
      count: itemsPerPage,
      page_number: currentPageNumber,
      search_term: contactsSearchTerm,
    };

    post('/api/support/contact/contacts_list', data).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        const resData = response.data.data;
        if (resData.hasOwnProperty('contacts')) {
          this.setState({
            contacts: resData.contacts,
            totalItems: resData.total_count,
          });
        }
      } else message.error('Something went wrong! Try again.');
    });
  };

  handleItemsCount = value => {
    setLocalData('contacts-items-per-page', value, 1);
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: value,
        },
        () => {
          this.getOffers();
        },
    );
  };

  handleSearchTermChange = e => {
    let value = e.target.value;
    setLocalData('contacts-search-term', value, 1);
    this.setState({
      contactsSearchTerm: value,
    });
  };

  handleSearchTermSubmit = () => {
    this.getOffers();
  };

  onPageChange = pageNumber => {
    let actualPage = Math.max(0, pageNumber - 1);
    let start = actualPage * this.state.itemsPerPage;
    setLocalData('contacts-page-number', pageNumber, 1);
    this.setState(
        {
          currentPageNumber: pageNumber,
          paginationStart: start + 1,
        },
        () => {
          this.getOffers();
        },
    );
  };

  resetFilters = () => {
    this.setState(
        {
          currentPageNumber: 1,
          paginationStart: 1,
          itemsPerPage: 25,
          contactsSearchTerm: '',
        },
        () => {
          setLocalData('contacts-items-per-page', 25, 1);
          setLocalData('contacts-page-number', 1, 1);
          setLocalData('contacts-search-term', '', 1);
          this.getOffers();
        },
    );
  };

  redirectToEditOfferPage = id => {
    this.props.history.push('/dashboard/contacts/contact-detailed/' + id);
  };

  getStatusBadge = (status) => {
    if (status === 1) {
      return <Tag color="#28a745">Completed</Tag>;
    } else return <Tag color="#17a2b8">Pending</Tag>;
  };

  render() {
    const {
      contacts,
      currentPageNumber,
      itemsPerPage,
      paginationStart,
      contactsSearchTerm,
      totalItems,
    } = this.state;

    return (
        <>
          <div className="secondary-header">
            <Row>
              <Col span={24} className="secondary-header-left">
                <p>Contacts</p>
              </Col>
            </Row>
          </div>
          <div className="main-content-block">
            <Filters
                countValue={itemsPerPage}
                onItemsCountChange={this.handleItemsCount}
                searchTermValue={contactsSearchTerm}
                onSearchTermChange={this.handleSearchTermChange}
                onSearchTermSubmit={this.handleSearchTermSubmit}
                onFiltersReset={this.resetFilters}
            />
            {
              contacts !== undefined && contacts.length > 0 ? (
                  <Row className="mc-mt-10">
                    <Col xs={24}>
                      <div className="mc-table-responsive">
                        <table className="mc-table notes-table">
                          <thead>
                          <tr>
                            <th style={styles.firstCol}>#</th>
                            <th style={styles.secondCol}>Name</th>
                            <th style={styles.thirdCol}>Message</th>
                            <th style={styles.fourthCol}>Status</th>
                            <th style={styles.fifthCol}>Date</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            contacts.map((contact, index) => {
                              const {contact_id, name, message, status, stored} = contact;
                              return (
                                  <tr key={contact_id}>
                                    <td>{paginationStart + index}</td>
                                    <td onClick={() => this.redirectToEditOfferPage(
                                        contact_id)}>
                                      {name}
                                    </td>
                                    <td className="text-left">
                                      {message}
                                    </td>
                                    <td>
                                      {this.getStatusBadge(status ? status : 1)}
                                    </td>
                                    <td>
                                      {moment.unix(stored).format('DD/MM/YYYY')}
                                    </td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                          className="mc-mt-15"
                          current={currentPageNumber}
                          pageSize={itemsPerPage}
                          total={totalItems}
                          onChange={this.onPageChange}
                      />
                    </Col>
                  </Row>
              ) : (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Empty
                          className="mc-mtb-100"
                          image="https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/03/06191929/no-data.png"
                          description={<span>No contacts found</span>}
                      />
                    </Col>
                  </Row>
              )
            }
          </div>
        </>
    );
  }
}

const Contacts = withRouter(ContactsPre);

export {Contacts};
