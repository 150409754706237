import React from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {McCkEditor, Tags} from '../../../components';
import {
  getChallenzData,
  handleEditChallengeFormReset,
  handleEditChallenzFieldChanges,
  handleSeoDataChange,
} from '../../../actions/medchallenge_actions/EditChallengeActions';
import {patch, post} from '../../../components/mc_axios/McAxios';
import Subjects from '../../../components/subjects/Subjects';
import Systems from '../../../components/systems/Systems';

const {TextArea} = Input;
const Option = Select.Option;
const Dragger = Upload.Dragger;
const {Paragraph} = Typography;

class EditMedChallengeForm extends React.Component {
  handlers = {};

  componentDidMount() {
    const {match: {params: {challengeId}}, getChallenzData} = this.props;
    getChallenzData(challengeId);
  }

  componentWillUnmount() {
    this.props.handleEditChallengeFormReset();
  }

  handleChangeByEvent = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.props.handleEditChallenzFieldChanges(name, event.target.value);
      };
    }
    return this.handlers[name];
  };

  handleChangeByValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = value => {
        this.props.handleEditChallenzFieldChanges(name, value);
      };
    }
    return this.handlers[name];
  };

  handleChangeByDateValue = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = (moment, dateString) => {
        this.props.handleEditChallenzFieldChanges(name, dateString);
      };
    }
    return this.handlers[name];
  };

  handleEditorChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        const data = event.editor.getData();
        this.props.handleEditChallenzFieldChanges(name, data);
      };
    }
    return this.handlers[name];
  };

  handleSubmit = (status) => {
    if (this.props.editChallenz.title !== '') {
      const {
        editChallenz,
        handleEditChallengeFormReset,
        history,
      } = this.props;

      let data = {
        challenz_id: editChallenz.id,
        challenz_name: editChallenz.title,
        status: status,
        prime: editChallenz.isPremium,
        subject: editChallenz.subjects,
        system: editChallenz.systems,
        tags: editChallenz.tags.map(item => item.key),
        challenz_basis: parseInt(editChallenz.basis),
        number_of_questions: parseInt(editChallenz.totalQuestions),
        start_time: moment(editChallenz.startDate, 'DD/MM/YYYY').unix(),
        end_time: moment(editChallenz.endDate, 'DD/MM/YYYY').unix(),
        description: editChallenz.description,
        featured_image_url: editChallenz.featuredImageUrl,
        featured_image_alt_text: editChallenz.featuredImageAltText,
        question_ids: editChallenz.questionIds,
        video_explanation: editChallenz.videoExplanationUrl,
        video_explanation_alt_text: editChallenz.videoExplanationAltText,
        video_explanation_prime: editChallenz.isVideoExplanationPremium,
        master_note_id: editChallenz.masterNoteId,
        master_note_prime: editChallenz.isMasterNotePremium,
        meta_title: editChallenz.seoMetaTitle,
        slug: editChallenz.seoSlug,
        meta_description: editChallenz.seoMetaDescription,
        meta_keywords: editChallenz.seoMetaKeywords,
        created_at: editChallenz.createdAt,
        updated_at: editChallenz.updatedAt,
      };

      post('/api/challenzes/challenzes/update', data).then(response => {
        if (response.data.status === true && response.data.code === 200) {
          handleEditChallengeFormReset();
          message.success('MedChallenz has been updated');
          history.push(
              `/dashboard/medchallenz/add-questions/${
                  response.data.data.challenz_id
              }`,
          );
        } else message.error('Something went wrong! Try again.');
      });
    } else message.warning('Please enter title');
  };

  confirmDelete = () => {
    patch('/api/challenzes/challenzes/delete', {
      challenz_id: this.props.editChallenz.id,
    }).then(response => {
      if (response.data.status === true && response.data.code === 200) {
        message.success('Notes deleted successfully');
        this.props.history.push('/dashboard/medchallenz/');
      } else message.error('Something went wrong. Try again.');
    });
  };

  render() {
    const {
      editChallenz,
      handleEditChallenzFieldChanges,
    } = this.props;

    const uploaderProps = {
      name: 'file',
      accept: '.png, .jpg, .jpeg',
      action: '/api/media/upload',
      data: {
        product_type: 4,
      },
      onChange(info) {
        if (info.file.status === 'done' && info.file.response.status === true) {
          let fileData = info.file.response.data;
          message.success(`${info.file.name} file uploaded successfully`);
          let data = {
            imgUrl: fileData.file_url,
            imgAltText: fileData.alt_text,
          };
          handleEditChallenzFieldChanges('featured-image', data);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    const startDateNew = editChallenz.startDate !== '' ?
        moment(editChallenz.startDate, 'DD/MM/YYYY') :
        undefined;
    const endDateNew = editChallenz.endDate !== '' ?
        moment(editChallenz.endDate, 'DD/MM/YYYY') :
        undefined;

    const challengeFullUrl = 'https://www.medcampus.io/challenges/' +
        editChallenz.seoSlug + '-' + editChallenz.id;

    return (
        <Form>
          <Row className="main-content-block" gutter={16}>
            <Col xs={24} sm={12}>
              <div className="page-header">
                <Link to={'/dashboard/medchallenz/'}>
                  <Icon type="arrow-left" title={'Back'}/>
                </Link>
                <Divider type="vertical"/> Edit Challenge
              </div>
            </Col>
            <Col xs={24} sm={12} className="text-right">
              <Popconfirm
                  title="Are you sure?"
                  onConfirm={this.confirmDelete}
                  okText="Yes"
                  cancelText="No"
              >
                <Button type="danger" htmlType="button" className="mc-mr-5">
                  Delete
                </Button>
              </Popconfirm>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(0)}
                  className="mc-mr-5"
              >
                Update
              </Button>
              <Button
                  htmlType="button"
                  onClick={() => this.handleSubmit(2)}
                  className="mc-mr-5"
              >
                Publish
              </Button>
            </Col>
            <Col span={24}>
              <hr className="mc-mt-10"/>
            </Col>
            <Col xs={24} lg={15}>
              <Row gutter={16}>
                <Col span={24}>
                  <p className={'mc-mt-10'}>
                    Title:
                    <span className="pull-right">
                      Premium
                      <Switch
                          className="mc-ml-15"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={editChallenz.isPremium}
                          onChange={checked =>
                              handleEditChallenzFieldChanges(
                                  'premium', checked)
                          }
                          style={{width: 60}}
                      />
                    </span>
                  </p>
                </Col>
                <Col span={24}>
                  <Input
                      placeholder="Enter title"
                      size={'large'}
                      value={editChallenz.title}
                      onChange={this.handleChangeByEvent('title')}
                      className="mc-mb-15"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Subjects
                      value={editChallenz.subjects}
                      onChange={this.handleChangeByValue('subjects')}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <Systems
                      value={editChallenz.systems}
                      onChange={this.handleChangeByValue('systems')}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <Tags
                      value={editChallenz.tags}
                      onChange={this.handleChangeByValue('tags')}
                      onBlur={this.handleChangeByValue('tagsToMetaTags')}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Type">
                    <Select
                        size={'large'}
                        value={parseInt(editChallenz.basis)}
                        onChange={this.handleChangeByValue('basis')}
                    >
                      <Option value={0}>Select basis</Option>
                      <Option value={1}>Normal</Option>
                      <Option value={2}>Image</Option>
                      <Option value={3}>Audio</Option>
                      <Option value={4}>Video</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Total Questions">
                    <InputNumber
                        min={0}
                        value={editChallenz.totalQuestions}
                        onChange={this.handleChangeByValue('total-questions')}
                        className="width-fluid"
                        size={'large'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Start Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        format={'DD/MM/YYYY'}
                        value={startDateNew}
                        onChange={this.handleChangeByDateValue('start-date')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="End Date">
                    <DatePicker
                        size={'large'}
                        className="width-fluid"
                        format={'DD/MM/YYYY'}
                        value={endDateNew}
                        onChange={this.handleChangeByDateValue('end-date')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description">
                    <McCkEditor
                        content={editChallenz.description}
                        onMcEditorChange={this.handleEditorChange(
                            'description')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {editChallenz.featuredImageUrl === '' ? (
                      <Form.Item label="Featured Image">
                        <Dragger {...uploaderProps} listType="picture">
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                      </Form.Item>
                  ) : (
                      <Row>
                        <Col span={24}>
                          <p className="mc-mt-20">Featured Image:</p>
                        </Col>
                        <Col span={24}>
                          <img
                              src={editChallenz.featuredImageUrl}
                              alt={editChallenz.featuredImageAltText}
                              style={{width: '100%', maxWidth: 400}}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                              className={'mc-mt-10'}
                              htmlType={'button'}
                              type={'primary'}
                              onClick={() =>
                                  handleEditChallenzFieldChanges(
                                      'remove-featured-image',
                                      '',
                                  )
                              }
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={9}>
              <Row>
                <Col span={24}>
                  <p className="mc-mt-10">Challenge url</p>
                  <Card>
                    <Paragraph
                        className="mc-mb-0"
                        copyable
                    >{challengeFullUrl}</Paragraph>
                  </Card>
                </Col>
                <Col span={24}>
                  <div className={'seo-section'}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <h3>SEO</h3>
                        <hr/>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Meta Title">
                          <Input
                              size={'large'}
                              value={editChallenz.seoMetaTitle}
                              onChange={this.handleChangeByEvent(
                                  'seo-meta-title')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Slug">
                          <Input
                              size={'large'}
                              value={editChallenz.seoSlug}
                              onChange={this.handleChangeByEvent(
                                  'seo-meta-slug')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Meta Description">
                          <TextArea
                              rows={4}
                              value={editChallenz.seoMetaDescription}
                              onChange={this.handleChangeByEvent(
                                  'seo-meta-description')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Meta Keywords">
                          <Select
                              mode="tags"
                              size={'large'}
                              value={editChallenz.seoMetaKeywords}
                              onChange={this.handleChangeByValue(
                                  'seo-meta-keywords')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  }
}

const mapStateToProps = state => {
  return state.medchallenge;
};

const EditMedChallenge = withRouter(
    connect(
        mapStateToProps,
        {
          getChallenzData,
          handleEditChallenzFieldChanges,
          handleSeoDataChange,
          handleEditChallengeFormReset,
        },
    )(Form.create({name: 'edit_medchallenz'})(EditMedChallengeForm)),
);

export {EditMedChallenge};
