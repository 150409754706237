import {
  NOTE_EXPLANATION_CHANGE,
  NOTE_REFERENCE_CHANGE,
  NOTE_TAGS_CHANGE,
  NOTE_TITLE_CHANGE,
  RESET_NOTES_DATA,
} from '../../../actions/types';
import {getLocalData} from '../../../components/util';

const initialState = {
  noteTitle: getLocalData('create-note-title', 1),
  noteExplanation: getLocalData('create-note-explanation', 1),
  noteTags: getLocalData('create-note-tags', 2),
  noteReference: getLocalData('create-note-reference', 1),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTE_TITLE_CHANGE:
      return {
        ...state,
        noteTitle: action.payload,
      };
    case NOTE_EXPLANATION_CHANGE:
      return {
        ...state,
        noteExplanation: action.payload,
      };
    case NOTE_TAGS_CHANGE:
      return {
        ...state,
        noteTags: action.payload,
      };
    case NOTE_REFERENCE_CHANGE:
      return {
        ...state,
        noteReference: action.payload,
      };
    case RESET_NOTES_DATA:
      return {
        state,
      };
    default:
      return state;
  }
};
