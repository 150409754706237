import React from 'react';
import {Progress} from 'antd';
import '../UserMedBook.css';

const UserTrophiesCard = props => {

  const {trophyCount: {count = 0}, trophyStatus: {completedPercentage = 0}} = props;

  return (
      <div className="stats-card lite-blue-bg">
        <div>
          <Progress
              type="circle"
              format={() => {
                return <img
                    src={'https://assets.medcampus.io/assets/images/trophy-icon.png'}
                    width={25}
                    alt={'Trophy'}
                />;
              }}
              percent={completedPercentage}
              width={60}
          />
        </div>
        <div>
          <p>TROPHIES</p>
          <p>{count}</p>
        </div>
      </div>
  );
};

export {UserTrophiesCard};
