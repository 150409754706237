import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {withCookies} from 'react-cookie';
import {Col, Icon, Row} from 'antd';
import {Login, Signup, UpdateAdminData} from './pages';
import {BaseLayout, ScrollToTop} from './components';
import {setUserRole, storeUserId} from './actions/auth_actions/AuthActions';
import {getUserRoleReselector} from './redux/selectors';
import {parseJWT} from './components/util';
import './App.css';
import './components/util.css';
import {AccountDeactivated} from './pages/account-deactivated/AccountDeactivated';

// if (process.env.NODE_ENV === 'development') {
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const App = props => {
  const [data, setData] = useState({
    isUserLoggedIn: false,
    isLoading: true,
    user_info: false,
    deactivate: false,
  });
  const userRole = useSelector(getUserRoleReselector);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = props.cookies.get('token');
    if (token) {
      const {sub, role, user_info, deactivate} = parseJWT(token);
      dispatch(setUserRole(role));
      dispatch(storeUserId(sub));
      setData({
        isUserLoggedIn: true,
        isLoading: false,
        user_info,
        deactivate,
      });
    } else setData({
      isUserLoggedIn: false,
      isLoading: false,
      user_info: false,
      deactivate: false,
    });
  }, []);

  const {isUserLoggedIn, isLoading, user_info, deactivate} = data;

  if (isLoading) return (
      <Row>
        <Col span={24} className="text-center">
          <h3 className='mc-mt-100'>
            <b><Icon type="loading" className="mc-mr-15"/> Loading...</b>
          </h3>
        </Col>
      </Row>
  );

  const asRedirectionUrl = userRole === 2 ?
      '/dashboard/home/' :
      '/dashboard/qbank/';

  const renderDashboardRoute = () => {
    if (isUserLoggedIn) {
      if (deactivate) {
        return <Redirect to={'/deactivated/'}/>;
      } else if (user_info) {
        return <BaseLayout/>;
      } else return <Redirect to={'/update-data/'}/>;
    } else return <Redirect to="/login"/>;
  };

  return (
      <ScrollToTop>
        <Switch>
          <Route
              path="/"
              exact
              render={() =>
                  isUserLoggedIn ?
                      <Redirect to={asRedirectionUrl}/> :
                      <Redirect to="/login"/>
              }
          />
          <Route
              path="/dashboard"
              render={renderDashboardRoute}
          />
          <Route
              path="/login"
              render={() =>
                  isUserLoggedIn ?
                      <Redirect to={asRedirectionUrl}/> :
                      <Login/>
              }
          />
          <Route
              path="/register"
              render={() =>
                  isUserLoggedIn ?
                      <Redirect to={asRedirectionUrl}/> :
                      <Signup/>
              }
          />
          <Route
              path="/update-data/"
              render={() =>
                  isUserLoggedIn ?
                      <UpdateAdminData/> :
                      <Redirect to={'/login/'}/>
              }
          />
          <Route
              path="/deactivated/"
              render={() =>
                  isUserLoggedIn ?
                      <AccountDeactivated/> :
                      <Redirect to={'/login/'}/>
              }
          />
        </Switch>
      </ScrollToTop>
  );
};

export default withCookies(App);
