import {
  RESET_TEST_DATA,
  TEST_BASIS_CHANGE,
  TEST_CORRECT_QUESTION_MARKS_CHANGE,
  TEST_DESCRIPTION_CHANGE,
  TEST_END_DATE_CHANGE,
  TEST_FEATURED_IMAGE_CHANGE,
  TEST_IS_PREMIUM_CHANGE,
  TEST_MODE_CHANGE,
  TEST_SEO_META_DESCRIPTION_CHANGE,
  TEST_SEO_META_KEYWORDS_CHANGE,
  TEST_SEO_META_TITLE_CHANGE,
  TEST_SEO_SLUG_CHANGE,
  TEST_SERUM_CHANGE,
  TEST_START_DATE_CHANGE,
  TEST_TITLE_CHANGE,
  TEST_TOTAL_QUESTIONS_CHANGE,
  TEST_TOTAL_TIME_CHANGE,
  TEST_WRONG_QUESTION_MARKS_CHANGE,
} from '../../actions/types';
import {setLocalData, slugify} from '../../components/util';

export function handleCreateTestFieldChanges(actionField, data) {
  switch (actionField) {
    case 'title':
      return async dispatch => {
        let slugifyTitle = slugify(data);
        setLocalData('create-test-title', data, 1);
        setLocalData('create-test-meta-title', data, 1);
        setLocalData('create-test-meta-slug', slugifyTitle, 1);
        dispatch({
          type: TEST_TITLE_CHANGE,
          payload: {
            title: data,
            metaTitle: data,
            slug: slugifyTitle,
          },
        });
      };
    case 'premium':
      setLocalData('create-test-is-premium', data, 1);
      return {
        type: TEST_IS_PREMIUM_CHANGE,
        payload: data,
      };
    case 'basis':
      setLocalData('create-test-basis', data, 1);
      return {
        type: TEST_BASIS_CHANGE,
        payload: data,
      };
    case 'mode':
      setLocalData('create-test-mode', data, 1);
      return {
        type: TEST_MODE_CHANGE,
        payload: data,
      };
    case 'total-questions':
      setLocalData('create-test-total-questions', data, 1);
      return {
        type: TEST_TOTAL_QUESTIONS_CHANGE,
        payload: data,
      };
    case 'total-time':
      setLocalData('create-test-tt', data, 1);
      return {
        type: TEST_TOTAL_TIME_CHANGE,
        payload: data,
      };
    case 'cqm':
      setLocalData('create-test-cqm', data, 1);
      return {
        type: TEST_CORRECT_QUESTION_MARKS_CHANGE,
        payload: data,
      };
    case 'wqm':
      setLocalData('create-test-wqm', data, 1);
      return {
        type: TEST_WRONG_QUESTION_MARKS_CHANGE,
        payload: data,
      };
    case 'start-date':
      setLocalData('create-test-start-date', data, 1);
      return {
        type: TEST_START_DATE_CHANGE,
        payload: data,
      };
    case 'end-date':
      setLocalData('create-test-end-date', data, 1);
      return {
        type: TEST_END_DATE_CHANGE,
        payload: data,
      };
    case 'serum':
      setLocalData('create-test-serum', data, 1);
      return {
        type: TEST_SERUM_CHANGE,
        payload: data,
      };
    case 'description':
      setLocalData('create-test-description', data, 1);
      return {
        type: TEST_DESCRIPTION_CHANGE,
        payload: data,
      };
    case 'featured-image':
      setLocalData('create-test-featured-image-url', data.imgUrl, 1);
      setLocalData('create-test-featured-image-alt-text', data.imgAltText, 1);
      return {
        type: TEST_FEATURED_IMAGE_CHANGE,
        payload: data,
      };
    case 'remove-featured-image':
      setLocalData('create-test-featured-image-url', '', 1);
      setLocalData('create-test-featured-image-alt-text', '', 1);
      return {
        type: TEST_FEATURED_IMAGE_CHANGE,
        payload: {
          imgUrl: '',
          imgAltText: '',
        },
      };
    case 'slug':
      setLocalData('create-test-meta-slug', data, 1);
      return {
        type: TEST_SEO_SLUG_CHANGE,
        payload: data,
      };
    case 'meta-title':
      setLocalData('create-test-meta-title', data, 1);
      return {
        type: TEST_SEO_META_TITLE_CHANGE,
        payload: data,
      };
    case 'meta-description':
      setLocalData('create-test-meta-description', data, 1);
      return {
        type: TEST_SEO_META_DESCRIPTION_CHANGE,
        payload: data,
      };
    case 'meta-keywords':
      setLocalData('create-test-meta-keywords', data, 2);
      return {
        type: TEST_SEO_META_KEYWORDS_CHANGE,
        payload: data,
      };
    default:
      break;
  }
}

export function handleCreateTestFormReset() {
  setLocalData('create-test-title', '', 1);
  setLocalData('create-test-is-premium', false, 1);
  setLocalData('create-test-basis', 0, 1);
  setLocalData('create-test-mode', 0, 1);
  setLocalData('create-test-total-questions', 0, 1);
  setLocalData('create-test-tt', 0, 1);
  setLocalData('create-test-featured-image-url', 0, 1);
  setLocalData('create-test-featured-image-alt-text', 0, 1);
  setLocalData('create-test-start-date', '', 1);
  setLocalData('create-test-end-date', '', 1);
  setLocalData('create-test-serum', 0, 1);
  setLocalData('create-test-meta-description', '', 1);
  setLocalData('create-test-meta-title', '', 1);
  setLocalData('create-test-meta-slug', '', 1);
  setLocalData('create-test-meta-keywords', [], 2);
  return {
    type: RESET_TEST_DATA,
  };
}
