import {GET_QUESTION_DATA, RESET_QUESTION_DATA} from '../../../actions/types';

const initialState = {
  visible: false,
  title: '',
  basis: 1,
  media: [],
  options: [],
  correctAnswer: [],
  explanation: '',
  explanationVideoUrl: '',
  assignedChallenges: [],
  assignedArenaChallenges: [],
  assignedTests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_DATA:
      const {question, challenges, tests, plays} = action.payload;
      return {
        ...state,
        visible: true,
        title: question.title,
        basis: question.question_basis,
        media: question.question_media,
        options: question.options,
        correctAnswer: question.correct_answer,
        explanation: question.explanation,
        explanationVideoUrl: question.video_explanation_url,
        assignedChallenges: challenges !== undefined && challenges !== null ?
            challenges :
            [],
        assignedArenaChallenges: plays !== undefined && plays !== null ?
            plays :
            [],
        assignedTests: tests !== undefined && tests !== null ? tests : [],
      };
    case RESET_QUESTION_DATA:
      return {
        ...state,
        visible: false,
      };
    default:
      return initialState;
  }
};
